import React, {useState, useRef, Fragment, useEffect} from "react"
import {fetchSession} from "./common_functions";

export function ManageForms(props) {

  const [forms, setForms] = useState(props.forms);

  const [createErrorMessage, setCreateErrorMessage] = useState(null);
  const [createFormName, setCreateFormName] = useState('');

  // We will use this to pop the latest form open when its created
  const [justCreatedFormId, setJustCreatedFormId] = useState(null);

  function handleFormCreate() {
    if(createFormName === '') {
      setCreateErrorMessage('Form name is required.');
      return;
    } else {
      setCreateErrorMessage(null);
    }

    fetchSession(`/settings/forms/create`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({formName: createFormName})
    }).then((response) => response.json())
      .then((result) => {
        setJustCreatedFormId(result.form_id);
        setForms([...forms, result]);
        setCreateFormName('');
    });
  }

  function handleHideArchivedClick() {
    fetchSession(`/settings/forms/user_toggle_hide_forms`, {
      method: 'post',
    }).then((response) => response.json())
      .then((result) => {
        window.location.reload();
    });
  }

  return (
    <Fragment>
      <div className="row c-bg-color no-gutters p-3 mt-3 align-content-center">
        <div className="col-auto mr-3">
          <i class="fal fa-filter"></i>
        </div>

        <div className="col">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" onChange={handleHideArchivedClick}
                   id="hide-archived-switch"
                   defaultChecked={props.hideArchived}/>

            <label className="custom-control-label c-cursor-pointer" htmlFor="hide-archived-switch">
              Hide Archived Forms
            </label>
          </div>
        </div>
      </div>

      <div class="row c-bg-color no-gutters p-3 mt-3">
        <div className="col">
          <div className="row mb-4">
            <div className="col-auto">
              <h3 className="">
                Create a New Form
              </h3>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <input type="text"
                     className={'form-control ' + (createErrorMessage !== null ? 'is-invalid' : '')}
                     placeholder="Form Name"
                     value={createFormName}
                     onChange={(event) => setCreateFormName(event.target.value)}/>
              <div className="invalid-feedback">
                Form name is required.
              </div>
            </div>

            <div className="col-md-auto mt-3 mt-md-0">
              <button className="btn c-txt-btn btn-dark w-100" type="button" onClick={handleFormCreate}>Create Form
              </button>
            </div>
          </div>
        </div>
      </div>

      {forms.filter(form => props.hideArchived ? !form.archived : true).map(form => (
          <FormConfig key={form.form_id} form={form} forms={forms} setForms={setForms}
                      justCreatedFormId={justCreatedFormId}/>
      ))}
    </Fragment>
  )
}


export function FormConfig({form, forms, setForms, justCreatedFormId}) {
  const [createErrorMessage, setCreateErrorMessage] = useState(null);
  const [createFieldName, setCreateFieldName] = useState('');
  const [createFieldRequired, setCreateFieldRequired] = useState(false);
  const [createFieldType, setCreateFieldType] = useState('string');

  const [formNameIsEditing, setFormNameIsEditing] = useState(false);
  const [newFormName, setNewFormName] = useState(form.form_name);
  const [formName, setFormName] = useState(form.form_name);

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const [fields, setFields] = useState(form.fields)

  const [confirmDeleteFieldId, setConfirmDeleteFieldId] = useState(null);

  const [fieldNameEditId, setFieldNameEditId] = useState(null);

  const [actionEnabled, setActionEnabled] = useState(
    'form_action_enabled' in form && form.form_action_enabled);

  const [actionButtonText, setActionButtonText] = useState(
    'form_action_button_text' in form ? form.form_action_button_text : null);

  const [actionModalPrompt, setActionModalPrompt] = useState(
    'form_action_modal_prompt' in form ? form.form_action_modal_prompt : null);

  const [actionDueDate, setActionDueDate] = useState(
    'form_action_due_date' in form ? form.form_action_due_date : null);

  const [isEditingButtonText, setIsEditingButtonText] = useState(false);
  const [isEditingModalPrompt, setIsEditingModalPrompt] = useState(false);
  const [isEditingDueDate, setIsEditingDueDate] = useState(false);

  function handleFieldCreate() {
    if(createFieldName === '') {
      setCreateErrorMessage('Form name is required.');
      return;
    } else {
      setCreateErrorMessage(null);
    }

    fetchSession(`/settings/forms/field/create`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        formId: form.form_id,
        fieldName: createFieldName,
        required: createFieldRequired,
        fieldType: createFieldType,
      })
    }).then((response) => response.json())
      .then((result) => {
        setFields([...fields, result]);
        setCreateFieldName('');
        setCreateFieldType('string');
        setCreateFieldRequired(false);
    });
  }

  function handleFieldOptionCreate(fieldId) {
    const input = document.querySelector(`[data-add-option-field-id='${fieldId}']`)

    if(input.value === '') {
      return;
    }

    fetchSession(`/settings/forms/field/create_subform_option`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        formId: form.form_id,
        optionName: input.value,
        fieldId: fieldId,
      })
    }).then((response) => response.json())
      .then((result) => {
        setFields(result);
        input.value = '';
    });
  }

  function handleFieldOptionDelete(fieldId, optionId) {
    fetchSession(`/settings/forms/field/delete_subform_option`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        formId: form.form_id,
        fieldId: fieldId,
        optionId: optionId
      })
    }).then((response) => response.json())
      .then((result) => {
        setFields(result);
    });
  }

  function handleFormNameChange() {
    if(newFormName === '') {
      setNewFormName(form.form_name);
      return;
    }

    fetchSession(`/settings/forms/${form.form_id}/rename`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        formName: newFormName,
      })
    }).then((response) => response.json())
      .then((result) => {
        setFormName(newFormName);
        setFormNameIsEditing(false);
    });
  }

  function handleFieldPositionChange(field_id, position) {
    //@app.route('/settings/forms/<form_id>/field/<field_id>/move/<position>', methods=['POST'])
    fetchSession(`/settings/forms/${form.form_id}/field/${field_id}/move/${position}`, {
      method: 'post',
    }).then((response) => response.json())
      .then((result) => {
        setFields(result);
    });
  }

  function handleFieldOptionPositionChange(field_id, option_id, position) {
    //@app.route('/settings/forms/<form_id>/field/<field_id>/move/<position>', methods=['POST'])
    fetchSession(`/settings/forms/${form.form_id}/field/${field_id}/option/${option_id}/move/${position}`, {
      method: 'post',
    }).then((response) => response.json())
      .then((result) => {
        setFields(result);
    });
  }

  function handleMultiSelectToggle(field_id) {
    //@app.route('/settings/forms/<form_id>/field/<field_id>/move/<position>', methods=['POST'])
    fetchSession(`/settings/forms/${form.form_id}/field/${field_id}/toggle_multi_select`, {
      method: 'post',
    }).then((response) => response.json())
      .then((result) => {
        setFields(result);
    });
  }


  function handleDeleteForm() {
    fetchSession(`/settings/forms/${form.form_id}/delete`, {
      method: 'post',
    }).then((result) => {
      setForms(forms.filter(x => x.form_id !== form.form_id));
    });
  }

  function handleFieldDelete(fieldId) {
    fetchSession(`/settings/forms/${form.form_id}/field/${fieldId}/delete`, {
      method: 'post',
    }).then((result) => {
      setFields(fields.filter(x => x.field_id !== fieldId));
    });
  }

  function handleFieldNameChange(fieldId, fieldName) {
    fetchSession(`/settings/forms/${form.form_id}/field/${fieldId}/rename`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        fieldName: fieldName,
      })
    }).then((response) => response.json())
      .then((result) => {
        fields.find(x => x['field_id'] === fieldId)['field_name'] = fieldName;
        setFieldNameEditId(null);
    });
  }

  function handleFieldRequiredChange(fieldId, required) {
    fetchSession(`/settings/forms/${form.form_id}/field/${fieldId}/set_required`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        required: required,
      })
    }).then((response) => response.json())
      .then((result) => {
        fields.find(x => x['field_id'] === fieldId)['required'] = required;
    });
  }

  function handleFormActionChange(actionEnabled) {
    fetchSession(`/settings/forms/${form.form_id}/set_action_enabled`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        enabled: actionEnabled,
      })
    }).then((response) => response.json())
      .then((result) => {
        setActionEnabled(actionEnabled);
    });
  }

  function handleActionTextBlur(text) {
    fetchSession(`/settings/forms/${form.form_id}/set_action_button_text`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        buttonText: text,
      })
    }).then((response) => response.json())
      .then((result) => {
        if(text === "") {
          text = null;
        }
        setActionButtonText(text);
        setIsEditingButtonText(false);
    });
  }

  function handleModalPromptBlur(text) {
    fetchSession(`/settings/forms/${form.form_id}/set_action_modal_prompt`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        prompt : text,
      })
    }).then((response) => response.json())
      .then((result) => {
        if(text === "") {
          text = null;
        }
        setActionModalPrompt(text);
        setIsEditingModalPrompt(false);
    });
  }

  function handleDueDateBlur(text) {
    if(isNaN(text)) {
      setIsEditingDueDate(false);
      return
    }
    fetchSession(`/settings/forms/${form.form_id}/set_action_due_date`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        due_date : text,
      })
    }).then((response) => response.json())
      .then((result) => {
        if(text === "") {
          text = null;
        }
        setActionDueDate(text);
        setIsEditingDueDate(false);
    });
  }

  function handleArchivedClick(event, formId) {
    event.target.checked = !event.target.checked;
    fetchSession(`/settings/forms/${form.form_id}/set_archived`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
      })
    }).then((response) => response.json())
      .then((result) => {
        forms.find(e => e.formId === formId).archived = !event.target.checked;
        setForms([...forms]);
    });
  }


  return (
    <div className="card mt-3 c-top-border-black">
      <div className="card-title mb-0">
        <div className="row align-items-center no-gutters border-bottom pt-2 pb-2 pl-0">
          <div className="col-auto c-expand-collapse-btn-container">
            <a className="btn btn-link pr-1 collapsed"
               onClick={(event) => {
                 // Pretty annoying to implement this
                 if(event.target.parentNode.classList.contains('collapsed')) {
                   event.target.parentNode.classList.remove('collapsed');
                 } else {
                   event.target.parentNode.classList.add('collapsed');
                 }
                 $('#' + form.form_id.replaceAll('-', '')).collapse('toggle');
               }}
               role="button"
               aria-expanded="false">
              <i className="fas fa-angle-right c-collapse-btn"></i>
              <i className="fas fa-angle-down c-expand-btn"></i>
            </a>
          </div>
          {formNameIsEditing ? (
            <div className="col">
              <input type="text" className="form-control" style={{width: '100%'}}
                     value={newFormName}
                     autoFocus={true}
                     onBlur={handleFormNameChange}
                     onChange={(event) => setNewFormName(event.target.value)}/>
            </div>
          ) : (
            <div className="col" onClick={() => setFormNameIsEditing(true)}>
              <h2>{formName}</h2>
            </div>
          )}

          {/*TODO OUTBOUND If there is a Project link created, remove above delete button and replace with this */}
          <div className="col-lg-auto mt-2 mt-lg-0 pr-2 d-none">
           Used in [1] <a className="btn-link c-text-underline" href="/settings/manage_project_links">Project Link</a>
            <a className="btn-link pl-1"
               tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-placement="right"
               title="How can I delete this form?"
               data-content="<p>This form is used as at least one project link. If you would like to delete this form, you must first delete all links related to this form</p>"
            >
              <i className="far fa-info-circle text-muted"></i>
            </a>
          </div>
          {/*If there is a Project link created, remove above delete button and replace with this*/}

          <div className="col-auto pr-2">
            <input type="checkbox" checked={form.archived}
                   onChange={() => handleArchivedClick(event, form.formId)} /> Archived
          </div>
          <div className="col-auto pr-2">
            <a href={`/settings/forms/${form.form_id}/download_all`} target="_blank" data-toggle="tooltip" data-placement="top" title="Download All Responses (CSV)">
              <i className="fas fa-download"></i>
            </a>
          </div>
          <div className="col-auto pr-2">
            <a href={`/settings/forms/${form.form_id}/download_all_pdf`} target="_blank" data-toggle="tooltip" data-placement="top" title="Download All Responses (PDF)">
              <i className="fas fa-file-pdf"></i>
            </a>
          </div>
          {!isConfirmingDelete ? (
            <div className="col-auto pr-2">
              <button className="btn btn-link-edit-danger" onClick={() => setIsConfirmingDelete(true)}>
                <i className="far fa-trash-alt"></i> Delete
              </button>
            </div>
          ) : (
            <Fragment>
              <div className="col-auto">
                <button className="btn btn-link pl-1 pr-1" onClick={() => setIsConfirmingDelete(false)}>
                  Cancel
                </button>
                &nbsp;
                <button className="btn btn-link text-danger pl-1 pr-2" onClick={handleDeleteForm}>
                  Confirm Delete?
                </button>
              </div>
            </Fragment>
          )}

          {/* TODO OUTBOUND If this form meets all requirements to make a project link, show this */}
          <div className="col-12 text-right pr-2 mt-2 d-none">
            <a className="btn-link c-text-underline" href="/settings/manage_project_links">Create Project Link</a>
          </div>
          {/*If this form meets all requirements to make a project link, show this*/}

        </div>
      </div>

      <div className={(form.form_id === justCreatedFormId ? '' : 'collapse')} id={form.form_id.replaceAll('-','')}>
        <div className="card-body p-0">
          <div className="row no-gutters pt-0 pl-3 pr-3">
            <div className="col c-internal-heading mt-2">
              Add New Field
            </div>
          </div>

          {/*Add form field*/}
          <div className="row no-gutters pt-3 pr-3 pb-3 pl-5 border-bottom">
            <div className="col-lg mb-2 mb-lg-0 mr-0 mr-lg-2">
              <input type="text"
                     className={'form-control ' + (createErrorMessage !== null ? 'is-invalid' : '')}
                     value={createFieldName}
                     placeholder="New Field Name"
                     data-new-field-name-form-name={formName}
                     onChange={(event) => setCreateFieldName(event.target.value)} />
              <div className="invalid-feedback">
                Field name is required.
              </div>
            </div>

            <div className="col-auto mr-2">
              <select value={createFieldType}
                      className="custom-select"
                      data-new-field-type-form-name={formName}
                      onChange={(event) => setCreateFieldType(event.target.value)}>
                <option value="string">Text Field</option>
                <option value="textarea">Text Box</option>
                <option value="email">Email</option>
                <option value="checkbox">Checkbox</option>
                <option value="dropdown">Dropdown</option>
                <option value="file">File</option>
                <option value="datepicker">Date Picker</option>
              </select>
            </div>

            <div className="col-auto mr-0 mr-lg-3" style={{margin: '5px'}}>
              <div className="row align-items-center no-gutters d-flex flex-nowrap">
                <div className="col-auto pt-1">
                  <input type="checkbox" checked={createFieldRequired}
                         data-new-field-required-form-name={formName}
                         onChange={(event) => setCreateFieldRequired(event.target.checked)} />
                </div>

                <div className="col-auto ml-2">
                  <span className="">Required</span>
                </div>
              </div>
            </div>

            <div className="col-auto ml-auto">
              <button className="btn btn-dark" type="button"
                      data-new-field-submit-form-name={formName}
                      onClick={handleFieldCreate}>
                Add Field
              </button>
            </div>
          </div>

          {/*Add form field*/}
          {fields.sort((a,b) => a['order'] - b['order']).map(field => (
            <div key={field.field_id}
                 className="row border-bottom border-left no-gutters d-flex flex-nowrap align-items-center ml-5">
            {/*<div className="row border-bottom border-left no-gutters d-flex flex-nowrap align-items-center ml-5">*/}
              <div className="col-auto pl-1 pr-1">
                <div className="row no-gutters">
                  <div className="col">
                    <div className="c-icon-container">
                      <div className="c-icon-btn">
                        <a className="text-secondary c-center-element-parent" type="button"
                           onClick={() => handleFieldPositionChange(field.field_id, -1)}>
                          <i className="fal fa-caret-up c-center-element-child"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row no-gutters">
                  <div className="col">
                    <div className="c-icon-container">
                      <div className="c-icon-btn">
                        <a className="text-secondary c-center-element-parent" type="button"
                           onClick={() => handleFieldPositionChange(field.field_id, 1)}>
                          <i className="fal fa-caret-down c-center-element-child"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col pt-2 pb-2 pl-2 pr-3 border-left border-right">
                {/* Dropdown Title Field */}
                <div className="row align-items-center">
                  <div className="col-lg c-internal-heading">
                    <i className="far fa-eye-slash text-danger d-none"></i>
                     {fieldNameEditId === field.field_id ? (
                      <input type="text"
                             className="form-control"
                             defaultValue={field.field_name}
                             onBlur={(event) => handleFieldNameChange(field.field_id, event.target.value)} />
                    ): (
                      <span className="c-cursor-pointer" onClick={() => setFieldNameEditId(field.field_id)}>
                        {field.field_name}
                      </span>
                    )}
                  </div>

                  <div className="col-lg-auto mt-2 mt-lg-0">
                     {
                      (() => {
                        switch(field.field_type) {
                          case 'string': return <span>Text Field</span>
                          case 'email': return <span>Email</span>
                          case 'textarea': return <span>Text Box</span>
                          case 'checkbox': return <span>Checkbox</span>
                          case 'dropdown': return <span>Dropdown</span>
                          case 'file': return <span>File</span>
                          case 'datepicker': return <span>Date Picker</span>
                        }
                      })()
                    }
                  </div>

                  <div className="col-lg-auto mt-2 mt-lg-0">
                    <div className="row align-items-center no-gutters d-flex flex-nowrap">
                      <div className="col-auto pt-1">
                        <input type="checkbox" defaultChecked={field.required}
                               onChange={(event) => handleFieldRequiredChange(field.field_id, event.target.checked)} />
                      </div>
                      <div className="col-auto ml-2">
                        <span className="">Required</span>
                      </div>
                    </div>
                  </div>
                </div>


                {field.field_type === 'dropdown' && (
                  <Fragment>
                    <div className="row align-items-center">
                      {field.field_options.length === 0 ? (
                        <div className="col text-danger">
                          <i className="fas fa-asterisk c-font-8"></i> Must add at least 1 dropdown option for this field to
                          show up in the form
                        </div>
                      ) : (
                        <div className="col-auto">
                          <div className="row align-items-center no-gutters d-flex flex-nowrap">
                            <div className="col-auto pt-1">
                              <input type="checkbox" defaultChecked={field.field_multi_select}
                                     onClick={() => handleMultiSelectToggle(field.field_id)}/>
                            </div>
                            <div className="col-auto ml-2">
                              <span className="">Allow Multi-Select</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Dropdown Configure */}

                    {/* Dropdown additional input */}
                    {field.field_options.sort((a,b) => a['order'] - b['order']).map(option => (
                      <div key={option.option_id} className="row align-items-center no-gutters border-top">
                        {/* carets */}
                        <div className="col-auto pr-1">
                          <div className="row no-gutters">
                            <div className="col">
                              <div className="c-icon-container">
                                <div className="c-icon-btn">
                                  <a className="text-secondary c-center-element-parent" type="button"
                                     onClick={() => handleFieldOptionPositionChange(field.field_id, option.option_id, -1)}>
                                    <i className="fal fa-caret-up c-center-element-child"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row no-gutters">
                            <div className="col">
                              <div className="c-icon-container">
                                <div className="c-icon-btn">
                                  <a className="text-secondary c-center-element-parent" type="button"
                                     onClick={() => handleFieldOptionPositionChange(field.field_id, option.option_id, 1)}>
                                    <i className="fal fa-caret-down c-center-element-child"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* carets */}

                        <div className="col">
                          {option.option_name}
                        </div>

                        <div className="col-auto">
                          <button className="btn btn-link-edit-danger pt-1 pb-1"
                                  onClick={() => handleFieldOptionDelete(field.field_id, option.option_id)}>
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    ))}
                    {/* Dropdown additional input */}

                    {/*Add form field*/}
                    <div className="row no-gutters pt-2 border-top">
                      <div className="col-lg mb-2 mb-lg-0 mr-0 mr-lg-2">
                        <input type="text"
                               className={'form-control ' + (createErrorMessage !== null ? 'is-invalid' : '')}
                               placeholder="Dropdown option"
                               data-add-option-field-id={field.field_id}
                               data-new-optionfield-name-form-name={formName}
                               data-new-optionfield-name-field-name={field.field_name} />
                        <div className="invalid-feedback">
                          Dropdown option is required.
                        </div>
                      </div>

                      <div className="col-auto ml-auto">
                        <button className="btn btn-outline-dark" type="button"
                                data-new-option-submit={field.field_name}
                                onClick={() => handleFieldOptionCreate(field.field_id)}>
                          Add Option
                        </button>
                      </div>
                    </div>
                    {/*Add form field*/}
                  </Fragment>
                )}
              </div>

              <div className="col-auto pr-2 pl-2">
                {confirmDeleteFieldId !== field.field_id ? (
                  <button className="btn btn-link-edit-danger"
                          onClick={() => setConfirmDeleteFieldId(field.field_id)}>
                    <i className="far fa-trash-alt"></i>
                  </button>
                ) : (
                  <Fragment>
                    <button className="btn btn-link pl-1 pr-1"
                            onClick={() => setConfirmDeleteFieldId(null)}>
                      Cancel
                    </button>
                    &nbsp;
                    <button className="btn btn-link text-danger pl-1 pr-1" onClick={() => handleFieldDelete(field.field_id)}>
                      Confirm Delete?
                    </button>
                  </Fragment>
                )}
              </div>
            </div>
          ))}


          {/*Enable Action*/}
          {/*fixing double border with negative margin if the form needs to be indented*/}
          <div className="row pt-3 pl-3 pr-4 pb-0 no-gutters align-items-center d-flex flex-nowrap border-top c-border-margin-fix">
            <div className="col-auto c-internal-heading pr-2">
              Turn this form into an actionable link for collaborators on any project
            </div>

            <div className="col-auto">
              <span className="wistia_embed wistia_async_fndk30khc2 popover=true popoverContent=link">
                <a href="#"><i className="fal fa-question-circle"></i></a>
              </span>
            </div>
          </div>

          {/* Enable Action checkbox */}
          <div className="row align-items-center pl-3 pr-3 pb-3 mt-2">
            {/*checkbox option*/}
            <div className="col-auto pt-1">
              <input type="checkbox" checked={actionEnabled}
                     data-action-enabled-form-name={formName}
                     onChange={(event) => handleFormActionChange(event.target.checked)} />
            </div>

            <div className="col-auto pl-0">
              <span className="">Enable Action</span>
            </div>
          </div>
          {/* Enable Action checkbox */}

          {/* Confirm remove button */}
          {/* TODO: hook up the confirm disable button */}
          <div className="row pl-3 pr-3 pb-3 mt-2 d-none">
            <div className="col">
              <button className="btn btn-link text-danger p-0 pr-3">
                Confirm disable action? Yes
              </button>
            </div>
            <div className="col-auto">
              <button className="btn btn-link text-secondary p-0 pr-3">
                Cancel
              </button>
            </div>
          </div>
          {/* Confirm remove button */}

          <div className={'row no-gutters pt-0 pl-5 pr-3 border-top d-none-feature ' + (!actionEnabled ? 'd-none': '')}>
            <div className="col c-internal-heading mt-2 mb-3">
              Customize appearance
            </div>
          </div>

          {/* Customize appearance enable action options - button text */}
          <div className={'row no-gutters align-items-center pt-3 pl-5 pr-3 border-top ' + (!actionEnabled ? 'd-none': '')}>
            <div className="col-auto c-internal-heading mr-4 mb-2">
              Action Link Text
            </div>

            <div className="col" onClick={() => setIsEditingButtonText(true)}>
              <div className="row align-items-center d-flex flex-nowrap">
                <div className="col pr-0">
                  {isEditingButtonText ? (
                    <input type="text" className="form-control"  placeholder={formName}
                           defaultValue={actionButtonText}
                           autoFocus={true}
                           onBlur={(event) => handleActionTextBlur(event.target.value)} />
                  ) : (
                    <div className="text-right" style={{cursor: 'pointer'}}>
                      <a className="btn c-btn-link text-primary pl-0 pt-1 pb-2">
                        {actionButtonText === null ? formName : actionButtonText}
                      </a>
                    </div>
                  )}
                </div>
                <div className="col-auto" style={{cursor: 'pointer'}}>
                  <i className="fal fa-edit ml-1"></i>
                </div>
              </div>
            </div>
          </div>
          {/* Customize appearance enable action options - button text */}

          <div className={'row pt-0 pl-5 pr-3 d-none-feature ' + (!actionEnabled ? 'd-none': '')}>
            <div className="col c-text-optional">
              This is the info text area
            </div>
          </div>

          {/* Customize appearance enable action options - Modal Prompt */}
          <div className={'row no-gutters pt-0 pl-5 pr-3 mt-3 ' + (!actionEnabled ? 'd-none': '')}>
            <div className="col-lg-auto c-internal-heading mr-4 mb-2">
              Instructions for your client
            </div>

            <div className="col" onClick={() => setIsEditingModalPrompt(true)}>
              <div className="row align-items-center d-flex flex-nowrap">
                {isEditingModalPrompt ? (
                  <div className="col pr-0">
                    <textarea className="form-control c-font-10" rows="5" placeholder="Prompt"
                              defaultValue={actionModalPrompt}
                              autoFocus={true}
                              onBlur={(event) => handleModalPromptBlur(event.target.value)} />
                  </div>
                ) : (
                  <div className="col pr-0" style={{cursor: 'pointer'}}>
                    <span className="ml-auto float-right">{actionModalPrompt === null ? "No prompt set." : actionModalPrompt}</span>
                  </div>
                )}

                <div className="col-auto" style={{cursor: 'pointer'}}>
                  <i className="fal fa-edit ml-1"></i>
                </div>
              </div>
            </div>
          </div>

          {/* Customize appearance enable action options - Modal Prompt */}
          <div className={'row no-gutters pt-0 pl-5 pr-3 pb-3 mt-3 ' + (!actionEnabled ? 'd-none': '')}>
            <div className="col-lg-auto c-internal-heading mr-4 mb-2">
              Due date relative business days
            </div>
            <div className="col" onClick={() => setIsEditingDueDate(true)}>
              <div className="row align-items-center d-flex flex-nowrap">
                {isEditingDueDate ? (
                  <div className="col pr-0">
                    <input className="form-control c-font-10"
                           defaultValue={actionDueDate}
                           type="number"
                           onBlur={(evnet) => handleDueDateBlur(event.target.value)}/>
                  </div>
                ) : (
                  <div className="col pr-0" style={{cursor: 'pointer'}}>
                    <span className="ml-auto float-right">{actionDueDate === null ? "No due date." : actionDueDate}</span>
                  </div>
                )}

                <div className="col-auto" style={{cursor: 'pointer'}}>
                  <i className="fal fa-edit ml-1"></i>
                </div>
              </div>
            </div>
          </div>


          {/* not implemented*/}
          <div className={'row pt-0 pl-5 pr-3 pb-3 mt-2 d-none-feature ' + (!actionEnabled ? 'd-none': '')}>
            <div className="col c-text-optional">
              This is the info text area
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}