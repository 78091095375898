//'use strict';
import React, {Fragment, useEffect, useRef, useState} from "react"
import * as ReactDOMClient from 'react-dom/client';



// Froala imports
// Note the css here seems to not be required and makes the payload much larger, removing temp for now
//import 'froala-editor/css/froala_style.min.css';
//import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js'
// Require Font Awesome.
//import 'font-awesome/css/font-awesome.css';

// We use this parsing library to render out the contents of the Froala editor
import flatpickr from "flatpickr";

// this import style is required for react-bootstrap
import {Comment} from './comment'
import {Discussion} from './discussion'


import * as linkify from 'linkifyjs';
import linkifyStr from 'linkify-string';
import Linkify from 'linkify-react';


import {Tags, TagsDropdown} from "./tags";
import {Task} from "./task";
import {ProjectDetailEditor} from "./project_detail_editor"

import {
  dateIsBeforeToday,
  fetchSession, formatDate, formatDate3, formatDateShort,
  handleAttachFileChange, isMobile,
  parseDateIgnoringTimezone, parseDateIgnoringTimezoneGetDate,
  setIsUser,
  setUserStakeholderId
} from "./common_functions";
import {Goal} from "./goal";
import {ProjectCustomFields, ProjectCustomFieldsManage} from "./project_custom_fields";
import {ManageForms} from "./manageForms";
import {Form} from "./form";
import parse from "html-react-parser";
import {Status} from "./constants";
import {Title} from "./title";
import {Progress} from "./progress";
import {StatusDropdown} from "./status_dropdown";
import {Description} from "./description";
import {Attachments} from "./attachments";
import {NotificationSettings} from "./notificationSettings";
import {EditProjectLink} from "./editProjectLink";
import {ShiftDatesModal} from "./shiftDatesModal";
import {ChatGPTModal} from "./chatgptModal";
import {ChatGPTProjectChatModal} from "./chatgptProjectChatModal";
import {Inbox} from "./inbox";
import {RecurringTaskList} from "./recurringTaskList";
import {SadminWhiteList} from "./sadmin_white_list";
import FroalaEditor from "react-froala-wysiwyg";
import {ManageTaskStatus} from "./manageTaskStatus";
import {Search} from "./search";
import {CalendarModal} from "./calendarModal";


export function attachTask(container, taskId, customerId, _userStakeholderId, _isUser, stakeholderToken, vendorId,
                           closeFunction, isNewTask, isOnTemplate, timeTrackingEnabled, openInternal=false) {
  const root = ReactDOMClient.createRoot(container);
  setUserStakeholderId(_userStakeholderId);
  setIsUser(_isUser);
  root.render(React.createElement(Task, {
    taskId: taskId,
    customerId: customerId,
    stakeholderToken: stakeholderToken,
    vendorId: vendorId,
    isNewTask: isNewTask,
    closeFunction: closeFunction,
    isOnTemplate: isOnTemplate,
    timeTrackingEnabled: timeTrackingEnabled,
    openInternal: openInternal,
  }));

  return root;
}

//
// ProjectCustomFields vendor level advanced settings

export function attachProjectCustomFields(container, vendorId) {
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(ProjectCustomFields, {
    vendorId: vendorId
  }));

  return root;
}


//
//  Manage the custom fields on project dialog

export function attachProjectCustomFieldsManage(container, vendorId, projectId) {
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(ProjectCustomFieldsManage, {
    vendorId: vendorId,
    projectId: projectId,
  }));

  return root;
}


export function attachGoal(container, goalId, customerId, _userStakeholderId, _isUser, stakeholderToken, vendorId,
                           closeFunction, isNewGoal, isOnTemplate, alternateGoalName) {
  const root = ReactDOMClient.createRoot(container);
  setUserStakeholderId(_userStakeholderId);
  setIsUser(_isUser);
  root.render(React.createElement(Goal, {
    goalId: goalId,
    customerId: customerId,
    stakeholderToken: stakeholderToken,
    vendorId: vendorId,
    isNewGoal: isNewGoal,
    closeFunction: closeFunction,
    isOnTemplate: isOnTemplate,
    alternateGoalName: alternateGoalName
  }));

  return root;
}


// This is the two edit fields within the collaborator portal manage page
function PortalManage(props) {

  let froalaConfig = {
    // toolbarButtons are added conditionally below
    toolbarButtons: ['paragraphFormat',
      'alignLeft', 'alignCenter', 'alignRight',
      'fontSize', 'bold', 'italic', 'underline', 'textColor',
                     'insertLink', 'formatOL', 'formatUL', 'html', 'insertTable',
                     'clearFormatting'],
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    //zIndex: raisedZindex ? 2501 : null,
    linkInsertButtons: ['linkBack'],
    //autofocus: true,
    linkAlwaysBlank: true,
    attribution: false,
    quickInsertEnabled: false,
    iconsTemplate: 'font_awesome_5r',
    charCounterCount: false,
    fileUpload: false,
    imageUpload: false,
    imagePaste: false,
    videoUpload: false,
    key: 'AVB8B-21B2B3C3B2B2E1ua2BD1IMNBUMRWAd1AYMSTRBUZYA-9H3E2J2C4C6C3B1B5B1C2==',
  }

  const [loaded, setLoaded] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [desc, setDesc] = useState(null);
  const [descIsEditing, setDescIsEditing] = useState(false);

  const descInput = useRef();
  const promptInput = useRef();

  function handleDescBlur(event) {
    fetch('/js/set_desc_prompt', {
        method: 'post',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          field: 'desc',
          value: desc
        })
     })
    .then(response => response.json())
    .then((result) => {
      setDesc(result.desc);
      setDescIsEditing(false);
    });
  }

  function handlePromptBlur(event) {
    fetch('/js/set_desc_prompt', {
        method: 'post',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          field: 'prompt',
          value: promptInput.current.value
        })
     })
    .then(response => response.json())
    .then((result) => {
      setPrompt(result.prompt);
    });
  }

  function handleModelChange(model) {
    setDesc(model);
  }

  function handleDescFocus(event) {
    // move the stupid cursor to the end...
    event.target.selectionEnd = event.target.value.length
    event.target.selectionStart = event.target.value.length
  }

  useEffect(() => {
    if(!loaded) {
      fetchSession(`/js/get_desc_prompt`, {
        method: 'GET',
      })
      .then(response => response.json())
      .then(result => {
        setDesc(result.desc);
        setPrompt(result.prompt);
        setLoaded(true);
      })
    }
  })

  return (
    <Fragment>
      <div className="row mt-5 mb-4">
        <div className="col text-center">
          <div className="input-group input-group-lg">
            <input type="text" className="form-control text-center"
                   id="prompt-input"
                   autoComplete="off"
                   defaultValue={prompt}
                   onBlur={handlePromptBlur}
                   onKeyDown={(e) => e.key === 'Enter' && handlePromptBlur(e)}
                   ref={promptInput}
                   aria-describedby="inputGroup-sizing-lg" placeholder="Client Login" />
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col">
          {descIsEditing ? (
            <div className="form-group">
              <FroalaEditor config={froalaConfig}
                            tag="textarea"
                            model={desc}
                            onModelChange={handleModelChange}/>
              <button className="btn btn-outline-secondary mt-1"
                      onMouseDown={(event) => {
                        event.preventDefault();
                        setDescIsEditing(false);
                      }}>
                Cancel
              </button>
              {/* The on blur will handle the save */}
              <button className="btn btn-primary mt-1 ml-1" onClick={handleDescBlur}>Save</button>
            </div>
          ) : (
            <span style={{cursor: 'pointer'}} className="fr-view" id="desc-display" onClick={() => setDescIsEditing(true)}>
              {desc !== null ? parse(desc) : 'Welcome to our client portal, please enter your email address to access your portal:'}
              <i className="fal fa-edit ml-1"></i>
            </span>
          )}
        </div>
      </div>
    </Fragment>
  )

}


export function attachPortalManage(container) {
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(PortalManage, {
  }));
  return root;
}


function CheckboxCelEditor(props) {
  const [checked, setChecked] = useState(props.value === undefined ? false : props.value === "True");

  function handleChange() {
    fetchSession(`/js/custom_fields/project/${props.projectId}/field/set`, {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        field: props.field,
        value: !checked
      })
    })
    .then(response => response.json())
    .then(result => {
      setChecked(!checked);
    })
  }

  return (
    <div className="form-check text-center">
      {/*we need these &nbsp; spaces to center the checkbox in the project list colomn... h444xxxxxx*/}
      &nbsp; <input className="form-check-input" data-behave-field={props.field} type="checkbox"
             defaultChecked={checked} onChange={handleChange} />&nbsp;
    </div>
  )
}

function StringCelEditor(props) {
  const [value, setValue] = useState(props.value === '' ? null : props.value)
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef();

  const [currencyError, setCurrencyError] = useState(null);

  function handleBlur() {
    let nulledValue = value === '' ? null : value;
    if(props.type === 'Dollars' && nulledValue != null) {
      let currency = value.replaceAll(',', '').trim();
      // Note are just using parseFloat for "number like" and will send the text up and store as Decimal on backend
      let parsed = parseFloat(currency);
      if(isNaN(parsed)) {
        setCurrencyError('Must be a valid number');
        return;
      } else {
        nulledValue = currency;
      }
    }

    fetchSession(`/js/custom_fields/project/${props.projectId}/field/set`, {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        field: props.field,
        value: nulledValue
      })
    })
    .then(response => response.json())
    .then(result => {
      setIsEditing(false);
      setValue(nulledValue)

      if(props.type === 'Dollars') {
        // The sum is rendered server side, if they change it here, reload to recompute the sum
        window.location.reload();
      }
    });
  }

  return isEditing ?
    ((props.type === 'Dollars' ? (
        <Fragment>
          <input type="text" className={"form-control " + (currencyError !== null ? 'is-invalid' : '')}
                 ref={inputRef}
                 autoFocus={true}
                 style={{width: '125px'}}
                 defaultValue={value}
                 data-behave-field={props.field}
                 onBlur={handleBlur}
                 onKeyDown={(e) => e.key === 'Enter' && handleBlur(e)}
                 onChange={(event) => setValue(event.target.value)} />
          <div className="invalid-feedback">{currencyError}</div>
        </Fragment>
      ) : (
        <textarea
               className="form-control"
               autoFocus={true}
               defaultValue={value}
               data-behave-field={props.field}
               rows={3}
               onBlur={handleBlur}
               onKeyDown={(e) => e.key === 'Enter' && handleBlur(e)}
               onChange={(event) => setValue(event.target.value)}>
        </textarea>
      ))
    ) : (
      <div onClick={() => setIsEditing(true)}
            data-behave-field={props.field}
            style={{cursor: "pointer"}}
            className={(props.type === 'Dollars' && value !== null) ? 'text-right' : 'text-left'}>
        {props.type === 'Dollars' ? (
          value !== null ? `\$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2})}` : '-'
        ) : (
          value !== null && value.includes('http') ? (
            <Linkify tagName="span" options={{
              target: '_blank',
              attributes: {
                onClick: (event) => {
                  event.stopPropagation();
                }
              }
            }}>
              {value.substring(0, 150)}
            </Linkify>
          ) : (
            value !== null ? `${value.substring(0, 150)} ${value.length >= 150 ? '...' : ''}` : '-'
          )

        )}
      </div>
    )
}

function DateCelEditor(props) {
  const [value, setValue] = useState(props.value === 'None' ? null : props.value);
  const [isEditing, setIsEditing] = useState(false);
  const input = useRef()

  function handleChange(selectedDates) {
    fetchSession(`/js/custom_fields/project/${props.projectId}/field/set`, {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        field: props.field,
        value: selectedDates[0]
      })
    })
    .then(response => response.json())
    .then(result => {
      if(selectedDates.length > 0) {
        setValue(selectedDates[0]);
      } else {
        setValue(null);
      }
      input.current._flatpickr.destroy();
      setIsEditing(false);
    })
  }

  useEffect((didUpdate) => {
    if(isEditing) {
      flatpickr(input.current, {
                wrap: true,
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                defaultDate: value,
                onChange: (selectedDates) => handleChange(selectedDates)
              }).open()
    }
  })

  return isEditing ? (
    <div className="input-group mb-0 flatpickr" ref={input} style={{maxWidth: "250px"}}>
          <input className="form-control c-hover-indicator-container"
                 //defaultValue={parseDateIgnoringTimezoneGetDate(startDate)}
                 data-input="" />
          <div className="input-group-append">
            <button className="btn c-btn-dropdown-delete-border-left" type="button" data-clear="">
              <i className="fal fa-times"></i>
            </button>
          </div>
        </div>
  ) : (
    <div onClick={() => setIsEditing(true)}
         data-behave-field={props.field}
         style={{cursor: "pointer"}}>
      {value !== null ? (
        <div>
          {formatDate(value)}
        </div>
      ) : (
        <p>-</p>
      )}
    </div>
  )
}

export function attachCelEditor(celElement) {
  const root = ReactDOMClient.createRoot(celElement);
  const lookup = {
    'Checkbox' : CheckboxCelEditor,
    'String' : StringCelEditor,
    'Dollars': StringCelEditor,
    'Date': DateCelEditor,
  };

  root.render(React.createElement(lookup[celElement.dataset.celEditorType], {
    projectId: celElement.dataset.celEditorProjectId,
    field: celElement.dataset.celEditorField,
    value: celElement.dataset.celEditorValue,
    type: celElement.dataset.celEditorType
  }));
}


function HolidayEntry(props) {
  let existingName = props.holidayName;
  const [holidayErrorMessage, setHolidayErrorMessage] = useState(null);
  const [holidayName, setHolidayName] = useState(props.holidayName);
  const [holidayDate, setHolidayDate] = useState(props.holidayDate);

  const [isRemoving, setIsRemoving] = useState(false);

  const holidayDateInput = useRef();

  function handleRemove() {
    fetchSession('/settings/work_week/remove_holiday', {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        holidayName: holidayName,
      })
    }).then(response => response.json())
      .then(result => {
        /* This is not triggering a repaint, lets just reload for now
        delete props.holidays[holidayName]
        props.setHolidays(props.holidays)
         */
        location.reload();
      })
      .catch(error => console.log(error));
  }

  function handleBlur() {
    if(holidayName === '') {
      setHolidayErrorMessage("Holiday name is required.")
      return;
    }

    submitEdit(holidayDate);
  }

  function handleHolidayChange(selectedDates) {
    let d = parseDateIgnoringTimezone(selectedDates[0]);
    setHolidayDate(d);
    submitEdit(d);
  }

  function submitEdit(date) {
    fetchSession('/settings/work_week/add_holiday', {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        holidayName: holidayName,
        holidayDate: date,
        existingName: existingName,
      })
    }).then(response => response.json())
      .then(result => {
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    let instance = flatpickr(holidayDateInput.current, {
      wrap: true,
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      defaultDate: holidayDate !== null ? holidayDate.toString() : null,
      onChange: (selectedDates, dateStr, instance) => {
        handleHolidayChange(selectedDates, dateStr, instance);
      }
    })
    // This is for testing to be able to easily force/reload dates
    //window.holidayDateFlatpickr = instance;
  })

  return (
    <div className="row border-top pt-3 pb-3 pb-md-0">
      <div className="col-md">
        <div className="form-group">
          <input className={"form-control" + (holidayErrorMessage === null ? '':' is-invalid')} type="text"
                 data-holiday-name={holidayName}
                 defaultValue={holidayName}
                 placeholder="Holiday Name" required
                 onKeyDown={(e) => e.key === 'Enter' && handleBlur(e)}
                 onBlur={handleBlur}
                 onChange={(e) => setHolidayName(e.target.value)} />
          <div className="invalid-feedback">
            {holidayErrorMessage}
          </div>
        </div>
      </div>

      <div className="col-md">
        <div className="row" ref={holidayDateInput}>
          <div className="col">
            <input className="form-control c-hover-indicator-container flatpickr-white"
                   data-input="" />
          </div>
        </div>
      </div>

      <div className="col-md-auto mt-3 mt-md-0">
        {isRemoving ? (
          <button className="btn c-txt-btn btn-danger w-100"
                  data-behave-remove-holiday-name={holidayName}
                  onClick={handleRemove}>
            Confirm Remove
          </button>
        ) : (
          <button className="btn c-txt-btn btn-outline-secondary w-100"
                  data-behave-remove-holiday-name={holidayName}
                  onClick={() => setIsRemoving(true)}>
            Remove
          </button>
        )}

      </div>
    </div>
  )
}

function WorkWeekEditor(props) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const [workingDays, setWorkingDays] = useState(props.workingDays)
  const [isAdding, setIsAdding] = useState(false);

  function handleWorkingDayChange(event) {
    workingDays[event.target.dataset.dayOfWeek] = event.target.checked

    fetchSession('/settings/work_week/set', {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({workingDays: workingDays})
    }).then(response => response.json())
      .then(result => {
        setWorkingDays(workingDays)
      })
      .catch(error => console.log(error));
  }

  const [holidays, setHolidays] = useState(props.holidays);
  const [holidayName, setHolidayName] = useState('');
  const holidayDateInput = useRef();
  const [holidayDate, setHolidayDate] = useState(null);
  const [holidayErrorMessage, setHolidayErrorMessage] = useState(null);
  const [holidayDateErrorMessage, setHolidayDateErrorMessage] = useState(null);

  function handleAdd(event) {
    // Do some validation
    if(holidayName === '') {
      setHolidayErrorMessage("Holiday name is required.")
      return;
    }

    if(holidayDate == null) {
      setHolidayDateErrorMessage("Holiday date is required.")
      return;
    }

    if(holidayName in holidays) {
      setHolidayErrorMessage("Holiday name already exists.")
      return;
    }

    fetchSession('/settings/work_week/add_holiday', {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        holidayName: holidayName,
        holidayDate: holidayDate.toString()
      })
    }).then(response => response.json())
      .then(result => {
        holidays[holidayName] = holidayDate
        setHolidays(holidays)
        setIsAdding(false);
        setHolidayDate(null);
        setHolidayName(null);
      })
      .catch(error => console.log(error));
  }

  function handleHolidayChange(selectedDates) {
    let d = parseDateIgnoringTimezone(selectedDates[0]);
    setHolidayDate(d);
  }


  useEffect(() => {
    // startDate init flatpickr
    if(isAdding) {
      let instance = flatpickr(holidayDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: holidayDate !== null ? holidayDate.toString() : null,
        onChange: (selectedDates, dateStr, instance) => {
          handleHolidayChange(selectedDates, dateStr, instance);
        }
      })
      // This is for testing to be able to easily force/reload dates
      window.holidayDateFlatpickr = instance;
    }
  });

  return (
      <Fragment>
        <div className="row c-bg-color no-gutters p-3 mt-3">
          <div className="col">
            <div className="row mb-4">
              <div className="col-auto">
                <h3 className="">
                  Work week
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col">
                Select which days of the week are considered business days
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                {daysOfWeek.map((day, index) => (
                    <div key={day} className="form-check">
                      <input className="form-check-input" type="checkbox" defaultChecked={workingDays[index]}
                             data-day-of-week={index}
                             onChange={handleWorkingDayChange}/>
                      <label className="form-check-label">
                        {day}
                      </label>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row c-bg-color no-gutters p-3 mt-3">
          <div className="col">
            <div className="row mb-4">
              <div className="col-auto">
                <h3 className="">
                  Holiday Schedule
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col">
                Enter company holidays that you want to skip when calculating business days
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="">
                  {Object.entries(holidays).sort((a, b) => a[1] > b[1]).map(entry => (
                      <HolidayEntry key={entry[0]} holidayName={entry[0]} holidayDate={entry[1]} holidays={holidays}
                                    setHolidays={setHolidays}/>
                  ))}

                  {isAdding ? (
                      <div className="row border-top pt-3 pb-3 pb-md-0">
                        <div className="col-md">
                          <div className="form-group">
                            <input className={"form-control" + (holidayErrorMessage === null ? '' : ' is-invalid')}
                                   type="text"
                                   id="holiday-name-input"
                                   placeholder="Holiday Name" required
                                   onChange={(e) => setHolidayName(e.target.value)}/>
                            <div className="invalid-feedback">
                              {holidayErrorMessage}
                            </div>
                          </div>
                        </div>

                        <div className="col">
                          <div className="row" ref={holidayDateInput}>
                            <div className="col">
                              <input
                                  className={"form-control c-hover-indicator-container flatpickr-white" + (holidayDateErrorMessage === null ? '' : ' is-invalid')}
                                  placeholder="Date"
                                  data-input=""/>
                              <div className="invalid-feedback">
                                {holidayDateErrorMessage}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-auto mt-3 mt-md-0">
                          <button className="btn c-txt-btn btn-dark w-100 custom-field-finish-add"
                                  id="add-holiday-button" onClick={handleAdd}>
                            Add
                          </button>
                        </div>

                        <div className="col-md-auto mt-3 mt-md-0">
                          <button className="btn c-txt-btn btn-outline-secondary w-100" onClick={() => setIsAdding(false)}>
                            Cancel
                          </button>
                        </div>
                      </div>
                  ) : (
                      <div className="row mt-2">
                        <div className="col-md-auto behave-add-custom-field-button" onClick={() => setIsAdding(true)}>
                          <button className="btn c-txt-btn btn-dark w-100" id="add-holiday-button">
                            <i className="fa fa-plus"></i> Add Holiday
                          </button>
                        </div>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

      </Fragment>
  )
}

export function attachWorkWeekEditor(container, workingDays, holidays) {
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(WorkWeekEditor, {
    workingDays: workingDays,
    holidays: holidays
  }));

  return root;
}

function ManageProjectRoles(props) {
  const [roles, setRoles] = useState(props.roles);
  const [isAdding, setIsAdding] = useState(false);
  const [roleErrorMessage, setRoleErrorMessage] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [hasClickedDeleted, setHasClickedDelete] = useState(null);


  function handleAdd() {
    if (roleName === '' || roleName === null) {
      setRoleErrorMessage('Required')
      return;
    }
    fetchSession('/settings/project_roles/add_role', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        role: roleName
      })
    }).then(response => response.json())
        .then(result => {
          if (!result.sucess) {
            window.location.reload()
          } else {
            setIsAdding(false);
            setRoles([...roles, roleName]);
            setRoleName(null);
            setRoleErrorMessage(null);
          }
        })
        .catch(error => console.log(error));
  }

  function deleteRole(role) {
    fetchSession('/settings/project_roles/remove_role', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        role: role
      })
    }).then(response => response.json())
        .then(result => {
          setRoles(roles.filter(x => x !== role));
        })
        .catch(error => console.log(error));
  }

  return (
      <div className="">
        {isAdding ? (
            <div className="row c-bg-color no-gutters pt-3 pr-3 pb-1 pl-3 mt-4">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <input className={"form-control" + (roleErrorMessage === null ? '' : ' is-invalid')} type="text"
                             id="role-name-input"
                             placeholder="Role Name"
                             autoFocus={true}
                             required
                             onChange={(e) => setRoleName(e.target.value)}/>
                      <div className="invalid-feedback">
                        {roleErrorMessage}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-auto">
                    <button className="btn c-txt-btn btn-dark custom-field-finish-add w-100"
                            id="add-role-button" onClick={handleAdd}>
                      Add
                    </button>
                  </div>

                  <div className="col-md-auto mt-3 mt-md-0">
                    <button className="btn c-txt-btn btn-outline-secondary w-100" onClick={() => setIsAdding(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
        ) : (
            <div className="row mt-4">
              <div className="col behave-add-custom-field-button" onClick={() => setIsAdding(true)}>
                <button className="btn c-txt-btn btn-dark" id="add-role-button">
                  <i className="fa fa-plus"></i> Add Project Role
                </button>
              </div>
            </div>
        )}

        <div className="row c-bg-color no-gutters pt-3 pr-3 pb-1 pl-3 mt-3">
          <div className="col">
            <div className="row mb-2">
              <div className="col-auto">
                <h3 className="">
                  Your Project Roles
                </h3>
              </div>
            </div>

            <div className="row no-gutters border-top align-items-center pt-2 pb-2">
              <div className="col">
                <h3 className="behave-project-role-entry c-font-10">
                  <i className="fas fa-star"></i> Project Manager
                </h3>
              </div>

              <div className="col-auto pr-1">
                <span className="c-text-optional">Cannot remove default role</span>
              </div>
            </div>

            {roles.map(entry => (
                <div key={entry} className="row no-gutters border-top align-items-center pt-2 pb-2">
                  <div className="col">
                    <h3 className="behave-project-role-entry c-font-10">{entry}</h3>
                  </div>

                  <div className="col-auto pr-1">
                    {hasClickedDeleted === entry ? (
                        <button className="btn c-txt-btn btn-danger"
                                data-behave-role-delete={entry}
                                onClick={(event) => deleteRole(entry)}>Confirm Delete?</button>
                    ) : (
                        <i className="btn btn-link-edit-danger far fa-trash-alt"
                           data-behave-role-delete={entry}
                           onClick={() => setHasClickedDelete(entry)}></i>
                    )}
                  </div>
                </div>
            ))}

          </div>
        </div>
      </div>
  )
}

export function attachManageProjectRoles(container, roles) {
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(ManageProjectRoles, {
    roles: roles
  }));

  return root;
}

function StakeholderRoleEditor(props) {
  // Overloading this to also handle username (for @username) editor

  const [roles, setRoles] = useState(props.roles);
  const [username, setUsername] = useState(props.username);
  const roleSelect = useRef();

  function addRole(event) {
    const role = event.target.value;

    fetchSession(`/customer/${props.customerId}/stakeholder/${props.stakeholderId}/add_role`, {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        role: role
      })
    }).then(response => response.json())
      .then(result => {
        setRoles([...roles, role]);
        roleSelect.current.value = "placeholder";
      })
      .catch(error => console.log(error));
  }

  function removeRole(role) {
    fetchSession(`/customer/${props.customerId}/stakeholder/${props.stakeholderId}/remove_role`, {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        role: role
      })
    }).then(response => response.json())
      .then(result => {
        setRoles(roles.filter(x => x !== role))
      })
      .catch(error => console.log(error));
  }

  function handleChangeUsername() {
    fetchSession(`/js/customer/${props.customerId}/stakeholder/${props.stakeholderId}/change_username`, {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        username: username
      })
    }).then(response => response.json())
      .then(result => {
        setUsername(result.username);
      })
      .catch(error => console.log(error));
  }

  return (
      <Fragment>
        {roles.length > 0 && (
            <Fragment>
              <div className="row">
                <div className="col">
                  {roles.map(role => (
                      <span key={role} className="badge badge-light behave-role-pill c-font-10 p-2 pl-3 mr-2 mb-2 mt-1">
                  {role}
                        <i className="fas fa-times ml-3 c-cursor-pointer"
                           data-behave-remove-role-button={role}
                           onClick={() => removeRole(role)}></i>
                </span>
                  ))}
                </div>
              </div>
            </Fragment>
        )}

        <div className="row align-items-center mt-2">
          <div className="col">
            <select className="custom-select behave-role-select"
                    defaultValue="placeholder"
                    ref={roleSelect}
                    onChange={(e) => addRole(e)}>
              <option disabled value="placeholder">
                {props.vendorRoles.filter(x => !roles.includes(x)).length > 0 ? 'Add Role' : 'No Roles Available'}
              </option>
              {props.vendorRoles.filter(x => !roles.includes(x)).map((role) => (
                  <option key={role} value={role}>{role}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row align-items-center mt-1">
          <div className="col">
            <a className="c-btn-link" href="/settings/project_roles" target="_blank">Edit Project Roles</a>
          </div>
        </div>

        {props.isActualStakeholder && (
            <div className="row align-items-center mt-4">
              <div className="col-auto c-internal-heading pr-0">
                @mention Username
              </div>

              <div className="col-auto pl-1">
                <a className="btn btn-link p-0 pr-2"
                   tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-placement="right"
                   title="What are @mention Usernames?"
                   data-content="
                  <p>
                    When you are sending a discussion message and want to specifically @mention a user or collaborator by name.
                  </p>
                  <p>
                    This is the username that you can use when @mentioning to send an email to that specific user or collaborator.
                  </p>
                  "
                >
                  <i class="far fa-info-circle text-muted"></i>
                </a>
              </div>
              
              <div className="col-12 mt-2">
                <input className="form-control" type="text"
                       onChange={(event) => setUsername(event.target.value)}
                       value={username}/>
                <button className="btn btn-outline-secondary btn-sm mt-2"
                        onClick={handleChangeUsername}>Save New @Username
                </button>
              </div>
            </div>
        )}

      </Fragment>
  )
}

export function attachStakeholderRoleEditor(container, roles, vendorRoles, customerId, stakeholderId, username,
                                            isActualStakeholder) {
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(StakeholderRoleEditor, {
    roles: roles,
    vendorRoles: vendorRoles,
    customerId: customerId,
    stakeholderId: stakeholderId,
    username: username,
    isActualStakeholder: isActualStakeholder
  }));

  return root;
}


export function renderComments(_isUser, _userStakeholderId) {
  /* This is a bit of wackiness, the assigned task was the only page left not using the react based Comment component
     instead of writing the reaction-emoji feature twice I rigged that page to use the component so it could get
     the emojis as well
   */

  setIsUser(_isUser)
  setUserStakeholderId(_userStakeholderId);

  document.querySelectorAll('.react-comment-history-div').forEach(element => {
    const root = ReactDOMClient.createRoot(element);
    const projectId = element.dataset.projectId;
    const taskId = element.dataset.taskId;

    fetchSession(`/js/customer/${projectId}/task/${taskId}`)
      .then(response => response.json())
      .then(result => {
        let elements = []
        // just hide empty comments these are probably coming from attachments
        result.comments.filter(c => c.discussion_comment != null).slice(0,2).forEach(comment => {
          elements.push(React.createElement(Comment, {
            key:comment.discussion_entry_id,
            projectId: comment.project_id,
            targetEntityId: comment.target_entity_id,
            atCompleteNames: result.atCompleteNames,
            comment:comment,
            commentMoveTargets: [],
            entityType: 'task'
          }));
        });
        root.render(elements);
      });
  });
}

export function attachProjectDiscussion(container, customerId, vendorId, entityName, entityId, entityTitle,
                                        _userStakeholderId, _isUser, internal=false) {
  const root = ReactDOMClient.createRoot(container);
  setUserStakeholderId(_userStakeholderId);
  setIsUser(_isUser);

  // we are getting passed jinja escaped entities so parse out the text for the title to avoid &amp etc
  const parser = new DOMParser();
  const dom = parser.parseFromString(entityTitle, "text/html");
  root.render(React.createElement(Discussion, {
    customerId: customerId,
    vendorId: vendorId,
    entityName: entityName,
    entityId: entityId,
    entityTitle: dom.body.innerText,
    raisedZIndex: false,
    internal: internal
  }));

  return root;
}

export function attachProjectDetailEditor(container, customerId, _userStakeholderId, vendorId, closeFunction,
                                          isTemplate, renderProjectPageLink=false) {
  const root = ReactDOMClient.createRoot(container);
  setUserStakeholderId(_userStakeholderId);
  setIsUser(true);
  root.render(React.createElement(ProjectDetailEditor, {
    customerId: customerId,
    vendorId: vendorId,
    closeFunction: closeFunction,
    renderProjectPageLink: renderProjectPageLink,
    isTemplate: isTemplate
  }));

  return root;
}


export function attachManageForms(container, forms, hideArchived) {
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(ManageForms, {
    forms: forms,
    hideArchived: hideArchived
  }));

  return root;
}


export function attachManageTaskStatus(container) {
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(ManageTaskStatus, {
  }));

  return root;
}

export function renderForms(_isUser, _userStakeholderId, captcha=null) {
  // This is used on the active tasks page in the same way renderComments is

  setIsUser(_isUser)
  setUserStakeholderId(_userStakeholderId);

  document.querySelectorAll('.react-form-div').forEach(element => {
    const root = ReactDOMClient.createRoot(element);
    let props = Object.assign({}, element.dataset); // Must convert out the DOMStringMap to normal Object
    // These are in the data attributes as escaped strings
    props.form = JSON.parse(props.form);
    props.formDataIn = props.formData === undefined ? new Map(props.form.fields.map(x => [x.field_id, null]))
                                            : new Map(Object.entries(JSON.parse(props.formData)));

    props.formCompletedBy = props.formCompletedBy === undefined ? null : JSON.parse(props.formCompletedBy);
    props.captcha = captcha;

    root.render(React.createElement(Form, props));
  });
}

export function FormModal({container, customerId, form, formData, formCompletedBy, name, prompt}) {
  return (
    // enable action modal header
    <Fragment>
      <div className="modal-header data-behave-click-target pt-2 pb-0 border-0">
        <button id="close_react_modal" type="button" className="close c-font-12" data-dismiss="modal">
          <span aria-hidden="true"><i className="fa fa-times"></i></span>
        </button>
      </div>

      <div className="modal-body pt-0">
        <h2>{name}</h2>

        {prompt !== undefined && (
          <p className="mt-2 mb-0">{prompt}</p>
        )}

        <Form customerId={customerId} form={form} formDataIn={formData} formCompletedBy={formCompletedBy}
              reloadOnFormSubmit={true} />
      </div>
    </Fragment>
  )
}

export function attachForm(container, customerId, form, name, prompt,
                            _userStakeholderId, _isUser) {
  setIsUser(_isUser);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  const formp = JSON.parse(form)
  root.render(React.createElement(FormModal, {
    customerId: customerId,
    form: formp,
    name: name,
    prompt: prompt,
    formData: new Map(formp.fields.map(x => [x.field_id, null])),
    reloadOnFormSubmit: true,
    formCompletedBy: () => { return null; }
  }));

  return root;
}

// vendorSettings is a special case for the vendor wide defaults and be set to 'manager', 'collaborator' or 'profile'
export function attachNotificationSettings(container, stakeholderId, customerId, emailAddress, _userStakeholderId,
                                           _isUser, vendorSettings=null) {
  setIsUser(_isUser);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(NotificationSettings, {
    stakeholderId: stakeholderId,
    customerId: customerId,
    emailAddress: emailAddress,
    vendorSettings: vendorSettings
  }));

  return root;
}

export function attachEditProjectLink(container, projectLinkId, vendorId, projectLink, forms, roles, templates, managers,
                                      _userStakeholderId, _isUser) {
  setIsUser(_isUser);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(EditProjectLink, {
    projectLinkId: projectLinkId,
    vendorId: vendorId,
    projectLink: projectLink,
    forms: forms,
    roles: roles,
    templates: templates,
    managers: managers,
  }));

  return root;
}

export function attachShiftDatesModal(container, taskId, customerId, _userStakeholderId, _isUser) {
  setIsUser(_isUser);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(ShiftDatesModal, {
    taskId: taskId,
    customerId: customerId,
  }));

  return root;
}

export function attachChatGPTModal(container, customerId, _userStakeholderId) {
  setIsUser(true);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(ChatGPTModal, {
    customerId: customerId,
  }));

  return root;
}

export function attachChatGPTProjectChatModal(container, projectId, projectName, _userStakeholderId) {
  setIsUser(true);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(ChatGPTProjectChatModal, {
    projectId: projectId,
    projectName: projectName,
  }));

  return root;
}


export function attachInbox(container, projectNoun, projectPluralNoun, userRole, collaboratorLastReplyFilter,
                            _userStakeholderId) {
  setIsUser(true);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(Inbox, {
    projectNoun: projectNoun,
    projectPluralNoun: projectPluralNoun,
    userRole: userRole,
    collaboratorLastReplyFilter: collaboratorLastReplyFilter
  }));

  return root;
}


export function attachRecurringTaskList(container, customerId, _userStakeholderId) {
  setIsUser(true);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(RecurringTaskList, {
    customerId: customerId
  }));

  return root;
}

export function attachSadminWhiteList(container, _userStakeholderId) {
  setIsUser(true);
  setUserStakeholderId(_userStakeholderId);
  const root = ReactDOMClient.createRoot(container);
  root.render(React.createElement(SadminWhiteList, {
  }));

  return root;
}

export function attachSearch(container, _userId, backgroundColor, backgroundColorLighten, textColor) {
  const root = ReactDOMClient.createRoot(container);
  setUserStakeholderId(_userId);
  setIsUser(true);
  root.render(React.createElement(Search, {
    backgroundColor: backgroundColor,
    backgroundColorLighten: backgroundColorLighten,
    textColor: textColor
  }));
  return root;
}

export function attachCalendarModal(container, projectId, projectName, _userId) {
  const root = ReactDOMClient.createRoot(container);
  setUserStakeholderId(_userId);
  setIsUser(true);
  root.render(React.createElement(CalendarModal, {
    projectId: projectId,
    projectName: projectName
  }));
  return root;
}
