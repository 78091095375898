import React, {useState, useRef, Fragment, useEffect} from "react"
import {fetchSession} from "./common_functions";

export function SadminWhiteList(props) {

  const [loaded, setLoaded] = useState(false);
  const [accounts, setAccounts] = useState([]);

  function handleUnblock(vendor_id) {
    fetchSession(`/sadmin/whitelist/accounts/by_vendor_id`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({vendor_id: vendor_id})
    }).then(() => {
      window.location.reload();
    })
  }

  function toggleEmailEnable(vendor_id) {
    fetchSession(`/sadmin/whitelist/accounts/toggle_email`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({vendor_id: vendor_id})
    }).then(() => {
      window.location.reload();
    })
  }


  useEffect(() => {
    if(!loaded) {
      fetchSession('/sadmin/whitelist/accounts')
        .then(response => response.json())
        .then(result => {
          setLoaded(true);
          setAccounts(result);
        });
    }
  });

  return (
    <table>
      <thead>
      <tr>
        <th>vendor_name</th>
        <th>user_email</th>
        <th>user_name</th>
        <th>created_dt</th>
        <th>vendor_id</th>
        <th>whitelist (lead_params_btoa)</th>
        <th>email_disabled (complaints)</th>
      </tr>
      </thead>
      <tbody>
      {accounts.map(a => (
        <tr key={a.vendor_id}>
          <td>{a.vendor_name}</td>
          <td>{a.user_name}</td>
          <td>{a.user_email}</td>
          <td>{a.created_dt}</td>
          <td>{a.vendor_id}</td>

          <td>{a.lead_params_btoa ? (
            a.lead_params_btoa
          ) : (
            <button onClick={() => handleUnblock(a.vendor_id)}>unblock</button>
          )}</td>

          <td>
            <button onClick={() => toggleEmailEnable(a.vendor_id)}>
              {a.email_disabled ? (
                <span>**DISABLED**</span>
              ) : (
                <span>Enabled</span>
              )}
            </button>
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  )
}
