import React, {useState, useRef, Fragment, useEffect, useReducer, useCallback} from "react"

export function Search({backgroundColor, backgroundColorLighten, textColor}) {

  const [pendingFetchAbort, setPendingFetchAbort] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [searchInputTimer, setSearchInputTimer] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  function getResults(searchString, entityType, appendResults, then) {
    const controller = new AbortController();
    setPendingFetchAbort(controller);

    fetchSession('/search', {
        method: 'post',
        signal: controller.signal,
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          searchString: searchString,
          entityType: entityType
        })
      })
        .then(result => result.json())
        .then(result => {
          setPendingFetchAbort(null);
          if(appendResults) {
            setSearchResults(prev => [...prev, ...result]);
          } else {
            setSearchResults(result);
          }

          if(then) {
            then();
          }
        })
        .catch((e) => {
          debugger;
          console.log(e);
        })

  }

  function handleSearchChange(event) {
    const searchString = event.target.value;
    setSearchString(searchString);
    if(searchString === '') {
      clearTimeout(searchInputTimer);
      return
    }

    if(searchInputTimer) {
      clearTimeout(searchInputTimer);
    }

    setSearchInputTimer(setTimeout(() => {
      setShowResults(true);
      if(pendingFetchAbort) {
        console.log("aborting")
        pendingFetchAbort.abort()
        setPendingFetchAbort(null);
      }

      getResults(searchString, 'Customer', false, () => {
        getResults(searchString, 'Task', true, () => {
          getResults(searchString, 'DiscussionEntry', true, () => {
            getResults(searchString, 'Org', true);
          });
        });
      });

    }, 700));
  }

  function get_icon(item) {
    switch(item['item_type']) {
      case 'Customer':
        return (<i>Project</i>)
      case 'Task':
        return (<i>Task</i>)
      case 'DiscussionEntry':
        return (<i>Comment</i>)
      case 'Org':
        return (<i>Org</i>)
    }
  }

  function getStyle() {
    const ret = {}
    if(backgroundColor) {
      ret['backgroundColor'] = backgroundColorLighten;
      ret['border'] = '1px solid ' + backgroundColor;
    }

    if(textColor) {
      ret['color'] = textColor;
    }
    return ret;
  }

  return (
    <Fragment>

      <input
        style={getStyle()}
        type="text" className="form-control c-btn-round d-inline c-search-bar-override" placeholder="Search"
        onFocus={() => setSearchString('')}
        onBlur={() => setTimeout(() => {
          setSearchResults([]);
          setSearchString('');
          setShowResults(false);
        }, 700)}
        value={searchString} onChange={handleSearchChange}/>

      {showResults && (
        <div style={{position: 'absolute', zIndex: '1000'}} className="c-omnisearch-container">
          {pendingFetchAbort && (
            <p className="text-center text-body mb-0">Searching...</p>
          )}

          {searchResults.length > 0 && (
            <div className="row no-gutters">
              {searchResults.map(item => (
                <a className="col-auto c-omnisearch-item" key={item.PK + item.SK} href={item.url}>
                  <div className="row no-gutters">
                    <div className="col-auto c-text-item">
                      {get_icon(item)}
                    </div>
                    <div className="col">
                      {item.search_title}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          )}

          {searchResults.length === 0 && !pendingFetchAbort && (
            <p className="text-center text-body mb-0">No results.</p>
          )}
        </div>
      )}
    </Fragment>
  )
}