import React, {useState, useRef, Fragment, useEffect} from "react"
import parse from "html-react-parser";

import {DateTime} from "luxon";

import {
  fetchSession,
} from "./common_functions";

export function ChatGPTProjectChatModal({projectId, projectName}) {

  const [prompt, setPrompt] = useState(localStorage.getItem(`gpt_project_prompt_${projectId}`) === null ? '' : localStorage.getItem(`gpt_project_prompt_${projectId}`));
  const [resultsPending, setResultsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messages, setMessages] = useState([]);

  // caching
  const [projectText, setProjectText] = useState(null);
  const [projectActivity, setProjectActivity] = useState(null);

  const sendButtonRef = useRef();

  function handlePromptSubmit() {
    if(prompt === '') {
      return;
    }

    setResultsPending(true);
    setErrorMessage(null);

    fetchSession(`/js/customer/${projectId}/prompt_results_project_chat`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        prompt: prompt,
        messages: messages,
        project_text: projectText,
        text_html_activities: projectActivity
      })
    })
    .then(response => response.json())
    .then(result => {
      if('error' in result) {
        console.log(result.gptResult);
        setErrorMessage("There was a problem. Please try again.")
        setResultsPending(false);
        return;
      }

      setMessages(result.messages);
      setProjectText(result.project_text);
      setProjectActivity(result.text_html_activities);
      setPrompt('');
      setResultsPending(false);
      setErrorMessage(null);
    })
    .catch(error => {
      setErrorMessage("There was a problem. Please try again.")
      setResultsPending(false);
    });
  }

  useEffect(() => {
    sendButtonRef.current.scrollIntoView();
  });

  return (
    <Fragment>
      <div className="modal-header pt-2 pb-0 border-0">
        <h5><i className="fal fa-robot"></i> Chat About Plan With AI - {projectName}</h5>

        <button id="close_react_modal" type="button" className="close c-font-12" data-dismiss="modal">
          <span aria-hidden="true"><i className="fa fa-times"></i></span>
        </button>
      </div>

      <div className="modal-body c-modal-left-side">
        <div>
          <b>CoordinateBot:</b><br/>
          What would you like to ask?
        </div>
        <hr/>

        {messages.map(message => (
          <Fragment key={message.id}>
            {message.role === 'assistant' ? (
              <Fragment>
                <div>
                  <b>CoordinateBot:</b> {parse(message.content)}
                </div>
                <hr/>
              </Fragment>
            ) : (
              <Fragment>
                <div>
                  <b>You:</b> {parse(message.content)}
                </div>
                <hr/>
              </Fragment>
            )}
          </Fragment>
        ))}

        {(resultsPending) && (
          <div>
            <div className="progress m-1 mt-2 mb-2"
                   style={{width: '99%', height: '40px'}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                     style={{width: '100%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                  Please wait...
                </div>
              </div>
          </div>
        )}
        <div className="mb-0">
          {/*{# Clicking inside should expand the input field to be multi-line like in discussion #}*/}
          <textarea className="form-control" rows="3"
                    value={prompt}
                    onChange={(event) => {
                      setPrompt(event.target.value);
                      localStorage.setItem(`gpt_prompt_${projectId}`, event.target.value);
                    }}
                    onKeyDown={(event) => {
                      if(event.keyCode === 13) {
                        handlePromptSubmit();
                      }
                    }}
                    placeholder="What would you like to ask?"
                    autoFocus>
          </textarea>
          <button className="btn btn-primary float-right mt-2 c-coordinatebot-send" onClick={handlePromptSubmit}
                  ref={sendButtonRef}>
            Send
          </button>
        </div>

        {errorMessage && (
          <div>
            <p className="mr-auto text-danger">
              {errorMessage}
            </p>
          </div>
        )}

      </div>
    </Fragment>
  )
}