import React, {useState, useRef, Fragment, useEffect} from "react"
import {Status, statusCategories} from "./constants";
import {Dropdown} from "react-bootstrap";

import {getStatusNameAndStyle, fetchSession, getIsUser} from "./common_functions";
import {DateTime} from "luxon";


export function StatusDropdown({customerId, entityId, entityType, title, status, setStatus, locked, completedCallback,
                        id, includeOffTrackStatus, includeInProgressStatus, includeOnTrackStatus,
                        setCurrentGroup, setAssigneeStakeholderId, allowEdit,
                        includeWaitDependencyStatus=false, labels=null,
                        preventComplete=false, includeInformationStatus=true,
                        preventCompleteMessage=null,
  statusLabel, setStatusLabel, changeCallback
}) {

  const statusIcons = {
    not_complete: (<i className="fal fa-circle"></i>),
    information: (<i className="fal fa-info-circle text-dolph-grey"></i>),
    in_progress: (<i className="fad fa-circle text-primary"></i>),
    on_track: (<i className="fad fa-circle text-primary"></i>),
    off_track: (<i className="fal fa-exclamation-circle text-warning"></i>),
    dependency_wait: (<i className="fal fa-clock text-warning"></i>),
    blocked: (<i className="fal fa-ban text-danger"></i>),
    complete: (<i className="far fa-check-circle text-success"></i>),
  }

  function handleStatusChange(event) {
    changeCallback && changeCallback();
    fetchSession(`/js/customer/${customerId}/${entityType}/${entityId}/set_status`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        status: event.target.dataset.status,
        labelUuid: event.target.dataset.labelUuid
      })
    }).then(response => response.json())
      .then((result) => {
      debugger;
      completedCallback && completedCallback(DateTime.now().toHTTP());
      if(entityType === 'task') {
        setCurrentGroup && setCurrentGroup(result['current_group'])
        setAssigneeStakeholderId(result['task_assignee_stakeholder_id'])
        setStatus(result.task_status_current);
        setStatusLabel(event.target.dataset.labelUuid !== undefined ? event.target.dataset.labelUuid : null);
      } else {
        setStatus(event.target.dataset.status);
      }
      //setCompletedDate(String(new Date()))
    });
  }

  function renderOptions(category) {
    return (
      <Fragment>
       {(status !== category || statusLabel !== null) && (
        <Dropdown.Item onClick={handleStatusChange} href="#" data-status={category} data-status-label={null}>
          {statusIcons[category]} {statusCategories[category]}
        </Dropdown.Item>
      )}

      {labels && labels.filter(e => e.category === category && statusLabel !== e.uuid)
        .sort((a, b) => a.order < b.order ? -1 : 0)
        .map(label => (
          <Dropdown.Item key={label.label} onClick={handleStatusChange} href="#" data-status={category}
                         data-label={label.label} data-label-uuid={label.uuid}>
            {statusIcons[category]} {label.label}
          </Dropdown.Item>
      ))}
    </Fragment>

  )}

  return (
    <Fragment>
      <div className="row mt-3 mb-1">
        <div className="col c-internal-heading">{title}</div>
      </div>

      {locked ? (
        <Fragment>
        {status === Status.Complete ? (
          <span>
            <i className="far fa-check-circle"></i> {getStatusNameAndStyle(status).text}
          </span>
        ) :
          <span>
            {getStatusNameAndStyle(status).text}
          </span>
        }
        </Fragment>
      ) : (
        <Dropdown>
        <Dropdown.Toggle className={`${getStatusNameAndStyle(status).style} c-override-bootstrap-dropdown`}
            id={id}
            variant={`${getStatusNameAndStyle(status).buttonStyle !== '' ? getStatusNameAndStyle(status).buttonStyle : 'dropdown'}`}>
          <div className="row">
            <div className="col text-left">
            {status === Status.Complete ? (
              <Fragment>
                <i className="far fa-check-circle"></i> {getStatusNameAndStyle(status).text}
              </Fragment>
            ) :
              getStatusNameAndStyle(status, statusLabel, labels).text
            }
            </div>
            {allowEdit && (
              <div className="col-auto ml-2">
                <i className="fas fa-caret-down"></i>
              </div>
            )}

          </div>
        </Dropdown.Toggle>
          {allowEdit && (
            <Dropdown.Menu>
              {renderOptions(Status.NotStarted)}

              {includeInformationStatus && renderOptions(Status.Information)}

              {includeInProgressStatus && renderOptions(Status.InProgress)}

              {includeOnTrackStatus && renderOptions(Status.OnTrack)}

              {includeWaitDependencyStatus && getIsUser() && renderOptions(Status.DependencyWait)}

              {renderOptions(Status.Blocked)}

              {includeOffTrackStatus && renderOptions(Status.OffTrack)}

              {!preventComplete && renderOptions(Status.Complete)}

              {/*TODO: apply this to all task status dropdowns: Sample code for disabled complete state in event of unfinished subtask checklist item*/}
              {status !== Status.Complete && preventComplete && (
                <Dropdown.Item href="#" data-status={Status.Complete}
                               className="font-italic c-cursor-not-allowed">
                  <i className="far fa-check-circle"></i> {preventCompleteMessage}
                </Dropdown.Item>
              )}
           </Dropdown.Menu>
          )}

       </Dropdown>
      )}
    </Fragment>
  )
}
