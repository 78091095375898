import React, {useState, useRef, Fragment, useEffect} from "react"
import {fetchSession} from "./common_functions";
import {statusCategories} from "./constants";

export function ManageTaskStatus(props) {

  const [loaded, setLoaded] = useState(false);


  const [labels, setLabels] = useState([]);
  const [deletePending, setDeletePending] = useState(false);

  const [addingStatusToCategory, setAddingStatusToCategory] = useState(null);
  const [newStatusName, setNewStatusName] = useState('');
  const [editingLabelText, setEditingLabelText] = useState('');

  const [editingUuid, setEditingUuid] = useState(null);
  const [deletingUuid, setDeletingUuid] = useState(null);
  const [deleteReplaceUuid, setDeleteReplaceUuid] = useState(null);

  function handleNewStatusSave() {
    fetchSession('/settings/task_statuses/new_status', {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        category:  addingStatusToCategory,
        label: newStatusName,
      })
    }).then(response => response.json())
      .then(result => {
        setLabels(result);
        setAddingStatusToCategory(null);
    });
  }

  function handleChangeOrder(label, order) {
    fetchSession('/settings/task_statuses/reorder', {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        order: order,
        uuid: label.uuid,
      })
    }).then(response => response.json())
      .then(result => {
        setLabels(result);
    });
  }

  function handleRename(label, newLabel) {
    fetchSession('/settings/task_statuses/rename', {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        label: newLabel,
        uuid: label.uuid,
      })
    }).then(response => response.json())
      .then(result => {
        setLabels(result);
        setEditingUuid(null);
    });
  }

  function handleDelete(label, replacementUuid) {
    fetchSession('/settings/task_statuses/delete', {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        replacementUuid: replacementUuid,
        uuid: label.uuid,
      })
    }).then(response => response.json())
      .then(result => {
        setLabels(result);
        setDeletingUuid(null);
        setDeletePending(true);
        // Dirty but just reload the page since we need them to see the warning banner we aren't rendering in this
        // component
        window.location.reload();
    });
  }


  useEffect((didUpdate) => {
    if(!loaded) {
      fetchSession('/settings/task_statuses/get_labels', {})
        .then(response => response.json())
        .then(result => {
          setLoaded(true);
          setLabels(result.labels);
          setDeletePending(result.deletePending);
        });
    }
  });

  return Object.entries(statusCategories)
    .filter(x => !['on_track', 'off_track'].includes(x[0]))
    .map(([category,  title]) => (
      <div key={category} className="card mt-3 mb-4 c-top-border-black">
        <div className="card-title mb-0">

          <div className="row align-items-center no-gutters border-bottom pt-2 pb-2 pl-0">
            {/* TODO: hook up the appropriate icon for each default status including color */}
            <div className="col-auto pr-1 pl-1 d-none">
              <h2>
                <i className="far fa-check-circle"></i>
              </h2>
            </div>

            <div className="col pl-2">
              <div className="row align-items-center">
                <div className="col">
                  <h2>{title}</h2>
                </div>

                <div className="col-lg-auto pr-4 c-text-optional">
                  Default Status - Cannot be removed
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body p-0">
          {labels.filter(e => e.category === category).sort((a, b) => a.order < b.order ? -1 : 0).map(label => (
              <div key={label.uuid}
                   className="row align-items-center no-gutters border-bottom d-flex flex-nowrap">
                <div className="col-auto pl-1 pr-1">
                  <div className="row">
                    <div className="col">
                        <div className="c-icon-container c-cursor-pointer"
                             onClick={() => handleChangeOrder(label, -1)}>
                          <div className="c-icon-btn">
                            <div className="text-secondary c-center-element-parent">
                              <i className="fal fa-caret-up fa-2x c-center-element-child"></i>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                        <div className="c-icon-container c-cursor-pointer">
                          <div className="c-icon-btn">
                            <div className="text-secondary c-center-element-parent">
                              <i className="fal fa-caret-down fa-2x c-center-element-child"
                                 onClick={() => handleChangeOrder(label, 1)}></i>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="col pl-3 pr-2 pt-3 pb-3 border-left">
                  {editingUuid === label.uuid ? (
                      <Fragment>
                        <div className="row">
                          <div className="col">
                            <input type="text" className="form-control"
                                   autoFocus={true}
                                   value={editingLabelText}
                                   onChange={(event) => setEditingLabelText(event.target.value)}/>
                          </div>

                          <div className="col-auto pl-1">
                            <button className="btn btn-outline-secondary" onClick={() => setEditingUuid(null)}>
                              Cancel
                            </button>

                            <button className="btn btn-dark ml-3 custom-field-finish-add"
                                    onClick={() => handleRename(label, editingLabelText)}>
                              Save
                            </button>
                          </div>
                        </div>
                      </Fragment>
                  ) : (
                      <Fragment>
                        <div className="row no-gutters align-items-center">
                          <div className="col">
                            <h5 data-behave-label-name={label.label}>{label.label}</h5>
                          </div>

                          <div className="col-auto">
                            <button type="button"
                                    className="btn btn-link-edit"
                                    onClick={() => {
                                      setEditingLabelText(label.label);
                                      setEditingUuid(label.uuid);
                                    }}>
                              <i className="fas fa-edit"></i>
                            </button>
                          </div>

                          {!deletePending && (
                            <div className="col-auto c-cursor-pointer">
                              <button type="button"
                                      className="btn btn-link-edit-danger"
                                      data-behave-label={label.label}
                                      onClick={() => setDeletingUuid(label.uuid)}>
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </Fragment>
                  )}

                  {deletingUuid === label.uuid && (
                      <Fragment>
                        <div className="row mt-3">
                          <div className="col">
                            <select className="custom-select" required
                                    data-behave-delete-label-select={label.label}
                                    onChange={(event) => setDeleteReplaceUuid(event.target.value)}>
                              <option value="_default">Category Default</option>
                              {labels.filter(e => e.category === category && e.uuid !== label.uuid)
                                  .sort((a, b) => a.order < b.order ? -1 : 0)
                                  .map(replacementLabel => (
                                      <option value={replacementLabel.uuid}
                                              data-behave-replacement-label={replacementLabel.label}>
                                        {replacementLabel.label}
                                      </option>
                                  ))}
                            </select>
                          </div>

                          <div className="col-auto pl-1">
                            <button className="btn btn-outline-secondary" onClick={() => setDeletingUuid(null)}>
                              Cancel
                            </button>

                            <button className="btn btn-dark ml-3 custom-field-finish-add"
                                    data-behave-replace-label={label.label}
                                    onClick={() => handleDelete(label, deleteReplaceUuid)}>
                              Replace
                            </button>
                          </div>
                        </div>
                      </Fragment>
                  )}
                </div>
              </div>
          ))}

          {addingStatusToCategory !== category && (
              <div className="row no-gutters p-3">
                <div className="col">
                  <button type="button" className="btn c-btn-add-info-status-name"
                          data-behave-status-category-add={category}
                          onClick={() => setAddingStatusToCategory(category)}>
                    <i className="fal fa-plus"></i> Add New Status Name
                  </button>
                </div>
              </div>
          )}

          {addingStatusToCategory === category && (
              <div className="row p-3">
                <div className="col">
                  <input className="form-control" value={newStatusName}
                         data-behave-new-status-text-input-category={category}
                         autoFocus
                         onChange={(event) => setNewStatusName(event.target.value)}
                         placeholder="New Status Name"
                         type="text"/>
                </div>

                <div className="col-auto pl-1">
                  <button className="btn btn-outline-secondary" onClick={() => setAddingStatusToCategory(null)}>
                    Cancel
                  </button>

                  <button className="btn btn-dark ml-3 custom-field-finish-add"
                          data-behave-new-status-submit-category={category}
                          onClick={handleNewStatusSave}>
                    Add
                  </button>
                </div>
              </div>
          )}

        </div>
      </div>
  ))


}

