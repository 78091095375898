import React, {useState, useRef, Fragment, useEffect, useReducer, useCallback} from "react"

export function CalendarModal({projectId, projectName}) {

  const [loaded, setLoaded] = useState(false);
  const [taskLink, setTaskLink] = useState()
  const [taskType, setTaskType] = useState('all_my_tasks')

  function handleTypeChange(event) {
    setTaskType(event.target.value);
    getLink(event.target.value);
  }

  function getLink(selectedType) {
    fetchSession(`/ical/get_or_create`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        type: selectedType,
        projectId: projectId
      })
    }).then(result => result.json())
      .then(result => {
        setTaskLink(result);
    })
  }

  useEffect(() => {
    if(!loaded) {
      getLink(taskType);
      setLoaded(true);
    }
  });


  return (
      <Fragment>

        <div className="row mb-4">
          <div className="col-12">
            <div className="mb-2">When using this feature, please note the following:</div>
            <ul>
              <li><h3>Most calendars will refresh every 24 hours</h3></li>
              <li><h3>Only <span className="c-text-underline">Tasks with Due Dates</span> will appear in your calendar</h3></li>
            </ul>
          </div>

          <div className="col-12">
            <a className="btn-link text-primary c-cursor-pointer" href="https://coordinatehqhelp.zendesk.com/hc/en-us/articles/35002463919123-Setting-Up-Calendar-Links" target="_blank">
              <i className="fas fa-question"></i> Click Here to read our
              guide
            </a>
          </div>
        </div>

        {projectId && (
            <h4 className="text-primary-accent mb-3">For: {projectName}</h4>
        )}

        <div className="row">
          <div className="col">
            <select className="custom-select" onChange={handleTypeChange}>
              <option value="all_my_tasks">My Tasks</option>
              <option value="all_tasks">All Tasks</option>
            </select>
          </div>
        </div>

        {taskLink && (
          <div className="row no-gutters mt-3">
            <div className="col-12">
              <h5 className="text-center">
                Your Calendar Link:
              </h5>
            </div>

            <div className="col-12 mt-2 c-background-bghighlight p-3">
              <h5 className="text-break">
                {taskLink}
              </h5>
            </div>

            <div className="col-12 mt-3">
              <button type="button" className="btn btn-primary btn-block"
                      onClick={() => navigator.clipboard.writeText(taskLink)}>
                <i className="fal fa-copy"></i> Copy Link
              </button>
            </div>
          </div>
        )
        }

      </Fragment>
  )
}