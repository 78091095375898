import React, {useState, useRef, Fragment, useEffect} from "react"
import FroalaEditor from "react-froala-wysiwyg";
import parse from "html-react-parser";
import {fetchSession} from "./common_functions";

export function Description({customerId, entityId, entityType, descriptionIsEditing, setDescriptionIsEditing,
                       description, setDescription, allowEdit, descriptionSub, setDescriptionSub}) {

  let descriptionPreviousValue = useRef(description);  // Will be used for cancel
  const descriptionEditor = useRef(null);

  const froalaDescriptionConfig = {
    toolbarButtons: ['paragraphFormat',
      'alignLeft', 'alignCenter', 'alignRight',
      'fontSize', 'bold', 'italic', 'underline', 'textColor',
      'insertLink', 'formatOL', 'formatUL', 'insertVideo', 'insertImage', 'html', 'insertTable',
      'clearFormatting'],
    zIndex: 2501,
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    linkInsertButtons: ['linkBack'],
    linkAlwaysBlank: true,
    attribution: false,
    quickInsertEnabled: false,
    iconsTemplate: 'font_awesome_5r',
    charCounterCount: false,
    fileUpload: false,
    imageUpload: true,
    imagePaste: true,
    videoUpload: false,
    imageUploadParam: 'image_param',
    imageUploadURL: `/image_upload/${customerId}`,
    imageUploadMethod: 'POST',
    imageMaxSize: 10 * 1024 * 1024,
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    key: 'AVB8B-21B2B3C3B2B2E1ua2BD1IMNBUMRWAd1AYMSTRBUZYA-9H3E2J2C4C6C3B1B5B1C2==',
    events: {
      initialized: function () {
        this.el.scrollIntoViewIfNeeded();
        this.el.focus();
      }
    },
    wordCounterCount: false,

  }

  function handleDescriptionCancelClick(event) {
    setDescription(descriptionPreviousValue.current);
    setDescriptionIsEditing(false);
    // clear the cache as well
    localStorage.removeItem(`desc_cache_${entityId}`)
  }

  function handleDescriptionSaveClick(event) {
    fetchSession(`/js/customer/${customerId}/${entityType}/${entityId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({description: description})

    }).then(response => response.json())
      .then(result => {
      debugger;
      if(result !== true) {
        setDescriptionSub(result);
      }
      setDescriptionIsEditing(false)
      if(description === '') {
        setDescription(null);
      }
      descriptionPreviousValue.current = description;
    });
  }

  function handleDescriptionModelChange(model) {
    localStorage.setItem(`desc_cache_${entityId}`, model);
    setDescription(model);
  }

  return (
    <Fragment>
      <div className="row mt-3 border-top">
        <div className="col"></div>
      </div>

      <div className="row mt-3 mb-2 align-items-center">
        <div className="col c-internal-heading">Description</div>
        <div className="col-auto">
          {(!descriptionIsEditing && allowEdit) && (
            <button type="button" className="btn c-btn-addto p-1" style={{width: "20px"}}
                    id="edit_description_button"
                    onClick={() => setDescriptionIsEditing(true)}>
              <i className="fal fa-edit"></i>
            </button>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col">
        {descriptionIsEditing ? (
          <Fragment>
            <div className="row">
              <div className="col">
                <span id="froala_description_locator">
                  <FroalaEditor config={froalaDescriptionConfig}
                                tag='textarea'
                                model={
                                  localStorage.getItem(`desc_cache_${entityId}`) == null
                                    ? description : localStorage.getItem(`desc_cache_${entityId}`)}
                                ref={descriptionEditor}
                                onModelChange={handleDescriptionModelChange}/>
                </span>
              </div>
            </div>
            
            <div className="row mt-2 justify-content-end">
              <div className="col-auto">
                <button className="btn btn-outline-secondary btn-sm"
                      id="cancel_description_edit"
                      onClick={handleDescriptionCancelClick}>
                  Cancel
                </button>
              </div>
              <div className="col-auto pl-0">
                <button className="btn btn-primary btn-sm"
                        id="save_description_edit"
                        onClick={handleDescriptionSaveClick}>
                  Save
                </button>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="fr-view" id="task_description_value">
            {descriptionSub ? parse(descriptionSub) : parse(description)}
          </div>
        )}
        </div>
      </div>
    </Fragment>
  )
}
