import React, {useState, useRef, Fragment, useEffect} from "react"
import * as ReactDOMClient from 'react-dom/client';
import {fetchSession} from "./common_functions";
import FroalaEditor from "react-froala-wysiwyg";

export function EditProjectLink({projectLinkId, vendorId, projectLink, forms, roles, templates, managers}) {
  const [currentForm, setCurrentForm] = useState(null);
  const [config, setConfig] = useState(projectLink)

  const [isDeleting, setIsDeleting] = useState(false);

  const sortedManagers = Object.keys(managers)
    .map(k => [managers[k], k])
    .sort((a, b) => {
      if(a[0] < b[0]) {
        return -1
      }
      if(a[0] > b[0]) {
        return 1;
      }
      return 0
    });

  function handleDelete() {
    fetchSession(`/js/project_links/${projectLinkId}/delete`, {
      method: 'post',
    }).then(result => {
      window.location.reload();
    });
  }

  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const titleInput = useRef()
  const projectNamePromptInput = useRef();
  const collaboratorEmailPromptInput = useRef();
  const collaboratorEmailPromptInput2 = useRef();
  const instructionsInput = useRef();

  const [instructionsText, setInstructionsText] = useState(config.instructions);

  function handleInstructionChange(model) {
    setInstructionsText(model);
    let c = {...config}
    if(model.length === 0 || model === '<p><br></p>') {
      delete c['instructions']
    } else {
      c['instructions'] = model;
    }
    updateConfig(c);
  }

  let froalaConfig = {
    toolbarButtons: ['paragraphFormat', 'align', 'fontSize', 'bold', 'italic', 'underline', 'textColor',
                     'insertLink', 'formatOL', 'formatUL', 'insertVideo', 'insertImage', 'html', 'insertTable',
                     'clearFormatting'],
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    linkInsertButtons: ['linkBack'],
    zIndex: null,
    //autofocus: true,
    linkAlwaysBlank: true,
    attribution: false,
    quickInsertEnabled: false,
    iconsTemplate: 'font_awesome_5r',
    charCounterCount: false,
    fileUpload: false,
    imageUpload: true,
    imagePaste: true,
    videoUpload: false,
    imageUploadParam: 'image_param',
    imageUploadURL: `/image_upload/VENDOR_PROJECT_LINK!${projectLinkId}!${vendorId}`,
    imageUploadMethod: 'POST',
    imageMaxSize: 10 * 1024 * 1024,
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    key: 'AVB8B-21B2B3C3B2B2E1ua2BD1IMNBUMRWAd1AYMSTRBUZYA-9H3E2J2C4C6C3B1B5B1C2==',
    wordCounterCount: false,
  }

  function updateConfig(c) {
    setConfig(c);
    fetchSession(`/js/project_links/${projectLinkId}`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify(c)
    })
  }

  function handleBlur(attribute, ref, callback) {
    const val = ref.current.value.trim()
    let c = {...config}
    if(val.length === 0) {
      delete c[attribute]
    } else {
      c[attribute] = val
    }

    updateConfig(c)
    if(callback !== undefined) {
      callback();
    }
  }

  function configIsValid() {
    return 'collaboratorEmailPrompt' in config
      && config.collaboratorEmailPrompt.length > 0
      && 'manager_user_id' in config
      && config.manager_user_id in managers
      && 'projectNamePrompt' in config
      && config.projectNamePrompt.length > 0
      && 'title' in config
      && config.title.length > 0;
  }

  return (
    <div className="" id={projectLinkId}>
      <div className="card mt-3 c-top-border-black">
        <div className="card-title mb-0">
          <div className="row align-items-center no-gutters border-bottom pt-2 pb-2 pl-3">
            <div className="col">
              {isEditingTitle ? (
                <input defaultValue={config.title}
                       className="form-control behave-create-link-title-input"
                       placeholder="Please provide a title"
                       autoFocus={true}
                       onBlur={() => handleBlur('title', titleInput, () => setIsEditingTitle(false))}
                       ref={titleInput} />
              ) :
                'title' in config ? (
                  <h2 className="c-cursor-pointer text-primary-accent" onClick={() => setIsEditingTitle(true)}>{config.title}</h2>
                ) : (
                  <h2 onClick={() => setIsEditingTitle(true)}
                      className="behave-set-title-link c-cursor-pointer c-project-link-title-input">
                    <i className="fal fa-pencil text-warning"></i> Please provide a title <i className="fas fa-asterisk c-text-required mt-2"></i>
                  </h2>
                )
              }
            </div>

            {!isDeleting ? (
              <div className="col-auto pr-1">
                <button className="btn btn-link-edit-danger" onClick={() => setIsDeleting(true)}>
                  <i className="far fa-trash-alt"></i> Delete Link
                </button>
              </div>
            ) : (
              <div className="col-auto">
               <button className="btn btn-link text-danger pl-1 pr-2" onClick={handleDelete}>
                  Confirm Delete?
                </button>
                &nbsp;
                <button className="btn btn-link pl-1 pr-1" onClick={() => setIsDeleting(false)}>
                  Cancel
                </button>
              </div>
            )}

            <div className="col-12"></div>

            {/*url*/}
            <div className="col">
              <div className="row no-gutters align-items-center">
                <div className="col-lg-auto mr-2 text-break">
                  Project Links URL:
                </div>

                <div className="col">
                  <span className="c-font-10 text-break" id="">
                    {configIsValid() ? (
                      <span className="behave-valid-create-link-config">
                        {`${window.location.origin}/create_project/${projectLinkId}`}
                      </span>
                    ) : (
                      <span className="text-danger">
                        <i className="fas fa-asterisk c-font-8"></i> Please complete required fields to activate
                      </span>

                    )}
                  </span>
                </div>
              </div>
            </div>
            {/*url*/}

            {/*Copy link button*/}
            <div className="col-auto pr-2 pl-2">
              <button type="submit" className="btn btn-outline-info"
                      onClick={() => {try {
                          navigator.clipboard.writeText(`${window.location.origin}/create_project/${projectLinkId}`);
                          document.getElementById('copy_link_button2').classList.add('d-none');
                          document.getElementById('copied_link_notification2').classList.remove('d-none');
                          setTimeout(x => {
                            document.getElementById('copy_link_button2').classList.remove('d-none');
                            document.getElementById('copied_link_notification2').classList.add('d-none');
                          }, 1000);
                      }catch (e) {}}}
                      id="copy_link_button2">
                <i className="fal fa-copy"></i>
              </button>
              <span className="ml-2 d-none" id="copied_link_notification2">Copied to clipboard!</span>
            </div>
            {/*Copy link button*/}
          </div>
        </div>

        <div className="card-body p-0">
          <div className="row no-gutters pt-0 pl-3 pr-3 pb-3">
            <div className="col">

              <div className="row mt-3">
                {/*Project Manager dropdown*/}
                <div className="col-lg-6">
                  <label className="c-internal-heading">
                    Project Manager

                    {/* TODO: Rick to create copy */}
                    <a className="btn-link ml-2 d-none"
                       tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-placement="right"
                       title="Title"
                       data-content="<p>Any tips/instructions here</p>"
                    >
                      <i className="far fa-info-circle text-muted"></i>
                    </a>
                    <i className="fas fa-asterisk c-text-required mt-1"></i>

                    <div className="c-text-optional mt-1">
                      <i className="fal fa-info-circle"></i> Who on your team will own the new project?
                    </div>
                  </label>

                  <div className="dropdown">
                    <a className="btn btn-dropdown behave-select-manager-dropdown"
                       href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                      <div className="row">
                        <div className="col text-left">
                          {config.manager_user_id in managers ? (
                            managers[config.manager_user_id]
                          ) : (
                            <span>
                              Select manager
                            </span>
                          )}
                        </div>

                        <div className="col-auto ml-2">
                          <i className="fas fa-caret-down"></i>
                        </div>
                      </div>
                    </a>

                    <div className="dropdown-menu behave-select-manager-dropdown-menu">
                      {sortedManagers.map(e => (
                        <a key={e[1]} data-behave-manager={e[0]}
                           className="dropdown-item" onClick={() => {
                          let c = {...config}
                          c['manager_user_id'] = e[1]
                          updateConfig(c);
                        }}>
                          {e[0]}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                {/*Project Manager dropdown*/}

                {/*Template dropdown*/}
                <div className="col-lg-6 mt-3 mt-lg-0">
                  <label className="c-internal-heading">
                    Template <span className="c-text-optional">(optional)</span>

                    {/* TODO: Rick to create copy */}
                    <a className="btn-link ml-2 d-none"
                       role="button" data-toggle="popover" data-trigger="focus" data-placement="right"
                       title="Title"
                       data-content="<p>If there are templates, info text here explaining that the project will be created from the template selected</p>"
                    >
                      <i className="far fa-info-circle text-muted"></i>
                    </a>

                    <div className="c-text-optional mt-1">
                      <i className="fal fa-info-circle"></i> Which template do you want to apply to the new project?
                    </div>
                  </label>

                  <p className="d-none">You do not have any templates <a className="btn-link p-0" href="#">learn more here</a> [disabled state on dropdown if no templates]</p>

                  <div className="dropdown">
                    <a className="btn btn-dropdown behave-template-dropdown"
                       href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                      <div className="row">
                        <div className="col text-left">
                          {templates.map(x => x[1]).includes(config.template_id) ? (
                            templates.find(x => x[1] === config.template_id)[0]
                          ) : (
                            <span>
                              None Selected
                            </span>
                          )}
                        </div>

                        <div className="col-auto ml-2">
                          <i className="fas fa-caret-down"></i>
                        </div>
                      </div>
                    </a>

                    <div className="dropdown-menu behave-template-menu">
                      {templates.map(template => (
                        <a key={template[1]} className="dropdown-item" data-behave-template-name={template[0]}
                           onClick={() => {
                          let c = {...config}
                          c['template_id'] = template[1]
                          updateConfig(c);
                        }}>
                          {template[0]}
                        </a>
                      ))}

                      <div className="dropdown-divider"></div>

                      {templates.map(x => x[1]).includes(config.template_id) && (
                        <a className="dropdown-item behave-remove-role" onClick={() => {
                          let c = {...config}
                          delete c.template_id;
                          updateConfig(c);
                        }}>
                          Remove template
                        </a>
                      )}
                    </div>
                  </div>

                  {/* if templates have dates - hide this if they don't TODO handle dates at all*/}
                  <div className="row mt-2 d-none">
                    <div className="col">
                      <p className="c-text-optional">This template has dates</p>
                    </div>
                  </div>
                </div>
                {/*Template dropdown*/}
              </div>


              {/* instructions for your client */}
              <div className="row mt-3">
                <div className="col">
                  <label className="c-internal-heading">
                    Instructions for your client <span className="c-text-optional">(optional)</span>

                    <div className="c-text-optional mt-1">
                      <i className="fal fa-info-circle"></i> What instructions would you like to give the person filling out this form to create a new project?
                    </div>
                  </label>
                  <FroalaEditor config={froalaConfig} tag="textarea" model={instructionsText}
                                onModelChange={handleInstructionChange} />
                </div>
              </div>
              {/* instructions for your client */}

              {/* Project Name Prompt */}
              <div className="row mt-3">
                {/* Project Name Prompt */}
                <div className="col-lg-6">
                  <label className="c-internal-heading">
                    Project Name Prompt

                    {/* TODO: Rick to create copy */}
                    <a className="btn-link ml-2 d-none"
                       tabIndex="0"
                       role="button" data-toggle="popover" data-trigger="focus" data-placement="right"
                       title="Title"
                       data-content="<p>Any tips/instructions here</p>"
                    >
                      <i className="far fa-info-circle text-muted"></i>
                    </a>
                    <i className="fas fa-asterisk c-text-required mt-1"></i>

                    <div className="c-text-optional mt-1">
                      <i className="fal fa-info-circle"></i> This is the text of the question asking them what to name their new project. Try something like "What is the name of your project?"
                    </div>
                  </label>

                  <input className="form-control behave-project-name-prompt-input"
                         placeholder="Please provide a prompt"
                         onBlur={() => handleBlur('projectNamePrompt', projectNamePromptInput)}
                         ref={projectNamePromptInput}
                         defaultValue={config.projectNamePrompt}/>

                </div>
                {/* Project Name Prompt */}

                {/* Form select dropdown */}
                <div className="col-lg-6 mt-3 mt-lg-0">
                  <label className="c-internal-heading">
                    Form <span className="c-text-optional">(optional)</span>

                    {/* TODO: Rick to create copy */}
                    <a className="btn-link ml-2 d-none"
                       role="button" data-toggle="popover" data-trigger="focus" data-placement="right"
                       title="What is a Project Role?"
                       data-content="<p>Project roles help you organize common responsibilities on projects. For example, a role can be assigned for specific tasks within a project</p>"
                    >
                      <i className="far fa-info-circle text-muted"></i>
                    </a>

                    <div className="c-text-optional mt-1">
                      <i className="fal fa-info-circle"></i> Do you want to ask them additional questions before they create the project? If so, select a form here.
                    </div>
                  </label>

                  <div className="dropdown">
                    <a className="btn btn-dropdown behave-form-dropdown"
                       href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                      <div className="row">
                        <div className="col text-left">
                        {forms.find(x => x.form_id === config.form_id) ? (
                          forms.find(x => x.form_id === config.form_id).form_name
                        ) : (
                          <span>
                            None
                          </span>
                        )}
                        </div>

                        <div className="col-auto ml-2">
                          <i className="fas fa-caret-down"></i>
                        </div>
                      </div>
                    </a>

                    <div className="dropdown-menu behave-form-menu">
                      {forms.map((form) => (
                        <a key={form.form_id} className="dropdown-item" data-behave-form-name={form.form_name} onClick={() => {
                          let c = {...config};
                          c['form_id'] = form.form_id;
                          updateConfig(c);
                      }}>
                          {form.form_name}
                        </a>
                      ))}

                      <div className="dropdown-divider"></div>

                      {'form_id' in config && forms.find(x => x.form_id === config.form_id) && (
                        <a className="dropdown-item behave-remove-role" onClick={() => {
                          let c = {...config}
                          delete c.form_id;
                          updateConfig(c);
                        }}>
                          Remove Form
                        </a>
                      )}

                      <a className="dropdown-item" href="/settings/forms">
                        Edit Project Forms
                      </a>
                    </div>
                  </div>
                </div>
                {/* Form select dropdown */}
              </div>

              {/* Email Prompt/Roles */}
              <div className="row mt-4">
                <div className="col">
                  <label className="c-internal-heading">
                    Collaborator Email Prompt
                    <i className="fas fa-asterisk c-text-required mt-1"></i>

                    <div className="c-text-optional mt-1">
                      <i className="fal fa-info-circle"></i> This is the text of the question asking them for their email address. The email they enter will be invited as a collaborator to the new project.
                    </div>
                  </label>

                  <input className="form-control behave-collaborator-email-prompt-input"
                         placeholder="Please provide a prompt"
                         ref={collaboratorEmailPromptInput}
                         onBlur={() => handleBlur('collaboratorEmailPrompt', collaboratorEmailPromptInput)}
                         defaultValue={config.collaboratorEmailPrompt}/>

                </div>

                {config.multiple_invite == undefined && (
                  <div className="col-lg-6 mt-2 mt-lg-0 ml-4 ml-lg-0">
                    <Fragment>
                      <label className="c-internal-heading">
                        Project Role <span className="c-text-optional">(optional)</span>

                        <div className="c-text-optional mt-1">
                          <i className="fal fa-info-circle"></i> The collaborator who is invited will also be assigned this role.
                        </div>
                      </label>

                      <div className="dropdown">
                        <a className="btn btn-dropdown behave-role-dropdown"
                           href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                          <div className="row">
                            <div className="col text-left">
                            {roles.includes(config.role) ? (
                              config.role
                            ) : (
                              <span>
                                None
                              </span>
                            )}
                            </div>

                            <div className="col-auto ml-2">
                              <i className="fas fa-caret-down"></i>
                            </div>
                          </div>
                        </a>

                        <div className="dropdown-menu behave-role-menu">
                          {roles.map((role) => (
                            <a key={role} className="dropdown-item" data-behave-role={role} onClick={() => {
                              let c = {...config};
                              c['role'] = role;
                              updateConfig(c);
                          }}>
                              {role}
                            </a>
                          ))}


                          <div className="dropdown-divider"></div>

                          {roles.includes(config.role) && (
                            <a className="dropdown-item behave-remove-role" onClick={() => {
                              let c = {...config}
                              delete c.role;
                              updateConfig(c);
                            }}>
                              Remove Role
                            </a>
                          )}

                          <a className="dropdown-item" href="/settings/project_roles">
                            Edit Project Roles
                          </a>
                        </div>
                      </div>
                    </Fragment>
                  </div>
                )}
              </div>
              {/* Email Prompt */}

              {/* Second Email Prompt/Roles */}
              <div className="row mt-4">
                <div className="col">
                  <label className="c-internal-heading">
                    Collaborator Email Prompt 2 <span className="c-text-optional">(optional)</span>
                  </label>

                  <input className="form-control behave-collaborator-email-prompt-input2"
                         placeholder="Please provide a prompt"
                         ref={collaboratorEmailPromptInput2}
                         onBlur={() => handleBlur('collaboratorEmailPrompt2', collaboratorEmailPromptInput2)}
                         defaultValue={config.collaboratorEmailPrompt2}/>

                </div>

                {config.multiple_invite === undefined && (
                  <div className="col-lg-6 mt-2 mt-lg-0 ml-4 ml-lg-0">
                    <Fragment>
                      <label className="c-internal-heading">
                        Project Role 2 <span className="c-text-optional">(optional)</span>
                      </label>

                      <div className="dropdown">
                        <a className="btn btn-dropdown behave-role-dropdown2"
                           href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                          <div className="row">
                            <div className="col text-left">
                            {roles.includes(config.role2) ? (
                              config.role2
                            ) : (
                              <span>
                                None
                              </span>
                            )}
                            </div>

                            <div className="col-auto ml-2">
                              <i className="fas fa-caret-down"></i>
                            </div>
                          </div>
                        </a>

                        <div className="dropdown-menu behave-role-menu2">
                          {roles.map((role) => (
                            <a key={role} className="dropdown-item" data-behave-role={role} onClick={() => {
                              let c = {...config};
                              c['role2'] = role;
                              updateConfig(c);
                          }}>
                              {role}
                            </a>
                          ))}


                          <div className="dropdown-divider"></div>

                          {roles.includes(config.role) && (
                            <a className="dropdown-item behave-remove-role2" onClick={() => {
                              let c = {...config}
                              delete c.role2;
                              updateConfig(c);
                            }}>
                              Remove Role
                            </a>
                          )}

                          <a className="dropdown-item" href="/settings/project_roles">
                            Edit Project Roles
                          </a>
                        </div>
                      </div>
                    </Fragment>
                  </div>
                )}
              </div>
              {/* Email Prompt */}

              <div className="row mt-2 d-none-feature">
                <div className="col ml-4 ml-lg-0">
                  <input className="" type='checkbox' defaultChecked={config.multiple_invite} onChange={(change) => {
                      let c = {...config};
                      if(change.target.checked) {
                        c.multiple_invite = true;
                      } else {
                        delete c.multiple_invite;
                      }
                      updateConfig(c);
                    }} />
                  <label className="c-internal-heading ml-2">
                    Allow multiple invites with role select
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <button className="btn btn-primary behave-save-create-project-link">
                    Save Project Link
                  </button>
                </div>
                {/*
                <div className="col">
                  <a className="btn-link text-left" href="#">
                    + Add another collaborator email field [only show if there are more applicable fields, hide if none left]
                  </a>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

