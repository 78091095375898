import React, {useState, useRef, Fragment, useEffect} from "react"


export function FileProgress({uploadProgress}) {
  /* Expects a list of file progress objects
  {
    'fileUID': xy
    'name': 'woot'
    'progress': 0-100
  }
   */
  return (
    <Fragment>
      {uploadProgress.filter(e => !e.done).map(entry => (
        <div className="progress m-1 mt-2 " style={{width: '100%', height: '40px'}} key={entry.fileUid}>
          <div className="progress-bar progress-bar-striped" role="progressbar"
               style={{width: `${entry.progress}%`}} aria-valuenow={entry.progress} aria-valuemin="0" aria-valuemax="100">
            {entry.name}
          </div>
        </div>
      ))}
    </Fragment>
  )
}