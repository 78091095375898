import React, {useState, useRef, Fragment, useEffect} from "react"
import * as ReactDOMClient from 'react-dom/client';
import {DateTime} from "luxon";

import {fetchSession, formatDateShort, getUserStakeholderId} from "./common_functions";

// Froala imports
import FroalaEditor from 'react-froala-wysiwyg';

// We use this parsing library to render out the contents of the Froala editor
import parse from 'html-react-parser';

import Select from 'react-select'

import flatpickr from "flatpickr";


export function TimeTracking({timeTrackingEntries, setTimeTrackingEntries,
                              timeTrackingIsEditing, setTimeTrackingIsEditing,
                              timeTrackingDateEntryValue, setTimeTrackingDateEntryValue,
                              isBudgeting, allowEdit, stakeholders, customerId, taskId}) {

  // Time Tracking

  const timeTrackingUserSelect = useRef();
  const timeTrackingDateInput = useRef();

  // entry_id of the currently under edit entry, if any
  const [timeTrackingEntryUnderEdit, setTimeTrackingEntryUnderEdit] = useState(null);

  const [timeTrackingNoteErrorMessage, setTimeTrackingNoteErrorMessage] = useState(null);
  const [timeTrackingNewEntryNote, setTimeTrackingNewEntryNote] = useState('');
  const [timeTrackingHoursErrorMessage, setTimeTrackingHoursErrorMessage] = useState(null);
  const [timeTrackingNewEntryHours, setTimeTrackingNewEntryHours] = useState('');

  // We only show the two most recent by default
  const [showAll, setShowAll] = useState(false);

  function handleTimeTrackingNewEntryClick() {
    if(timeTrackingNewEntryNote === '') {
      setTimeTrackingNoteErrorMessage("Note is required.");
      return
    }

    if(timeTrackingNewEntryHours === '') {
      setTimeTrackingHoursErrorMessage('Number required.');
      return
    }

    // Seeing the above trip for NaN, seems like input type=number when handed a string "Bob" will record empty string
    if(isNaN(timeTrackingNewEntryHours)) {
      setTimeTrackingHoursErrorMessage('Hours must be a number.');
    }

    fetchSession(`/js/customer/${customerId}/task/${taskId}/add_time_tracking_entry`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        note: timeTrackingNewEntryNote,
        hours: timeTrackingNewEntryHours,
        entry_id: timeTrackingEntryUnderEdit,
        stakeholder_id: timeTrackingUserSelect.current.value,
        entry_dt: timeTrackingDateEntryValue,
        budget: isBudgeting
      })
    }).then((response) => response.json())
      .then((result) => {
        if(timeTrackingEntryUnderEdit) {
          setTimeTrackingEntries([...timeTrackingEntries.filter(x => x.entry_id !== timeTrackingEntryUnderEdit), result].sort((a,b) => a.entry_dt > b.entry_dt))
        } else {
          setTimeTrackingEntries([...timeTrackingEntries, result])
        }
        setTimeTrackingNewEntryHours(null);
        setTimeTrackingNewEntryNote(null)
        setTimeTrackingIsEditing(false);
        setTimeTrackingEntryUnderEdit(null);

        // forces reload of main page
        window.taskIsDirty = true;
    });
  }

  function handleTimeTrackingEntryRemove(event) {
    fetchSession(`/js/customer/${customerId}/task/${taskId}/remove_time_tracking_entry`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        entryId: timeTrackingEntryUnderEdit,
      })
    }).then((response) => response.json())
      .then((result) => {
        setTimeTrackingEntries(timeTrackingEntries.filter((entry) => entry.entry_id !== timeTrackingEntryUnderEdit));
        setTimeTrackingEntryUnderEdit(null);
    });
  }

  function handleTimeTrackingEntryEditClick(entry_id) {
    let entry = null;
    if(entry_id !== undefined) {
      entry = timeTrackingEntries.filter(x => x.entry_id === entry_id)[0];
      setTimeTrackingNewEntryHours(entry.hours);
      setTimeTrackingNewEntryNote(entry.note);
      setTimeTrackingDateEntryValue(timeTrackingEntries.filter(x => x.entry_id === entry_id)[0].entry_dt );
    } else {
      setTimeTrackingDateEntryValue(new Date());
    }

    setTimeTrackingNoteErrorMessage(null);
    setTimeTrackingHoursErrorMessage(null);

    setTimeTrackingEntryUnderEdit(entry_id);
    setTimeTrackingIsEditing(false);
  }

  function renderTimeTrackingEntryEdit(entry_id) {
    let entry = null;
    if(entry_id !== undefined) {
      entry = timeTrackingEntries.filter(x => x.entry_id === entry_id)[0];
    }

    return (
      <Fragment key={entry_id === undefined ? 'new_entry' : entry_id} >
        <div className="row align-items-center no-gutters mt-2">
          <div className="col">
            <i className="fal fa-edit"></i> Manual Entry - Time is recorded in hours
          </div>
        </div>

        <div className="row align-items-center no-gutters mt-2">
          <div className="col-2">
            <div className="form-group mb-0">
              <input className={"form-control behave-time-tracking-hours-input " + (timeTrackingHoursErrorMessage === null ? '' : 'is-invalid') }
                     type="number" placeholder="Hours"
                     defaultValue={entry !== null ? entry.hours : null}
                     onChange={(e) => setTimeTrackingNewEntryHours(e.target.value)}/>
              <div className="invalid-feedback">
                {timeTrackingHoursErrorMessage}
              </div>
            </div>
          </div>

          <div className="col">
            <div className="form-group mb-0 ml-2">
              <input className={"form-control behave-time-tracking-note-input " + (timeTrackingNoteErrorMessage === null ? '' : 'is-invalid')}
                     type="text" placeholder="Note"
                     defaultValue={entry !== null ? entry.note : null}
                     onKeyDown={(e) => e.key === 'Enter' && handleTimeTrackingNewEntryClick(e)}
                     onChange={(e) => setTimeTrackingNewEntryNote(e.target.value)}/>
              <div className="invalid-feedback">
                {timeTrackingNoteErrorMessage}
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center no-gutters mt-2">
          <div className="col">
            <select id="time-tracking-who-select"
                    ref={timeTrackingUserSelect}
                    defaultValue={entry === null ? getUserStakeholderId() : entry.stakeholder_id}
                    className="custom-select">
              {stakeholders.filter(e => e.is_user && e.is_on_plan).map(stakeholder => (
                <option key={stakeholder.stakeholder_id}
                        value={stakeholder.stakeholder_id}>
                  {stakeholder.stakeholder_full_name}
                </option>
              ))}
            </select>
          </div>

          <div className="col ml-2">
            <div className="flatpickr" ref={timeTrackingDateInput}>
                <input type="text" className="form-control" data-input=""
                       id="time-tracking-date-input" />
            </div>
          </div>
        </div>

        <div className="row no-gutters mt-2 pb-2 border-bottom">
          {entry_id !== undefined ? (
            <Fragment>
              <div className="col">
                <button className="btn btn-sm btn-outline-danger" onClick={(e) => handleTimeTrackingEntryRemove(e)}
                        data-entry-id={entry.entry_id}>
                  <i className="far fa-trash-alt"></i> Delete Entry
                </button>
              </div>
            </Fragment>
          ) : (
            <div className="col"></div>
          )}

          <div className="col-auto">
            <button type="button" className="btn btn-sm mr-2 btn-outline-muted"
                    onClick={() => {
                      entry_id === undefined ? setTimeTrackingIsEditing(false) : setTimeTrackingEntryUnderEdit(null);
                      setTimeTrackingNoteErrorMessage(null);
                      setTimeTrackingHoursErrorMessage(null);
                    }}>
              Cancel
            </button>

            <button type="button" className="btn btn-sm btn-primary"
                    id="add-time-tracking-button"
                    onClick={handleTimeTrackingNewEntryClick}>
              {entry_id === undefined ? 'Add Entry' : 'Update'}
            </button>
          </div>
        </div>
      </Fragment>
    )
  }


  useEffect((didUpdate) => {
    // time tracking date picker
    // completedDate init flatpickr, may not exist if this task was completed, instead we render a completed date picker
    if(timeTrackingIsEditing || timeTrackingEntryUnderEdit) {
      let instance = flatpickr(timeTrackingDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: timeTrackingDateEntryValue,
        onChange: (selectedDates, dateStr, instance) => {
          setTimeTrackingDateEntryValue(selectedDates[0]);
          //handleCompletedDateChange(selectedDates, dateStr, instance);
        }
      });

      window.timeTrackingDateFlatpickr = instance;
    }
  });

  return (
    <Fragment>
    {((timeTrackingEntries.length > 0 || timeTrackingIsEditing)) && (
      <Fragment>
        <div className="row mt-3 no-gutters border-top">
          <div className="col">
          </div>
        </div>

        <div className="row mt-3 mb-4 align-items-center">
          <h3 className="col c-internal-heading text-primary-accent">
            <i className="far fa-stopwatch-20"></i> Time Tracking {isBudgeting ? 'Budget' : ''}
          </h3>

          <Fragment>
            {timeTrackingEntries.length > 2 && (
                <div className="col-sm-auto mt-3 mt-sm-0">
                  <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowAll(!showAll)}>
                    {showAll ? (
                        <Fragment>
                          <i className="far fa-eye-slash"></i> Hide entries
                        </Fragment>
                    ) : (
                        <Fragment>
                          {timeTrackingEntries.length > 2 && (
                              <Fragment>
                                <i className="far fa-eye"></i> Show {timeTrackingEntries.length - 2} more {(timeTrackingEntries.length - 2) > 1 ? 'entries' : 'entry'}

                              </Fragment>
                          )}
                        </Fragment>
                    )}
                  </button>
                </div>
            )}
          </Fragment>
        </div>

        {timeTrackingEntries.slice(showAll ? null : -2).map(entry => entry.entry_id === timeTrackingEntryUnderEdit ? (
            renderTimeTrackingEntryEdit(entry.entry_id)
        ) : (
            <div key={entry['entry_id']}
                 className="row align-items-center border-bottom no-gutters mt-2 pb-2 ml-4 behave-time-tracking-row">
              <div className="col">
                <div className="row mb-2">
                  <div className="col">
                    <h4 className="pt-0 text-primary-accent">{parseFloat(entry['hours']).toFixed(2)} hour{entry['hours'] > 1 ? 's' : ''}</h4>
                  </div>

                  <div className="col-md-auto pt-2 pt-md-0">
                    {entry['stakeholder_full_name']}
                  </div>
                </div>

                <div className="row">
                  <div className="col order-md-1 order-2 pt-2 pt-md-0">
                    <span className="c-text-optional ">Note:</span> {entry['note']}
                  </div>

                  <div className="col-md-auto order-md-2 order-1">
                    {DateTime.fromISO(entry['entry_dt']).toLocaleString()}
                  </div>
                </div>
              </div>

            {allowEdit && (
                <div className="col-auto c-cursor-pointer">
                  <i className="fal fa-edit behave-time-tracking-edit pt-3 pb-3 pl-3"
                   onClick={() => handleTimeTrackingEntryEditClick(entry.entry_id)}
                   data-entry-id={entry['entry_id']}>
                </i>
              </div>
            )}
          </div>
        ))}

        {timeTrackingIsEditing && renderTimeTrackingEntryEdit()}

        {!timeTrackingIsEditing && allowEdit && (
          <div className="row mt-2 mb-3">
            <div className="col">
              <button type="button" className="btn btn-link text-primary pl-4"
                      id={'add-time-tracking-entry-button' + (isBudgeting ? '-budget' : '')}
                      onClick={() => {
                        setTimeTrackingIsEditing(true);
                        setTimeTrackingEntryUnderEdit(null);
                        setTimeTrackingDateEntryValue(new Date());
                      }}>
                <i className="fas fa-plus"></i> Record {isBudgeting ? 'Time Budget' : 'Time'} Manually
              </button>
            </div>
          </div>
        )}
      </Fragment>
    )}
    </Fragment>
  )

}