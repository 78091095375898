import React, {useState, useRef, Fragment, useEffect} from "react"
import Select from 'react-select'

import {fetchSession} from "./common_functions";

export function Tags({projectStyle, customerId, goalId, taskId, tags, setTags, allAvailableTags}) {

  function handleTagRemoveClick(tag) {
    let tagList = tags.filter((t) => t !== tag);

    let fetchUrl = null;
    if(!goalId && !taskId) {
      fetchUrl = `/js/project/${customerId}/set`
    } else {
      fetchUrl = goalId ?
        `/js/customer/${customerId}/goal/${goalId}/set`
        : `/js/customer/${customerId}/task/${taskId}/set`
    }

    fetchSession(fetchUrl, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({tags: tagList})
    }).then(() => {
      setTags(tagList);
    });
  }

  if(projectStyle !== undefined) {
    return tags.length > 0 && (
      <Fragment>
        <div className="row mt-3 border-top">
          <div className="col">
          </div>
        </div>

        <div className="row mt-3 mb-2">
          <div className="col c-internal-heading">Tags</div>
        </div>

        <div className="row">
          <div className="col-12 c-tag">
          {tags.map((t) => (
            <span className="badge badge-muted mb-2 ml-2 behave-tag-marker c-badge"
                  style={allAvailableTags[t] ? {'background': allAvailableTags[t]} : null}
                  key={t}
                  data-behave_goal_tag={t}>
            {t}
              <button className="btn badge badge-muted border-muted c-badge"
                      data-behave-tag-remove={t}
                      style={allAvailableTags[t] ? {'background': allAvailableTags[t]} : null}
                      onClick={(e) => handleTagRemoveClick(t)}>
                <i className="fas fa-times badge-muted"
                      style={allAvailableTags[t] ? {'background': allAvailableTags[t]} : null}></i>

              </button>
            </span>
          ))}
          </div>
        </div>
      </Fragment>
    )
  } else {
    return tags.length > 0 && (
      <div className="row align-items-center no-gutters c-hover-indicator-container mt-3">
        <div className="col">
          <div className="card border-dark mb-3 mt-3">
            <div className="card-header p-2">
              <div className="row align-items-center">
                <div className="col">
                  Tags <i>(only visible to Users)</i>
                </div>
              </div>
            </div>
            <div className="card-body p-2 text-dark">
              <div className="row align-items-center">
                <div className="col-12 c-tag">
                  {tags.map((t) => (
                    <span className="badge badge-muted mb-2 ml-2 behave-tag-marker c-badge"
                          style={allAvailableTags[t] ? {'background': allAvailableTags[t]} : null}
                          key={t}
                          data-behave_goal_tag={t}>
                    {t}

                      <button className="btn badge badge-muted border-muted c-badge"
                              data-behave-tag-remove={t}
                              style={allAvailableTags[t] ? {'background': allAvailableTags[t]} : null}
                              onClick={(e) => handleTagRemoveClick(t)}>
                      <i className="fas fa-times badge-muted"
                         style={allAvailableTags[t] ? {'background': allAvailableTags[t]} : null} ></i>
                    </button>
                  </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// hack can't see to determine how to pass props here so we will duplicate this for the blocked and blocks dropdown
function DropdownIndicatorBlocks(props) {
  return (
   <div style={{'marginRight': '13px'}} data-behave-task-tag-select={true}>
    <i className="fas fa-caret-down"></i>
   </div>)
}

const IndicatorSeparator = ({children, ...props}) => {
  return (<Fragment></Fragment>)
}

export function TagsDropdown(props) {

  const [selectedValue, setSelectedValue] = useState('');

  function handleAddTagClick(event) {
    const newTag = event.label;
    const newTags = [...new Set([...props.tags, newTag])];

    let fetchUrl = null;
    if(!props.hasOwnProperty('goalId') && !props.hasOwnProperty('taskId')) {
      fetchUrl = `/js/project/${props.customerId}/set`
    } else {
      fetchUrl = props.hasOwnProperty('goalId') ?
        `/js/customer/${props.customerId}/goal/${props.goalId}/set`
        : `/js/customer/${props.customerId}/task/${props.taskId}/set`
    }

    fetchSession(fetchUrl, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({tags: newTags})
    }).then(() => {
      props.setTags(newTags);
      setSelectedValue(null);
    });
  }

  return (
    <Select
      value={selectedValue}
      components={{DropdownIndicator:DropdownIndicatorBlocks, IndicatorSeparator}}
      styles={{
        control: (baseStyles, state) => {
           return {
             ...baseStyles,
             width: '140px',
             borderWidth: '0px',
             borderColor: '#b3b3b3',
             backgroundColor: '#f9f9f9',
             cursor: 'pointer',
             textAlign: 'left'
         }}
       }}

      options={Object.keys(props.allAvailableTags).map(t => {
        return {value: t, label: t}
      })}
      placeholder="Add Tag"
      onChange={handleAddTagClick}
    />
  )
}