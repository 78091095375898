import React, {useState, useRef, Fragment, useEffect, useReducer} from "react"
import {fetchSession} from "./common_functions";
import {Discussion} from "./discussion";
import {DateTime} from "luxon";
import parse from "html-react-parser";


export function Inbox({projectNoun, projectPluralNoun, userRole, collaboratorLastReplyFilter}) {
  const [loaded, setLoaded] = useState(false);

  // False is all projects, gated on role
  const [filterMyProjects, setFilterMyProjects] = useState(true);

  function handleFilterMyProjectsChange(filterState) {
    setFilterMyProjects(filterState);
    getMessages([], new Set(), viewingInternal, viewingArchived, viewingStarred, filterState);
  }

  const [threads, setThreads] = useState([]);
  const [entityIdClosedSet, setEntityIdClosedSet] = useState(new Set());
  const [hasMoreThreads, setHasMoreThreads] = useState(false);

  // When they click on a thread this will get set and we will render that thread
  const [selectedThread, setSelectedThread] = useState(null);

  const [viewingInternal, setViewingInternal] = useState(false);
  const [viewingArchived, setViewingArchived] = useState(false);
  const [viewingStarred, setViewingStarred] = useState(false);

  // mention is the red dot, mention is the black dot, we will async load these as another call after loading
  // we may try to remove these based on mark read actions, it is not critical this be 100% accurate
  // so we may have some very old unread mentions as we "load older discussions"
  const [inboxMentionIndicator, setInboxMentionIndicator] = useState(false);
  const [inboxUnreadIndicator, setInboxUnreadIndicator] = useState(false);
  const [internalInboxMentionIndicator, setInternalInboxMentionIndicator] = useState(false);
  const [internalInboxUnreadIndicator, setInternalInboxUnreadIndicator] = useState(false);
  const [starredMentionIndicator, setStarredMentionIndicator] = useState(false);
  const [starredUnreadIndicator, setStarredUnreadIndicator] = useState(false);
  const [starredInternalMentionIndicator, setStarredInternalMentionIndicator] = useState(false);
  const [starredInternalUnreadIndicator, setStarredInternalUnreadIndicator] = useState(false);

  const [filterCollabLastReply, setFilterCollabLastReply] = useState(collaboratorLastReplyFilter);
  // checkbox refuses to change, we'll just useref wtf?
  const filterCheckbox = useRef();

  function handleCollabLastReplyFilterChange(event) {
    fetchSession(`/inbox/messages/toggle_last_reply`, {
      method: 'post'
    }).then(() => {
      filterCheckbox.current.checked = !filterCollabLastReply;
      setFilterCollabLastReply(!filterCollabLastReply);
    })
  }


  function handleViewSwitch(internal, archived, starred) {
    // no-op if we are already viewing what they want
    setSelectedThread(null);
    if(internal === viewingInternal && archived === viewingArchived && starred === viewingStarred) {
      return
    }
    setViewingInternal(internal);
    setViewingArchived(archived)
    setViewingStarred(starred)
    getMessages([], new Set(), internal, archived, starred, filterMyProjects);
  }

  function handleArchive(thread) {
    fetchSession(`/inbox/messages/mark_archived/${thread.customer_id}/entity/${thread.discussion_entity_name}/${thread.discussion_entity_id}/internal/${viewingInternal ? 'True' : 'False'}`, {
      method: 'post',
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify({})
    }).then((response) => response.json())
      .then((result) => {
        const newThreads = threads.filter(t => t.discussion_entity_id !== thread.discussion_entity_id)
        setThreads(newThreads);
        updateIndicators(viewingInternal, newThreads);
      })
  }

  function handleUnArchive(thread) {
    fetchSession(`/inbox/messages/mark_unarchived/${thread.customer_id}/entity/${thread.discussion_entity_name}/${thread.discussion_entity_id}/internal/${viewingInternal ? 'True' : 'False'}`, {
      method: 'post',
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify({})
    }).then((response) => response.json())
      .then((result) => {
        const newThreads = threads.filter(t => t.discussion_entity_id !== thread.discussion_entity_id)
        setThreads(newThreads);
        updateIndicators(viewingInternal, newThreads);
      })
  }


  function getMessages(currentThreads, currentClosedSet,
                       internal=false, archived=false, starred=false,
                       myprojects=true) {
    // Note the use of a closed entity_id set, we can have very old discussion_entries come in from the get_messages
    // and they may be older entries for an entity we already have in the list at the top
    let oldestTimestamp = null;
    if(currentThreads.length > 0) {
      oldestTimestamp = currentThreads[currentThreads.length - 1].original_discussion_timestamp_dt;
    }
    fetchSession('/inbox/messages', {
      method: 'post',
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify({
        oldestTimestamp: oldestTimestamp,
        filterMyProjects: myprojects,
        internal: internal,
        archived: archived,
        starred: starred
      })
    }).then((response) => response.json())
      .then((result) => {
        setLoaded(true);
        // parse some of the dates coming back
        let processed = result.threads.map(entry => {
          // We save this old one so we can hand it to the backend during "load more"
          // luxon was handing back a +timezone which was messing up the sort on the dynamo call
          entry.original_discussion_timestamp_dt = entry.discussion_timestamp_dt
          entry.discussion_timestamp_dt = DateTime.fromISO(entry.discussion_timestamp_dt);
          return entry;
        })
        .filter(entry => !currentClosedSet.has(entry['discussion_entity_id']))

        processed.forEach(entry => currentClosedSet.add(entry['discussion_entity_id']))
        setEntityIdClosedSet(currentClosedSet);

        setThreads(currentThreads.concat(processed));
        setHasMoreThreads(result.has_more_threads);

        // this is a double catch in case the backend gives us a false has more threads
        if(processed.length === 0) {
          setHasMoreThreads(false)
        }
    });
  }

  useEffect(() => {
    if(!loaded) {
      getMessages([], new Set(), viewingInternal, viewingArchived, viewingStarred, filterMyProjects);
      // Have these load in after first paint
      setTimeout(() => {
         fetchSession('/inbox/messages/indicators', {
          method: 'post',
        }).then((response) => response.json())
          .then((result) => {
            setInboxMentionIndicator(result.inbox_mention);
            setInboxUnreadIndicator(result.inbox_unread);
            setInternalInboxMentionIndicator(result.internal_inbox_mention);
            setInternalInboxUnreadIndicator(result.internal_inbox_unread);

            setStarredMentionIndicator(result.starred_mention);
            setStarredUnreadIndicator(result.starred_unread);

            setStarredInternalMentionIndicator(result.starred_internal_mention);
            setStarredInternalUnreadIndicator(result.starred_internal_unread);

          })
      }, 100);
    } else {
      sizeChatElements();
    }
  });

  function getEntityName(thread) {
    switch(thread.discussion_entity_name) {
      case "Task": return '[Task]';
      case "Goal": return '[Goal]';
      default: return '[General Discussion]';
    }
  }

  function dateTimeIsToday(datetime) {
    const n = DateTime.now();
    return n.hasSame(datetime, 'day');
  }

  function getTextFromHTML(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  function markThreadAsRead(thread) {
    const threadsPrime = threads.map(t => {
      if(t.discussion_entity_id === thread.discussion_entity_id) {
        t.unseen_mentions = 0;
        t.indicate = false;
      }
      return t;
    })
    setThreads(threadsPrime);
    updateIndicators(viewingInternal, threadsPrime);

    fetchSession(`/inbox/messages/mark_read/${thread.customer_id}/entity/${thread.discussion_entity_name}/${thread.discussion_entity_id}/internal/${viewingInternal ? 'True' : 'False'}`, {
      method: 'post',
    });
  }

  function updateIndicators(internal, threads) {
    const mentions = threads.filter(t => t.unseen_mentions > 0).length > 0;
    const unread = threads.filter(t => t.indicate).length > 0;

    if(internal) {
      if(viewingStarred) {
        setStarredInternalMentionIndicator(mentions);
        setStarredInternalUnreadIndicator(unread);
      } else {
        setInternalInboxUnreadIndicator(unread);
        setInternalInboxMentionIndicator(mentions);
      }

      if(!mentions && !inboxMentionIndicator) {
        document.querySelector('#notification-inbox').classList.add('d-none');
      }
    } else {
      if(viewingStarred) {
        setStarredMentionIndicator(mentions);
        setStarredUnreadIndicator(unread);
      } else {
        setInboxMentionIndicator(mentions);
        setInboxUnreadIndicator(unread);
      }
      if(!mentions && !internalInboxMentionIndicator) {
        document.querySelector('#notification-inbox').classList.add('d-none');
      }
    }

  }

  function handleThreadClick(thread) {
    markThreadAsRead(thread);
    setSelectedThread(thread);
  }

  function handleStarClick(thread) {
     fetchSession(`/inbox/messages/star_toggle/${thread.customer_id}/entity/${thread.discussion_entity_name}/${thread.discussion_entity_id}/internal/${viewingInternal ? 'True' : 'False'}`, {
      method: 'post',
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify({})
    }).then((response) => response.json())
      .then((result) => {
        const newThreads = threads.map(t => {
          if(t.discussion_entity_id === thread.discussion_entity_id) {
            t.starred = !t.starred;
          }
          return t;
        })
        // They hide if starred when viewing starred
        if(viewingStarred) {
          setThreads(newThreads.filter(x => x.starred));
        } else {
          setThreads(newThreads);
        }
      })
  }

  return (
    <Fragment>
      <div className="c-inbox-header" id="inboxHeader">
        {/* Showing x of y header */}
        <div className="container-fluid mt-2 mb-2 d-none">
          <div className="row align-items-center">
            <div className="col-auto c-project-list-info-text">
              <span className="c-text-info font-italic">
                Showing {threads.length} Discussions
                {/*
                Showing 100 of 30000 total get_project_noun_singular(vendor) Discussions
                (Only showing first 100)
                */}
              </span>
            </div>
          </div>
        </div>

        {/* Filters */}
        <div className="container-fluid p-0">
          <div className="row c-main-cta align-items-center">
            <div className="col-auto c-phone-md pr-0">
              <a className="btn-link p-1" type="button" data-toggle="collapse" data-target="#navbarInboxMobile"
                 aria-controls="navbarInboxMobile" aria-expanded="false" aria-label="Toggle navigation">
                <h2 className="c-navbar-icon"><i className="fas fa-bars"></i></h2>
              </a>
            </div>

            {/* TODO adding filtering */}
            <div className="col-auto d-none-feature">
              <div id="all_projects_filter_dropdown" className="dropdown">
                <button className="btn btn-sm c-btn-dropdown dropdown-toggle"
                        id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fal fa-filter c-text-info"></i>
                  Add Filter
                </button>

                <ul className="dropdown-menu" aria-labelledby="filterAddMenu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Filter Item
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {userRole !== 'RESTRICTED_MANAGER' && (
                <div className="col-auto">
                  <div id="all_projects_filter_dropdown" className="dropdown">
                    <button className="btn c-txt-btn dropdown-toggle" role="button"
                            id="all-projects-filter-button"
                            data-toggle="dropdown">
                      {filterMyProjects ? (
                          <span>My {projectPluralNoun}</span>
                      ) : (
                          <span>All {projectPluralNoun}</span>
                      )}
                    </button>

                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#" onClick={() => handleFilterMyProjectsChange(false)}>
                        All {projectPluralNoun}
                      </a>
                      <a className="dropdown-item" href="#" onClick={() => handleFilterMyProjectsChange(true)}>
                        My {projectPluralNoun}
                      </a>
                    </div>
                  </div>
                </div>
            )}

            <div className="col-auto p-0">
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input"
                       defaultChecked={filterCollabLastReply}
                       ref={filterCheckbox}/>
                <label className="custom-control-label c-cursor-pointer c-text-custom"
                       style={{'padding-top': "2px"}}
                       onClick={handleCollabLastReplyFilterChange}>
                  Collaborator Last Replied
                </label>
              </div>
            </div>

            {/* TODO some other kind of filter */}
            <div className="col-auto pr-2 pb-2 d-none-feature">
              <div id="all_projects_filter_dropdown" className="dropdown">
                <button className="btn btn-sm c-btn-dropdown dropdown-toggle"
                        id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Unread First
                </button>

                <ul className="dropdown-menu" aria-labelledby="filterAddMenu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Chronological
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="#">
                      Collaborator Last Replied
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* TODO filters */}
            <div className="col-auto pr-2 pb-2 d-none-feature">
              <form action="" method="post">
                <button className="btn btn-link c-font-8" id="">
                  <i className="far fa-trash-alt"></i> Clear Filters
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Inbox Content */}
      <div className="container-fluid">
        <div className="row border-top d-flex flex-nowrap">
          {/* left side */}
          <div className="col-auto c-inbox-left border-right p-0" id="inboxLeft">
            <nav className="navbar navbar-expand-md navbar-light p-0">
              <div className="collapse navbar-collapse" id="navbarInboxMobile">
                {/* if we need more width for this navbar, change it in c-v-nav-inbox and c-chat-right-inbox */}
                <div className="navbar-nav flex-column c-v-nav-inbox">
                  <a className={"nav-link-inbox " + ((!viewingInternal && !viewingArchived && !viewingStarred) ? 'active' : '')}
                     style={{'cursor': "pointer"}}
                     onClick={() => handleViewSwitch(false, false, false)}
                     href="#">
                    <div className="row no-gutters d-flex flex-nowrap nav-item align-items-center">
                      <div className="col-auto">
                        <i className="far fa-envelope"></i>
                      </div>

                      <div className="col pl-1 text-truncate mr-0">
                        Inbox
                      </div>

                      {inboxMentionIndicator && (
                          <div className="col-auto">
                            <i className="fas fa-circle c-font-6 text-notification c-notification-border"></i>
                          </div>
                      )}

                      {(!inboxMentionIndicator && inboxUnreadIndicator) && (
                          <div className="col-auto">
                            <i className="fas fa-circle c-font-6 text-notification"></i>
                          </div>
                      )}
                    </div>
                  </a>

                  <a className={"nav-link-inbox " + (viewingInternal && !viewingArchived && !viewingStarred ? 'active' : '')}
                     style={{'cursor': "pointer"}}
                     onClick={() => handleViewSwitch(true, false, false)}
                     href="#">
                    <div className="row no-gutters d-flex flex-nowrap nav-item align-items-center">
                      <div className="col-auto">
                        <i className="fas fa-eye-slash"></i>
                      </div>

                      <div className="col pl-1 text-truncate mr-0">
                        Internal notes
                      </div>

                      {internalInboxMentionIndicator && (
                          <div className="col-auto">
                            <i className="fas fa-circle c-font-6 text-notification c-notification-border"></i>
                          </div>
                      )}

                      {(!internalInboxMentionIndicator && internalInboxUnreadIndicator) && (
                          <div className="col-auto">
                            <i className="fas fa-circle c-font-6 text-notification"></i>
                          </div>
                      )}
                    </div>
                  </a>

                  <a className={"nav-link-inbox " + ((!viewingInternal && viewingStarred) ? 'active' : '')}
                     onClick={() => handleViewSwitch(false, false, true)}
                     href="#">
                    <div className="row no-gutters d-flex flex-nowrap nav-item align-items-center">
                      <div className="col-auto">
                        <i className="fas fa-star text-primary"></i>
                      </div>

                      <div className="col pl-1 text-truncate mr-0">
                        Important
                      </div>
                      {starredMentionIndicator && (
                          <div className="col-auto">
                            <i className="fas fa-circle c-font-6 text-notification c-notification-border"></i>
                          </div>
                      )}

                      {(!starredMentionIndicator && starredUnreadIndicator) && (
                          <div className="col-auto">
                            <i className="fas fa-circle c-font-6 text-notification"></i>
                          </div>
                      )}

                    </div>
                  </a>

                  <a className={"nav-link-inbox " + ((viewingInternal && viewingStarred) ? 'active' : '')}
                     onClick={() => handleViewSwitch(true, false, true)}
                     href="#">
                    <div className="row no-gutters d-flex flex-nowrap nav-item align-items-center">
                      <div className="col-auto">
                        <i className="fas fa-star text-primary"></i>
                      </div>

                      <div className="col pl-1 text-truncate mr-0">
                        Important Internal
                      </div>

                      {starredInternalMentionIndicator && (
                          <div className="col-auto">
                            <i className="fas fa-circle c-font-6 text-notification c-notification-border"></i>
                          </div>
                      )}

                      {(!starredInternalMentionIndicator && starredInternalUnreadIndicator) && (
                          <div className="col-auto">
                            <i className="fas fa-circle c-font-6 text-notification"></i>
                          </div>
                      )}

                    </div>
                  </a>


                  <a className={"nav-link-inbox " + ((!viewingInternal && viewingArchived) ? 'active' : '')}
                     id="inbox-archived"
                     style={{'cursor': "pointer"}}
                     onClick={() => handleViewSwitch(false, true, false)}
                     href="#">
                    <div className="row no-gutters d-flex flex-nowrap nav-item align-items-center">
                      <div className="col-auto">
                        <i className="fal fa-archive"></i>
                      </div>

                      <div className="col pl-1 text-truncate mr-0">
                        Archived
                      </div>
                    </div>
                  </a>

                  <a className={"nav-link-inbox " + ((viewingInternal && viewingArchived) ? 'active' : '')}
                     style={{'cursor': "pointer"}}
                     onClick={() => handleViewSwitch(true, true, false)}
                     href="#">
                    <div className="row no-gutters d-flex flex-nowrap nav-item align-items-center">
                      <div className="col-auto">
                        <i className="fal fa-archive"></i>
                      </div>

                      <div className="col pl-1 text-truncate mr-0">
                        Archived Internal
                      </div>
                    </div>
                  </a>

                </div>
              </div>
            </nav>
          </div>

          {/* right side */}
          <div id="chatContainer" className="col c-chat-right-inbox p-0">
            {selectedThread === null ? (
                <div className="pl-3 pr-3">
                  {!loaded && (
                      <div className="row mt-4">
                        <div className="col text-center">
                          Loading ...
                        </div>
                      </div>
                  )}
                  {(threads.length === 0 && loaded) && (
                      <div className="row mt-4">
                        <div className="col text-center">
                          No discussions to display.
                        </div>
                      </div>
                  )}

                  {/* TODO this comes back when we support bulk actions */}
                  <div className="row c-inbox-preview d-none-feature">
                    <div className="col-auto c-inbox-checkbox">
                      <input className=""
                             type="checkbox"/>
                    </div>

                    <div className="col">
                      Bulk Actions
                    </div>
                  </div>

                  {threads.filter(thread => {
                    return viewingInternal || thread.is_collaborator || !filterCollabLastReply;
                  }).map(thread => (
                      <div key={thread.discussion_entity_id}
                           onClick={() => handleThreadClick(thread)}
                           className={"row c-inbox-preview c-hover-indicator-container pb-2 " + (thread.indicate ? 'c-unread' : '')}>
                        {/* TODO this comes back with bulk actions */}
                        <div className="col-auto d-none-feature">
                          <input className=""
                                 type="checkbox"/>
                        </div>

                        {/* TODO this comes back with bulk sactions */}
                        <div className="col-auto" onClick={(event) => {
                          event.stopPropagation();
                          handleStarClick(thread);
                        }}>
                          {thread.starred ? (
                              <i className="fas fa-star text-primary"></i>
                          ) : (
                              <i className="fal fa-star"></i>
                          )}
                        </div>


                        <div className="col-md-3 c-inbox-project-name text-truncate">
                    <span className={thread.indicate ? 'c-unread' : ''}>
                      {thread.project_name}
                    </span>
                        </div>

                        <div className="col-md-2 c-inbox-author-name text-truncate">
                    <span className={thread.indicate ? 'c-unread' : ''}>
                      {thread.discussion_author_name}
                    </span>
                        </div>

                        <div className="col-md c-inbox-task-name text-truncate">
                          <div className="row no-gutters align-items-center">
                            <div className="col text-truncate">
                        <span className={thread.indicate ? 'c-unread' : ''}>
                          {getEntityName(thread)}&nbsp;
                          {thread.discussion_entity_name !== 'Customer' && (
                              <span>{thread.title} - </span>
                          )}
                        </span>
                              {getTextFromHTML(thread.discussion_comment)}
                            </div>

                            {thread.unseen_mentions > 0 && (
                                <div className="col-auto c-chat-notification-dm-task-row">
                                  <div className="c-center-element-parent">
                                    <div className="c-center-element-child">
                                      {thread.unseen_mentions}
                                    </div>
                                  </div>
                                </div>
                            )}

                            {thread.unseen_mentions === 0 && thread.indicate && (
                                <div className="col-auto">
                                  <i className="fas fa-circle c-font-6 text-notification"></i>
                                </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-auto c-inbox-date">
                    <span className="">
                      {dateTimeIsToday(thread.discussion_timestamp_dt)
                          ? thread.discussion_timestamp_dt.toLocaleString(DateTime.TIME_WITH_SECONDS)
                          : thread.discussion_timestamp_dt.toLocaleString(DateTime.DATE_SHORT)}
                    </span>

                          <div className="c-hover-menu">
                            <div className="row no-gutters c-edit-menu align-items-center justify-content-center">
                              {!viewingArchived && (
                                  <div className="col-auto pr-0"
                                       onClick={(event) => {
                                         event.stopPropagation();
                                         handleArchive(thread);
                                       }}>
                                    <i className="fal fa-archive btn-link-edit text-decoration-none behave-archive-target"
                                       data-toggle="tooltip" data-placement="top" title="Archive"></i>
                                  </div>
                              )}

                              {thread.indicate && (
                                  <div className="col-auto pr-0 pl-2"
                                       onClick={(event) => {
                                         event.stopPropagation();
                                         markThreadAsRead(thread);
                                       }}>
                                    <i className="far fa-envelope-open btn-link-edit text-decoration-none"
                                       data-toggle="tooltip" data-placement="top" title="Mark as read"></i>
                                  </div>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>
                  ))}

                  {hasMoreThreads && (
                      <div className="row"
                           onClick={() => getMessages(threads, entityIdClosedSet, viewingInternal, viewingArchived, viewingStarred, filterMyProjects)}>
                        <button className="btn btn-outline-secondary">Load Older Discussions</button>
                      </div>
                  )}

                  <div className="row c-inbox-preview c-hover-indicator-container pb-2 d-none">
                    <div className="col-auto">
                      <input className=""
                             type="checkbox"/>
                    </div>

                    <div className="col-auto">
                      <i className="fal fa-star"></i>
                      <i className="fas fa-star text-primary d-none"></i>
                    </div>

                    <div className="col-md-3 c-inbox-project-name text-truncate">
                      Coordinate Onboarding Project Coordinate Onboarding Project
                    </div>

                    <div className="col-md c-inbox-task-name text-truncate">
                      <div className="row no-gutters align-items-center">
                        <div className="col text-truncate">
                          <span>[Task] [Goal]</span> <span className="c-unread">Coordinate Onboarding Project Coordinate Onboarding Project</span>
                        </div>

                        <div className="col-auto c-chat-notification-dm-task-row">
                          <div className="c-center-element-parent">
                            <div className="c-center-element-child">
                              5
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <i className="fas fa-circle c-font-6 text-notification"></i>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col text-truncate">
                          Message preview
                        </div>
                      </div>
                    </div>

                    <div className="col-md-auto c-inbox-date">
                      <span className="">Show time if today otherwise full date 11:27:52 AM</span>
                      <span className="d-none">4/1/2024</span>

                      <div className="c-inbox-avatar ml-auto">
                        <div className="c-center-element-parent">
                          <div className="c-avatar-container c-avatar-bg c-cursor-default" data-toggle="tooltip"
                               data-placement="top" title="Name">
                            <div className="c-center-element-child c-avatar-initials">NG</div>
                          </div>
                        </div>
                      </div>

                      <div className="c-hover-menu">
                        <div className="row no-gutters c-edit-menu align-items-center justify-content-center">
                          <div className="col-auto pr-0">
                            <i className="fal fa-archive btn-link-edit text-decoration-none"
                               data-toggle="tooltip" data-placement="top" title="Archive"></i>
                          </div>

                          <div className="col-auto pr-0 pl-2">
                            <i className="far fa-envelope-open btn-link-edit text-decoration-none"
                               data-toggle="tooltip" data-placement="top" title="Mark as read"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
            ) : (
                <Fragment>
                  <div className="row align-items-center border-bottom p-1 mb-2">

                    <div className="col-auto pl-4">
                      <a className="c-icon-btn c-center-element-parent" type="button"
                         data-placement="top"
                         title="Back"
                         onClick={() => setSelectedThread(null)}>
                        <i className="fas fa-long-arrow-left c-font-10 text-dim c-center-element-child"></i>
                      </a>
                    </div>

                    <div className="col-auto pl-2">
                      <a className="c-icon-btn c-center-element-parent" type="button"
                         data-placement="top"
                         onClick={(event) => {
                           event.stopPropagation();
                           handleStarClick(selectedThread);
                         }}
                         title="Important">
                        {selectedThread.starred ? (
                            <i className="fas fa-star text-primary c-center-element-child"></i>
                        ) : (
                            <i className="fal fa-star c-center-element-child"></i>
                        )}
                      </a>
                    </div>

                    <div className="col-auto pl-2">
                      <a className="c-icon-btn c-center-element-parent"
                         type="button"
                         data-placement="top"
                         title="Activity"
                         data-customer_id={selectedThread.customer_id}
                         data-url={`/js/customer/${selectedThread.customer_id}/entity/${selectedThread.discussion_entity_id}/history`}
                         data-portal-url={`/customer/${selectedThread.customer_id}/shared_portal`}
                         data-activity-customer-id={selectedThread.customer_id}
                         data-behave-activity-button-customer-name={`[${selectedThread.project_name}] ${selectedThread.title}`}
                         data-toggle="modal"
                         data-target="#activity_modal">
                        <i className="fal fa-analytics c-font-10 text-dim c-center-element-child"></i>
                      </a>
                    </div>
                    {/*TODO: hook up star and activity feed button*/}

                    <div className="col-auto ml-auto">
                      {threads.findIndex((x) => x.discussion_entity_id === selectedThread.discussion_entity_id) !== 0 && (
                          <a className="c-icon-btn c-center-element-parent" type="button"
                             data-placement="top"
                             onClick={() => handleThreadClick(threads[threads.findIndex((x) => x.discussion_entity_id === selectedThread.discussion_entity_id) - 1])}
                             title="Previous discussion">
                            <i className="fas fa-chevron-left c-font-10 text-dim c-center-element-child"></i>
                          </a>
                      )}

                    </div>

                    {threads.findIndex((x) => x.discussion_entity_id === selectedThread.discussion_entity_id) !== threads.length - 1 && (
                        <div className="col-auto">
                          <a className="c-icon-btn c-center-element-parent" type="button"
                             data-placement="top"
                             onClick={() => handleThreadClick(threads[threads.findIndex((x) => x.discussion_entity_id === selectedThread.discussion_entity_id) + 1])}
                             title="Next discussion">
                            <i className="fas fa-chevron-right c-font-10 text-dim c-center-element-child"></i>
                          </a>
                        </div>
                    )}

                    <div className="col-auto pr-4">
                      {selectedThread.archived ? (
                          <a className="c-icon-btn c-center-element-parent" type="button"
                             data-placement="top"
                             onClick={(event) => {
                               event.stopPropagation();
                               handleUnArchive(selectedThread);
                               setSelectedThread(null);
                             }}
                             title="Un-Archive">
                            <i className="fal fa-box-open c-font-10 text-dim c-center-element-child"></i>
                          </a>
                      ) : (
                          <a className="c-icon-btn c-center-element-parent" type="button"
                             data-placement="top"
                             onClick={(event) => {
                               event.stopPropagation();
                               handleArchive(selectedThread);
                               setSelectedThread(null);
                             }}
                             title="Archive">
                            <i className="fal fa-archive c-font-10 text-dim c-center-element-child"></i>
                          </a>
                      )}
                    </div>
                  </div>

                  <div className="row pl-4 pb-0">
                    <div className="col">
                      <span className="">[{projectNoun}] </span>
                      <a className="font-weight-bold" href={`/customer/${selectedThread.customer_id}/shared_portal`}>
                        {selectedThread.project_name}
                      </a>

                      <a className="d-inline pl-1"
                         data-project-id={selectedThread.customer_id}
                         data-project-name={selectedThread.project_name}
                         data-toggle="modal"
                         data-target="#chatgpt-project-chat-modal">
                        <i className="fal fa-robot c-cursor-pointer"></i>
                      </a>
                    </div>
                  </div>

                  <Discussion customerId={selectedThread.customer_id}
                              entityName={selectedThread.discussion_entity_name}
                              raisedZIndex={false}
                              internal={viewingInternal}
                              entityId={selectedThread.discussion_entity_id} entityTitle={selectedThread.title}/>
                </Fragment>
            )}
          </div>
        </div>
      </div>

    </Fragment>
  )
}