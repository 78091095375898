import React, {useState, useRef, Fragment, useEffect} from "react"
import * as ReactDOMClient from 'react-dom/client';

import {
  fetchSession,
  getIsUser,
  formatDate,
  getUserStakeholderId, parseDateIgnoringTimezoneGetDate,
} from "./common_functions"

import Select from "react-select";
import {Attachments} from "./attachments";
import flatpickr from "flatpickr";


export function Form({customerId, entityId, entityType, form, formDataIn, setFormDataIn, formCompletedBy, setFormCompletedBy,
                     allowEdit, setCurrentForm, reloadOnFormSubmit=false, taskTitle=null, captcha=null}) {

  // Note allow edit only applies to create/delete not to filling it out
  const [resetData, setResetData] = useState(null); // We will use this for cancel button reset state
  const [isEditing, setIsEditing] = useState(false);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);

  const [fields, setFields] = useState(form.fields);

  const [errorMessage, setErrorMessage] = useState(null);

  // We are calling this as a stand alone component from the active tasks page, which doesn't hand us state callbacks
  const [formData, setFormData] = setFormDataIn === undefined ? useState(formDataIn) : [formDataIn, setFormDataIn];

  const lostFields = Array.from(formData.keys()).filter(x => !fields.map(x => x.field_id).includes(x))
  debugger;

  function setData(data) {
    saveDraft(data);
    return setFormData(data);
  }

  //
  // Draft bits
  const [hasLoadedDraft, setHasLoadedDraft] = useState(false);

  function getDraftKey() {
    return `form_draft_${customerId}${entityId}${form.form_id}`
  }

  function saveDraft(data) {
    const json = JSON.stringify(data, replacer)
    window.localStorage.setItem(getDraftKey(), json);
  }

  function loadDraftIfPresent() {
    const draftJson = window.localStorage.getItem(getDraftKey());
    if(draftJson) {
      const draftData = JSON.parse(draftJson, reviver);
      setData(draftData);
    }
  }

  // we can't stringify a native Map directly, so this
  // lifted from https://stackoverflow.com/questions/29085197/how-do-you-json-stringify-an-es6-map
  function replacer(key, value) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  function reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  const [altCompletedBy, setAltCompletedBy] = useState(formCompletedBy);
  function setCompletedBy(data) {
    if(setFormCompletedBy === undefined) {
      setAltCompletedBy(data);
    } else {
      setFormCompletedBy(data);
    }
  }

  function getCompletedBy(data) {
    if(setFormCompletedBy === undefined) {
      return altCompletedBy;
    } else {
      return formCompletedBy;
    }
  }

  // These are custom components for the react-select to look like our wacky disabled state dropdowns
  // Note they are slightly different than those in task.js
  function DropdownIndicator(props) {
    return (
     <div style={{'marginRight': '13px'}} className="behave-react-select-dropdown-form">
      <i className="fas fa-caret-down"></i>
     </div>)
  }

  const IndicatorSeparator = ({children, ...props}) => {
    return (<Fragment></Fragment>)
  }

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  // field_id -> error message, if null there are no errors
  const [fieldErrors, setFieldErrors] = useState(new Map());

  const captchaInput = useRef();
  const [captchaError, setCaptchaError] = useState(null);


  // file upload

  // Not sure we need to actually useRef here, note how we instantiate the ref in the jsx below
  const attachFileInput = useRef();

  // Using same pattern here where the dateInputs get refs as dict by fieldId
  const dateInputs = useRef();

  const [pendingAttachments, setPendingAttachments] = useState({});

  function handleAttachFileClick(fieldId, event) {
    attachFileInput[fieldId].click();
  }

  function handleAttachFileChange(fieldId, event) {
    setPendingAttachments({[fieldId]: event.target.files, ...pendingAttachments})
  }

  function handleRemovePendingAttachments(fieldId, event) {
    const input = attachFileInput[fieldId];
    // Clears out the file input, we should have just been using the pending attachments like comment.js
    // instead of grabbing the file list from the ref but its already working not going to stop and rework it
    input.type = 'text';
    input.type = 'file';
    setPendingAttachments({});
  }

  const submitButtonRef = useRef();
  function handleSubmit() {
    // Prevent dumbasses from clicking multiple times
    submitButtonRef.current.disabled = true;
    // https://stackoverflow.com/questions/72092658/how-can-i-validate-react-input-is-not-empty-and-valid-email
    // Should redo email validation to use native but then we have to Ref all these inputs just manually validate
    // for now
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;
    // extract all the current inputs and generate a new map of field_id => value

    function getDataForField(field) {
      let input = document.getElementById(field.field_id)
      switch(field.field_type) {
        case 'string':
        case 'email':
        case 'textarea':
        case 'dropdown-single-select':
          return [field.field_id, input.value === "" ? null : input.value];
        case 'checkbox':
          return [field.field_id, input.checked]
        case 'dropdown':
          return [field.field_id, input.value === "" ? null : input.value];
        case 'field-group':
          // We will need to iterate through these and grab the offset numbers
          return [field.field_id, field.fields.map(subField => {
            return getDataForField({...subField, field_id: `${subField.field_id}${field.field_group_index}`})
          })]
        case 'file':
          // we handle file types out of band of this method, they appear at the top level of the formData post
          return [field.field_id, []]
        case 'datepicker':
          const dates = dateInputs[field.field_id]._flatpickr.selectedDates
          return [field.field_id, dates[0]]
      }
    }

    let newData = new Map(
      fields.map(field => getDataForField(field))
                 .reduce((accumulator, currentValue) => {
                   // lift the subfield data to the top level
                   if(Array.isArray(currentValue[1])) {
                     currentValue[1].forEach(entry => accumulator.push(entry))
                   } else {
                     accumulator.push(currentValue)
                   }
                   return accumulator;
                 }, [])
    );

    function getErrorForField(field, newData) {
      switch(field.field_type) {
        case 'string':
        case 'textarea':
        case 'dropdown-single-select':
        case 'dropdown':
          return [field.field_id, ((newData.get(field.field_id) == null && field.required) ? 'Required' : null)];
        case 'email':
          let email = newData.get(field.field_id)
          if(field.required) {
            return [field.field_id, (email == null || !email.match(isValidEmail) ? 'Email Required' : null)]
          } else if (email !== null) {
            return [field.field_id, (!email.match(isValidEmail) ? 'Invalid Email' : null)]
          } else {
            return [field.field_id, null]
          }
        case 'checkbox':
          return [field.field_id, newData.get(field.field_id) === false && field.required ? 'Required': null];
        case 'field-group':
          return [field.field_id, field.fields.map(subField => {
            return getErrorForField({...subField, field_id: `${subField.field_id}${field.field_group_index}`}, newData)
          })]
        case 'file':
          // Let the existing files count since we are gonna be additive, only way to delete is to delete form
          if(field.required
            && !(field.field_id in pendingAttachments)
            && formData.get(field.field_id) === undefined) {
            return [field.field_id, 'Required']
          } else {
            return [field.field_id, null];
          }
        case 'datepicker':
          const dates = dateInputs[field.field_id]._flatpickr.selectedDates;
          return [field.field_id, field['required'] && dates.length === 0 ? 'Required' : null];
      }
    }

    // create a new map of required errors and messages
    let newErrors = new Map(fields
      .map(field => getErrorForField(field, newData))
      .reduce((accumulator, currentValue) => {
        if(Array.isArray(currentValue[1])) {
          currentValue[1].forEach(entry => accumulator.push(entry))
        } else {
          accumulator.push(currentValue)
        }
        return accumulator;
      }, [])
      .filter(entry => entry[1] !== null));

    setFieldErrors(newErrors);

    if(captcha != null && captchaInput.current.value === '') {
      submitButtonRef.current.disabled = false;
      setCaptchaError("Required");
      return;
    }

    if(newErrors.size > 0) {
      submitButtonRef.current.disabled = false;
      return;
    }

    // do the submit
    // TODO we just handle task right now, not goals etc
    let fetchUrl;
    let fetchMethod = fetchSession
    if(entityType === 'Link') {
      fetchUrl = `/js/new_project_link/${form.form_id}`
      fetchMethod = fetch
    } else {
      fetchUrl = entityId === undefined ? `/js/customer/${customerId}/form/${form.form_id}/set_data`
      : `/js/customer/${customerId}/task/${entityId}/form/set_data`;
    }

    let payload = {form_data: Object.fromEntries(newData)}
    if(captcha !== null) {
      payload.captcha_input = captchaInput.current.value;
      payload.captcha_token = captcha;
    }

    const formSubmitData = new FormData();
    formSubmitData.append('json_payload', JSON.stringify(payload))

    for(const fieldId in pendingAttachments) {
      for(const file of pendingAttachments[fieldId]) {
        formSubmitData.append(fieldId, file);
      }
    }

    setFormIsSubmitting(true);

    fetchMethod(fetchUrl, {
      method: 'post',
      body: formSubmitData
    }).then(response => response.json())
      .then((result) => {
        setFormIsSubmitting(false);
        if(entityType === 'Link') {
          if('errorMsg' in result) {
            submitButtonRef.current.disabled = false;
            setErrorMessage(result.errorMsg);
            return;
          }
          // Success we redirect to the next page
          window.location = result.redirectUrl;
          return;
        }

        if(reloadOnFormSubmit) {
          // This is defined in shared_portal_active_tasks, we use this to show an indicator after complete
          try {
            addTaskToLocalStorage(entityId, taskTitle);
          } catch{}
          window.location.reload();
        } else {
          setData(new Map(Object.entries(result.form_data)));
          setCompletedBy(result.task_form_completed_by);
          setIsEditing(false);
        }
    })
    .catch(error => {
      submitButtonRef.current.removeAttribute('disabled');
      setFormIsSubmitting(false);
    });
  }

  function handleDeleteForm() {
    fetchSession(`/js/customer/${customerId}/task/${entityId}/form/clear`, {
      method: 'post',
    }).then((result) => {
      setData(new Map());
      setFormCompletedBy(null);
      setIsEditing(false);
      setIsConfirmingDelete(false)
      setCurrentForm(null);
    });
  }

  function handleAddFieldGroup(fieldGroupId) {
    // Get the highest index field for this field group
    const fieldGroup = fields.filter((f) => f.field_group_id === fieldGroupId).reduce((accumulator, current) => {
      return current.field_group_index > accumulator.field_group_index ? current : accumulator;
    })

    const newFieldGroup = {
      field_group_id: fieldGroup.field_group_id,
      field_group_index: fieldGroup.field_group_index + 1,
      field_id: `${fieldGroup.field_group_id}${fieldGroup.field_group_index + 1}`,
      fields: fieldGroup.fields.slice(),
      order: fieldGroup.order,
      field_type: fieldGroup.field_type
    }
    setFields([...fields, newFieldGroup]);
  }

  function handleRemoveFieldGroup(fieldId) {
    setFields(fields.filter(f => f.field_id !== fieldId));
  }

  function getOptionMap(field) {
    return field.field_options.sort((a,b) => a.order - b.order).map(e => {
      return {
        // We are going to use label as value to survive when the option is deleted in the future by the user
        // so they don't open tickets saying why is this result NULL now or something like this
        value: e.option_name,
        label: e.option_name,
      }
    })
  }

  function renderField(field, form, fieldGroupIndex=null) {
    const fieldId = fieldGroupIndex === null ? field.field_id : `${field.field_id}${fieldGroupIndex}`
    switch(field.field_type) {
      case 'checkbox':
        return (
          <Fragment>
            <div className="col-auto">
              <input id={fieldId} type="checkbox"
                     disabled={getCompletedBy() != null && !isEditing}
                     onChange={(event) => setData(new Map(formData.set(fieldId, event.target.checked)))}
                     checked={formData.get(fieldId) === null ? "" : formData.get(fieldId)}
                     style={{position: 'static', marginRight: '0.5rem', height: '15px'}}
                     data-field-name={field.field_name}
                     className={'form-control ' + (fieldErrors.get(fieldId) !== undefined ? 'is-invalid':'')} />
              <div className="invalid-feedback c-form-checkbox-required-text d-flex flex-nowrap">
                {fieldErrors.get(fieldId)}
              </div>
            </div>

            <label className={field.field_type === 'checkbox' ? 'col col-form-label ml-2' : ''}>
              {field.field_name}
              {field.required && (<span className=""><i className="fas fa-asterisk c-text-required mt-1"></i></span>)}
            </label>
          </Fragment>
        )
      case 'dropdown':
        return (
          <Fragment>
             <label className={'col-12 c-internal-heading'}>
              {field.field_name}
              {field.required && (<span className=""><i className="fas fa-asterisk c-text-required mt-1"></i></span>)}
            </label>
            <div className="col-12 mt-0">
              {
                // hack we just dump in this hidden field to the select can change it and our existing input handlers
              }
              <input type="hidden" id={field.field_id}
                     value={formData.get(field.field_id) === null ? '' : formData.get(field.field_id)}/>
              <Select
                onChange={(selected_values) => {
                  if(field.field_multi_select === true) {
                    let vals = (selected_values.map(x => x.label)).join(",")
                    if(vals === "") {
                      vals = null;
                    }
                    document.getElementById(field.field_id).value = vals
                    setData(new Map(formData.set(fieldId, vals)))
                  } else {
                    document.getElementById(field.field_id).value = selected_values.value;
                    setData(new Map(formData.set(fieldId, selected_values.value)))
                  }
                }}
                isMulti={field.field_multi_select === true}
                styles={{
                  control: (baseStyles, state) => {
                    return {
                      ...baseStyles,
                      color: '#2c2c2c',
                      borderWidth: '1px',
                      borderColor: '#b3b3b3',
                      backgroundColor: '#ffffff',
                    }
                  }
                }}
                isDisabled={getCompletedBy() != null && !isEditing}
                components={{DropdownIndicator, IndicatorSeparator}}
                //formatOptionLabel={formatOptionaLabel}
                value={formData.get(fieldId) == null ? null : field.field_multi_select === true ? formData.get(fieldId).split(',').map(x => {return {label: x, value: x}}) : getOptionMap(field).find(x => x.label === formData.get(fieldId))}
                options={getOptionMap(field)}
                // attemping to theme based off our monochrome
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#b0b0b0',
                    primary75: '#dddddd',
                    primary50: '#eeeeee',
                  },
                })}
                // Leaving this his to remember if we get weird pop-under behavior again
                //menuPortalTarget={document.querySelector('#assigned-to')}
              />

              <div className="invalid-feedback c-form-checkbox-required-text d-flex flex-nowrap">
                {fieldErrors.get(fieldId)}
              </div>

            </div>
          </Fragment>
        )
      case 'string':
      case 'email':
      case 'textarea':
        return (
          <Fragment>
            <label className={'col-12 c-internal-heading'}>
              {field.field_name}
              {field.required && (<span className=""><i className="fas fa-asterisk c-text-required mt-1"></i></span>)}
            </label>

            <div className="col-12 mt-0">
              {field.field_type === 'textarea' ? (
                <textarea id={fieldId}
                          disabled={getCompletedBy() != null && !isEditing}
                          value={formData.get(fieldId) == null ? '' : formData.get(fieldId)}
                          data-field-name={field.field_name}
                          rows={8}
                          onChange={(event) => setData(new Map(formData.set(fieldId, event.target.value)))}
                          className={'form-control ' + (fieldErrors.get(fieldId) !== undefined ? 'is-invalid':'')}>
                </textarea>
              ) : (
                <input id={fieldId}
                       type={field.field_type === 'email' ?  'email': 'text'}
                       disabled={getCompletedBy() != null && !isEditing}
                       value={formData.get(fieldId) == null ? '' : formData.get(fieldId)}
                       data-field-name={field.field_name}
                       // Note we must use new Map below otherwise react will compare references and not trigger update
                       onChange={(event) => setData(new Map(formData.set(fieldId, event.target.value)))}
                       className={'form-control ' + (fieldErrors.get(fieldId) !== undefined ? 'is-invalid':'')} />
              )}

              <div className="invalid-feedback">
                {fieldErrors.get(fieldId)}
              </div>
            </div>
          </Fragment>
        )
      case 'dropdown-single-select':
        return (
          <Fragment>
            {/*Role*/}
            <label className="col-12 c-internal-heading mt-3">
              {field.field_name}
              {(field.required === true) && (<span className=""><i className="fas fa-asterisk c-text-required mt-1"></i></span>)}
            </label>
            <div className="col-12">
              <select id={fieldId} className="form-control custom-select"
                      disabled={getCompletedBy() != null && !isEditing}
                      value={formData.get(fieldId) == null ? '' : formData.get(fieldId)}
                      data-field-name={field.field_name}
                      onChange={(event) => setData(new Map(formData.set(fieldId, event.target.value)))}>
                <Fragment>
                  <option value=''>None</option>
                  {field.options.map((option) => (<option key={option} value={option}>{option}</option>))}
                </Fragment>
              </select>
            </div>
          </Fragment>
        )
      case 'field-group':
        return (
          <Fragment key={fieldId}>
            <div className="form-group row align-items-center border-top mb-1 mt-4 pt-3">

              <div className="col-12 pb-3">
                <h3>Invite collaborator</h3>
              </div>

              {field.fields.map((subField) => (
                <Fragment key={subField.field_id}>
                  {renderField(subField, form, fieldGroupIndex=field.field_group_index)}
                </Fragment>
              ))}
            </div>

            <div className="row align-items-center mb-4 pt-0">
              <div className="col pt-0 pl-1">
                <div className="btn btn-link" onClick={() => handleAddFieldGroup(field.field_group_id)}>
                  <i className="fas fa-plus"></i> Add Another
                </div>
              </div>
              <div className="col-auto pr-2">
                {field.field_group_index !== 0 && (
                  <div className="btn btn-link-edit-danger" onClick={() => handleRemoveFieldGroup(field.field_id)}>
                    <i className="far fa-trash-alt"></i> Remove
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )
      case 'file':
        return (
          <Fragment>
            <label className="col-12 c-internal-heading mt-3">
              {field.field_name}
              {(field.required === true) && (<span className=""><i className="fas fa-asterisk c-text-required mt-1"></i></span>)}
            </label>
            <div className="col-12">
              {(getCompletedBy() == null || isEditing) && (
                <Fragment>
                 <input id={'fileinput' + fieldId}
                        data-behave-file-field-name={field.field_name}
                        disabled={getCompletedBy() != null && !isEditing}
                        ref={ref => (attachFileInput[fieldId] = ref)}
                        className="d-none"
                        type="file"
                        multiple="multiple"
                        onChange={(event) => handleAttachFileChange(fieldId, event)} />
                  <button className="btn btn-secondary" onClick={() => attachFileInput[fieldId].click()}>
                    Select Files
                  </button>
                  {fieldErrors.get(fieldId) !== undefined && (
                    <div className="text-danger">{fieldErrors.get(fieldId)}</div>
                  )}

                  {fieldId in pendingAttachments && (
                    <Fragment>
                      {Array.from(pendingAttachments[fieldId]).map(attachment => (
                        <div key={attachment.name}>{attachment.name}</div>
                        ))}
                      <div className="btn btn-link"
                           onClick={(event) => handleRemovePendingAttachments(fieldId, event)}>
                        Clear Pending Files</div>
                    </Fragment>
                  )}


                </Fragment>
              )}

              {formData.get(fieldId) !== null && formData.get(fieldId) !== undefined && (
                <Attachments customerId={customerId} entityId={entityId} attachments={formData.get(fieldId)}
                             entityType="TaskForm" fieldId={fieldId} isEditing={false} />
              )}
            </div>
          </Fragment>
        )
      case 'datepicker':
        return (
          <Fragment>
             <label className={'col-12 c-internal-heading'}>
              {field.field_name}
              {field.required && (<span className=""><i className="fas fa-asterisk c-text-required mt-1"></i></span>)}
            </label>
            <div className="col-12 mt-0">
              {getCompletedBy() === null || isEditing ? (
                <div className="row flatpickr"
                     ref={ref => (dateInputs[fieldId] = ref)}>
                  <div className="col">
                    <div className="input-group mb-0">
                      <input className="form-control c-hover-indicator-container"
                             //defaultValue={parseDateIgnoringTimezoneGetDate(startDate)}
                             data-input="" />
                      <div className="input-group-append">
                        <button className="btn c-btn-dropdown-delete-border-left" type="button" data-clear="">
                          <i className="fal fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  {fieldErrors.get(fieldId) !== null && (
                    <div className="text-danger col-12">
                      {fieldErrors.get(fieldId)}
                    </div>
                  )}
                </div>
              ) : (
                formData.get(fieldId) !== undefined && formatDate(formData.get(fieldId))
              )}

            </div>
          </Fragment>
        )
    }
  }

  useEffect((didUpdate) => {
    if(getCompletedBy() === null || isEditing) {

      if(!hasLoadedDraft) {
        loadDraftIfPresent();
        setHasLoadedDraft(true);
      }
      // Initialize our flatpickers
      for(const prop in dateInputs) {
        if(prop !== 'current') {
          const element = dateInputs[prop];
          flatpickr(element, {
            wrap: true,
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d",
            defaultDate: formData.get(prop),
            onChange: selectedDates => setData(new Map(formData.set(prop, selectedDates[0])))
          })
        }
      }
    }
  })

  return (
    <Fragment>
      {entityType !== 'Link' &&  (
        <Fragment>
          <div className="row mb-2">
            <div className="col">
              {entityId !== undefined && (
                <h2 className={getCompletedBy() !== null ? "text-muted" : undefined}>
                  {getCompletedBy() !== null && (
                    <i className="far fa-check-circle mr-2"></i>
                  )}
                  {form.form_name}
                </h2>
              )}
            </div>

            {getCompletedBy() !== null && (
              <Fragment>
                <div className="col-auto pr-0">
                  {getIsUser() && (
                    <Fragment>
                      <a className="btn btn-link-edit pl-3 pr-2"
                         data-toggle="tooltip" data-placement="top" title="Download CSV"
                         href={`/customer/${customerId}/task/${entityId}/get_form_results`}>
                        <i className="far fa-file-download"></i>
                      </a>
                      <a className="btn btn-link-edit pl-3 pr-2"
                         data-toggle="tooltip" data-placement="top" title="Download PDF"
                         href={`/customer/${customerId}/task/${entityId}/get_form_results_pdf`}>
                        <i className="far fa-file-pdf"></i>
                      </a>
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}

            {allowEdit && (
              !isConfirmingDelete ? (
                <div className="col-auto c-hover-indicator-container">
                  <span className="c-hover-show">
                    <button className="btn btn-link-edit-danger" onClick={() => setIsConfirmingDelete(true)}>
                      <i className="far fa-trash-alt"></i>
                    </button>
                  </span>
                </div>
              ) : (
                <Fragment>
                  <button className="btn btn-link p-0 pr-2 pl-2" onClick={() => setIsConfirmingDelete(false)}>
                    Cancel
                  </button>
                  &nbsp;
                  <button className="btn btn-link text-danger p-0 pr-3" onClick={handleDeleteForm}>
                    Confirm Delete?
                  </button>
                </Fragment>
              )
            )}
          </div>

          <div className="row align-items-center mb-3">
            <div className="col text-muted">
              {getCompletedBy() !== null && (
                <Fragment>
                  <div className="font-italic mt-1">
                    Completed by: <span className="font-weight-bold">{getCompletedBy().full_name} </span>
                    ({getCompletedBy().email})
                    <div className="">on {new Date(getCompletedBy().completed_dt).toLocaleString()}</div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {fields.sort((a,b) => a['order'] - b['order']).map(field => field.field_type !== 'field-group' ? (
        <div key={field.field_id}
             className={'form-group row align-items-center ' + (field.field_type === 'checkbox' ? 'no-gutters d-flex flex-nowrap ' : '')}>
          {renderField(field, form)}
        </div>
      ) : (
        renderField(field, form)
      ))}

      {lostFields.length > 0 && (
        <p className="text-danger">Some fields were removed from the form here is the raw response data:</p>
      )}
      {lostFields.map(fieldId => (
        <p className="text-danger">{formData.get(fieldId)}</p>
      ))}

      {captcha != null && (
          <div className="form-row">
            <div className="col">
              <input type="text"
                     className={'form-control ' + (captchaError !== null ? 'is-invalid':'')} name="captcha" id="captcha-input"
                     ref={captchaInput}
                     placeholder="Type the letters in the image"
                     required />
              <input type="hidden" name="captcha_token" value="" />
              <div className="invalid-feedback">{captchaError}</div>
            </div>
            <div className="col-auto">
              <img alt="captcha"
                   src={`/create_project/${form.form_id}/captcha/${captcha}`} />
            </div>
          </div>
      )}

      {getCompletedBy() == null ? (
        <Fragment>
          <button className="btn btn-primary behave-form-submit" type="submit" ref={submitButtonRef}
                  onClick={handleSubmit}>
            Submit Form
          </button>
          {errorMessage != null && (
            <div className="text-danger">
              {errorMessage}
            </div>
          )}
        </Fragment>
      ) : (
        isEditing ? (
          <Fragment>
            <button className="btn btn-outline-secondary" type="submit" onClick={() => {
              setData(resetData);
              setIsEditing(false);
            }}>
              Cancel
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit" ref={submitButtonRef}
                    onClick={handleSubmit}>
              Submit Form
            </button>
          </Fragment>
        ) : (
          <button className="btn btn-outline-secondary" type="submit" onClick={() => {
            setResetData(new Map(formData));
            setIsEditing(true);
          }}>
            Make Changes
          </button>
        )
      )}

      {formIsSubmitting && (
         <div className="progress float-right m-1 mt-2" style={{'width': '100%', 'height': '40px'}}>
          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{'width': '100%'}}
               aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">Submitting ...
          </div>
        </div>
      )}

    </Fragment>
  )
}
