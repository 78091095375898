import React, {useState, useRef, Fragment, useEffect} from "react"
import {
  dateIsBeforeToday,
  fetchSession,
  parseDateIgnoringTimezone,
  parseDateIgnoringTimezoneGetDate
} from "./common_functions";
import flatpickr from "flatpickr";
import {DateTime} from "luxon";



export function ShiftDatesModal(props) {
  const [loaded, setLoaded] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [initialDueDate, setInitialDueDate] =  useState(null);
  const [newDueDate, setNewDueDate] =  useState(null);
  const [daysDifference, setDaysDifference] = useState(0);
  const [group, setGroup] = useState(null);

  const [targetDateArray, setTargetDateArray] = useState(null);  // original due date, new due date
  const [previewTasks, setPreviewTask] = useState([]);

  const dueDateInput = useRef();

  function handleDueDateChange(selectedDates, dateStr, instance) {
    let selectedDateString = `${1900 + selectedDates[0].getYear()}-${String(1+selectedDates[0].getMonth()).padStart(2, '0')}-${String(selectedDates[0].getDate()).padStart(2, '0')}`
    let luxon1 = DateTime.fromISO(selectedDateString);
    let luxon2 = DateTime.fromISO(initialDueDate);
    let daysDiff = luxon1.diff(luxon2, 'days').days
    fetchSession(`/js/customer/${props.customerId}/task/${props.taskId}/shift_date_info/${daysDiff}`)
        .then(response => {
          return response.json();
        })
        .then(result => {
          let newDueDateFormatted = DateTime.fromISO(result.tasks.find((t) => t.task_id === props.taskId).preview_due_date);
          newDueDateFormatted = newDueDateFormatted.toFormat("MM/dd/yyyy");

          let initialDueDateFormatted = DateTime.fromISO(initialDueDate)
          initialDueDateFormatted = initialDueDateFormatted.toFormat("MM/dd/yyyy")

          setTargetDateArray([initialDueDateFormatted, newDueDateFormatted])

          setPreviewTask(result.tasks);

          setDaysDifference(luxon1.diff(luxon2, 'days').days);
          setNewDueDate(selectedDates[0]);
          //setNewDueDate(luxon1.toFormat("MM/dd/yyyy"));
        });
  }

  useEffect((didUpdate) => {
    if (loaded === false) {
      fetchSession(`/js/customer/${props.customerId}/task/${props.taskId}/shift_date_info`)
        .then(response => {
          return response.json();
        })
        .then(result => {
          setGroup(result.group);
          setTasks(result.tasks)

          setInitialDueDate(result.tasks.find((t) => t.task_id === props.taskId).task_due_date);
          setNewDueDate(result.tasks.find((t) => t.task_id === props.taskId).task_due_date);
          setLoaded(true);
        });
    }


    if(newDueDate) {
      let instance = flatpickr(dueDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: newDueDate,
        onChange: (selectedDates, dateStr, instance) => {
          handleDueDateChange(selectedDates, dateStr, instance);
        }
      });

      window.shiftDatePicker = instance;
    }

  });

  return loaded && (
    <Fragment>
      <input type="hidden" value={group.group_id} name="group_id" />
      <input type="hidden" value={daysDifference} name="days_difference" />
      <div className="row mb-1">
        <div className="col c-internal-heading">
          You are shifting dates relative to the task:
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h2>{tasks.find((t) => t.task_id === props.taskId).task_title}</h2>
        </div>
      </div>

      <div className="row mt-3 mb-1">
        <div className="col c-internal-heading">
          Due Date
        </div>
      </div>

      <div className="row flatpickr" ref={dueDateInput}>
        <div className="col">
          {/* We will need to attach a class onto the input group that the classes below respect for red line
              because flatpickr clones our input group here and makes a new one */}
          <div className={'input-group mb-0' + (dateIsBeforeToday(newDueDate) && ' c-date-past-due') }>
            <input type="text" className="form-control" data-input=""
                   id="task_due_date_value"
                   defaultValue={parseDateIgnoringTimezoneGetDate(newDueDate)} />
            <div className="input-group-append" style={{'zIndex':'0'}}>
              <button className="btn c-btn-dropdown-delete-border-left"
                      type="button"
                      id="due-date-remove-button"
                      data-clear="">
                <i className="fal fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row border m-3 pt-3 pb-3">
        <div className="col">
          <div className="row">
            <div className="col">
              <h3>You are {daysDifference >= 0 ? 'extending' : 'shortening'} the due date by <span
                className="font-weight-bold">{daysDifference} business days</span></h3>
            </div>
          </div>

          {targetDateArray !== null && (
            <div className="row mt-3">
              <div className="col-auto">
                <i className="fal fa-calendar"></i> {targetDateArray[0]}
              </div>
              <div className="col-auto">
                <i className="fal fa-arrow-alt-right"></i>
              </div>
              <div className="col-auto font-weight-bold text-primary-accent">
                <i className="fal fa-calendar"></i> {targetDateArray[1]}
              </div>
            </div>
          )}

          <div className="row mt-3 d-none">
            <div className="col-12 mt-2">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Also shift all start dates by <span className="font-weight-bold">6 days</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {previewTasks.length > 0 && (
        <Fragment>
          <div className="row mt-3 pt-2 pb-2">
            <div className="col">
              Select all tasks in group {group.group_title} that will be moved relative to the date of&nbsp;
              {tasks.find((t) => t.task_id === props.taskId).task_title}
            </div>
          </div>

          <div className="row pt-2 pb-2">
            <div className="col-auto pr-0">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value=""
                       onChange={(event) => {
                         document.querySelectorAll('.shift-checkbox-selector').forEach((el) => {
                           el.checked = event.target.checked;
                         })
                       }} />
              </div>
            </div>

            <div className="col">
              Select All
            </div>
          </div>

          {previewTasks.map((previewTask) => (
            <div key={previewTask.task_id} className="row pt-2 pb-2 border-top">
              <div className="col-auto pr-0">
                <div className="form-check">
                  <input className="form-check-input shift-checkbox-selector" type="checkbox"
                         value={previewTask.task_id} name="task_id[]"
                         defaultChecked={previewTask.task_id === props.taskId}/>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-12">
                    <h5>{previewTask.task_title}</h5>
                  </div>
                  <div className="col-12">
                    <div className="row mt-1">
                      <div className={'col-auto ' + ((DateTime.fromISO(previewTask.task_due_date).diff(DateTime.now(), 'seconds') < 0) ? 'text-danger' : '')}>
                        <i className="fal fa-calendar"></i>&nbsp;
                        {DateTime.fromISO(previewTask.task_due_date).toFormat("MM/dd/yyyy")}
                      </div>
                      <div className="col-auto">
                        <i className="fal fa-arrow-alt-right"></i>
                      </div>
                      <div className={'col-auto font-weight-bold ' + ((DateTime.fromISO(previewTask.preview_due_date).diff(DateTime.now(), 'seconds') < 0) ? 'text-danger' : 'text-primary-accent')}>
                        <i className="fal fa-calendar"></i>&nbsp;
                        {DateTime.fromISO(previewTask.preview_due_date).toFormat("MM/dd/yyyy")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Fragment>
      )}
    </Fragment>
  )

}
