import React, {useState, useRef, Fragment, useEffect} from "react"
import {fetchSession} from "./common_functions";

export function RecurringTaskList({customerId}) {

  const [loaded, setLoaded] = useState(false);
  const [recurringTasks, setRecurringTasks] = useState([]);

  function getPeriodDisplay(period) {
    if(period === 'days') {
      return "Daily";
    } else if(period === 'weeks') {
      return "Weekly";
    } else if(period === 'months') {
      return "Monthly";
    } else {
      return 'Unknown';
    }
  }

  useEffect((didUpdate) => {
    if(!loaded) {
      fetchSession(`/js/customer/${customerId}/task/recurring`)
        .then(response => response.json())
        .then(result => {
          setLoaded(true);
          setRecurringTasks(result);
        });
    }
  });

  return (
    <Fragment>
      <div className="row border-bottom pb-3">
        <div className="col">
          <button type="button" className="btn btn-primary" id="newRecurringTaskModalButton"
                  onClick={() => {
                    new_task(customerId, 'NONE', true);
                    $('#recurringListModal').modal('hide');
                  }}>
            <i className="fas fa-plus"></i> New Recurring Task
          </button>
        </div>
      </div>

      {recurringTasks.map(task => (
        <div key={task.task_id} className="row border-bottom c-hover-row"
             onClick={() => $('#recurringListModal').modal('hide')}
             data-customer-id={customerId}
             data-task-id={task.task_id}
             data-toggle="modal" data-target="#react-modal">
          <div className="col-auto d-none-feature">
            {/* TODO: add the status dropdown here - it can only be in progress or not started */}
            status
          </div>

          {/* TODO: remove pl-4 when the above status is enabled, we might also need to look at where the click to open the modal happens unless the status is going to be read only */}
          <div className="col pl-4">
            <div className="row">
              <div className="col c-spacing-title-taskgoal">
                <h5 className="">
                  <div className="c-chat-notification-inline-text text-break">
                    {task.task_title}
                  </div>
                </h5>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-auto c-spacing-icons">
                <h6 className="">
                    <span>
                      <i className="far fa-repeat-alt"></i>
                    </span>
                  <span className="ml-1">
                    {getPeriodDisplay(task.task_recurring_period)}
                    </span>
                </h6>
              </div>

              <div className="col-auto c-spacing-icons d-none-feature">
                <h6 className="">
                    <span>
                      <i className="fal fa-calendar"></i>
                    </span>
                  <span className="">
                    {/* TODO: Next recurring task date */}
                    05/24/24
                    </span>
                </h6>
              </div>

              {task.task_recurring_paused && (
                <div className="col-auto c-spacing-icons">
                  <h6 className="">
                    <span>
                      <i className="far fa-pause-circle"></i>
                    </span>
                    <span className="ml-1">
                      Paused
                    </span>
                  </h6>
                </div>
              )}
            </div>
          </div>

          {/* TODO: put the assignee stuff here - icon only */}
          <div className="col-auto c-spacing-avatar d-none-feature">
            <a className="btn p-0" type="button">
              <div className="c-avatar-container c-avatar-bg">
                <div className="c-center-element-parent">
                  <div className="c-center-element-child">
                    <div className="c-avatar-initials">FA</div>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="col-auto pt-2 d-none-feature">
            <a type="button" className="nav-link btn-link-edit-danger">
              <i className="far fa-trash-alt"></i>
            </a>

            <a type="button" className="nav-link text-danger d-none">
              Confirm Delete?
            </a>
          </div>
        </div>
      ))}
    </Fragment>
  )
}