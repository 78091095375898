//
//
import React, {useState, useRef, Fragment, useEffect} from "react"
import {getIsUser, getUserStakeholderId} from "./common_functions";


export function Attachments({customerId, entityId, fieldId, entityType, attachments, setAttachments, entryId, isEditing}) {

  function handleRemove(event) {
    let fileUid = event.target.dataset.fileUid;
    let fetchUrl = entryId === undefined ?
      `/js/customer/${customerId}/delete_attach/entity/${entityId}/entity_type/${entityType}/uid/${fileUid}`
      :
      `/js/customer/${customerId}/delete_attach/entity/${entityId}/comment/${entryId}/uid/${fileUid}`;

    fetchSession(fetchUrl,
      {
        method: 'post',
      })
      .then(result => {
        setAttachments(attachments.filter(file => file.file_uid !== fileUid))
      });
  }

  function getDownloadUrl(fileUid) {
    let url = entryId === undefined ?
      `/js/customer/${customerId}/entity/${entityId}/entity_type/${entityType}/attachment/${fileUid}`
      :
      `/js/customer/${customerId}/entity/${entityId}/discussion/${entryId}/attachment/${fileUid}`;
    // append the get parameters for the fetch auth wrapper, these come from a global in this file that are set
    // we attachTask is called
    return `${url}?isUser=${getIsUser()}&userStakeholderId=${getUserStakeholderId()}`;
  }


  function getDownloadAllUrl() {
    const url = entryId !== undefined ?
      `/js/customer/${customerId}/entity/${entityId}/discussion/${entryId}/attachment/all`
      :
      `/customer/${customerId}/entity/${entityId}/entity_type/${entityType}/field_id/${fieldId}/attachment/all`;

    // append the get parameters for the fetch auth wrapper, these come from a global in this file that are set
    // we attachTask is called
    return `${url}?isUser=${getIsUser()}&userStakeholderId=${getUserStakeholderId()}`;
  }

  return (
    <div className="row no-gutters">

      {attachments && attachments.map(file => (
        <div className="col-auto" key={file.file_uid}>
          <div className="row align-items-center no-gutters c-hover-indicator-container c-attachment">
            <div className="col-auto mr-1">
              <i className="fal fa-file"></i>
            </div>

            <div className="col">
              <a className="text-break" data-behave-filename={file.file_name}
                 target="_blank" href={getDownloadUrl(file.file_uid)}>
                {file.file_name}
              </a>
            </div>

            {isEditing === true && (
              <div className="col-auto ml-1 c-hover-show">
                <div className="btn btn-link-edit-danger">
                  <i className="fas fa-times"
                     data-remove-file-name={file.file_name}
                     data-file-uid={file.file_uid}
                     key={file.file_uid}
                     onClick={handleRemove}>
                  </i>
                </div>
              </div>
            )}

         </div>
       </div>
      ))}

      {(attachments.length > 0) && (
        <div className="col-auto">
          <div className="row align-items-center no-gutters c-hover-indicator-container c-attachment">
            <div className="col-auto mr-1">
              <i className="fa fa-files-o"></i>
            </div>

            <div className="col">
              <a href={getDownloadAllUrl()}>
                Download all
              </a>
            </div>
          </div>
        </div>
      )}

   </div>
  )

}
