import React, {Fragment, useEffect, useRef, useState} from "react";
import {fetchSession} from "./common_functions";

export function ProjectCustomFields(props) {
  const [loaded, setLoaded] = useState(false);
  const [fields, setFields] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (loaded === false) {
      fetchSession('/js/custom_fields/project')
        .then(response => {
          return response.json();
        })
        .then(result => {
          setFields(result.customFields);
          setTags(result.tags);
          setLoaded(true);
        });
    }
  })

  // adding
  const fieldName = useRef();
  const dataTypeSelect = useRef();
  const tagSelect = useRef();
  const [isAdding, setIsAdding] = useState(false);
  const [isTaggedTaskGoal, setIsTaggedTaskGoal] = useState(false);
  const [fieldNameErrorMessage, setFieldNameErrorMessage] = useState(null);

  function handleAdd() {
    // make sure there isn't a name conflict

    const name = fieldName.current.value.trim();
    if (name === '') {
      setFieldNameErrorMessage('Name is required.');
      return;
    }

    if (fields.map((x) => x.fieldName).includes(name)) {
      setFieldNameErrorMessage('Name already in use.');
      return;
    }

    // submit the new one
    fetchSession('/js/custom_fields/project', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        fieldName: name,
        fieldType: dataTypeSelect.current.value,
        tag: tagSelect.current !== undefined ? tagSelect.current.value : null
      })
    })
      .then(response => response.json())
      .then(result => {
        setFields([...fields, result]);
        setIsAdding(false);
      });
  }

  const [confirmDeleteName, setConfirmDeleteName] = useState(null);

  function handleRemoveConfirmClick(event) {
    const fieldName = event.target.dataset.fieldName;
    fetchSession('/js/custom_fields/project/remove', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        fieldName: fieldName,
      })
    })
      .then(response => response.json())
      .then(result => {
        setFields(fields.filter((f) => f.fieldName !== fieldName));
      });
  }

  return (
    (loaded ? (
      <div className="">
        {isAdding ? (
          <Fragment>
            <div className="row c-bg-color no-gutters pt-3 pr-3 pb-1 pl-3 mt-4">
              <div className="col">
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <input className={"form-control" + (fieldNameErrorMessage === null ? '' : ' is-invalid')} type="text"
                             placeholder="Field Name" required ref={fieldName}/>
                      <div className="invalid-feedback">
                        {fieldNameErrorMessage}
                      </div>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="form-group">
                      <select className="custom-select behave-custom-field-type-select" ref={dataTypeSelect}
                              onChange={(event) => {
                                if (event.target.value === 'TagTaskGoalComplete') {
                                  setIsTaggedTaskGoal(true);
                                } else {
                                  setIsTaggedTaskGoal(false);
                                }
                              }}>
                        <option value="Checkbox">Checkbox</option>
                        <option value="String">Text string</option>
                        <option value="TagTaskGoalComplete">Tagged Task/Goal Date</option>
                        <option value="Dollars">Dollars</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-auto">
                    <button className="btn c-txt-btn btn-dark custom-field-finish-add w-100 custom-field-finish-add"
                            onClick={handleAdd}>
                      Add
                    </button>
                  </div>

                  <div className="col-md-auto mt-3 mt-md-0">
                    <button className="btn c-txt-btn btn-outline-secondary w-100" onClick={() => setIsAdding(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {isTaggedTaskGoal && (
                <div className="row">
                  <div className="col"></div>
                  <div className="col">
                    <div className="form-group">
                      <select className="custom-select behave-custom-field-type-select" ref={tagSelect}>
                        {tags.map((t) => (
                            <option key={t} value={t}>{t}</option>
                      ))}

                    </select>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="row mt-4">
            <div className="col behave-add-custom-field-button" onClick={() => setIsAdding(true)}>
              <button className="btn c-txt-btn btn-dark">
                <i className="fa fa-plus"></i> Add Custom Field
              </button>
            </div>
          </div>
        )}

        <div className="row c-bg-color no-gutters pt-3 pr-3 pb-1 pl-3 mt-3">
          <div className="col">
            <div className="row mb-2">
              <div className="col-auto">
                <h3 className="">
                  Project Custom Fields
                </h3>
              </div>
            </div>

            {fields.length > 0 && (
              <div className="row no-gutters border-top align-items-center pt-2 pb-2">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <h4>Field Name</h4>
                    </div>

                    <div className="col-5 col-md-3">
                      <h4>Field Type</h4>
                    </div>

                    <div className="col-auto">
                      <i className="btn btn-link-edit-danger far fa-trash-alt invisible"></i>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {fields.map((field) => (
              <div key={field.fieldName} className="row no-gutters border-top align-items-center pt-2 pb-2">
                <div className="col">
                  <div className="row">
                    <div className="col behave-custom-field-name">
                      {field.fieldName}
                    </div>

                    <div className="col-5 col-md-3">
                      {/*TODO get the proper name here */}
                      {field.fieldType === 'String' ? 'Text string' : field.fieldType === 'TagTaskGoalComplete' ? 'Tagged Task/Goal Date' : field.fieldType }
                    </div>

                    {confirmDeleteName === field.fieldName ? (
                      <Fragment>
                        <div className="col-md-auto mt-3 mt-md-0">
                          <button className="btn c-txt-btn btn-danger behave-confirm-delete-custom-field w-100"
                                  data-field-name={field.fieldName}
                                  onClick={handleRemoveConfirmClick}>Confirm Delete?
                          </button>
                        </div>

                        <div className="col-md-auto mt-3 mt-md-0">
                          <button className="btn c-txt-btn btn-outline-secondary w-100"
                                  onClick={() => setConfirmDeleteName(null)}>Cancel
                          </button>
                        </div>
                      </Fragment>
                    ) : (
                      <div className="col-auto">
                        <i className="btn btn-link-edit-danger far fa-trash-alt c-cursor-pointer"
                           data-behave-delete-custom-field-name={field.fieldName}
                           onClick={() => setConfirmDeleteName(field.fieldName)}></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    ) : (
      <p>Loading...</p>
    ))
  )
}

export function ProjectCustomFieldsManage(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [fields, setFields] = useState([]);
  const [values, setValues] = useState({});

  const [fieldNameEditing, setFieldNameEditing] = useState(null);

  const [currencyError, setCurrencyError] = useState(null);

  useEffect(() => {
    if (!isLoaded) {
      fetchSession(`/js/custom_fields/project/${props.projectId}`)
        .then(response => {
          return response.json();
        })
        .then(result => {
          setFields(result.fields);
          setValues(result.values);
          setIsLoaded(true);
        });
    }
  });

  function handleCheckboxClick(event) {
    // submit the new one
    fetchSession(`/js/custom_fields/project/${props.projectId}/field/set`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        field: event.target.dataset.valueName,
        value: event.target.checked,
      })
    });
  }

  function handleTextBlur(event) {
    const name = event.target.dataset.valueName;
    let value = event.target.value
    let nulledValue = value === '' ? null : value;
    if(event.target.dataset.type === 'Dollars' && nulledValue != null) {
      let currency = value.replaceAll(',', '').trim();
      // Note are just using parseFloat for "number like" and will send the text up and store as Decimal on backend
      let parsed = parseFloat(currency);
      if(isNaN(parsed)) {
        setCurrencyError('Must be a valid number');
        return;
      } else {
        nulledValue = currency;
      }
    }

    // submit the new one
    fetchSession(`/js/custom_fields/project/${props.projectId}/field/set`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        field: name,
        value: nulledValue
      })
    }).then(() => {
      if (value === "") {
        value = null;
      }
      values.find((f) => f.name === name).value = value;
      setValues(values);
      setFieldNameEditing(null);
    });
  }

  function renderValue(value) {
    switch (value.type) {
      case 'Checkbox':
        return (
          <div className="row align-items-center no-gutters border-bottom mb-1 pb-1" key={value.name}>
            <div className="col">
              <div className="form-check">
                <input className="form-check-input" type="checkbox"
                       defaultChecked={value.value}
                       data-behave-custom-field-name={value.name}
                       data-value-name={value.name}
                       onClick={(e) => handleCheckboxClick(e)}/>
                <label className="form-check-label">
                  {value.name}
                </label>
              </div>
            </div>
          </div>
        )
      case 'String':
        return (
          <Fragment key={value.name}>
            {fieldNameEditing === value.name ? (
              <div className="row align-items-center no-gutters border-bottom mb-1 pb-1">
                <div className="form-row">
                  <label className="col-form-label">{value.name}:</label>
                  <input className="form-control col-7"
                         type="text"
                         data-value-name={value.name}
                         defaultValue={value.value}
                         data-behave-custom-field-name={value.name}
                         onBlur={handleTextBlur}
                         onKeyDown={(e) => e.key === 'Enter' && handleTextBlur(e)}
                         autoFocus placeholder={value.name}/>
                </div>
              </div>
            ) : (
              <div className="row align-items-center no-gutters border-bottom mb-1 pb-1"
                   onClick={() => setFieldNameEditing(value.name)}
                   data-behave-custom-field-name-span={value.name}>
                <div className="col-auto">
                  <span className="c-text-info">{value.name}:</span>
                </div>
                <div className="col ml-2">
                  {value.value}
                </div>
              </div>
            )}
          </Fragment>
        )
      case 'Dollars':
        return (
          <Fragment key={value.name}>
            {fieldNameEditing === value.name ? (
              <div className="row align-items-center no-gutters border-bottom mb-1 pb-1">
                <div className="form-row">
                  <label className="col-form-label">{value.name}</label>
                  <input className={"form-control col-7 " + (currencyError !== null ? 'is-invalid' : '')}
                         type="text"
                         data-value-name={value.name}
                         data-type={value.type}
                         defaultValue={value.value}
                         data-behave-custom-field-name={value.name}
                         onBlur={handleTextBlur}
                         onKeyDown={(e) => e.key === 'Enter' && handleTextBlur(e)}
                         autoFocus placeholder={value.name}/>
                  <div className="invalid-feedback">{currencyError}</div>

                </div>
              </div>
            ) : (
              <div className="row align-items-center no-gutters border-bottom mb-1 pb-1"
                   onClick={() => setFieldNameEditing(value.name)}
                   data-behave-custom-field-name-span={value.name}>
                <div className="col-auto">
                  <span className="c-text-info">{value.name}:</span>
                </div>
                <div className="col ml-2">
                  {value.value !== undefined && (
                    '$' + parseFloat(value.value).toLocaleString(undefined, {minimumFractionDigits: 2})
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )
    }
  }

  return (
    (isLoaded ? (
      <Fragment>
        {values.sort((a,b) => `${a.type}${a.name}` > `${b.type}${b.name}`).map((value) => renderValue(value))}
      </Fragment>
    ) : (
      <div>loading</div>
    ))
  )
}