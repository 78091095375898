import React, {useState, useRef, Fragment, useEffect} from "react"
import FroalaEditor from "react-froala-wysiwyg";
import parse from "html-react-parser";
import Select from "react-select";

import {Attachments} from "./attachments";
import {getIsUser, getUserStakeholderId, handleAttachFileChange, isMobile} from "./common_functions";
import {FileProgress} from "./fileProgress";


export function Comment({projectId, targetEntityId, atCompleteNames, comment, comments, setComments, isNewComment,
                          commentMoveTargets, badgeElement, entityType, internal=false, otherComments, setOtherComments,
                          startAsEditing=false, newCommentPostHook=null, commentReplyTarget=null,
                          setCommentReplyTarget=null, raisedZindex=true, allowUpload=true}) {
  /*
  {'target_entity_id': '1ed18544-e478-4ec6-8919-2e728544ac66',
  'target_entity_type': 'Task',
  'target_entity_title': 'New Task22GOGO222',
  'project_id': 'e7fac36c-9d2b-4d1c-a24a-bef5c0cf86d8',
  'project_name': '!bang',
  'customers': [],
  'discussion_author_name': 'Fixture Admin',
  'discussion_comment': '<p>this entry has a file too!</p>',
  'discussion_timestamp_dt': '2022-06-27T19:02:51.993513+00:00',
  'entity_url': 'http://local.coordinatehq.com:5000/vendor/0ab37cf1-fc60-4d93-b72b-89335f759581/customer/e7fac36c-9d2b-4d1c-a24a-bef5c0cf86d8/TASK/1ed18544-e478-4ec6-8919-2e728544ac66', 'file_entries_json': [{'file_uid': 'b988bb04-fcc6-4e6e-a33e-004ce71372ef', 'file_size': 88329, 'file_content_type': 'image/jpeg', 'file_name': 'meme.jpg', 'file_dt': '2022-06-27T19:02:51.829457+00:00'}, {'file_uid': '41bf831d-63ed-450d-a69e-53006be8bcb6', 'file_size': 17748, 'file_content_type': 'image/svg+xml', 'file_name': 'logo.svg', 'file_dt': '2022-06-27T19:02:51.978484+00:00'}]}
   */
  const capType = entityType[0].toUpperCase() + entityType.substring(1);

  // We will use this to lookup in localStorage in case they close the dialog
  const draftId = `${projectId}${targetEntityId}_comment_draft`;

  const [isEditing, setIsEditing] = useState(startAsEditing);

  const [isReacting, setIsReacting] = useState(false);
  const [reactions, setReactions] = useState(comment !== undefined ? comment.discussion_entry_reactions : null);

  const reactionMap = {
    smile: '😀',
    thumbsUp: '👍',
    thumbsDown: '👎',
    partyPopper: '🎉',
    oneHundred: '💯',
    heart: '❤️️',
    check: '✅',
    eyes: '👀',
    fire: '🔥'
  }

  const reactionDiv = useRef();

  function handleReactionClick(event) {
    fetchSession(`/js/customer/${projectId}/entity/${targetEntityId}/comment/${comment.discussion_entry_id}/reaction`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({reaction: event.target.dataset.reactionName})
      })
      .then(response => response.json())
      .then(result => {
        setIsReacting(false);
        setReactions(result);
      })
  }

  const [attachments, setAttachments] = useState(comment !== undefined ? comment.file_entries_json : []);

  // These are only for the new comment case
  const [pendingAttachments, setPendingAttachments] = useState([]);

  //let editorText = comment !== undefined ? comment.discussion_comment : '';
  const draftContent = localStorage.getItem(draftId)
  const [editorText, setEditorText] = useState(comment !== undefined ? comment.discussion_comment : (
    draftContent !== null ? draftContent : ''
  ));

  const uploadSpinner = useRef();

  const [commentError, setCommentError] = useState(null);

  let froalaConfig = {
    // toolbarButtons are added conditionally below
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    zIndex: raisedZindex ? 2501 : null,
    linkInsertButtons: ['linkBack'],
    //autofocus: true,
    linkAlwaysBlank: true,
    attribution: false,
    quickInsertEnabled: false,
    iconsTemplate: 'font_awesome_5r',
    charCounterCount: false,
    fileUpload: false,
    imageUpload: true,
    imagePaste: true,
    videoUpload: false,
    imageUploadParam: 'image_param',
    imageUploadURL: `/image_upload/${projectId}`,
    imageUploadMethod: 'POST',
    imageMaxSize: 10 * 1024 * 1024,
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    key: 'AVB8B-21B2B3C3B2B2E1ua2BD1IMNBUMRWAd1AYMSTRBUZYA-9H3E2J2C4C6C3B1B5B1C2==',
    wordCounterCount: false,
  }


  if(isMobile()) {
    froalaConfig.toolbarButtons = [];
  } else {
    froalaConfig.toolbarButtons = ['paragraphFormat', 'fontSize', 'bold', 'italic', 'underline', 'textColor',
                     'insertLink', 'formatOL', 'formatUL', 'insertVideo', 'insertImage', 'html', 'insertTable',
                     'clearFormatting'];
  }

  if(isEditing) {

    froalaConfig.events = {
      initialized: function () {
        var editor = this;

        // Tribute is brought in from a top level script include from base.html, there seems to be issues with the npm
        // packages so this is working for now
        let tribute = new Tribute({
          values: atCompleteNames.map(e => {return {key: e[1]+e[0], name: e[1],  email: e[0], icon: e[2], value: e[0]}}),
          containerClass: 'custom-tribute-container',
          selectClass: 'custom-tribute-highlight',
          itemClass: 'custom-tribute-item',
          menuItemLimit: 6,

          /* as of Froala 4.0.14 we were seeing the dropup/dropdown behavior place the list in sometimes wrong
             locations, this seems to fix it
             also dropping tons of whitespace at the end of <main> with BR fix the issue.
           */

          // THIS ONE is close
          // bug here is scrolling background with scrolling foreground container the menu renders too high/off screen
          //menuContainer: editor.el.parentElement.parentElement.parentElement,

          // we have a situation on the project page where the discussion is not in the react modal, this is already
          // messy so here is another hack to make at complete work on the discussion page
          menuContainer: document.getElementById('react-modal-content').offsetParent == null ?
            editor.el.parentNode.parentNode : document.getElementById('react-modal-content'),

          menuItemTemplate: function (item) {
            return `<span>
                    <div data-email="${item.original.email}" class="row align-items-center no-gutters" style="cursor: pointer;">
                      <div data-email="${item.original.email}" class="col-auto pr-3" style="cursor: pointer;">
                        <i data-email="${item.original.email}" class="${item.original.icon}" style="cursor: pointer;"></i>
                      </div>
                      <div data-email="${item.original.email}" class="col" style="cursor: pointer;">
                        <div data-email="${item.original.email}" class="font-weight-bold" style="cursor: pointer;">${item.original.name}</div>
                          <div data-email="${item.original.email}" style="cursor: pointer;">${item.original.email}</div
                        </div>
                    </div>
                  </span>`;
          },
          selectTemplate: function (item) {
            return '<strong>@' + item.original.name + '</strong>';
          }
        });

        tribute.attach(editor.el);

        editor.events.on('keydown', function (e) {
          //if (e.which === FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
          if (e.which === 13 && tribute.isActive) {
            tribute.selectItemAtIndex(tribute.menuSelected);
            tribute.hideMenu();
            e.preventDefault();
            return false;
          }
        }, true);

        // Store the draft, we will clear this on submit
        editor.events.on('keyup', (e) => {
          // don't save the draft if we're editing as this creates weird behavior since we don't distinguish
          // between which comment was being edited so the new comment will get the draft saved as part of the
          // edit
          if(isNewComment) {
            localStorage.setItem(draftId, editor.html.get())
          }
        });

        editor.events.focus(true);
      }
    }
  }

  const [notifyTimer, setNotifyTimer] = useState(null);
  const [stakeholdersToNotify, setStakeholdersToNotify] = useState([]);
  const [didInitialNotifyLoad, setDidInitialNotifyLoad] = useState(false);

  function handleModelChange(model) {
    if(model !== null && model !== '') {
      // Clear current timer if set then start a new one when the timer fires it will set the to text
      if(notifyTimer != null) {
        clearTimeout(notifyTimer);
      }
      setNotifyTimer(setTimeout(() => fetchAndUpdateRecipients(model), 1500));
    } else {
      setStakeholdersToNotify([]);
    }
    setEditorText(model);
  }

  function fetchAndUpdateRecipients(model) {
    const replyTargetAuthorId = commentReplyTarget !== null ? commentReplyTarget.discussion_author_id : 'None';
    const fetchUrl = internal ? `/js/customer/${projectId}/discussion/${entityType}/${targetEntityId}/${replyTargetAuthorId}/get_notification_targets/internal`
                      : `/js/customer/${projectId}/discussion/${entityType}/${targetEntityId}/${replyTargetAuthorId}/get_notification_targets`
    fetchSession(fetchUrl, {
      method: 'POST',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({comment: model})
    })
    .then(response => response.json())
    .then(result => {
      setStakeholdersToNotify(result);
    });
  }


  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState([]);

  let pendingAttach = [];
  function handleAttachmentCallback(result, comment, totalAttach) {
    // this is called with an array of a single file entry object after committing the ephemeral upload
    pendingAttach.push(result[0]);
    if(pendingAttach.length === totalAttach) {
      comment.file_entries_json = [...pendingAttach];
      setComments([comment, ...comments])
      setCommentError(null);
      setIsEditing(startAsEditing);
      setCommentReplyTarget(null);
      setEditorText('');
      setPendingAttachments([]);
    }
  }

  function handleSave() {
    // Just return if there is no comment or attachment, later on we can maintain disabled state of the button
    if(!(pendingAttachments.length > 0 || editorText !== "")) {
      return;
    }

    if(pendingAttachments.length > 0) {
      uploadSpinner.current.classList.remove('d-none');
    }

    const formData = new FormData();
    /* we are gonna send these via the handleAttachFileChange
    pendingAttachments.forEach(file => {
      formData.append('File', file);
    });
     */
    formData.append('new_comment', editorText !== null ? editorText : '');
    formData.append('internal', String(internal));
    if(commentReplyTarget != null) {
      formData.append('reply_target_entry_id', commentReplyTarget.discussion_entry_id);
      formData.append('reply_target_comment', commentReplyTarget.discussion_comment);
      formData.append('reply_target_author_id', commentReplyTarget.discussion_author_id);
      formData.append('reply_target_author_badge_html', commentReplyTarget.badge_html);
      formData.append('reply_target_author_user_name', commentReplyTarget.discussion_author_user_name);
    }

    if(isNewComment) {
      setCommentError(null);

      fetchSession(`/js/customer/${projectId}/entity/${targetEntityId}/comment/new/type/${capType}`,
        {
          method: 'POST',
          body: formData
        })
        .then(response => response.json())
        .then(result => {
          if('error' in result) {
            setCommentError(result.error)
            uploadSpinner.current.classList.add('d-none');
            return;
          }

          localStorage.removeItem(draftId);

          // We will set the new comment in the callback when the attachments are finished uploading
          if(pendingAttachments.length === 0) {
            setComments([result, ...comments])
            setCommentError(null);
            setIsEditing(startAsEditing);
            setCommentReplyTarget(null);
            setEditorText('');
            setPendingAttachments([]);
          }

          if('email_trace_ids' in result) {
            let traces = JSON.parse(result['email_trace_ids']);
            let testDiv = document.querySelector('#email_trace_ids_for_comments');
            while(testDiv.firstChild) {
             testDiv.removeChild(testDiv.lastChild);
            }

            traces.forEach((id) => {
              let newEl = document.createElement('div');
              newEl.dataset.behave_email_trace_id = id;
              newEl.classList.add('behave-email-trace-id-react');
              testDiv.append(newEl);
            });
          }

          if(newCommentPostHook) {
            newCommentPostHook();
          }
          if(pendingAttachments.length > 0) {
            handleAttachFileChange(pendingAttachments, null, null, projectId, targetEntityId, capType,
              setUploadInProgress, setUploadProgress, result.discussion_entry_id,
              (fileEntry) => handleAttachmentCallback(fileEntry, result, pendingAttachments.length));
          }

          uploadSpinner.current.classList.add('d-none');
        })
        .catch(error => {
          setCommentError('Failed to create comment/attach file.')
          uploadSpinner.current.classList.add('d-none');
        });
    } else {
      fetchSession(`/js/customer/${projectId}/entity/${targetEntityId}/comment/${comment.discussion_entry_id}`,
        {
          method: 'POST',
          body: formData
        })
        .then(response => response.json())
        .then(result => {
          if('error' in result) {
            setCommentError(result.error)
            uploadSpinner.current.classList.add('d-none');
            return;
          }

          setIsEditing(false);
          comments.find(e => e.discussion_entry_id = comment.discussion_entry_id).discussion_comment = result.discussion_comment;
          comment.discussion_comment = result.discussion_comment
          setAttachments(result.file_entries_json)
          setPendingAttachments([]);
          setComments(comments);
          uploadSpinner.current.classList.remove('d-none');
        });
    }
  }

  const attachFileInput = useRef();

  function handleAttachFileClick(event) {
    attachFileInput.current.click();
  }

  function handlePendingAttachFileChange(event) {
    setPendingAttachments([...event.target.files]);
  }

  function handleRemovePendingAttachments() {
    setPendingAttachments([]);
  }

  //
  //
  const [hasClickedDelete, setHasClickedDelete] = useState(false);

  function handleConfirmDelete() {
    fetchSession(`/js/customer/${projectId}/entity/${targetEntityId}/discussion/${comment.discussion_entry_id}/delete`, {
          method: 'POST',
        })
        .then(response => response.json())
        .then(result => {
          // We have a weird spot where renderComments for the active task page doesn't have a list of comments
          // just reload
          if(comments) {
            setComments(comments.filter((c) => c.discussion_entry_id !== comment.discussion_entry_id))
          } else {
            window.location.reload();
          }
        });
  }

  // Moving comments
  const moveComment = useRef();
  const moveCommentKeepOriginalCheckbox = useRef();
  const [isMovingComment, setIsMovingComment] = useState(false);
  const moveCommentSelect = useRef();

  function handleMoveCommentSubmit() {
    const targetId = moveCommentSelect.current.getValue()[0].value
    fetchSession(`/js/customer/${projectId}/entity/${targetEntityId}/comment/${comment.discussion_entry_id}/move`, {
          method: 'POST',
          headers: {
           'Content-type': 'application/json'
          },
          body: JSON.stringify({keepOriginal: moveCommentKeepOriginalCheckbox.current.checked,
                                      id: targetId })
        })
        .then(response => response.json())
        .then(result => {
          setIsMovingComment(false);
          if(!moveCommentKeepOriginalCheckbox.current.checked) {
            setComments(comments.filter((c) => c.discussion_entry_id !== comment.discussion_entry_id))
          }
          if(['move_external', 'move_internal'].includes(targetId)) {
            // we are soft deleting the old entry and making a new one, if you keep moving back and forth
            // the frontend will not have the right entry id
            let entry = comments.find((c) => c.discussion_entry_id === comment.discussion_entry_id)
            entry.discussion_entry_id = result.entryId;
            setOtherComments([...otherComments, entry].sort((a, b) => a.discussion_timestamp_dt < b.discussion_timestamp_dt))
          }
        });
  }

  function renderCommentBody() {
    return (
      <div className="row c-chat-message behave_comment_marker c-hover-indicator-container">
        {parse(comment.badge_html)}
        <div className="col pl-1">
          <div className="row no-gutters pb-0">
            <div className="col">
              <span className="font-weight-bold mr-2">{comment.discussion_author_name}</span>
              <span className="c-date d-inline-block ml-1 c-font-8 text-secondary">
                {(new Date(comment.discussion_timestamp_dt)).toLocaleString()}
                {comment.discussion_edited && (<span className="c-text-optional"> (edited)</span>)}
              </span>
            </div>

            <div className="col-auto c-chat-options">
              {getIsUser() && comment.discussion_entry_sent_email_keys.length > 0 &&
                <a className="btn-link-edit c-edit-menu ml-1 mr-0 p-0"
                   data-target="#bigDebugModal" data-toggle="modal"
                   data-vendor-id={comment.vendor_id}
                   data-customer-id={comment.project_id}
                   data-entity-id={comment.target_entity_id}
                   data-entry-id={comment.discussion_entry_id}>
                  <i className="far fa-at" style={{padding: '5px 6px'}}></i>
                </a>
              }

              <span className="c-edit-menu">
                {/* TODO all the replace styles are not rendering from FA
                fas fa-reply
                   */}
                {setCommentReplyTarget !== null && (
                  <i className="fal fa-reply-all btn-link-edit text-decoration-none"
                     style={{padding: '5px 4px'}}
                     onClick={() => setCommentReplyTarget(comment)}></i>
                )}
              </span>

              <span className="c-edit-menu ml-1">
                {!comment.discussion_deleted &&
                  <i className="far fa-smile-plus btn-link-edit text-decoration-none"
                     style={{padding: '5px 4px'}} onClick={() => setIsReacting(true)}></i>
                }
              </span>

              {isReacting && (
                <span tabIndex={0} className="c-reaction-comment-container shadow"
                     ref={reactionDiv}
                     onBlur={() => setIsReacting(false)}>
                  {Object.entries(reactionMap).map(entry => (
                    <span key={entry[0]}
                          className="c-reaction-comment-hover"
                          data-reaction-name={entry[0]}
                          onClick={handleReactionClick}>
                      {entry[1]}
                    </span>
                  ))}
                </span>
              )}

              {(comment.allow_edit && !comment.discussion_deleted) &&
                <span className="c-edit-menu ml-1">
                    <i className="fal fa-pencil-alt behave-comment-edit btn-link-edit text-decoration-none" style={{padding: '5px 5px'}}
                       onClick={() => setIsEditing(true)} />
                </span>
              }


              {getIsUser() && commentMoveTargets.length > 0 &&
                <a className="btn-link-edit c-edit-menu ml-1 mr-0 p-0"
                        id=""
                        onClick={() => setIsMovingComment(true)}>
                  <i className="far fa-arrow-square-right" style={{padding: '5px 6px'}}></i>
                </a>
              }


              {isMovingComment &&
                <div className="c-move-comment-container shadow"
                     ref={moveComment}>
                  <div className="form-group">
                    <span>Move comment to:</span>
                    <Select options={commentMoveTargets}
                            defaultValue={commentMoveTargets[0]}
                            ref={moveCommentSelect} />
                  </div>

                  <div className="form-group row">
                    <div className="col">
                      <div className="form-check">
                        <input className="form-check-input"
                               type="checkbox"
                               name="keep_original"
                               ref={moveCommentKeepOriginalCheckbox} />

                        <label className="form-check-label behave-keep-original-check"
                                htmlFor="keep_original">
                          Keep original
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <button className="btn btn-outline-secondary"
                              type="button"
                              onClick={() => setIsMovingComment(false)}>
                        Cancel
                      </button>
                    </div>

                    <div className="col-auto">
                      <button className="btn btn-primary behave-move-comment-submit"
                              onClick={handleMoveCommentSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>}

              {((comment.allow_edit || comment.allow_delete) && !comment.discussion_deleted) && (
                <span className="c-edit-menu ml-2 mr-0">
                  {hasClickedDelete ? (
                    <span className="btn btn-link-edit-danger text-danger" onClick={handleConfirmDelete}>
                      Confirm Delete?
                    </span>
                  ) : (
                    <i className="fal fa-trash-alt btn-link-edit-danger" style={{padding: '5px 6px'}} onClick={() => setHasClickedDelete(true)}></i>
                  )}
                </span>
              )}
            </div>
          </div>

          <div className="row no-gutters mt-0 mb-1">
            <div className="col-12 behave-comment-marker c-discussion-cell text-break">
              {comment.discussion_comment && parse(comment.discussion_comment)}

              {(!comment.discussion_deleted && attachments !== null) && (
                <Fragment>
                  <Attachments customerId={projectId}
                               entityId={targetEntityId}
                               attachments={attachments}
                               setAttachments={setAttachments}
                               isEditing={isEditing}
                               entryId={comment !== undefined ? comment.discussion_entry_id : null}/>
                  <p className="mt-2"></p>
                </Fragment>

                )}
                {reactions !== null && Object.entries(reactions).filter(x => x[1].length > 0).map(entry => (
                  // <div className="mt-2">
                    <div className="reaction-tooltip c-emoji"
                          style={{cursor: 'pointer'}}
                          key={entry[0]}
                          data-toggle="tooltip"
                          data-reaction-name={entry[0]}
                          data-html="true"
                          title={entry[1].map(e => ` ${e.stakeholder_full_name}`)}
                          onClick={handleReactionClick}>
                      {reactionMap[entry[0]]} {entry[1].length}</div>
                  // </div>
                ))}
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect((didUpdate) => {
    if(reactionDiv.current) {
      reactionDiv.current.focus();
    }

    $('.reaction-tooltip').tooltip();

    if(commentReplyTarget != null) {
      setIsEditing(true);
    }

  });


  return (
    <Fragment>
      {isEditing ? (
        <div className="col-12 behave-discussion-marker behave_comment_marker"
             /* clear out all the massive layers of padding on mobile to get the chat input some room with the
                keyboard taking up half the screen */
             style={isMobile() ? {paddingLeft: '1px'} : {}}>
          {commentReplyTarget && (
             <div className="row mt-3">
              <div className="col-12">
                <div className="row align-items-center">
                  <div className="col-auto ml-3 pr-0">
                    <i className="fal fa-level-down fa-flip-horizontal"></i>
                  </div>

                  <div className="col">
                    Replying to&nbsp;
                    <span className="font-weight-bold">@{commentReplyTarget.discussion_author_user_name}</span>
                  </div>

                  <div className="col-auto pr-1">
                    <button className="btn btn-link" onClick={() => setCommentReplyTarget(null)}>
                      <i className="fas fa-times"></i> Clear reply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!uploadInProgress && (
            <div className="row pb-0">
              <div className="col" style={isMobile() ? {paddingLeft: '0px', paddingRight: '0px'} : {}}>

                <FroalaEditor config={froalaConfig}
                        tag="textarea"
                        model={editorText}
                        onModelChange={handleModelChange}/>
                <input id={'fileinput' + (comment !== undefined ? comment.discussion_entry_id : 'newcomment')}
                       ref={attachFileInput}
                       className="d-none"
                       type="file"
                       multiple="multiple"
                       onChange={handlePendingAttachFileChange} />
              </div>
            </div>
          )}

          {(isNewComment || (!comment.discussion_deleted && attachments !== null)) && (
            <Attachments customerId={projectId}
                         entityId={targetEntityId}
                         attachments={attachments}
                         setAttachments={setAttachments}
                         isEditing={isEditing}
                         entryId={comment !== undefined ? comment.discussion_entry_id : null} />
          )}

          {/* Start Sent To Footer */}
          {stakeholdersToNotify.length > 0 && (
            <div className="row align-items-start no-gutters c-notify-list pl-2">
              <div className="col-auto mr-2">
                <span className="c-phone-md">
                  <i className="fal fa-paper-plane" data-toggle="tooltip" data-placement="top"
                     title="This message will be sent to:"></i>
                </span>

                <span className="c-desktop-md">
                  <i className="fal fa-paper-plane"></i>
                </span>
                <span className="c-desktop-md"> An email notification will be sent to:</span>
              </div>

              {/* Start avatar section */}
              <div className="col c-collab-list">
                <div className="row no-gutters">
                  {stakeholdersToNotify.map((stakeholder) => (
                    <Fragment key={stakeholder.stakeholder_id}>
                      {parse(stakeholder.badge_html)}
                    </Fragment>
                  ))}
                </div>
              </div>
              {/* End avatar section */}

              {/* TODO when these come in we need to put the mb-3 back into user_badge_notification.html */}

              {/* Start See all */}
              <div className="col-auto d-none-feature">
                <div className="dropdown dropleft">
                  <a className="btn btn-link p-0 pr-2" type="button" data-toggle="dropdown" aria-expanded="false">
                    <span className="badge badge-pill badge-light">See all 3</span>
                  </a>

                  <div className="dropdown-menu">
                    {/* header */}
                    <div className="row pl-2">
                      <div className="col text-dim">
                        <i className="fal fa-paper-plane"></i> Immediately
                      </div>
                    </div>
                    {/* header */}

                    {/* avatar */}
                    <div className="row pl-2 mt-2 align-items-center d-flex flex-nowrap no-gutters">
                      <div className="col-auto mr-2">
                        <div className="c-center-element-parent">
                          <div className="c-avatar-container c-avatar-bg">
                            {/*TODO: make this work*/}
                            {/*<img src="" className="c-user-img c-center-element-child d-none" width="25px">*/}
                            <div className="c-center-element-child c-avatar-initials">FA</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        Fixture Admin
                      </div>
                    </div>
                    {/* avatar */}

                    {/* avatar */}
                    <div className="row pl-2 mt-2 align-items-center d-flex flex-nowrap no-gutters">
                      <div className="col-auto mr-2">
                        <div className="c-center-element-parent">
                          <div className="c-avatar-container c-avatar-bg">
                            {/*TODO: make this work*/}
                            {/*<img src="" className="c-user-img c-center-element-child d-none" width="25px">*/}
                            <div className="c-center-element-child c-avatar-initials">FA</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        Fixture Admin
                      </div>
                    </div>
                    {/* avatar */}

                    <div className="dropdown-divider"></div>

                    {/* header */}
                    <div className="row pl-2">
                      <div className="col text-dim">
                        <i className="fal fa-paper-plane"></i> In [3] days
                      </div>
                    </div>
                    {/* header */}

                    {/* avatar */}
                    <div className="row pl-2 mt-2 align-items-center d-flex flex-nowrap no-gutters">
                      <div className="col-auto mr-2">
                        <div className="c-center-element-parent">
                          <div className="c-avatar-container c-avatar-bg">
                            {/*TODO: make this work*/}
                            {/*<img src="" className="c-user-img c-center-element-child d-none" width="25px">*/}
                            <div className="c-center-element-child c-avatar-initials">FA</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        Fixture Admin
                      </div>
                    </div>
                    {/* avatar */}

                  </div>
                </div>
              </div>
              {/* End See all */}

              {/* Start Muted */}
              <div className="col-auto d-none-feature">
                <div className="dropdown dropleft">
                  <a className="btn btn-link p-0 pr-2" type="button" data-toggle="dropdown" aria-expanded="false">
                    <span className="badge badge-pill badge-secondary">2 muted</span>
                  </a>

                  <div className="dropdown-menu">
                    <div className="row pl-2">
                      <div className="col text-dim c-muted-dropdown">
                        <i className="fal fa-volume-mute"></i> These collaborators muted their notifications. To contact
                        them, you must use @mention
                      </div>
                    </div>

                    <div className="dropdown-divider"></div>

                    <div className="row pl-2 mt-2 align-items-center d-flex flex-nowrap no-gutters">
                      <div className="col-auto mr-2">
                        <div className="c-center-element-parent">
                          <div className="c-avatar-container c-avatar-bg">
                            {/*TODO: make this work*/}
                            {/*<img src="" className="c-user-img c-center-element-child d-none" width="25px">*/}
                            <div className="c-center-element-child c-avatar-initials">FA</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto ">
                        Fixture Admin
                      </div>
                    </div>

                    <div className="row pl-2 mt-2 align-items-center d-flex flex-nowrap no-gutters">
                      <div className="col-auto mr-2">
                        <div className="c-center-element-parent">
                          <div className="c-avatar-container c-avatar-bg">
                            {/*TODO: make this work*/}
                            {/*<img src="" className="c-user-img c-center-element-child d-none" width="25px">*/}
                            <div className="c-center-element-child c-avatar-initials">FA</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        Fixture Admin
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Muted */}

            </div>

          )}
          {/* END Sent To Footer */}

          <div className="row mb-2 mt-2 justify-content-end align-items-center d-flex flex-nowrap">
            <div className="col">
              {!uploadInProgress && (
                <Fragment>
                  {pendingAttachments.length > 0 ? (
                    <Fragment>
                      {pendingAttachments.map(file => (<span className="mr-2" key={file.name + file.size}>{file.name}</span>))}
                      <i className="fas fa-times ml-2" onClick={handleRemovePendingAttachments} />
                    </Fragment>
                  ) : allowUpload && (
                      <button className="btn btn-link float-right" onClick={handleAttachFileClick}>Attach File(s)</button>
                    )
                  }
                </Fragment>
              )}

              {uploadInProgress && (
                <FileProgress uploadProgress={uploadProgress} />
              )}

              <div ref={uploadSpinner} className="progress m-1 mt-2 d-none"
                   style={{width: '100%', height: '40px'}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                     style={{width: '100%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                  Uploading files ...
                </div>
              </div>
            </div>

            {commentError && (
              <div className="col"><span className="text-danger">{commentError}</span></div>
            )}

            {!startAsEditing && !uploadInProgress && (
              <div className="col-auto pl-0">
                <div className="btn btn-outline-secondary btn-sm"
                        onClick={() => {
                          setIsEditing(false);
                          setCommentReplyTarget(null);
                          localStorage.removeItem(draftId);
                        }}>
                  Cancel
                </div>
              </div>
            )}

            {!uploadInProgress && (
              <div className="col-auto pl-0">
                <div className="btn btn-primary btn-sm" id="discussion_entry_submit_just_comment"
                     onClick={() => {
                       if(!uploadInProgress) { handleSave()}
                     }}>
                  Comment
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (  // Not editing case
        <Fragment>
          {isNewComment ? (
            <div className="col-12 behave-discussion-marker behave_comment_marker">
              <div onClick={() => setIsEditing(true)}
                   className="mb-2 behave-comment-area">
                <div className="row">
                  {badgeElement !== null && parse(badgeElement)}
                  <div className="col">
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Add a Comment"
                               id="comment_entry_button_area" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            comment.reply_target_entry_id !== null ? (
              <div className={'col-12 card mb-2 mt-1 ' + (getUserStakeholderId() === comment.reply_target_author_id ? 'c-reply-container-to-you' : 'c-reply-container') }
                   id={comment.discussion_entry_id}>
                <a className="row mb-2 mt-2 pl-5 justify-content-end align-items-center d-flex flex-nowrap text-secondary"
                   href={'#'+comment.discussion_entry_id} role="button">
                  <div className="col-auto mb-0 p-0 pr-2">
                    <i className="fal fa-level-down fa-flip-horizontal"></i>
                  </div>

                  {/*
                  <div className="col-auto c-user-badge-container-xs mb-0 p-0">
                    <div className="c-center-element-parent">
                      <div className="c-center-element-child-bg c-user-img-bg-color"></div>
                        <span className="c-center-element-child c-user-initials">TT</span>
                    </div>
                  </div>
                  */}

                  {comment.reply_target_author_badge_html !== undefined && parse(comment.reply_target_author_badge_html.replace('c-user-badge-container-sm', 'c-user-badge-container-xs'))}

                  <div className="col-12 pl-2 text-truncate">
                    <span className="font-weight-bold">@{comment.reply_target_author_user_name}</span>
                    <span className="ml-2 text-truncate">
                      {(new DOMParser()).parseFromString(comment.reply_target_comment, "text/html").body.innerText}
                    </span>
                  </div>
                </a>

                {renderCommentBody()}
              </div>
            ) : (
              <div className="col-12 behave-discussion-marker behave_comment_marker">
                {renderCommentBody()}
              </div>
            )
          )
        }
        </Fragment>
      )}
    </Fragment>
  )
}

