import React, {useState, useRef, Fragment, useEffect} from "react"
import {Status} from "./constants";
import flatpickr from "flatpickr";
import {Tags, TagsDropdown} from "./tags";
import {Title} from "./title";
import {Progress} from "./progress";
import {StatusDropdown} from "./status_dropdown";
import {Description} from "./description";
import {Comment} from "./comment";

import {
  getIsUser,
  dateIsBeforeToday,
  parseDateIgnoringTimezone,
  parseDateIgnoringTimezoneGetDate,
  formatDate,
  formatDate3,
  formatDateShort,
  getStatusNameAndStyle,
  getUserStakeholderId,
  handleAttachFileChange,
  fetchSession
} from "./common_functions";

import {Attachments} from "./attachments";
import {Dropdown} from "react-bootstrap";
import {FileProgress} from "./fileProgress";

export function Goal(props) {
  const [loaded, setLoaded] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowUpload, setAllowUpload] = useState(true);

  const [title, setTitle] = useState(null);

  const [description, setDescription] = useState(null);
  const [descriptionIsEditing, setDescriptionIsEditing] = useState(false);

  //
  // Attachments
  const attachFileInput = useRef()

  const [attachments, setAttachments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([])
  const [uploadInProgress, setUploadInProgress] = useState(false)

  //
  // Comments
  const [comments, setComments] = useState([]);
  const [commentReplyTarget, setCommentReplyTarget] = useState(null);

  //
  // due
  const [dueDate, setDueDate] = useState(null);
  const dueDateInput = useRef();

  function handleDueDateChange(selectedDates, dateStr, instance) {
    fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({target_date: dateStr})
    }).then(() => {
      if(selectedDates.length === 0) {
        setDueDate(null);
      } else {
        setDueDate(selectedDates[0]);
      }
    });
  }


  const [targetDateTemplateDays, setTargetDateTemplateDays] = useState(null);
  const [targetDateTemplateDaysError, setTargetDateTemplateDaysError] = useState(null);
  function handleTargetDateTemplateDaysBlur() {
    if(targetDateTemplateDays !== '' && isNaN(targetDateTemplateDays)) {
      setTargetDateTemplateDaysError('Must be a whole number.');
      return;
    }
    setTargetDateTemplateDaysError(null);

    fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({target_date_template_days: parseInt(targetDateTemplateDays)})
    });
  }


  //
  // completed
  const [completedDate, setCompletedDate] = useState(null);
  const completedDateInput = useRef();

  function handleCompletedDateChange(selectedDates, dateStr, instance) {
    fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({completed_date: dateStr})
    }).then(() => {
      setCompletedDate(selectedDates[0]);
    });
  }


  // status
  const [status, setStatus] = useState(Status.NotStarted);

  // auto-complete
  const [autoComplete, setAutoComplete] = useState(null);

  const completeDisplay = {
    'manual': 'Manual',
    'task': 'Task',
    'progressbar': 'Progress Bar'
  }

  function handleAutoCompleteChange(event) {
    const type = event.target.dataset.completeType
    fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({goal_auto_complete: type})
    }).then((response) => response.json())
      .then((result) => {
        setStatus(result.goal_status);
        setAutoComplete(type);
    });
  }

  // progress
  const [progress, setProgress] = useState(null);
  const [progressIsEditing, setProgressIsEditing] = useState(false);
  const progressPreviousValue = useRef(); // TODO verify this needs to be up at this level

  function handleProgressCreate() {
    const newValue = {
      numerator: 0,
      denominator: 100,
      unit: 'Percent'
    }

    fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({progress: newValue})
    }).then(() => {
      setProgressIsEditing(true);
      setProgress(newValue);
      progressPreviousValue.current = newValue;
    });
  }

  //
  // sponsor
  const [sponsor, setSponsor] = useState(null);
  const [sponsorIsEditing, setSponsorIsEditing] = useState(false);
  const sponsorInput = useRef();

  function handleSponsorBlur() {
    const value = sponsorInput.current.value === "" ? null : sponsorInput.current.value;

    fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({sponsor_name: value})
    }).then(() => {
      setSponsorIsEditing(false)
      setSponsor(value);
    });

  }

  function handleSponsorRemoveClick(event) {
    fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({sponsor_name: null})
    }).then(() => {
      setSponsorIsEditing(false)
      setSponsor(null);
    });
  }


  // related tasks
  const [tasks, setTasks] = useState([]);
  const [relatedTasks, setRelatedTasks] = useState([]);
  const [renderedTasks, setRenderedTasks] = useState({})

  function renderRelatedTasksDropdown(renderId) {
    return tasks.filter(t => !relatedTasks.map(rt => rt.task_id).includes(t.task_id)).length > 0 ? (
      <div className="dropdown">
        <a className="btn c-btn-addto" href="#" role="button"
           data-toggle="dropdown">
          <div className="row align-items-center no-gutters">
            <div className="col-auto text-left" id={renderId === true ? 'relate-task-dropdown' : ''}>
              Related Task
            </div>
            <div className="col-auto ml-2">
              <i className="fas fa-caret-down"></i>
            </div>
          </div>
        </a>
        <div className="dropdown-menu">
          {tasks.filter(t => !relatedTasks.map(rt => rt.task_id).includes(t.task_id)).map(task => (
            <a key={task.task_id} data-task-id={task.task_id}
               className="dropdown-item" href="#" onClick={handleRelateTaskClick}>
              {task.task_title}
            </a>
          ))}
        </div>
      </div>
    ) : (
      <a className="btn c-btn-addto disabled" href="#" role="button"
         data-toggle="tooltip" title="No Takss Available">
        <div className="row align-items-center no-gutters">
          <div className="col text-left">
            No Tasks To Relate
          </div>
          <div className="col-auto">
            <i className="fas fa-caret-down"></i>
          </div>
        </div>
      </a>
    )
  }

  function handleRelateTaskClick() {
    const taskId = event.target.dataset.taskId
    // note this url was an existing js fetch so has old style route
    fetchSession(`/customer/${props.customerId}/goal/${props.goalId}/add_outbound_goal_goal`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({related_task_id: taskId})
    }).then(() => {
      let task = tasks.find(t => t.task_id === taskId);
      setRelatedTasks([...relatedTasks, task]);
    });
  }

  function getRenderedRelatedTasks() {
    // This seems to want to be in here vs buried in the jsx directly, something with the get from renderedTasks call
    // it doesn't like the [id] syntax
    return relatedTasks.map((task) => (
      <div key={task.task_id}
           className="row align-items-center no-gutters c-hover-indicator-container border-bottom">
        <div className="col">
          <a href="#" className="btn-link"
             onClick={() => {
               window.openTaskId = task.task_id;
               window.customerId = props.customerId;
               document.getElementById('close_react_modal').click();
             }}
             data-behave-related-task={task.task_title}>
            {task.task_title}
          </a>
        </div>

        {/* TODO kept here for when we tried to render out the entire task card
        <div className="col"
             onClick={() => {
               window.openTaskId = task.task_id;
               document.getElementById('close_react_modal').click();
             }}>
          HEY HEY HEY
          {parse(renderedTasks[task.task_id])}
        </div>
        */}

        <div className="col-auto ml-1 c-hover-show">
          <button className="btn btn-link-edit-danger"
                  data-behave-remove-blocked-task-outbound={task.task_title}
                  onClick={() => handleRemoveRelatedTaskClick(task.task_id)}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    ))
  }

  function handleRemoveRelatedTaskClick(taskId) {
    fetchSession(`/customer/${props.customerId}/goal/${props.goalId}/task/${taskId}/remove_task_goal_relation`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({related_task_id: taskId})
    }).then(() => {
      setRelatedTasks(relatedTasks.filter((t) => t.task_id !== taskId))
    });
  }


  // comment elements
  const [atCompleteNames, setAtCompleteNames] = useState([]);
  const [commentMoveTargets, setCommentMoveTargets] = useState([]);
  const [badgeElement, setBadgeElement] = useState(null);


  // tagging
  const [tags, setTags] = useState([]);
  const [allAvailableTags, setAllAvailableTags] = useState([]);

  //
  // Delete Confirm
  const [hasClickedDelete, setHasClickedDelete] = useState(false);

  function handleConfirmDeleteClick(event) {
    fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}/delete`, {
      method: 'post',
    }).then(() => {
      props.closeFunction();
    });
  }

  const [copyLinkUrl, setCopyLinkUrl] = useState(null);

  //
  //
  //
  useEffect((didUpdate) => {
    if (loaded === false) {
      fetchSession(`/js/customer/${props.customerId}/goal/${props.goalId}`)
        .then(response => {
          return response.json();
        })
        .then(result => {
          setTitle(result.goal_title);
          setAllowEdit(result.allow_edit);
          setAllowUpload(result.allow_upload);

          setDescription(result.goal_description);

          setAttachments(result.file_entries_json);

          setProgress(result.goal_progress_json);

          setSponsor(result.goal_sponsor_name);

          setStatus(result.goal_status_current);
          setAutoComplete(result.goal_auto_complete);

          setDueDate(result.goal_target_date);
          setTargetDateTemplateDays(result.goal_target_date_template_days);


          if(result.goal_status_current === 'complete') {
            setCompletedDate(result.goal_completed_dt)
          } else {
            setCompletedDate(null);
          }

          setRelatedTasks(result.related_tasks);
          setTasks(result.tasks);

          setRenderedTasks(result.rendered_tasks);

          setAtCompleteNames(result.atCompleteNames)
          setBadgeElement(result.badge_html);
          setCommentMoveTargets(result.commentMoveTargets);
          setComments(result.comments);

          setTags(result.tags);
          setAllAvailableTags(result.all_tags);

          setCopyLinkUrl(result.copy_link_url)


          setLoaded(true);
        })
    }

    // dueDate init flatpickr, may not exist if this task was completed, instead we render a completed date picker
    if(allowEdit && dueDateInput.current !== undefined && dueDateInput.current !== null) {
      let instance = flatpickr(dueDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: dueDate,
        onChange: (selectedDates, dateStr, instance) => {
          handleDueDateChange(selectedDates, dateStr, instance);
        }
      });

      window.goalDueDateFlatpickr = instance;
    }


    // completedDate init flatpickr, may not exist if this task was completed, instead we render a completed date picker
    if(allowEdit && completedDateInput.current !== undefined && completedDateInput.current !== null) {
      let instance = flatpickr(completedDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: completedDate,
        onChange: (selectedDates, dateStr, instance) => {
          handleCompletedDateChange(selectedDates, dateStr, instance);
        }
      });

      window.goalCompletedDateFlatpickr = instance;
    }

    // This is a bit of a hack to keep using the server side rendered activity feed only when the history tab is shown
    // The history tab is rarely if ever used so I didn't want to have it fetch everytime and I didn't want to rewrite
    // for an unused feature.
    window.register_history_handle(props.customerId, props.goalId, 'nav-history-tab');


  });

  return loaded ? (
    <Fragment>
      <div className="modal-header pt-2 pb-2 border-0 data-behave-click-target"
           data-goal-status-title={title}>

        <button id="close_react_modal" type="button" className="close c-font-12" data-dismiss="modal">
          <span aria-hidden="true"><i className="fa fa-times"></i></span>
        </button>
      </div>

      <div className="modal-body pt-0 pb-0">
        {/* Behave Test Marker */}
        {loaded && (<div className="d-none task-has-loaded"></div>)}

        {/* Sticking this here because we need to call click from the mobile nav and the desktop nav */}
        <input id="goalFileAttach"
               ref={attachFileInput}
               className="d-none"
               type="file"
               multiple="multiple"
               onChange={(event) => handleAttachFileChange(event.target.files, attachments, setAttachments,
                 props.customerId, props.goalId, 'goal', setUploadInProgress, setUploadProgress)} />


        <div className="c-phone-md">
          <nav className="navbar navbar-light pb-0 pt-2">
            <div className="row">
              <div className="col-auto c-modal-left-side">
                <a href="#" className="btn-link text-secondary">
                  <i className="fal fa-copy"></i> <span className="c-text-underline mr-2">Copy shareable link</span>
                </a>
                <span className="d-none font-weight-normal ml-2" id="">
                  Copied to clipboard!
                </span>
              </div>
            </div>

            <button className="navbar-toggler mb-2" type="button" data-toggle="collapse" data-target="#narrow-hamburger"
                    aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse border" id="narrow-hamburger">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item c-side-menu-heading pt-0 pb-0 pl-2">
                  Add {props.alternateGoalName} Fields
                </li>

                {description == null && (
                   <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link"
                       onClick={() => {
                         setDescriptionIsEditing(true);
                         window.toggle_narrow_hamburger();
                       }}
                       href="#">Description
                    </a>
                  </li>
                )}

                {progress == null && (
                  <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link c-cursor-pointer" onClick={(e) => {
                      handleProgressCreate(e);
                      window.toggle_narrow_hamburger();
                    }}>Progress</a>
                  </li>
                )}

                {sponsor == null && (
                  <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link c-cursor-pointer" onClick={() => setSponsorIsEditing(true)}>Sponsor</a>
                  </li>
                )}

                <li className="nav-item pt-0 pb-0 pl-4">
                  <a className="nav-link" href="#"
                     onClick={() => attachFileInput.current.click()}>
                    <i className="fas fa-paperclip"></i> Attach Files
                  </a>
                </li>

                <li className="nav-item dropdown-divider"></li>

                <li className="nav-item c-side-menu-heading pt-0 pb-0 pl-2 mb-2">
                  Add Dependencies
                </li>

                <li className="nav-item pt-0 pb-0 pl-4">
                  {renderRelatedTasksDropdown('goal-narrow-relate-task')}
                </li>

                <li className="nav-item dropdown-divider"></li>

                <li className="nav-item pt-0 pb-0 pl-4 mb-2">
                  <TagsDropdown goalId={props.goalId} customerId={props.customerId} tags={tags} setTags={setTags}
                                allAvailableTags={allAvailableTags} />
                </li>

                <li className="nav-item pb-3 pl-4">
                  {hasClickedDelete ? (
                    <a className="btn btn-outline-danger c-btn-addto"
                       data-behave_task_delete_title={title}
                       onClick={handleConfirmDeleteClick}>
                      Confirm Delete?
                    </a>
                  ) : (
                    <a className="nav-link btn-link-edit-danger"
                       onClick={() => setHasClickedDelete(true)}
                       href="#">
                      <i className="far fa-trash-alt"></i> Delete {props.alternateGoalName}
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>


        {/* Main body 2 column layout - GOALS */}
        <div className="row d-flex flex-nowrap" style={{position: 'relative', zIndex: 1000}}>

          {/* column 1 */}
          <div className="col pb-4 pt-0 c-modal-left-side">
            <div className="row align-items-center c-internal-title text-break">
              {((dueDate && dateIsBeforeToday(dueDate) && status !== Status.Complete)
                || status === Status.Blocked) && (
                <div className="col-auto pr-0">
                  <i className="far fa-calendar-exclamation text-danger"></i>
                </div>
              )}

              <div className="col">
                <Title initialTitle={title} allowEdit={getIsUser()} startInEditMode={props.isNewGoal} status={status}
                       customerId={props.customerId} entityId={props.goalId} entityType="goal" />
              </div>
            </div>


            {progress !== null && (
              <Progress customerId={props.customerId} entityId={props.goalId} entityType="goal"
                        progress={progress} setProgress={setProgress} progressIsEditing={progressIsEditing}
                        setProgressIsEditing={setProgressIsEditing}
                        allowEdit={allowEdit} setGoalStatus={setStatus} setGoalCompletedDate={setCompletedDate}/>
            )}


            {/* This area is a few cols holding the sponsor, status etc */}
            <div className="row">
              {status === 'complete' ? (
              <div className="col-sm-6">
                  <div className="row mt-3 mb-1">
                    <div className="col c-internal-heading">
                      Completed Date
                    </div>
                  </div>
                {allowEdit ? (
                    <div className="row flatpickr" ref={completedDateInput}>
                      <div className="col">
                        {/* We will need to attach a class onto the input group that the classes below respect for red line
                            because flatpickr clones our input group here and makes a new one */}
                        <div className="input-group mb-0">
                          <input type="text" className="form-control" data-input=""
                                 id="task_completed_date_value"
                                 defaultValue={formatDate3(completedDate)} />
                          <div className="input-group-append d-none">
                            <button className="btn c-btn-dropdown-delete-border-left"
                                    type="button"
                                    id="due-date-remove-button"
                                    data-clear="">
                              <i className="fal fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col">
                        {formatDate(completedDate)}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
              <div className="col-sm-6">
                <div className="row mt-3 mb-1">
                  <div className="col c-internal-heading">
                    Target Date {allowEdit && (<span className="c-text-info">(optional)</span>)}
                  </div>
                </div>

                {allowEdit ? (
                  <Fragment>
                    {props.isOnTemplate ? (
                      <div className="row">
                        <div className="col">
                          <input className={"form-control" + (targetDateTemplateDaysError === null ? '':' is-invalid')}
                                 type="text" placeholder="Relative Days"
                                 onBlur={handleTargetDateTemplateDaysBlur}
                                 defaultValue={targetDateTemplateDays}
                                 onChange={e => setTargetDateTemplateDays(e.target.value)}/>
                          <div className="invalid-feedback">
                            {targetDateTemplateDaysError}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row flatpickr" ref={dueDateInput}>
                        <div className="col">
                          {/* We will need to attach a class onto the input group that the classes below respect for red line
                              because flatpickr clones our input group here and makes a new one */}
                          <div className={'input-group mb-0' + (dateIsBeforeToday(dueDate) && ' c-date-past-due') }>
                            <input type="text" className="form-control" data-input=""
                                   id="task_due_date_value"
                                   defaultValue={parseDateIgnoringTimezoneGetDate(dueDate)} />
                            <div className="input-group-append">
                              <button className="btn c-btn-dropdown-delete-border-left"
                                      type="button"
                                      id="due-date-remove-button"
                                      data-clear="">
                                <i className="fal fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <div className="row">
                  {dueDate ? (
                    <div className={`col ${dateIsBeforeToday(dueDate) ? 'text-danger' : ''}`}>
                      {formatDate(dueDate)}
                    </div>
                  ) : (
                    <div className="col">
                      No due date set
                    </div>
                  )}
                  </div>
                )}
              </div>

              )}

              <div className="col-sm-6">
                {['manual', 'progressbar'].includes(autoComplete) ? (
                  <StatusDropdown customerId={props.customerId} entityId={props.goalId} entityType="goal"
                                  completedCallback={setCompletedDate}
                                  id="goal_status_dropdown"
                                  title={props.alternateGoalName + ' Status'}
                                  status={status} setStatus={setStatus}
                                  includeInformationStatus={false}
                                  includeOffTrackStatus={true} includeOnTrackStatus={true}
                                  allowEdit={true}/>
                ) : (
                  <Fragment>
                    <div className="row mt-3 mb-1">
                      <div className="col c-internal-heading">{props.alternateGoalName} Status</div>
                    </div>
                    {/* We keep the ID here for the behave tests */}
                    <button className={`btn btn-${getStatusNameAndStyle(status).buttonStyle}`} disabled
                            id="goal_status_dropdown">{getStatusNameAndStyle(status).text}</button>
                  </Fragment>
                )}

              </div>
            </div>

            <div className="row">
              {(sponsor != null || sponsorIsEditing) && (
              <div className="col-sm-6">
                  <div className="row mt-3 mb-1">
                    <div className="col c-internal-heading">{props.alternateGoalName} Sponsor</div>
                  </div>

                  <div className="row">
                    {(allowEdit && sponsorIsEditing) ? (
                      <input className="form-control" placeholder="Sponsor Name" type="text"
                             autoFocus={sponsorIsEditing}
                             onBlur={handleSponsorBlur}
                             onKeyDown={(e) => e.key === 'Enter' && handleSponsorBlur(e)}
                             ref={sponsorInput} defaultValue={sponsor}/>
                    ) : (
                      <div className="col">
                        <div className="row align-items-center d-flex flex-nowrap no-gutters c-btn-override">
                          <div className="col btn c-btn-dropdown c-text-input">
                            <div onClick={() => allowEdit && setSponsorIsEditing(true)}>
                              {sponsor}
                            </div>
                          </div>

                          <div className="col-auto">
                            <button className="btn c-btn-dropdown-delete-border-left c-text-input-btn" type="button" onClick={handleSponsorRemoveClick}>
                              <i className="fal fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
              </div>
              )}

              {allowEdit && (
                <div className="col-sm">
                  <Fragment>
                    <div className="row mt-3 mb-1">
                      <div className="col c-internal-heading">{props.alternateGoalName} Auto-Complete</div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Dropdown>
                          <Dropdown.Toggle className="btn c-btn-dropdown c-override-bootstrap-dropdown"
                                           id="goalAutoCompleteDropDownToggle"
                              variant="">
                            <div className="row">
                              <div className="col text-left">
                                {completeDisplay[autoComplete]}
                              </div>
                              <div className="col-auto ml-2">
                                <i className="fas fa-caret-down"></i>
                              </div>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={handleAutoCompleteChange} href="#" data-complete-type='manual'>
                              Manual
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleAutoCompleteChange} href="#" data-complete-type='task'>
                              Task
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleAutoCompleteChange} href="#" data-complete-type='progressbar'>
                              Progress Bar
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </Fragment>
                </div>
              )}
            </div>

            {(description !== null || descriptionIsEditing) && (
            <Description customerId={props.customerId} entityId={props.goalId} entityType='goal'
                         descriptionIsEditing={descriptionIsEditing} setDescriptionIsEditing={setDescriptionIsEditing}
                         description={description} setDescription={setDescription} allowEdit={allowEdit} />
            )}

            {attachments.length > 0 && (
              <Fragment>
                <div className="row mt-3 border-top">
                  <div className="col">
                  </div>
                </div>

                <div className="row mt-3 mb-2">
                  <div className="col">
                    <div className="c-internal-heading">Attachments</div>
                  </div>
                </div>

                <Attachments customerId={props.customerId}
                             entityId={props.goalId}
                             entityType="Goal"
                             attachments={attachments}
                             setAttachments={setAttachments}
                             isEditing={allowEdit} />
              </Fragment>
            )}

            {uploadInProgress && (
              <FileProgress uploadProgress={uploadProgress} />
            )}

            {getIsUser() && (
             <Tags goalId={props.goalId} customerId={props.customerId}
                  tags={tags} setTags={setTags} allAvailableTags={allAvailableTags} />
            )}

            {(relatedTasks.length > 0) && (
              <div className="row mt-3 border-top">
                <div className="col">
                </div>
              </div>
            )}

            {relatedTasks.length > 0 && (
              <div className="row mt-3 mb-2">
                <div className="col c-internal-heading">Related Tasks</div>
              </div>
            )}

            {getRenderedRelatedTasks()}
          </div>


          {/* column 2, maybe hidden if they cannot edit */}
          {allowEdit && (
            <div className="col-auto c-sticky-parent c-side-menu c-desktop-md">
              <div className="c-sticky-dialog-menu">

                <div className="row mt-3 mb-2">
                  <div className="col c-side-menu-heading">
                    Add {props.alternateGoalName} Fields
                  </div>
                </div>

                {/*Separator line - side menu*/}
                <div className="row mt-2 mb-2 pl-3 pr-3">
                  <div className="col border-top">
                  </div>
                </div>

                {(description == null && !descriptionIsEditing) && (
                  <div className="row mb-2">
                    <div className="col text-center">
                      <button type="button" className="btn c-btn-addto"
                              id="add_description_button"
                              onClick={() => setDescriptionIsEditing(true)}>
                        Description
                      </button>
                    </div>
                  </div>
                )}

                {progress == null && (
                  <div className="row mb-2">
                    <div className="col text-center">
                      <button id="create-progress-bar-button" type="button" className="btn c-btn-addto"
                              onClick={handleProgressCreate}>Progress Bar</button>
                    </div>
                  </div>
                )}

                {(sponsor == null && !sponsorIsEditing) && (
                  <div className="row mb-2">
                    <div className="col text-center">
                      <button type="button" className="btn c-btn-addto"
                              onClick={() => setSponsorIsEditing(true)}>
                        Sponsor
                      </button>
                    </div>
                  </div>
                )}

                <div className="row mb-2">
                  <div className="col text-center">
                    <button type="button" className="btn c-btn-addto"
                            onClick={() => attachFileInput.current.click()}>
                      <i className="fas fa-paperclip"></i> Attach Files
                    </button>
                  </div>
                </div>

                {getIsUser() && (
                <Fragment>
                  <div className="row mt2 mb-2">
                    <div className="col text-center">
                      {renderRelatedTasksDropdown()}
                    </div>
                  </div>
                </Fragment>
                )}


                {getIsUser() && (
                  <Fragment>
                    {/*Separator line - side menu*/}
                    <div className="row mt-2 mb-2 pl-3 pr-3">
                      <div className="col border-top">
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col text-center">
                        <TagsDropdown goalId={props.goalId} customerId={props.customerId} tags={tags} setTags={setTags}
                                      allAvailableTags={allAvailableTags} />
                      </div>
                    </div>

                    <div className="row mt-2 mb-2">
                      <div className="col text-center">
                        <button href="#" id="copy_link_button2" className="btn c-btn-addto"
                           onClick={() => {try {
                              navigator.clipboard.writeText(copyLinkUrl);
                              document.getElementById('copy_link_button2').classList.add('d-none');
                              document.getElementById('copied_link_notification2').classList.remove('d-none');
                              setTimeout(x => {
                                document.getElementById('copy_link_button2').classList.remove('d-none');
                                document.getElementById('copied_link_notification2').classList.add('d-none');
                              }, 1000);
                            }catch (e) {}}}>
                          <i className="fal fa-copy"></i> Copy shareable link
                        </button>
                        <span className="d-none font-weight-normal ml-2" id="copied_link_notification2">
                          Copied to clipboard!
                        </span>
                      </div>
                    </div>

                    <div className="row mt-2 mb-2">
                      <div className="col text-center">
                        {hasClickedDelete ? (
                           <button type="button" className="btn btn-danger btn-sm"
                                   data-behave_task_delete_title={title}
                                   onClick={handleConfirmDeleteClick}>
                            Confirm Delete?
                          </button>
                        ) : (
                          <button type="button" className="btn c-btn-delete c-btn-addto"
                                  data-behave_task_delete_title={title}
                                  onClick={() => setHasClickedDelete(true)}>
                            <i className="far fa-trash-alt"></i> Delete {props.alternateGoalName}
                          </button>
                        )}

                      </div>
                    </div>

                  </Fragment>
                )}

              </div>
            </div>
          )}

        </div>


        {!props.isOnTemplate && (
        <div className="row border-top pt-3 c-modal-discussion">
          <div className="col">
            <nav className="mb-3">
              <div className="c-card-nav nav nav-pills" id="nav-tab" role="tablist">
                <a className="nav-link active" id="nav-discussion-tab" data-toggle="tab" href="#nav-discussion" role="tab"
                   aria-controls="nav-home" aria-selected="true">
                  <span className="mr-2">Discussion</span>

                  {/* There is currently no internal discussion on a goal so they always will see this tab when
                   the goal is opened, todo later on
                   */}
                  <div className="c-chat-notification-dm-task-row mention-state-count-div d-none">
                    <div className="c-center-element-parent">
                      <div className="c-center-element-child mention-state-count">
                        0
                      </div>
                    </div>
                  </div>

                  <span className="mention-state-chat-bubble d-none">
                    <i className="fas fa-circle c-font-6 text-notification"></i>
                  </span>
                </a>

                <a className="nav-link" id="nav-history-tab" data-toggle="tab" href="#nav-history" role="tab"
                   data-customer-id={props.customerId} data-entity-id={props.taskId}
                   aria-controls="nav-home" aria-selected="true">Activity</a>
              </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane active" id="nav-discussion" role="tabpanel" aria-labelledby="nav-discussion-tab">
                <Comment key='newComment' projectId={props.customerId} targetEntityId={props.goalId}
                       atCompleteNames={atCompleteNames} comments={comments} setComments={setComments}
                       isNewComment={true} commentMoveTargets={commentMoveTargets} badgeElement={badgeElement}
                       commentReplyTarget={commentReplyTarget} setCommentReplyTarget={setCommentReplyTarget}
                       entityType="goal" allowUpload={allowUpload} />

                {comments.map(comment => (<Comment key={comment.discussion_entry_id} projectId={props.customerId}
                                                   targetEntityId={props.goalId} atCompleteNames={atCompleteNames}
                                                   comment={comment} comments={comments} setComments={setComments}
                                                   commentMoveTargets={commentMoveTargets} entityType="goal"
                                                   setCommentReplyTarget={setCommentReplyTarget}/> ))}
              </div>

              <div className="tab-pane c-activity-container" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
                <span>No Activity Present</span>
              </div>
            </div>
          </div>
        </div>

        )}



      </div>

    </Fragment>

  ) : (
    <Fragment>
      <div className="modal-header data-behave-click-target">
          <h5 className="modal-title">
          </h5>
          <button id="close_react_modal" type="button" className="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>

      <div className="modal-body p-5 text-center mt-5 mb-5">
        {/* position this modal body roughly the size of a newly created task */}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="spinner-border text-light" role="status" style={{width: '10rem', height: '10rem'}}><span
          className="sr-only">Loading...</span>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </Fragment>
  )
}
