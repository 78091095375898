import React, {useState, useRef, Fragment, useEffect, useReducer, useCallback} from "react"
import * as ReactDOMClient from 'react-dom/client';
import {DateTime, Interval} from "luxon";


import {fetchSession, getIsUser} from "./common_functions";

// Froala imports
import FroalaEditor from 'react-froala-wysiwyg';

// We use this parsing library to render out the contents of the Froala editor
import parse from 'html-react-parser';

import Select from 'react-select'

import flatpickr from "flatpickr";

import {Tags, TagsDropdown} from "./tags";
import {Status} from './constants';
import {Title} from './title.js';
import {Comment} from './comment';
import {Description} from './description.js';
import {Progress} from './progress.js';
import {StatusDropdown} from './status_dropdown.js';
import {Goal} from './goal.js'
import {Form} from './form.js'

import {
  dateIsBeforeToday,
  parseDateIgnoringTimezone,
  parseDateIgnoringTimezoneGetDate,
  formatDate,
  formatDate3,
  formatDateShort,
  getStatusNameAndStyle,
  getUserStakeholderId,
  handleAttachFileChange,
  isMobile
} from "./common_functions";

import {Attachments} from "./attachments";
import linkifyStr from "linkify-string";
import {TimeTracking} from "./timeTracking";
import {renderToStaticMarkup} from "react-dom/server";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import update from 'immutability-helper'
import {FileProgress} from "./fileProgress";



function getGoalUrl(goalId, customerId, isUser, stakeholderToken, vendorId) {
  if(isUser) {
    return `/ven, formatDatedor/${vendorId}/customer/${customerId}/GOAL/${goalId}/ignore/True`
  } else {
    return `/vendor/${vendorId}/customer/${customerId}/GOAL/${goalId}`
  }
}


export function Task(props) {

  const [loaded, setLoaded] = useState(false);
  const [taskId, setTaskId] = useState(props.taskId);  // This can change when we move to parent task on fetch

  const [title, setTitle] = useState();

  const [taskCompletedDt, setTaskCompletedDt] = useState(null);

  //
  // Description
  const [description, setDescription] = useState(null);
  const [descriptionSub, setDescriptionSub] = useState(null);
  const [descriptionIsEditing, setDescriptionIsEditing] = useState(false);


  //
  // Start Date
  const [startDate, setStartDate] = useState(null);
  const [startDateIsEditing, setStartDateIsEditing] = useState(false);
  const startDateInput = useRef();
  // we will pop open the picker once and force a blink, will set true if the right hand side button is clicked
  const hasPoppedOnceStartDate = useRef(true);

  const [hasClearedTaskDirty, setHasClearedTaskDirty] = useState(false);
  function setTaskIsDirty() {
    window.taskIsDirty = true;
  }

  function handleStartDateChange(selectedDates, dateStr, instance) {
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({start_date: dateStr})
    }).then(() => {
      if(selectedDates.length === 0) {
        setStartDate(null);
        setStartDateIsEditing(false);
        hasPoppedOnceStartDate.current = false;
      } else {
        setStartDate(selectedDates[0]);
      }
    });
  }

  const [startDateTemplateDays, setStartDateTemplateDays] = useState(null);
  let previousStartDateTemplateDays = null;
  const [startDateTemplateDaysError, setStartDateTemplateDaysError] = useState(null);
  function handleStartDateTemplateDaysBlur() {
    if(startDateTemplateDays !== '' && isNaN(startDateTemplateDays)) {
      setStartDateTemplateDaysError('Must be a whole number.');
      return;
    }
    setStartDateTemplateDaysError(null);

    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({start_date_template_days: parseInt(startDateTemplateDays)})
    });
  }

  //
  // Due Date
  const [dueDate, setDueDate] = useState(null);
  const dueDateInput = useRef();

  function handleDueDateChange(selectedDates, dateStr, instance) {
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({due_date: dateStr})
    }).then(() => {
      if(selectedDates.length === 0) {
        setDueDate(null);
      } else {
        setDueDate(selectedDates[0]);
      }

      setTaskIsDirty(true);
    });
  }

  const [dueDateTemplateDays, setDueDateTemplateDays] = useState(null);
  const [dueDateTemplateDaysError, setDueDateTemplateDaysError] = useState(null);

  function handleDueDateTemplateDaysBlur() {
    if(dueDateTemplateDays !== '' && isNaN(dueDateTemplateDays)) {
      setDueDateTemplateDaysError('Must be a whole number.');
      return;
    }
    setDueDateTemplateDaysError(null);

    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({due_date_template_days: parseInt(dueDateTemplateDays)})
    });
  }

  // Completed Date
  const [completedDate, setCompletedDate] = useState(null);
  const completedDateInput = useRef();
  function handleCompletedDateChange(selectedDates, dateStr, instance) {
    // TODO
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({completed_date: dateStr})
    }).then(() => {
      setCompletedDate(selectedDates[0]);
    });
    setTaskIsDirty(true);
  }

  const [status, setStatus] = useState(Status.NotStarted);
  const [statusLabel, setStatusLabel] = useState(null);
  const [preventUnassignedCollabEditStatus, setPreventUnassignedCollabEditStatus] = useState(false);
  const [preventUnassignedCollabAssign, setPreventUnassignedCollabAssign] = useState(false);
  const [preventUnassignedCollabAssign2, setPreventUnassignedCollabAssign2] = useState(false);

  // tagging
  const [tags, setTags] = useState([]);
  const [allAvailableTags, setAllAvailableTags] = useState([]);

  //
  // Wait on dependency state wait days
  const [dueDepDays, setDueDepDays] = useState('');
  const [depDaysError, setDepDaysError] = useState(null);

  function handleDueDepDaysChange(event) {
    const val = event.target.value;
    if(isNaN(event.target.value)) {
      setDueDepDays('');
      return;
    }
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        dueDateDepDays: val
      })
    }).then(() => {
      //setDueDepDays(event.target.value);
      setDueDepDays(val);
    });
  }

  function handleDueDepDaysClear(event) {
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        dueDateDepDays: null
      })
    }).then(() => {
      setDueDepDays('');
    });
  }


  //
  // Lock
  const [locked, setLocked] = useState(false);
  // This could be a property passed in a creation but for now just expedient to have it returned from backend and set
  // during load
  const [isUser, setIsUser] = useState(getIsUser());

  function handleLockClick() {
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({task_locked: !locked})
    }).then(() => {
      setLocked(!locked);
      // Clear the signature on the ui if we are unlocking a task
      if(!locked === false) {
        setSignature(null);
      }
      // toggle on locked state this so allow edit is backwards
      setAllowEdit(locked);
      forceUpdate();  // needed to get the checklist stuff to reset
    });

    setTaskIsDirty(true);
  }

  //
  // Assignments
  const [stakeholders, setStakeholders] = useState([]);
  const [roles, setRoles] = useState([]);
  const [assigneeStakeholderId, setAssigneeStakeholderId] = useState(null);
  const [assignees, setAssignees] = useState([]);
  // First time we are attempting to add beyond 1 we pop this true
  const [isAddingAdditionalAssignee, setIsAddingAdditionalAssignee] = useState(false);
  const [assigneeSelectOptions, setAssigneeSelectOptions] = useState([]);

  // This gets assigned into the window for use by tests
  const additionalAssigneeSelectRef = useRef()

  function assigneeSort(a, b) {
    // SOrt roles last
    if(a.role === undefined && b.role !== undefined) {
      return -1;
    }
    if(a.role !== undefined && b.role === undefined) {
      return 1;
    }

    if(a.role !== undefined && b.role !== undefined) {
      if(a.role === b.role) {
        return 0;
      } else {
        return a.role < b.role ? -1: 1
      }
    }

    if(a.stakeholder_full_name === b.stakeholder_full_name) {
      return 0;
    }

    return a.stakeholder_full_name < b.stakeholder_full_name ? -1: 1;
  }

  function generateStakeholderOptionContent(stakeholder) {
    return renderToStaticMarkup((
      <div className="row align-items-center d-flex flex-nowrap no-gutters"
           data-behave-stakeholder={stakeholder.stakeholder_full_name}>
        <div className="col-auto behave-click-target">
          {parse(stakeholder.badge_html)}
        </div>
        <div className="col ml-2" style={{maxWidth: "300px"}}>
          <div className="text-truncate">
            {stakeholder.stakeholder_full_name}
          </div>
        </div>
      </div>
    ))
  }

  function generateRoleOptionContent(role) {
    return renderToStaticMarkup((
      <div className="row align-items-center d-flex flex-nowrap no-gutters"
           data-behave-stakeholder={role}>
        <div className="col-auto behave-click-target">
          <div className={'c-avatar-container ' +  (role !== 'Manager' && 'c-avatar-bg')}>
            <div className="c-center-element-parent">
              <div className="c-center-element-child">
                {role === 'Manager' ? (
                  <i className="fas fa-star"></i>
                ) : (
                  <div className="c-avatar-initials">{role.substring(0, 1)}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col ml-2" style={{maxWidth: "300px"}}>
          <div className="text-truncate">
            Role: {role}
          </div>
        </div>
      </div>
    ))
  }

  function handleChecklistAssignmentChange(stakeholderId, checklistEntryUuid, event) {
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/assign_checklist_item`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        checklist_uuid: checklistEntryUuid,
        stakeholder_id: stakeholderId,
      })
    }).then((response) => response.json())
      .then((result) => {
        checklist.find((e) => e.uuid === result.uuid).subtask_task_id = result.subtask_task_id;
        checklist.find((e) => e.uuid === result.uuid).stakeholder_id = result.stakeholder_id;
        // The selectpicker lives outside the react lifecycle
        event.target.value = result.stakeholder_id;
        $(event.target).selectpicker('refresh');
        setChecklist(checklist);

        // Because we are putting this jquery dropdown into react we gotta do some nasty bits to have state False
        if(stakeholderId === 'unassigned') {
          document.getElementById(`${checklistEntryUuid}-hover-indicator`).classList.add(
          'c-hover-indicator-container');
        } else {
          document.getElementById(`${checklistEntryUuid}-hover-indicator`).classList.remove(
          'c-hover-indicator-container');
        }
        // This force update is required even tho we are updating the checklist state above, not sure why
        forceUpdate();
    });
  }

  function handleAssigneeSelectChange(option) {
    // This will come in as a string from selenium
    if(typeof(option) === 'string') {
      option = assigneeSelectOptions.find(x => x.value === option)
    }

    let stakeholderId = option.stakeholder_id;
    if(stakeholderId === undefined) {
      stakeholderId = null;
    }
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set_assignee`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        assignee_stakeholder_id: stakeholderId,
        currently_selected_stakeholder_id : option.currentlySelectedStakeholderId,
        delete: option.delete
      })
    }).then((response) => response.json())
      .then((result) => {
        setAssignees(result);
    });

    setTaskIsDirty(true);
  }

  function getAssignee(stakeholderId) {
    let search = stakeholders.find((s) => s.stakeholder_id === stakeholderId);
    if(search === undefined) {
      let ret = {}
      ret.badge_html = "<span></span>"
      ret.stakeholder_full_name = 'Private Task'
      return ret;
    } else {
      return search;
    }
  }

  // These are custom components for the react-select to look like our wacky disabled state dropdowns
  function DropdownIndicator(props) {
    return (
     <div style={{'marginRight': '13px'}} data-behave-assignee-task-title={title}>
      <i className="fas fa-caret-down"></i>
     </div>)
  }

  // hack can't see to determine how to pass props here so we will duplicate this for the blocked and blocks dropdown
  function DropdownIndicatorBlocks(props) {
    return (
     <div style={{'marginRight': '13px'}} data-behave-outbound-blocker-dropdown={title}>
      <i className="fas fa-caret-down"></i>
     </div>)
  }

  function DropdownIndicatorBlockedBy(props) {
    return (
     <div style={{'marginRight': '13px'}}>
      <i className="fas fa-caret-down"></i>
     </div>)
  }

  const IndicatorSeparator = ({children, ...props}) => {
    return (<Fragment></Fragment>)
  }

  const formatOptionLabel = (props) => {
    return props.label;
  }

  const [callToAction, setCallToAction] = useState();

  const callToActionStyles = {
    'complete': {icon: '', text: 'Default'},
    'attach': {icon: 'fal fa-paperclip', text: ' Attach Files'},
    'checklist': {icon: 'fal fa-check-square', text: ' Complete Checklist'},
    'form': {icon: 'far fa-clipboard-list', text: ' Complete Form'},
    'sign': {icon: 'fas fa-file-signature', text: ' Require Approval'},
  }

  function handleCallToActionClick(event) {
    const cta = event.currentTarget.dataset.callToAction;
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({call_to_action: cta})
    }).then(() => {
      setCallToAction(cta);
    });
  }


  //
  // Attachments
  const [attachments, setAttachments] = useState([]);
  // We need to attach a handler to this when they click, I think useRef is the way to do this
  const attachFileInput = useRef(null);
  const [uploadProgress, setUploadProgress] = useState([])
  const [uploadInProgress, setUploadInProgress] = useState(false);


  //
  // Progress
  const [progress, setProgress] = useState(null);
  const [progressIsEditing, setProgressIsEditing] = useState(false);

  function handleProgressCreate() {
    const newValue = {
      numerator: 0,
      denominator: 100,
      unit: 'Percent'
    }

    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({task_progress: newValue})
    }).then(() => {
      setProgressIsEditing(true);
      setProgress(newValue);
    });
  }

  //
  // Checklist

  const [checklist, setChecklist] = useState([]);
  // checklistIsEditing will be for a new entry, we will use the next one for editing an existing checklist item
  const [checklistIsEditing, setChecklistIsEditing] = useState(false);
  // checklist uuid -> true, if in the object it is editing
  const [checklistOffsetDaysIsEditing, setChecklistOffsetDaysIsEditing] = useState({});
  const newChecklistItemInput = useRef();
  const existingChecklistItemInput = useRef();

  const [existingChecklistItemEditUuid, setExistingChecklistItemEditUuid] = useState(null);
  const existingChecklistItemValue = useRef();

  function handleDeleteCheckListItem(event) {
    let item_uuid = event.currentTarget.dataset.uuid;
    deleteChecklistItemFetch(item_uuid);
  }

  const [hideCompletedChecklistItems, setHideCompletedChecklistItems] = useState(localStorage.getItem(`hide_completed_${taskId}`) !== null);

  function deleteChecklistItemFetch(item_uuid) {
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/delete_checklist_item`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({checklist_uuid: item_uuid})
    }).then((response) => response.json())
      .then((result) => {
        setChecklist(checklist.filter((element) => element.uuid !== item_uuid));
    });
  }

  function handleChecklistNewItemBlur(event) {
    const val = newChecklistItemInput.current.value;
    if(val === "") {
      setChecklistIsEditing(false);
    } else {
      fetchSession(`/js/customer/${props.customerId}/task/${taskId}/add_checklist_item`, {
        method: 'post',
        headers: {
         'Content-type': 'application/json'
        },
        body: JSON.stringify({checklist_item: val})
      }).then((response) => response.json())
        .then((result) => {
          setChecklist([...checklist, result]);
          newChecklistItemInput.current.value = '';
      });
    }
  }

  function handleChecklistNewItemCancel(event) {
    setChecklistIsEditing(false);
    newChecklistItemInput.current.value = '';
  }

  function handleChecklistExistingItemBlur(event) {
    const uuid = existingChecklistItemInput.current.dataset.itemUuid;
    const val = existingChecklistItemInput.current.value;

    // make sure they actually changed something
    if(existingChecklistItemValue.current === existingChecklistItemInput.current.value) {
      setExistingChecklistItemEditUuid(null);
      return;
    }

    // if they cleared the contents treat that as a delete
    if(val === '') {
      deleteChecklistItemFetch(uuid);
      return;
    }

    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/edit_checklist_item`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({checklist_uuid: uuid, checklist_item_text: val})
    }).then((response) => response.json())
      .then((result) => {
        checklist.find((e) => e.uuid === result.uuid).text = result.text;
        setChecklist(checklist);
        setExistingChecklistItemEditUuid(null);
    });
  }

  function handleChecklistOffsetDaysBlur(event, uuid) {
    let val = event.target.value;
    if(!isNaN(val) && val !== '') {
      checklist.find((e) => e.uuid === uuid).due_offset_days = val;
    } else {
      let entry = checklist.find((e) => e.uuid === uuid)
      delete entry['due_offset_days']
      val = null;
    }
    setChecklist(checklist);
    delete checklistOffsetDaysIsEditing[uuid];
    setChecklistOffsetDaysIsEditing(checklistOffsetDaysIsEditing);
    forceUpdate();

    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set_due_date_checklist_item`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({
        checklist_uuid: uuid,
        due_offset_days: val,
      })
    })
  }

  function handleChecklistExistingItemCancel(event) {
    // TODO
    // we are getting the blur event from the edit exisiting first and NOT getting the cancel button as a related
    // target but instead getting the modal itself so we can't figure out of this is a real blur or a cancel blur
    // going to just remove cancel button for now
  }

  /* This is also some really dirty shit
  We need to have the status dropdown for task status to re-render based on logic from the checklist items having
  no assigned subtasks that are not complete, this useReducer allows us to force a rerender where the statusdropdown
  component doesn't seem to know it depends on the checklist
  */
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  function handleChecklistItemCheckboxClick(event) {
    const uuid = event.currentTarget.id;
    const complete = event.currentTarget.checked;
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/toggle_checklist_item`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({checklist_uuid: uuid})
    }).then((response) => response.json())
      .then((result) => {
        // update the done dt
        checklist.find((e) => e.uuid === result.uuid).done_dt = result.done_dt;
        setChecklist(checklist);
        forceUpdate();
    });
  }

  function handleClearChecklistDueDate(event, uuid) {
    event.stopPropagation();
    event.preventDefault();
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set_due_date_checklist_item`, {
          method: 'post',
          headers: {
           'Content-type': 'application/json'
          },
          body: JSON.stringify({
            checklist_uuid: uuid,
            // no due date here will pull a None on the flask side setting it to None
          })
        }).then((response) => response.json())
          .then((result) => {
            checklist.find((e) => e.uuid === uuid).due_date = undefined;
            setChecklist(checklist);
            // This force update is required even tho we are updating the checklist state above, not sure why
            forceUpdate();
        });
  }

  function handleChecklistItemClick(event, entry) {
    if(event.target.tagName === "A") {
      return;
    }
    if(!allowEdit) {
      return;
    }
    setExistingChecklistItemEditUuid(entry.uuid);
    existingChecklistItemValue.current = entry.text;
  }

  function renderChecklistLinks(text) {
    // We check for an http/https prefix before linkifying because of how a user was adding indented checklist
    // of the form
    // 1.A Do the thing
    // 2.A Do another
    // 2.B Do this
    if(text.includes('http')) {
      return parse(linkifyStr(text.substring(0, 150), {target: '_blank'}))
    } else {
      return text
    }
  }


  // Checklist drag and drop
  // this is from the demo it uses
  // https://github.com/kolodny/immutability-helper
  // https://react-dnd.github.io/react-dnd/examples/sortable/simple

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setChecklist((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  function Card({ id, text, index, moveCard, children }) {
    const ref = useRef(null)

    const [{ handlerId }, drop] = useDrop({
      accept: 'card',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })

    const [{ isDragging }, drag] = useDrag({
      type: 'card',
      item: () => {
        return { id, index }
      },
      canDrag: allowEdit,
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging()
        }
      },
      end: (item, monitor) => {
        const { id: droppedId, index } = item
        const didDrop = monitor.didDrop()
        if(didDrop) {
          let newOrder = Array.from(document.querySelectorAll('[data-checklist-dragger-uuid]')).map((element, index) => {
            return {uuid: element.dataset.checklistDraggerUuid, index: index}
          })
          fetchSession(`/js/customer/${props.customerId}/task/${taskId}/order_checklist_items`, {
            method: 'post',
            headers: {
             'Content-type': 'application/json'
            },
            body: JSON.stringify(newOrder)
          });
        }
      }
    })
    const opacity = isDragging ? 0 : 1
    drop(ref)
    return (
      <div ref={ref} style={{ opacity }} data-handler-id={handlerId}
           data-checklist-dragger-uuid={id}>
        <div className="row d-flex flex-nowrap">
          {allowEdit && (
            <div ref={drag} className="col-auto p-0 mt-2">
              <button className="btn btn-link pl-0 c-cursor-move">
                <i className="fas fa-grip-vertical text-neutral"></i>
              </button>
            </div>
          )}
          <div className="col pl-0">
            {children}
          </div>
        </div>
      </div>
    )
  }


  // Time Tracking
  const [timeTrackingEntries, setTimeTrackingEntries] = useState([]);
  const [timeTrackingIsEditing, setTimeTrackingIsEditing] = useState(false);  // editing a NEW entry
  const [timeTrackingDateEntryValue, setTimeTrackingDateEntryValue] = useState(null);

  const [timeTrackingEntriesBudget, setTimeTrackingEntriesBudget] = useState([]);
  const [timeTrackingIsEditingBudget, setTimeTrackingIsEditingBudget] = useState(false);  // editing a NEW entry

  // live time tracking
  const [timeTrackingStartDt, setTimeTrackingStartDt] = useState(null)
  const [timeTrackingProjectId, setTimeTrackingProjectId] = useState(null);
  const [timeTrackingTaskId, setTimeTrackingTaskId] = useState(null);
  const [timeTrackingNote, setTimeTrackingNote] = useState(null);
  const [timeTrackingPendingNewEntry, setTimeTrackingPendingNewEntry] = useState(false);
  const [liveTimeTrackingNote, setLiveTimeTrackingNote] = useState('');
  const [liveTimeTrackingNoteError, setLiveTimeTrackingNoteError] = useState(null);
  const stopTimerButtonRef = useRef();
  const timerIntervalRef = useRef();

  function getLiveTimeTrackingInterval() {
    const interval = Interval.fromDateTimes(timeTrackingStartDt, DateTime.now())
    return parseFloat(interval.length('hours')).toFixed(2)
  }

  function handleStartTimer() {
    if(liveTimeTrackingNote === "") {
      setLiveTimeTrackingNoteError("Note is required");
      return;
    }
    setLiveTimeTrackingNoteError(null);
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/start_timer`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({note: liveTimeTrackingNote})
    }).then(() => {
      setTimeTrackingPendingNewEntry(false);
      setTimeTrackingTaskId(taskId);
      setTimeTrackingProjectId(props.customerId);
      setTimeTrackingStartDt(DateTime.now());

      startTimerDisplayLoop(DateTime.now());
    });
  }

  const [timerIntervalId, setTimerIntervalId] = useState(null);

  function startTimerDisplayLoop(startTime) {
    const id = setInterval(() => {
      // We are duplicating code here because for some reason the timeTrackingStartDt isn't parsing correctly
      // and i give up trying to troubleshoot why it works for the initial load but then had invalid afterwards
      const interval = Interval.fromDateTimes(startTime, DateTime.now())
      const timeString = parseFloat(interval.length('hours')).toFixed(2)
      timerIntervalRef.current.innerText = `${timeString} hours`;
    }, 4000);
    setTimerIntervalId(id);
  }

  function stopTimerDisplayLoop() {
    clearInterval(timerIntervalId);
  }

  function handleStopTimer() {
    stopTimerButtonRef.current.disabled = true;
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/stop_timer`, {
      method: 'post',
    }).then(response => response.json())
      .then((result) => {
        setTimeTrackingEntries([...timeTrackingEntries, result]);
        setTimeTrackingTaskId(null);
        setTimeTrackingProjectId(null);
        setTimeTrackingStartDt(null);
        stopTimerDisplayLoop();
    });
  }


  //
  // Allow edit
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowUpload, setAllowUpload] = useState(true);


  //
  // Recurring task bits

  const [isRecurring, setIsRecurring] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recurringInterval, setRecurringInterval] = useState('');
  const [recurringSourceInfo, setRecurringSourceInfo] = useState(null);

  const [relativeStartDateEnabled, setRelativeStartDateEnabled] = useState(false);
  const [relativeStartDateCount, setRelativeStartDateCount] = useState('0');
  const [relativeStartDateDaysWeeksMonths, setRelativeStartDateDaysWeeksMonths] = useState('days');

  const [relativeDueDateEnabled, setRelativeDueDateEnabled] = useState(false);
  const [relativeDueDateCount, setRelativeDueDateCount] = useState('0');
  const [relativeDueDateDaysWeeksMonths, setRelativeDueDateDaysWeeksMonths] = useState('days');


  const daysWeeksMonthsString = {
    'days': "Days",
    'weeks': "Weeks",
    'months': "Months"
  }

  function handleRecurringIntervalChange(event) {
    if(parseInt(event.target.value) < 1) {
      event.target.value = 1;
    }
    setRecurringInterval(event.target.value);
    setRecurringParameter('interval', event.target.value);
  }

  function handlePauseToggle() {
    fetchSession(`/js/customer/${props.customerId}/task/recurring/${taskId}/toggle_paused`, {
      method: 'post'
    }).then(() => {
      setIsPaused(!isPaused);
    });
  }

  function setRecurringParameter(param, value, thenFunc=null) {
    fetchSession(`/js/customer/${props.customerId}/task/recurring/${taskId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({[param]: value})
    }).then(() => {
      if(thenFunc !== null) {
        thenFunc();
      }
    })
  }

  // days months years
  const recurringPeriodOptions = [
    {value: 'days', label: 'Days'},
    {value: 'weeks', label: 'Weeks'},
    {value: 'months', label: 'Months'},
    {value: 'years', label: 'Years'},
  ]

  const [recurringPeriod, setRecurringPeriod] = useState('days');

  const recurringRelativeDateInput = useRef();
  const [recurringRelativeDate, setRecurringRelativeDate] = useState();
  const [lastRunDate, setLastRunDate] = useState();
  const [createdDate, setCreatedDate] = useState();
  const [recurringGroupId, setRecurringGroupId] = useState(null);

  function getSelectedGroupName() {
    if(recurringGroupId === null) {
      return "Ungrouped"
    }

    const search = groups.find((x) => x.group_id === recurringGroupId)
    if(search === undefined) {
      return "Ungrouped"
    } else {
      return search.group_title;
    }
  }

  function handleRecurringPeriodChange(event) {
    setRecurringPeriod(event.value);
    setRecurringParameter('period', event.value);
  }

  const createNowButton = useRef();
  const createdNowIndicator = useRef();

  function handleCreateRecurringTaskNow() {
    createNowButton.current.disabled = true

    fetchSession(`/js/customer/${props.customerId}/task/recurring/${taskId}/create_now`, {
      method: 'post',
    }).then(() => {
      createNowButton.current.classList.add('d-none');
      createNowButton.current.disabled = false
      createdNowIndicator.current.classList.remove('d-none');
      setTimeout(() => {
        createNowButton.current.classList.remove('d-none');
        createdNowIndicator.current.classList.add('d-none');
      }, 1000)
    });
  }

  function getNextTaskCreationDate() {
    if(recurringInterval === '') {
      return false;
    }

    if(recurringRelativeDate) {
      let initial = lastRunDate ? lastRunDate : typeof(recurringRelativeDate) === 'string' ? DateTime.fromISO(recurringRelativeDate) : DateTime.fromJSDate(recurringRelativeDate);
      if(lastRunDate === null && initial > DateTime.now()) {
        return initial.toLocaleString();
      } else if(lastRunDate == null
                && initial.plus({[recurringPeriod] : parseInt(recurringInterval)}) < DateTime.now() ) {
        // this is the case when we have a date behind and the interval plus that is STILL behind now
        // going to just naively keep adding the fucking interval lol
        let increment = parseInt(recurringInterval)
        let ctr = 0;
        while(initial.plus({[recurringPeriod] : increment*ctr}) < DateTime.now()) {
          ctr++;
        }
        return initial.plus({[recurringPeriod] : increment*ctr}).toLocaleString();
      } else {
        return initial.plus({[recurringPeriod] : parseInt(recurringInterval)}).toLocaleString();
      }
    } else {
      let initial = lastRunDate ? lastRunDate : createdDate;
      return initial.plus({[recurringPeriod] : parseInt(recurringInterval)}).toLocaleString();
    }
  }

  //
  // Related Goals
  const [goals, setGoals] = useState([])
  const [relatedGoals, setRelatedGoals] = useState([])

  function handleRelateGoalClick(event) {
    const goalId = event.target.dataset.goalId
    // note this url was an existing js fetch so has old style route
    fetchSession(`/customer/${props.customerId}/task/${taskId}/add_outbound_goal`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({outbound_goal_id: goalId})
    }).then(() => {
      let goal = goals.find(g => g.goal_id === goalId);
      setRelatedGoals([...relatedGoals, goal]);
    });
  }

  function handleRelatedGoalRemove(event) {
    const goalId = event.currentTarget.dataset.goalId
    // note this url was an existing post so we use that
    fetchSession(`/customer/${props.customerId}/goal/${goalId}/task/${taskId}/remove_task_goal_relation`,{
      method: 'post',
    }).then(() => {
      setRelatedGoals(relatedGoals.filter((goal) => goal.goal_id !== goalId))
    });
  }

  //
  // Inbound/outbound tasks
  const [outboundTasks, setOutboundTasks] = useState([]);
  const [inboundTasks, setInboundTasks] = useState([]);
  const [tasks, setTasks] = useState([])
  const [hasClickedAddDependency, setHasClickedAddDependency] = useState(false);

  const [outboundTaskSelectValue, setOutboundTaskSelectValue] = useState(null);

  function handleOutboundTaskClick(option) {
    const outboundTaskId = option.value;
    // note this url was an existing js fetch so has old style route
    fetchSession(`/customer/${props.customerId}/task/${taskId}/add_outbound_dep`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({outbound_task_id: outboundTaskId})
    }).then(() => {
      let task = tasks.find(t => t.task_id === outboundTaskId);
      setOutboundTasks([...outboundTasks, task]);
    });
  }

  function handleOutboundTaskRemoveClick(event) {
    const outboundTaskId = event.currentTarget.dataset.taskId
    // note this url was an existing post so we use that
    fetchSession(`/customer/${props.customerId}/task/${taskId}/remove_outbound_dep/${outboundTaskId}`,{
      method: 'post',
    }).then(() => {
      setOutboundTasks(outboundTasks.filter((task) => task.task_id !== outboundTaskId))
    });
  }

  const [inboundTaskSelectValue, setInboundTaskSelectValue] = useState(null);

  function handleInboundTaskClick(option) {
    const inboundTaskId = option.value;
    // note this url was an existing js fetch so has old style route
    fetchSession(`/customer/${props.customerId}/task/${taskId}/add_inbound_dep`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({inbound_task_id: inboundTaskId})
    }).then(() => {
      let task = tasks.find(t => t.task_id === inboundTaskId);
      setInboundTasks([...inboundTasks, task]);
    });
  }

  function handleInboundTaskRemoveClick(event) {
    const inboundTaskId = event.currentTarget.dataset.taskId
    // note this url was an existing post so we use that
    fetchSession(`/customer/${props.customerId}/task/${taskId}/remove_inbound_dep/${inboundTaskId}`,{
      method: 'post',
    }).then(() => {
      setInboundTasks(outboundTasks.filter((task) => task.task_id !== inboundTaskId))
    });
  }

  //
  // Move group
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null);

  function handleMoveGroupClick(event) {
    const groupId = event.target.dataset.groupId;
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/move_group`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({group_id: groupId})
    }).then(response => response.json())
      .then((result) => {
      if(groupId === 'UNGROUPED') {
        setCurrentGroup(null);
      } else {
        setCurrentGroup(groups.find(g => g.group_id === groupId));
      }
      setAssignees(result);
    });
  }

  const [copyLinkUrl, setCopyLinkUrl] = useState(null);

  //
  // Delete Confirm
  const [hasClickedDelete, setHasClickedDelete] = useState(false);

  function handleConfirmDeleteClick(event) {
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/delete`, {
      method: 'post',
    }).then(() => {
      setTaskIsDirty(true);
      props.closeFunction();
      
      // forces reload of main page
      window.taskIsDirty = true;
    });

  }

  //
  // Comments
  const [comments, setComments] = useState([]);
  const [commentReplyTarget, setCommentReplyTarget] = useState(null);
  const [atCompleteNames, setAtCompleteNames] = useState([]);
  const [commentMoveTargets, setCommentMoveTargets] = useState([]);
  const [commentMoveTargetsInternal, setCommentMoveTargetsInternal] = useState([]);
  const [badgeElement, setBadgeElement] = useState(null);

  const [commentsInternal, setCommentsInternal] = useState([]);
  const [atCompleteNamesInternal, setAtCompleteNamesInternal] = useState([]);

  const [isTemplate, setIsTemplate] = useState(false);

  // Chat notification bits
  const [internalModifyIcon, setInternalModifyIcon] = useState(false);
  const [newChatIcon, setNewChatIcon] = useState(false);
  const [unseenMentions, setUnseenMentions] = useState(0);
  const [unseentInternalMentions, setUnseenInternalMentions] = useState(0);



  // Forms

  const [forms, setForms] = useState([]);
  const [currentForm, setCurrentForm] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formCompletedBy, setFormCompletedBy] = useState(null);

  function handleAddForm(form) {
    fetchSession(`/js/customer/${props.customerId}/task/${taskId}/form/set/${form.form_id}`, {
      method: 'post',
    }).then(() => {
      setCurrentForm(form);
      setFormData(new Map(form.fields.map(x => [x.field_id, null])));
    });
  }


  // Signature
  const [signature, setSignature] = useState(null);

  const [isPrivate, setIsPrivate] = useState(false);

  // Alternate Status Labels
  const [labels, setLabels] = useState([]);

  //
  //
  //
  useEffect((didUpdate) => {
    // For testing
    window.additionalAssigneeSelect = additionalAssigneeSelectRef.current;

    // clear notifications since we see this, hacks!
    Array.from(document.querySelectorAll(`.seen_notifications_${taskId}`))
         .forEach(e => e.classList.add('d-none'));

    if(!hasClearedTaskDirty) {
      window.taskIsDirty = false;
      setHasClearedTaskDirty(true);
    }

    if(props.isNewTask) {
      window.taskIsDirty = true;
    }

    if (loaded === false) {
      const fetchUrl = props.openInternal ? `/js/customer/${props.customerId}/task/${taskId}/internal`
        : `/js/customer/${props.customerId}/task/${taskId}`
      fetchSession(fetchUrl)
        .then(response => {
          return response.json();
        })
        .then(result => {
          // We may have been shifted to a different task based on subtask
          setTaskId(result.task_id);
          setTitle(result.task_title);

          setDescription(result.task_description);
          setDescriptionSub(result.description_substituted)

          // Date handling is a bit wacky here, when we first setup keep the iso8601 GMT date but then format it
          // for where we need it eg initial value of the picker and the pretty print for read only
          // we have two date helpers at the top for formatting
          setStartDate(result.task_start_date);
          setStartDateTemplateDays(result.task_start_date_template_days);
          previousStartDateTemplateDays = result.task_start_date_template_days;

          setDueDate(result.task_due_date);
          setDueDepDays(result.task_due_date_wait_on_dependency_days === null ? '' : result.task_due_date_wait_on_dependency_days);
          setDueDateTemplateDays(result.task_due_date_template_days);

          if(result.task_status_current === 'complete') {
            setCompletedDate(result.task_status_current_dt)
          } else {
            setCompletedDate(null);
          }

          setStatus(result.task_status_current);
          setStatusLabel(result.current_status_label);

          setLocked(result.task_locked);
          setIsUser(result.is_user);

          setAttachments(result.file_entries_json);

          // handle the locked case here
          if(result.task_locked) {
            setAllowEdit(false);
          } else {
            setAllowEdit(result.allow_edit);
          }

          setAllowUpload(result.allow_upload);

          //
          //
          //
          setIsRecurring(result.task_is_recurring_task);
          setIsPaused(result.task_recurring_paused);
          setRecurringRelativeDate(
            'relative_date' in result.task_recurring_json ? result.task_recurring_json.relative_date : null);
          setCreatedDate(DateTime.fromISO(result.task_created_dt));
          setLastRunDate(result.task_recurring_last_run_dt ? DateTime.fromISO(result.task_recurring_last_run_dt) : null);

          if('recurring_source_info' in result) {
            setRecurringSourceInfo(result.recurring_source_info);
          }

          // process the information payload
          if('interval' in result.task_recurring_json && result.task_recurring_json['interval'] !== null) {
            setRecurringInterval(result.task_recurring_json['interval']);
          }
          if('period' in result.task_recurring_json && result.task_recurring_json['period'] !== null) {
            setRecurringPeriod(result.task_recurring_json['period']);
          }

          if('relative_start_date_enabled' in result.task_recurring_json
            && result.task_recurring_json['relative_start_date_enabled'] !== null) {
            setRelativeStartDateEnabled(result.task_recurring_json['relative_start_date_enabled']);
          }

          if('relative_start_date_count' in result.task_recurring_json
            && result.task_recurring_json['relative_start_date_count'] !== null) {
            setRelativeStartDateCount(result.task_recurring_json['relative_start_date_count']);
          }

          if('relative_start_days_weeks_months' in result.task_recurring_json
            && result.task_recurring_json['relative_start_days_weeks_months'] !== null) {
            setRelativeStartDateDaysWeeksMonths(result.task_recurring_json['relative_start_days_weeks_months']);
          }

          if('relative_due_date_enabled' in result.task_recurring_json
            && result.task_recurring_json['relative_due_date_enabled'] !== null) {
            setRelativeDueDateEnabled(result.task_recurring_json['relative_due_date_enabled']);
          }

          if('relative_due_date_count' in result.task_recurring_json
            && result.task_recurring_json['relative_due_date_count'] !== null) {
            setRelativeDueDateCount(result.task_recurring_json['relative_due_date_count']);
          }

          if('relative_due_days_weeks_months' in result.task_recurring_json
            && result.task_recurring_json['relative_due_days_weeks_months'] !== null) {
            setRelativeDueDateDaysWeeksMonths(result.task_recurring_json['relative_due_days_weeks_months']);
          }

          if('group_id' in result.task_recurring_json
            && result.task_recurring_json['group_id'] !== null) {
            setRecurringGroupId(result.task_recurring_json['group_id']);
          }


          setStakeholders(result.stakeholders);

          // Legacy call, we will remove this in facvor of this other one
          setAssigneeStakeholderId(result.task_assignee_stakeholder_id);
          setAssignees(result.task_assignments);

          setRoles([...result.vendor_project_roles, 'Manager'])

          //
          // init the assignee select options
          let stakeholderOptions = result.stakeholders.map(stakeholder => {
            return {
              value: stakeholder.stakeholder_full_name,
              stakeholder_id: stakeholder.stakeholder_id,
              label: (
              <div className="row align-items-center d-flex flex-nowrap no-gutters"
                   data-behave-stakeholder={stakeholder.stakeholder_full_name}>
                <div className="col-auto">
                  {parse(stakeholder.badge_html)}
                </div>
                <div className="col ml-2" style={{maxWidth: "300px"}}>
                  <div className="text-truncate">
                    {stakeholder.stakeholder_full_name}
                  </div>
                </div>
              </div>
            )
          }});

          let unassignedOption = {
            value: 'Unassigned',
            stakeholder_id: null,
            label: (
              <Fragment>
                <div className="row align-items-center d-flex flex-nowrap no-gutters">
                  <div className="col-auto">
                    <div className="c-avatar-container c-unassigned">
                      <div className="c-center-element-parent">
                        <div className="c-center-element-child">
                          <i className="fal fa-users-slash"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col ml-2">
                    <span className="text-truncate">Unassigned</span>
                  </div>
                </div>
              </Fragment>
            )
          }

          let rolesOptions = [...result.vendor_project_roles, 'Manager'].map((role) => {
            return {
              value: role,
              stakeholder_id: role,
              label: (
                <div className="row align-items-center d-flex flex-nowrap no-gutters"
                     data-behave-stakeholder={role}>
                  <div className="col-auto">
                     <div className={'c-avatar-container '  + (role !== 'Manager' && 'c-avatar-bg')}>
                      <div className="c-center-element-parent">
                        <div className="c-center-element-child">
                          {role === 'Manager' ? (
                            <i className="fas fa-star"></i>
                          ) : (
                            <div className="c-avatar-initials">{role.substring(0, 1)}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col ml-2"
                       style={{maxWidth: "300px"}}>
                    <div className="text-truncate">
                      Role: {role}
                    </div>
                  </div>
                </div>
              )
            }
          });

          setAssigneeSelectOptions([unassignedOption, ...stakeholderOptions, ...rolesOptions])

          setProgress(result.task_progress);

          setChecklist(result.task_checklist);

          setComments(result.comments);
          setCommentsInternal(result.comments_internal);

          setBadgeElement(result.badge_html);

          setCopyLinkUrl(result.copy_link_url)

          setGoals(result.goals);
          setRelatedGoals(result.related_goals);

          setTasks(result.tasks);
          setOutboundTasks(result.outbound_tasks);
          setInboundTasks(result.inbound_tasks);

          setGroups(result.groups);
          setCurrentGroup(result.current_group);

          setAtCompleteNames(result.atCompleteNames);
          setAtCompleteNamesInternal(result.atCompleteNamesInternal);

          let moveTargets = Array.from(result.commentMoveTargets);
          moveTargets.push({label: ">> Internal", value: "move_internal"})
          setCommentMoveTargets(moveTargets);

          let moveTargetsInternal = Array.from(result.commentMoveTargets);
          moveTargetsInternal.push({label: "<< External", value: "move_external"})
          setCommentMoveTargetsInternal(moveTargetsInternal);
          // label: value

          setTimeTrackingEntries(result.task_time_tracking_entries)
          setTimeTrackingEntriesBudget(result.task_time_tracking_entries_budget)

          setLoaded(true);

          setTags(result.tags)
          setAllAvailableTags(result.all_tags);

          setIsTemplate(result.is_template);

          setInternalModifyIcon(result.internal_modify_icon);
          setNewChatIcon(result.chat_icon);
          setUnseenMentions(result.unseen_mentions);
          setUnseenInternalMentions(result.unseen_internal_mentions);

          // We are opening to the Internal Notes page
          if(props.openInternal) {
            setUnseenInternalMentions(0);
            setInternalModifyIcon(false);
          }

          setCallToAction(result.call_to_action);

          setForms(result.forms);
          setCurrentForm(result.current_form);

          setIsPrivate(result.task_private)

          if(result.form_data !== null) {
            setFormData(new Map(Object.entries(result.form_data)));
          } else if(result.current_form !== null)  {
            setFormData(new Map(result.current_form.fields.map(x => [x.field_id, null])));
          }
          setFormCompletedBy(result.form_completed_by);
          setSignature(result.task_signature);

          setPreventUnassignedCollabEditStatus(result.vendor_permission_prevent_collaborator_changing_status);
          setPreventUnassignedCollabAssign(result.vendor_permission_prevent_collaborator_assign);
          setPreventUnassignedCollabAssign2(result.vendor_permission_prevent_collaborator_assign2);

          if(isUser) {
            setTimeTrackingTaskId(result.userTimeTrackingTaskId);
            // Handle the case where its tracking on some other task
            if(result.userTimeTrackingTaskId === taskId) {
              setTimeTrackingStartDt(DateTime.fromISO(result.userTimeTrackingStartDt));
              startTimerDisplayLoop(DateTime.fromISO(result.userTimeTrackingStartDt));
              setTimeTrackingProjectId(result.userTimeTrackingProjectId);
              setLiveTimeTrackingNote(result.userTimeTrackingNote === null ? '' : result.userTimeTrackingNote);
            }
          }


          // given a list of labels, build a map
          setLabels(result.labels)

        });
    }

    // startDate init flatpickr
    if((startDate || startDateIsEditing) && allowEdit && !props.isOnTemplate) {
      let instance = flatpickr(startDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: startDate,
        onChange: (selectedDates, dateStr, instance) => {
          handleStartDateChange(selectedDates, dateStr, instance);
        }
      })
      if(!hasPoppedOnceStartDate.current) {
        startDateInput.current.classList.add('highlight-blink')
        setTimeout(() => startDateInput.current.classList.remove('highlight-blink'), 1000);
        instance.open();
        hasPoppedOnceStartDate.current = true;
      }

      // This is for testing to be able to easily force/reload dates
      window.startDateFlatpickr = instance;
    }

    // dueDate init flatpickr, may not exist if this task was completed, instead we render a completed date picker
    if(allowEdit && dueDateInput.current !== undefined && dueDateInput.current !== null) {
      let instance = flatpickr(dueDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: dueDate,
        onChange: (selectedDates, dateStr, instance) => {
          handleDueDateChange(selectedDates, dateStr, instance);
        },
        onOpen: (selectedDates, dateStr, instance) => {
          if(startDate != null) {
            instance.jumpToDate(startDate, false);
          }
        }
      });

      window.dueDateFlatpickr = instance;
    }

    // completedDate init flatpickr, may not exist if this task was completed, instead we render a completed date picker
    if(allowEdit && completedDateInput.current !== undefined && completedDateInput.current !== null) {
      let instance = flatpickr(completedDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: dueDate !== null ? parseDateIgnoringTimezone(completedDate.toString()) : null,
        onChange: (selectedDates, dateStr, instance) => {
          handleCompletedDateChange(selectedDates, dateStr, instance);
        }
      });

      window.completedDateFlatpickr = instance;
    }

    // relativeDueDate recurring task picker
    if(isRecurring) {
      let instance = flatpickr(recurringRelativeDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: recurringRelativeDate,
        onChange: (selectedDates, dateStr, instance) => {
          setRecurringParameter('relative_date', selectedDates.length === 0 ?  null : selectedDates[0]);
          setRecurringRelativeDate(selectedDates[0]);
        },
        onOpen: (selectedDates, dateStr, instance) => {
          if(recurringRelativeDate !== null) {
            instance.jumpToDate(recurringRelativeDate, false);
          }
        }
      });

      window.relativeDateFlatpickr = instance;
    }


    // The start date template days does not render if empty, when deleting this causes the updateblur to not fire
    // result is the start date template days is hidden but it does not clear on the server
    if(previousStartDateTemplateDays !== startDateTemplateDays && startDateTemplateDays === "") {
      previousStartDateTemplateDays = "";
      handleStartDateTemplateDaysBlur();
    }

    // This is some really dirty shit, forcing a call into the selectpicker via react sigh sigh sigh
    $(function() {
      $('.select-it').selectpicker();
    });

    document.querySelectorAll('button.dropdown-toggle.selectpicker-target').forEach((element) => {
      if(allowEdit) {
        element.removeAttribute('disabled');
      } else {
        element.setAttribute('disabled', true);
      }
    });

    if(allowEdit) {
      document.querySelectorAll('.checklist-date').forEach(element => flatpickr(element, {
        onChange: (selectedDates, dateStr, instance) => {
          const itemUuid = element.dataset.checklistUuid;
          fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set_due_date_checklist_item`, {
            method: 'post',
            headers: {
             'Content-type': 'application/json'
            },
            body: JSON.stringify({
              checklist_uuid: itemUuid,
              due_date: dateStr,
            })
          }).then((response) => response.json())
            .then((result) => {
              checklist.find((e) => e.uuid === itemUuid).due_date = dateStr;
              setChecklist(checklist);
              // This force update is required even tho we are updating the checklist state above, not sure why
              forceUpdate();
          });
        }
      }));
    }
  });


  // This is a bit of a hack to keep using the server side rendered activity feed only when the history tab is shown
  // The history tab is rarely if ever used so I didn't want to have it fetch everytime and I didn't want to rewrite
  // for an unused feature.
  window.register_history_handle(props.customerId, taskId, 'nav-history-tab');


  function renderRelatedGoalsDropdown(renderId) {
    return goals.filter(g => !relatedGoals.map(rg => rg.goal_id).includes(g.goal_id)).length > 0 ? (
      <div className="dropdown">
        <a className="btn c-btn-addto" href="#" role="button"
           data-toggle="dropdown">
          <div className="row align-items-center no-gutters d-flex flex-nowrap">
            <div className="col-auto text-left" id={renderId === true ? 'relate-goal-dropdown' : ''}>
              Related Goal
            </div>
            <div className="col-auto ml-2">
              <i className="fas fa-caret-down"></i>
            </div>
          </div>
        </a>
        <div className="dropdown-menu">
          {goals.filter(g => !relatedGoals.map(rg => rg.goal_id).includes(g.goal_id)).map(goal => (
            <a key={goal.goal_id} data-goal-id={goal.goal_id}
               className="dropdown-item" href="#" onClick={handleRelateGoalClick}>
              {goal.goal_title}
            </a>
          ))}
        </div>
      </div>
    ) : (
      <a className="btn c-btn-addto disabled" href="#" role="button"
         data-toggle="tooltip" title="No Goals Available">
        <div className="row align-items-center no-gutters d-flex flex-nowrap">
          <div className="col-auto text-left">
            No Goals To Relate
          </div>
          <div className="col-auto ml-2">
            <i className="fas fa-caret-down"></i>
          </div>
        </div>
      </a>
    )
  }

  function renderTasksBlockedDropdown() {
    const taskOptions =  tasks.filter(t => !outboundTasks.map(ob => ob.task_id).includes(t.task_id)).map(task => {
      return {value: task.task_id, label: task.task_title}
    });

    return taskOptions.length > 0 ? (
      <Select
        components={{DropdownIndicator:DropdownIndicatorBlocks, IndicatorSeparator}}
        placeholder="Blocks"
        styles={{
          control: (baseStyles, state) => {
             return {
             ...baseStyles,
             color: '#2c2c2c',
             borderWidth: '1px',
             borderColor: '#b3b3b3',
             backgroundColor: '#ffffff',
             cursor: 'pointer',
           }}
         }}
        onChange={handleOutboundTaskClick}
        value={outboundTaskSelectValue}
        options={taskOptions}
      />
    ) : (
      <a className="btn c-btn-addto disabled" href="#" role="button"
         data-toggle="tooltip" title="No Goals Available">
        <div className="row align-items-center no-gutters d-flex flex-nowrap">
          <div className="col-auto text-left">
            No tasks to block
          </div>
          <div className="col-auto ml-2">
            <i className="fas fa-caret-down"></i>
          </div>
        </div>
      </a>
    )
  }

  function renderTasksBlockingInbound() {
    const taskOptions =  tasks.filter(t => !inboundTasks.map(ob => ob.task_id).includes(t.task_id)).map(task => {
      return {value: task.task_id, label: task.task_title}
    });

    return taskOptions.length > 0 ? (
       <Select
        components={{DropdownIndicator:DropdownIndicatorBlockedBy, IndicatorSeparator}}
        placeholder="Is Blocked by"
        styles={{
          control: (baseStyles, state) => {
             return {
             ...baseStyles,
             color: '#2c2c2c',
             borderWidth: '1px',
             borderColor: '#b3b3b3',
             backgroundColor: '#ffffff',
             cursor: 'pointer',
           }}
         }}
        onChange={handleInboundTaskClick}
        value={inboundTaskSelectValue}
        options={taskOptions}
      />
    ) : (
      <a className="btn c-btn-addto disabled" href="#" role="button"
         data-toggle="tooltip" title="No Goals Available">
        <div className="row align-items-center no-gutters d-flex flex-nowrap">
          <div className="col-auto text-left">
            No tasks to block this task
          </div>
          <div className="col-auto ml-2">
            <i className="fas fa-caret-down"></i>
          </div>
        </div>
      </a>
    )
  }

  function renderMoveTaskRow(centered) {
    return (
      <div className="row mb-2">
        <div className={'col' + (centered === true ? ' text-center' : '')}>
          <div className="dropdown" data-flip="false">
            <a className="btn c-btn-addto" href="#" role="button"
               id={centered === true ? 'group_select_button_id' : ''}
               data-current-group={currentGroup === null ? 'null' : currentGroup.group_title}
               data-toggle="dropdown" aria-expanded="false">
              <div className="row align-items-center no-gutters d-flex flex-nowrap">
                <div className="col-auto text-left">
                  Move Task
                </div>
                <div className="col-auto ml-2">
                  <i className="fas fa-caret-down"></i>
                </div>
              </div>
            </a>

            <div className="dropdown-menu">
              {isUser && (
                <a className={'dropdown-item ' + (currentGroup === null ? 'active' : '')}
                   href="#" key="ungrouped" data-group-id="UNGROUPED"
                   onClick={handleMoveGroupClick}>
                  Ungrouped
                </a>
              )}

              {groups.map(group => (
                <a className={'dropdown-item ' + (currentGroup !== null && currentGroup.group_title === group.group_title ? 'active' : '')}
                   href="#" key={group.group_id} data-group-id={group.group_id}
                   data-milestone-title-option={group.group_title}
                   onClick={handleMoveGroupClick}>
                  {group.group_title}
                </a>
              ))}

            </div>
          </div>
        </div>
      </div>
    )
  }

  return loaded ? (
    <Fragment>
      {/* This removes the dropdown arrows that split the icon on selectpicker */}
      <style>
        {`
        .caret-off::before {
            display: none !important;
        }
        .caret-off::after {
            display: none !important;
        }
        `}
      </style>

      <div className="modal-header data-behave-click-target pt-2 pb-2 border-0">
        {isRecurring && (
            <div className="row mb-2">
              <div className="col">
                <h3 className="text-secondary">
                  <i className="fal fa-save"></i> All of Your Changes are Automatically Saved
                </h3>
              </div>
            </div>
        )}

        <button id="close_react_modal"
                type="button" className="close c-font-12"
                data-dismiss="modal">
          <span aria-hidden="true"><i className="fa fa-times"></i></span>
        </button>
      </div>

      <div className="modal-body pt-0 pb-0">
        {/* Behave Test Marker */}
        {loaded && (<div className="d-none task-has-loaded"></div>)}

        <div className="c-phone-md">
          <nav className="navbar navbar-light pb-0 pt-2">
            {!isRecurring && (
                <div className="row">
                  <div className="col-auto c-modal-left-side">
                  <a href="#" className="btn-link text-secondary"
                     onClick={() => {try {
                          navigator.clipboard.writeText(copyLinkUrl);
                          document.getElementById('copy_link_button2').classList.add('d-none');
                          document.getElementById('copied_link_notification2').classList.remove('d-none');
                          setTimeout(x => {
                            document.getElementById('copy_link_button2').classList.remove('d-none');
                            document.getElementById('copied_link_notification2').classList.add('d-none');
                          }, 1000);
                        }catch (e) {}}}>
                    <i className="fal fa-copy"></i> <span className="c-text-underline mr-2">Copy shareable link</span>
                  </a>
                  <span className="d-none font-weight-normal ml-2" id="copied_link_notification2">
                    Copied to clipboard!
                  </span>
                </div>
              </div>
            )}

            <button className="navbar-toggler mb-2" type="button" data-toggle="collapse" data-target="#narrow-hamburger">
              <span className="navbar-toggler-icon"></span>
            </button>

            {allowEdit && (
            <div className="collapse navbar-collapse border" id="narrow-hamburger">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item c-side-menu-heading pt-0 pb-0 pl-2">
                  Add Task Fields
                </li>

                {(description == null && !descriptionIsEditing) && (
                  <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link" href="#" onClick={() => {
                      setDescriptionIsEditing(true);
                      window.toggle_narrow_hamburger();
                    }}>Description</a>
                  </li>
                )}

                {(startDate == null && !startDateIsEditing) && !isRecurring && (
                  <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link" href="#"
                       onClick={() => {
                         setStartDateIsEditing(true);
                         hasPoppedOnceStartDate.current = false;
                         window.toggle_narrow_hamburger();
                       }}>
                      Start Date
                    </a>
                  </li>
                )}

                {progress == null && !isRecurring && (
                  <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link" onClick={() => {
                      handleProgressCreate();
                      window.toggle_narrow_hamburger();
                    }}>Progress</a>
                  </li>
                )}

                {(checklist.length === 0 && !checklistIsEditing) && (
                  <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link" href="#" onClick={() => {
                      setChecklistIsEditing(true);
                      window.toggle_narrow_hamburger();
                    }}>Checklist</a>
                  </li>
                )}

                {/* Add Form */}
                {currentForm == null && (
                  <li className="nav-item pt-0 pb-0 pl-4" data-flip="false">
                    <a className={'nav-link '}
                       href="#" role="button"
                       id="addFormDropdownMobile"
                       data-toggle="dropdown" aria-expanded="false">
                      <div className="row align-items-center no-gutters d-flex flex-nowrap">
                        <div className="col-auto text-left">
                          Form
                        </div>
                        <div className="col-auto ml-2">
                          <i className="fas fa-caret-down"></i>
                        </div>
                      </div>
                    </a>

                    <div className="dropdown-menu">
                      {forms.map(form => (
                        <a className="dropdown-item"
                           href="#" key={form.form_id} data-form-name-mobile={form.form_name}
                           onClick={() => handleAddForm(form)}>
                            {form.form_name}
                          </a>
                        ))}

                        <div className="dropdown-divider"></div>

                        <a className="dropdown-item" href="/settings/forms">Create a new Form</a>
                        <a className="dropdown-item" href="https://coordinatehq.wistia.com/medias/hbszbm5idc" target="_blank">Learn about Forms</a>

                      </div>
                    </li>
                  )}

                {allowUpload && (
                   <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link" href="#"
                       onClick={() => attachFileInput.current.click()}>
                      <i className="fas fa-paperclip"></i> Attach Files
                    </a>
                  </li>
                )}


                {!isRecurring && (
                  <Fragment>
                    <li className="nav-item dropdown-divider"></li>
                    <li className="nav-item c-side-menu-heading pt-0 pb-0 pl-2  mb-2">
                      Add Dependencies
                    </li>

                    <li className="nav-item pt-0 pb-0 pl-4 mb-2">
                      {renderRelatedGoalsDropdown(false)}
                    </li>

                    <li className="nav-item pt-0 pb-0 pl-4 mb-2">
                      {renderTasksBlockedDropdown()}
                    </li>

                    <li className="nav-item pt-0 pb-0 pl-4">
                      {renderTasksBlockingInbound()}
                    </li>
                  </Fragment>
                )}

                {!isRecurring && (
                  <Fragment>
                    <li className="nav-item dropdown-divider"></li>
                    {props.timeTrackingEnabled && isUser && (timeTrackingTaskId === undefined || timeTrackingTaskId === null) && (
                      <li className="nav-item pt-0 pb-0 pl-4">
                        <a type="button" className="nav-link"
                                id="">
                          <i className="far fa-stopwatch-20"></i> Start Recording Time
                        </a>
                      </li>
                    )}

                    {(props.timeTrackingEnabled && isUser && timeTrackingEntries.length === 0 && !timeTrackingIsEditing) && (
                      <li className="nav-item pt-0 pb-0 pl-4">
                        <a type="button" className="nav-link"
                                id="add-track-time-button-mobile"
                                onClick={() => { setTimeTrackingIsEditing(true); setTimeTrackingDateEntryValue(new Date())}}>
                          Time Tracking
                        </a>
                      </li>
                    )}

                    {(props.timeTrackingEnabled && isUser && timeTrackingEntriesBudget.length === 0 && !timeTrackingIsEditingBudget) && (
                      <li className="nav-item pt-0 pb-0 pl-4">
                        <a type="button" className="nav-link"
                                id="add-track-time-button-budget-mobile"
                                onClick={() => { setTimeTrackingIsEditingBudget(true); setTimeTrackingDateEntryValue(new Date())}}>
                          Time Tracking Budget
                        </a>
                      </li>
                    )}
                  </Fragment>
                )}


                <li className="nav-item dropdown-divider"></li>

                {!isRecurring && (
                  <li className="nav-item pt-0 pb-0 pl-4">
                    {renderMoveTaskRow(false)}
                  </li>
                )}

                {isUser && (
                  <Fragment>
                    <li className="nav-item pt-0 pb-0 pl-4 mb-2">
                      <TagsDropdown taskId={taskId} customerId={props.customerId} tags={tags} setTags={setTags}
                                    allAvailableTags={allAvailableTags} />
                    </li>
                  </Fragment>
                )}

                {(locked === false && isUser) && !isRecurring && !isTemplate && (
                <li className="nav-item pt-0 pb-0 pl-4">
                  <a type="button" className="nav-link"
                          onClick={handleLockClick}>
                    <i className="fal fa-lock-alt"></i> Lock Task
                  </a>
                </li>
              )}

                <li className="nav-item pt-2 pb-3 pl-4">
                  {hasClickedDelete ? (
                     <a type="button" className="nav-link text-danger"
                             onClick={handleConfirmDeleteClick}>
                      Confirm Delete?
                    </a>
                  ) : (
                    <a type="button" className="nav-link btn-link-edit-danger"
                            onClick={() => setHasClickedDelete(true)}>
                      <i className="far fa-trash-alt"></i> Delete {isRecurring ? 'Recurring' : ''} Task
                    </a>
                  )}
                </li>
              </ul>
            </div>
            )}
          </nav>
        </div>

        {/* Stickin this here because we need to call click from the mobile nav and the desktop nav */}
        <input id="taskFileAttach"
               ref={attachFileInput}
               className="d-none"
               type="file"
               multiple="multiple"
               onChange={(event) => handleAttachFileChange(
                 event.target.files, attachments, setAttachments, props.customerId, taskId, 'task',
                 setUploadInProgress, setUploadProgress)} />


        {/* Main body 2 column layout - TASKS */}
        <div className="row d-flex flex-nowrap" style={{position: 'relative', zIndex: 1000}}>
          <div className="col pb-4 pt-0 c-modal-left-side">
            <div className="row c-internal-title text-break">
              {/* Past due bell icon */}
              {((dueDate && dateIsBeforeToday(dueDate) && status !== Status.Complete)
                || status === Status.Blocked) && (
                <div className="col-auto pr-0 mt-1">
                  <i className="far fa-calendar-exclamation text-danger c-font-12"></i>
                </div>
              )}
              {/* Past due bell icon */}

              {/* Locked Task Icon */}
              {locked && (
                <div className="col-auto pr-0 mt-1">
                  <i className="fas fa-lock-alt c-font-12"></i>
                </div>
              )}
              {/* Locked Task Icon */}

              {/* Currently tracking time */}
              {timeTrackingTaskId === taskId && (
                <div className="col-auto pr-0 mt-1">
                  <i className="far fa-stopwatch-20 text-upgrade c-font-12"></i>
                </div>
              )}

              <div className="col">
                <Title initialTitle={title} allowEdit={allowEdit} startInEditMode={props.isNewTask} status={status}
                                       customerId={props.customerId} entityId={taskId} entityType="task" />
              </div>
            </div>

            {isRecurring && (
              <div className="row mt-3">
                <h4 className="col-auto c-cursor-pointer" onClick={handlePauseToggle}>
                  {isPaused ? (
                    <Fragment>
                      <i className="far fa-pause-circle fa-lg text-secondary"></i> <span className="text-secondary">This Recurring Task is Paused. Click to Set as Active</span>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <i className="far fa-play-circle fa-lg text-primary"></i> <span className="text-primary">This Recurring Task is Active. Click to Pause this Recurring Task</span>
                    </Fragment>
                  )}

                </h4>
              </div>
            )}

            {(isUser && locked) && (
              <div className="row mt-3">
                <h4 className="col-auto c-cursor-pointer" onClick={handleLockClick}>
                  <i className="fal fa-toggle-on fa-lg"></i> This Task is Locked
                  {signature !== null && (
                    <span className="font-italic font-weight-normal">&nbsp;(Unlocking a signed task will remove the signature)</span>
                  )}
                </h4>
              </div>
            )}

            {(!isUser && locked) && (
              <div className="row mt-3">
                <div className="col">
                  <h4 className="font-italic font-weight-normal">This Task is Locked</h4>
                </div>
              </div>
            )}

            {signature !== null && (
              <Fragment>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row mt-3 mb-1">
                      <div className="col">
                        <div className="c-internal-heading">
                          Signature
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{border: '1px solid #2c2c2c', borderRadius: '3px'}} className="p-2">
                  <div className="row">
                    <div className="col">
                      Name:
                    </div>
                    <div className="col">
                      {signature.full_name}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      Email:
                    </div>
                    <div className="col">
                      {signature.email}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      IP Address:
                    </div>
                    <div className="col">
                      {signature.ip_address}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      Signature:
                    </div>
                    <div className="col" data-behave-signature-string={signature.signature_string}>
                      <i>
                        {signature.signature_string}
                      </i>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      Envelope Timestamp UTC:
                    </div>
                    <div className="col">
                      <i>
                        {signature.signed_dt}
                      </i>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}

            {/* Recurring task info */}
            {recurringSourceInfo && (
              <div className="row align-items-center border-top border-bottom mt-3">
                <div className="col pl-4">
                  <div className="row">
                    <div className="col c-spacing-title-taskgoal">
                      <h5 className="">
                        <div className="c-chat-notification-inline-text text-break">
                          Created from recurring task: {recurringSourceInfo['title']}
                        </div>
                      </h5>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-auto c-spacing-icons">
                      <h6 className="">
                        <span>
                          <i className="far fa-repeat-alt"></i>
                        </span>

                        <span className="ml-1">
                          Every {recurringSourceInfo['interval']} {recurringSourceInfo['period']}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-auto">
                  <button type="button" className="btn btn-sm c-btn-notifications"
                          onClick={() => {
                             window.customerId = props.customerId;
                             window.openTaskId = recurringSourceInfo['task_id'];
                             document.getElementById('close_react_modal').click();
                           }}>
                    <i className="far fa-repeat-alt"></i> Edit
                  </button>
                </div>
              </div>
            )}

            {/* Recurring task info */}

            {progress !== null && (
              <Progress customerId={props.customerId} entityId={taskId} entityType="task"
                        progress={progress} setProgress={setProgress} progressIsEditing={progressIsEditing}
                        setProgressIsEditing={setProgressIsEditing}
                        allowEdit={allowEdit} />
            )}

            {/* We hide this stuff if informational and collaborator */}
            {(getIsUser() || !(!getIsUser() && status === "information")) && (
              <div className="row">
                <div className="col-sm-6">
                  <div className="row mt-3 mb-1">
                    <div className="col">
                      <div className="c-internal-heading">
                        Assigned To
                      </div>
                    </div>
                  </div>

                  <div className="row" data-react-assignee-view={true}>
                    <div className="col pr-0">
                      <Select
                          styles={{
                            control: (baseStyles, state) => {
                              return {
                                ...baseStyles,
                                color: '#2c2c2c',
                                borderWidth: '1px',
                                borderColor: '#b3b3b3',
                                backgroundColor: '#ffffff',
                                cursor: 'pointer',
                              }
                            }
                          }}
                          isDisabled={locked || (!isUser && preventUnassignedCollabAssign && getUserStakeholderId() !== assigneeStakeholderId) || (!isUser && preventUnassignedCollabAssign2)}
                          components={{DropdownIndicator, IndicatorSeparator}}
                          formatOptionLabel={formatOptionLabel}
                          onChange={handleAssigneeSelectChange}
                          value={assigneeSelectOptions.find((o) => o.stakeholder_id === (assignees.length > 0 ? assignees.sort(assigneeSort)[0].stakeholder_id : null))}
                          options={assigneeSelectOptions.map((o) => {
                            // modify the unassigned option if we have something selected to show it as a delete
                            if(assignees.length === 0) {
                              return o;
                            }
                            const firstAssignee = assignees.sort(assigneeSort)[0]
                            if(o.stakeholder_id == null) {
                              const op = Object.assign({}, o);
                              op.stakeholder_id = firstAssignee.stakeholder_id;
                              op.delete = true;
                              return op;
                            }
                            // we need a way to understand if this is a change on a currently selected stakeholder
                            // if we got here there is a selected stakeholder, set that on the option so when
                            // we get into the onchange handler we can tell the backend this is a change not an add
                            // we don't get a handle into the select component to ask it directly
                            const op = Object.assign({}, o);  // copy so we don't mess up the option list
                            op.currentlySelectedStakeholderId = firstAssignee.stakeholder_id;
                            return op;
                          })}
                          // attemping to theme based off our monochrome
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: '#b0b0b0',
                              primary75: '#dddddd',
                              primary50: '#eeeeee',
                            },
                          })}
                          // Leaving this his to remember if we get weird pop-under behavior again
                          //menuPortalTarget={document.querySelector('#assigned-to')}
                      />
                    </div>

                    <div className="col-auto p-0"
                         onClick={() => assignees.length === 1 ? setIsAddingAdditionalAssignee(true) : null}>
                      <a className="btn btn-link" href="#" role="button"
                         data-behave-additional-assignee-plus={true}
                         data-toggle="tooltip" title="Add an another assignee">
                        <i className="fas fa-plus-circle fa-2x"></i>
                      </a>
                    </div>
                  </div>
                </div>

                {/* Task Status */}
                <div className="col">
                  <StatusDropdown customerId={props.customerId} entityId={taskId} entityType="task"
                                  completedCallback={setCompletedDate}
                                  changeCallback={() => setTaskIsDirty(true)}
                                  setCurrentGroup={setCurrentGroup}
                                  setAssigneeStakeholderId={setAssigneeStakeholderId}
                                  id="task_status_dropdown"
                                  title="Task Status"
                                  status={status} setStatus={setStatus}
                                  statusLabel={statusLabel} setStatusLabel={setStatusLabel}
                                  labels={labels}
                                  includeInProgressStatus={true}
                                  includeWaitDependencyStatus={true}
                                  allowEdit={
                                      !locked
                                      && (isUser
                                          || !preventUnassignedCollabEditStatus
                                          || assigneeStakeholderId === getUserStakeholderId())}
                                  checklst={checklist}
                                  preventComplete={(checklist.filter((x) => 'subtask_task_id' in x && x.done_dt == null).length > 0 && callToAction === 'checklist') || callToAction === 'sign'}
                                  preventCompleteMessage={callToAction === 'sign' ? "Completes with an Approval Only" : "Must complete checklist"}/>
                </div>
              </div>
            )}

            {/* Multi-Assignee */}
            {(assignees.length > 1 || isAddingAdditionalAssignee) && (
              <div className="row pt-3 pr-3 pl-3 pb-0">
                <div className="col border c-dynamic-due-date">
                  <div className="row mt-3">
                    <div className="col-sm c-internal-heading">
                      <i className="fas fa-plus-circle"></i> Additional Assignees
                    </div>

                    <div className="col">
                      <div className="form-check">
                        {/* TODO, throws warning as is
                          <input type="checkbox" className="form-check-input" checked/>
                        */}
                        <label className="form-check-label text-break">Any assignee can complete</label>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-2">
                    <div className="col">
                      When anyone who is assigned this task completes the task, the status will turn to complete
                    </div>
                  </div>

                  {/* assignee row */}
                  <div className="row mb-3">
                    {assignees.sort(assigneeSort).slice(1).map((assignee) =>  (
                      <div className="col-6 mt-3" key={assignee.stakeholder_id} data-react-assignee-view={true}>
                        <div className="row">
                          <div className="col pr-0">
                            <Select
                                styles={{
                                  control: (baseStyles, state) => {
                                    return {
                                      ...baseStyles,
                                      color: '#2c2c2c',
                                      borderWidth: '1px',
                                      borderColor: '#b3b3b3',
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    }
                                  }
                                }}
                                isDisabled={locked || (!isUser && preventUnassignedCollabAssign && getUserStakeholderId() !== assignee.stakeholder_id) || (!isUser && preventUnassignedCollabAssign2)}
                                components={{DropdownIndicator, IndicatorSeparator}}
                                formatOptionLabel={formatOptionLabel}
                                onChange={handleAssigneeSelectChange}
                                value={assigneeSelectOptions.find((o) => o.stakeholder_id === assignee.stakeholder_id)}
                                options={assigneeSelectOptions.filter((o) => o.stakeholder_id !== null).map((o) => {
                                  // this currentlySelected is used to swap when they change a selection in place
                                  const op = Object.assign({}, o);  // copy so we don't mess up the option list
                                  op.currentlySelectedStakeholderId = assignee.stakeholder_id;
                                  return op;
                                })}
                                // attemping to theme based off our monochrome
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: '#b0b0b0',
                                    primary75: '#dddddd',
                                    primary50: '#eeeeee',
                                  },
                                })}
                                // Leaving this his to remember if we get weird pop-under behavior again
                                //menuPortalTarget={document.querySelector('#assigned-to')}
                            />
                          </div>

                          <div className="col-auto p-0">
                            <button type="button" className="btn c-btn-delete"
                                    onClick={() => handleAssigneeSelectChange({
                                      stakeholder_id: assignee.stakeholder_id, delete: true})}>
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* this is for adding the new assignee */}
                    <div className="col-6" data-behave-new-additional-assignee={true}>
                      <div className="row mt-3">
                        <div className="col pr-0">
                          <Select
                              ref={additionalAssigneeSelectRef}
                              styles={{
                                control: (baseStyles, state) => {
                                  return {
                                    ...baseStyles,
                                    color: '#2c2c2c',
                                    borderWidth: '1px',
                                    borderColor: '#b3b3b3',
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                  }
                                }
                              }}
                              isDisabled={locked || (!isUser && preventUnassignedCollabAssign && getUserStakeholderId() !== null) || (!isUser && preventUnassignedCollabAssign2)}
                              components={{DropdownIndicator, IndicatorSeparator}}
                              formatOptionLabel={formatOptionLabel}
                              onChange={handleAssigneeSelectChange}
                              value={null}
                              options={assigneeSelectOptions.filter((o) => o.stakeholder_id !== null)}
                              // attemping to theme based off our monochrome
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: '#b0b0b0',
                                  primary75: '#dddddd',
                                  primary50: '#eeeeee',
                                },
                              })}
                              // Leaving this his to remember if we get weird pop-under behavior again
                              //menuPortalTarget={document.querySelector('#assigned-to')}
                          />
                        </div>

                        <div className="col-auto p-0">
                          <button type="button" className="btn c-btn-delete">
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>


                  </div>
                  {/* assignee row */}
                </div>
              </div>

            )}
            {/* Multi-Assignee */}

            {(getIsUser() && status === 'dependency_wait') && !isRecurring && (
              <div className="row pt-3 pr-3 pl-3 pb-0">
                <div className="col border c-dynamic-due-date">
                  <div className="row mt-3">
                    <div className="col c-internal-heading">
                      Dynamic Due Date <span className="c-text-info">(optional)</span>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col">
                      When the status is changed from:
                    </div>
                  </div>

                  <div className="row mt-2 mb-2">
                    <div className="col">
                      <span className="font-weight-bold"><i className="fal fa-clock text-warning"></i> Wait on Dependencies</span> <i className="far fa-long-arrow-right"></i> <span className="font-weight-bold"> <i className="fad fa-circle text-primary"></i> In Progress</span>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col">
                      Calculate a new Due Date from the day the status is changed
                    </div>
                  </div>

                  <div className="row mt-3 align-items-center">
                    <div className="col-auto pr-0">
                      <i className="fas fa-plus"></i>
                    </div>

                    <div className="col-sm-5 col-7">
                      <input className={"form-control" + (depDaysError === null ? '':' is-invalid')}
                             id="offsetDepDays"
                             type="text" placeholder="example: 3"
                             value={dueDepDays}
                             onChange={handleDueDepDaysChange}/>
                      <div className="invalid-feedback">
                        {depDaysError}
                      </div>
                    </div>

                    <div className="col-auto pl-0">
                      Days
                    </div>

                    {dueDepDays !== '' && status === 'dependency_wait' && (
                      <div className="col-auto p-0 ml-auto">
                        <button type="button" className="btn c-btn-delete" onClick={handleDueDepDaysClear}>
                          <i className="far fa-trash-alt"></i> clear
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="row mt-2 mb-3">
                    <div className="col">
                      <span className="c-text-info font-italic">
                        Setting a value will disable Due Date below</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* We hide this stuff if dep wait and collaborator */}
            {(getIsUser() || !(!getIsUser() && status === "information")) && (
              <div className="row">
                {(startDate || startDateIsEditing || (startDateTemplateDays && props.isOnTemplate)) && (
                  <div className="col-sm-6">
                    <div className="row mt-3 mb-1">
                      <div className="col c-internal-heading">Start Date</div>
                    </div>

                    {allowEdit ? (
                      <Fragment>
                        {(props.isOnTemplate) ? (
                          <div className="row">
                            <div className="col">
                              <input
                                className={"form-control" + (startDateTemplateDaysError === null ? '' : ' is-invalid')}
                                type="text" placeholder="Relative Days"
                                onBlur={handleStartDateTemplateDaysBlur}
                                defaultValue={startDateTemplateDays}
                                onChange={e => setStartDateTemplateDays(e.target.value)}/>
                              <div className="invalid-feedback">
                                {startDateTemplateDaysError}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row flatpickr" ref={startDateInput}>
                            <div className="col">
                              <div className="input-group mb-0">
                                <input className="form-control c-hover-indicator-container"
                                       defaultValue={parseDateIgnoringTimezoneGetDate(startDate)}
                                       data-input=""/>
                                <div className="input-group-append">
                                  <button className="btn c-btn-dropdown-delete-border-left" type="button" data-clear="">
                                    <i className="fal fa-times"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ) : (
                      <div className="row">
                        <div className="col">
                          {formatDate(startDate)}
                        </div>
                      </div>
                    )}

                  </div>
                )}

                {/* Due Date */}
                {!isRecurring && (
                  <Fragment>
                    {status === 'complete' ? (
                      <div className="col-sm-6">
                        <div className="row mt-3 mb-1">
                          <div className="col c-internal-heading">
                            Completed Date
                          </div>
                        </div>
                        {allowEdit ? (
                          <div className="row flatpickr" ref={completedDateInput}>
                            <div className="col">
                              {/* We will need to attach a class onto the input group that the classes below respect for red line
                                           because flatpickr clones our input group here and makes a new one */}
                              <div className="input-group mb-0">
                                <input type="text" className="form-control" data-input=""
                                       id="task_completed_date_value"
                                       defaultValue={formatDate3(completedDate)}/>
                                <div className="input-group-append d-none">
                                  <button className="btn c-btn-dropdown-delete-border-left"
                                          type="button"
                                          id="due-date-remove-button"
                                          data-clear="">
                                    <i className="fal fa-times"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col">
                              {formatDate(completedDate)}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="col-sm-6">
                        <div className="row mt-3 mb-1">
                          <div className="col c-internal-heading">
                            Due Date {allowEdit && (<span className="c-text-info">(optional)</span>)}
                          </div>
                        </div>

                        {dueDepDays !== '' && status === 'dependency_wait' && (
                          <div className="row">
                            <div className="col text-danger">
                              Cannot be used while Relative Due Date has a value
                            </div>
                          </div>
                        )}

                        {
                          // DueDates
                          // This is kinda wild, only render the due date if we are in the right conditions based on
                          // dep_wait then also check for edit privs etc
                        }
                        {(status !== 'dependency_wait' || dueDepDays === '') && (
                          <Fragment>
                            {allowEdit ? (
                              <Fragment>
                                {props.isOnTemplate ? (
                                  <div className="row">
                                    <div className="col">
                                      <input
                                        className={"form-control" + (dueDateTemplateDaysError === null ? '' : ' is-invalid')}
                                        type="text" placeholder="Relative Days"
                                        onBlur={handleDueDateTemplateDaysBlur}
                                        defaultValue={dueDateTemplateDays}
                                        onChange={e => setDueDateTemplateDays(e.target.value)}/>
                                      <div className="invalid-feedback">
                                        {dueDateTemplateDaysError}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row flatpickr" ref={dueDateInput}>
                                    <div className="col">
                                      {/* We will need to attach a class onto the input group that the classes below respect for red line
                                               because flatpickr clones our input group here and makes a new one */}
                                      <div
                                        className={'input-group mb-0' + (dateIsBeforeToday(dueDate) && ' c-date-past-due')}>
                                        <input type="text" className="form-control" data-input=""
                                               id="task_due_date_value"
                                               defaultValue={parseDateIgnoringTimezoneGetDate(dueDate)}/>
                                        <div className="input-group-append" style={{'zIndex': '0'}}>
                                          <button className="btn c-btn-dropdown-delete-border-left"
                                                  type="button"
                                                  id="due-date-remove-button"
                                                  data-clear="">
                                            <i className="fal fa-times"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                            ) : (
                              <div className="row">
                                {dueDate ? (
                                  <div className={`col ${dateIsBeforeToday(dueDate) ? 'text-danger' : ''}`}>
                                    {formatDate(dueDate)}
                                  </div>
                                ) : (
                                  <div className="col">
                                    No due date set
                                  </div>
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}

                {isUser && (
                  <div className="col-sm">
                    <div className="row mt-3 mb-1">
                      <div className="col">
                        <div className="c-internal-heading">
                          Call to action for the assignee
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="dropdown"
                             data-behave-assignee-action-dropdown={true}>
                          <a className="btn c-btn-dropdown" href="#" role="button"
                             data-toggle={locked ? '' : 'dropdown'}
                             aria-expanded="false">
                            <div className="row">
                              <div className="col text-left">
                                {callToActionStyles[callToAction].text}
                              </div>
                              <div className="col-auto ml-2">
                                <i className="fas fa-caret-down"></i>
                              </div>
                            </div>
                          </a>

                          <div className="dropdown-menu">
                            {Object.entries(callToActionStyles).map((entry) => (
                              <a className={"dropdown-item " + (entry[0] === callToAction ? 'active' : '')} href="#"
                                 key={entry[0]}
                                 data-call-to-action={entry[0]}
                                 onClick={handleCallToActionClick}>
                                <i className={entry[1].icon}></i>{entry[1].text}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* this is not implemented yet and is an outbound of ch6831 */}
            <div className="row p-3 d-none-feature">
              <div className="col border">
                <div className="row align-items-center">
                  <div className="col p-3">
                    <h3 className="mb-2">You are extending/shortening the due date by <span className="font-weight-bold">6 business days</span></h3>
                    <button type="button" className="btn btn-primary">Bulk change more tasks</button>
                  </div>

                  <div className="col-auto p-0">
                    <button type="button" className="btn btn-link"><i className="fas fa-times"></i></button>
                  </div>
                </div>
              </div>
            </div>

            {/* DESC */}
            {(description !== null || descriptionIsEditing) && (
            <Description customerId={props.customerId} entityId={taskId} entityType='task'
                         descriptionIsEditing={descriptionIsEditing} setDescriptionIsEditing={setDescriptionIsEditing}
                         description={description} setDescription={setDescription}
                         descriptionSub={descriptionSub} setDescriptionSub={setDescriptionSub}
                         allowEdit={allowEdit} />
            )}

            {attachments.length > 0 && (
              <Fragment>
                <div className="row mt-3 border-top">
                  <div className="col">
                  </div>
                </div>

                <div className="row mt-3 mb-2">
                  <div className="col">
                    <div className="c-internal-heading">Attachments</div>
                  </div>
                </div>

                <Attachments customerId={props.customerId}
                             entityType="Task"
                             entityId={taskId}
                             attachments={attachments}
                             setAttachments={setAttachments}
                             isEditing={allowEdit} />
              </Fragment>
            )}


            {uploadInProgress && (
              <FileProgress uploadProgress={uploadProgress} />
            )}

            {/* TODO extra style icons for files
            <div className="row no-gutters">
              <div className="col-auto">
                <div className="row align-items-center no-gutters c-hover-indicator-container c-attachment">
                  <div className="col-auto mr-1">
                    <i className="fal fa-file-image"></i>
                  </div>

                  <div className="col">
                    <a className="text-break" data-behave-filename="" target="_blank" href="">
                      filetype.jpg/png/tiff
                    </a>
                  </div>

                  <div className="col-auto ml-1 c-hover-show">
                    <button className="btn btn-link-edit-danger">
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row align-items-center no-gutters c-hover-indicator-container c-attachment">
                  <div className="col-auto mr-1">
                    <i className="fal fa-file-pdf"></i>
                  </div>

                  <div className="col">
                    <a className="text-break" data-behave-filename="" target="_blank" href="">
                      filetype.pdf
                    </a>
                  </div>

                  <div className="col-auto ml-1 c-hover-show">
                    <button className="btn btn-link-edit-danger">
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row align-items-center no-gutters c-hover-indicator-container c-attachment">
                  <div className="col-auto mr-1">
                    <i className="fal fa-file"></i>
                  </div>

                  <div className="col">
                    <a className="text-break" data-behave-filename="" target="_blank" href="">
                      filetype generic documents
                    </a>
                  </div>

                  <div className="col-auto ml-1 c-hover-show">
                    <button className="btn btn-link-edit-danger">
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row align-items-center no-gutters c-hover-indicator-container c-attachment">
                  <div className="col-auto mr-1">
                    <i className="fal fa-file-code"></i>
                  </div>

                  <div className="col">
                    <a className="text-break" data-behave-filename="" target="_blank" href="">
                      filetype.svg/code
                    </a>
                  </div>

                  <div className="col-auto ml-1 c-hover-show">
                    <button className="btn btn-link-edit-danger">
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row align-items-center no-gutters c-hover-indicator-container c-attachment">
                  <div className="col-auto mr-1">
                    <i className="fal fa-file-word"></i>
                  </div>

                  <div className="col">
                    <a className="text-break" data-behave-filename="" target="_blank" href="">
                      filetype.doc
                    </a>
                  </div>

                  <div className="col-auto ml-1 c-hover-show">
                    <button className="btn btn-link-edit-danger">
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="row align-items-center no-gutters c-hover-indicator-container c-attachment">
                  <div className="col-auto mr-1">
                    <i className="fal fa-file-spreadsheet"></i>
                  </div>

                  <div className="col">
                    <a className="text-break" data-behave-filename="" target="_blank" href="">
                      filetype.xls
                    </a>
                  </div>

                  <div className="col-auto ml-1 c-hover-show">
                    <button className="btn btn-link-edit-danger">
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

            </div>
            */}



            {(checklist.length > 0 || checklistIsEditing) && (
              <Fragment>
                <div className="row mt-3 border-top">
                  <div className="col">
                  </div>
                </div>

                <div className="row mt-3 mb-2">
                  <div className="col c-internal-heading">
                    Checklist <span className="">
                    ({checklist.filter(entry => entry.done_dt !== null).length}/{checklist.length})
                    </span>
                  </div>

                  <div className="col-auto">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input mt-1" checked={hideCompletedChecklistItems}
                      onChange={(event) => {
                        if(!hideCompletedChecklistItems) {
                          setHideCompletedChecklistItems(true);
                          localStorage.setItem(`hide_completed_${taskId}`, 'true')
                        } else {
                          setHideCompletedChecklistItems(null);
                          localStorage.removeItem(`hide_completed_${taskId}`)
                        }
                      }}/>

                      <label className="form-check-label text-break" >
                        Hide completed Checklist Item
                      </label>
                    </div>
                  </div>
                </div>

                <DndProvider backend={HTML5Backend}>
                  {checklist.filter((entry) => {
                    if(hideCompletedChecklistItems) {
                      return entry.done_dt === null;
                    } else {
                      return true;
                    }
                  }).map((entry, index) => (
                  <Card key={entry.uuid} id={entry.uuid} index={index} moveCard={moveCard}>
                  {(existingChecklistItemEditUuid === null || existingChecklistItemEditUuid !== entry.uuid) ? (
                    <div className={`row align-items-center no-gutters ${((entry.stakeholder_id ?? 'unassigned') === 'unassigned') && 'c-hover-indicator-container'} border-bottom d-flex flex-nowrap`}
                         id={`${entry.uuid}-hover-indicator`}
                         data-behave-checklist-entry-title={entry.text}
                         key={entry.uuid}>
                      <div className="col-auto c-hover-show drag-handle d-none">
                        <button className="btn btn-link pl-0">
                          <i className="fas fa-grip-vertical text-neutral"></i>
                        </button>
                      </div>

                      <div className="col">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" id={entry.uuid}
                                 data-behave-checklist-checkbox-text={entry.text}
                                 defaultChecked={entry.done_dt !== null}
                                 disabled={locked}
                                 onClick={handleChecklistItemCheckboxClick}/>
                          <label className="form-check-label text-break" htmlFor={entry.uuid}
                                 data-behave-checklist-name-text={entry.text}
                                 onClick={(e) => handleChecklistItemClick(e, entry)}>
                            {renderChecklistLinks(entry.text)}
                          </label>
                        </div>
                      </div>

                      {/* first we render out the non template case due date then the template case offset days */}
                      {!isTemplate && entry.subtask_task_id &&
                        (entry.due_date ? (
                          <div className="col-auto ml-1 mr-1 pt-2 pb-2 c-hover-indicator-container">
                            <div className="row no-gutters align-items-center d-flex flex-nowrap">
                              <div className="col-auto c-cursor-pointer checklist-date"
                                   data-checklist-uuid={entry.uuid}>
                                <h6 className={(DateTime.fromISO(entry.due_date) <= DateTime.now()) ? 'c-status-overdue':''}>
                                  <i className="fal fa-calendar"></i>&nbsp;

                                  {/* Doing this to avoid the awful date snapping to local timezone crap */}
                                  {entry.due_date.split('-')[1]}/
                                  {entry.due_date.split('-')[2]}/
                                  {entry.due_date.split('-')[0]}
                                </h6>
                              </div>
                              {allowEdit && (
                                <div className="col-auto c-hover-show"
                                     onClick={(event) => handleClearChecklistDueDate(event, entry.uuid)}>
                                  <div className="btn btn-link-edit-danger ml-1">
                                    <i className="fas fa-times"></i>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                         <div className="col-auto ml-1 c-cursor-pointer pt-2 pb-2 checklist-date"
                              data-checklist-uuid={entry.uuid}>
                          <div className="c-avatar-container c-avatar-bg">
                            <div className="c-center-element-parent">
                              <div className="c-center-element-child">
                                <i className="fal fa-calendar-alt text-white"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {isTemplate && entry.subtask_task_id &&
                        (entry.uuid in checklistOffsetDaysIsEditing ? (
                          <div className="col-auto">
                            <input className="form-control behave-days-input" type="text" autoFocus={true}
                                   placeholder="Days"
                                   size="2"
                                   onKeyDown={(e) => e.key === 'Enter' && handleChecklistOffsetDaysBlur(e, entry.uuid)}
                                   onBlur={(event) => handleChecklistOffsetDaysBlur(event, entry.uuid)}
                                   data-item-uuid-offset-days={entry.uuid}
                                   defaultValue={entry.due_offset_days || ''}/>
                          </div>
                        ) : (
                          <div className="col-auto ml-1 c-cursor-pointer pt-2 pb-2 pr-1"
                               onClick={() => setChecklistOffsetDaysIsEditing(
                                 {...checklistOffsetDaysIsEditing, ...{ [entry.uuid]: true }}) }
                              data-checklist-uuid={entry.uuid}>
                            {'due_offset_days' in entry ? (
                              <span>{entry.due_offset_days} days</span>
                            ) : (
                               <div className="c-avatar-container c-avatar-bg">
                                <div className="c-center-element-parent">
                                  <div className="c-center-element-child">
                                    <i id={'calendar_click'+entry.uuid} className="fal fa-calendar-alt"></i>
                                  </div>
                                </div>
                              </div>
                            )}

                          </div>
                        ))
                      }

                      {/*This is the code when the checklist is UNASSIGNED*/}
                      <div className="col-auto ml-1 c-hover-btn c-cursor-pointer pt-2 pb-2"
                           data-checklist-assign-text={entry.text}>
                        <select className="select-it"
                                disabled={locked || !allowEdit}
                                defaultValue={entry.stakeholder_id}
                                onChange={(event) => handleChecklistAssignmentChange(event.target.value, entry.uuid, event)}
                                data-live-search="true"
                                data-size="5"
                                data-style="btn p-0 caret-off"
                                data-width="28px">
                          <option value="unassigned" data-content="
                            <div class='row align-items-center d-flex flex-nowrap no-gutters selectpicker-target'
                                 data-stakeholder-id=''>
                              <div class='col-auto'>
                                <div class='c-avatar-container c-unassigned'>
                                  <div class='c-center-element-parent'>
                                    <div class='c-center-element-child'>
                                      <i class='fal fa-users-slash behave-click-target'></i>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class='col ml-2'>
                                <span class='text-truncate'>Unassigned</span>
                              </div>
                            </div>
                            "
                          >Unassigned
                          </option>
                          <option data-divider="true"></option>
                          {stakeholders.map(stakeholder => (
                            <option key={stakeholder.stakeholder_id}
                                    value={stakeholder.stakeholder_id}
                                    data-content={generateStakeholderOptionContent(stakeholder)}>
                            </option>
                          ))}

                          {roles.length > 0 && (<option data-divider="true"></option>)}
                          {roles.map(role => (
                            <option key={role}
                                    value={role}
                                    data-content={generateRoleOptionContent(role)}>
                            </option>
                          ))}
                        </select>
                      </div>

                      {allowEdit && (
                        <div className="col-auto ml-1 c-hover-show" onClick={handleDeleteCheckListItem}
                             data-behave-delete-checklist-item-text={entry.text}
                             data-uuid={entry.uuid}>
                          <div className="btn btn-link-edit-danger">
                            <i className="far fa-trash-alt"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="row align-items-center no-gutters mt-2" key={entry.uuid}>
                      <div className="col">
                        <div className="form-group mb-0">
                          <input className="form-control" type="text" autoFocus={true}
                                 onKeyDown={(e) => e.key === 'Enter' && handleChecklistExistingItemBlur(e)}
                                 onBlur={handleChecklistExistingItemBlur}
                                 data-item-uuid={entry.uuid}
                                 ref={existingChecklistItemInput}
                                 defaultValue={entry.text}/>
                        </div>
                      </div>

                      {/*  See notes in function handleChecklistExistingItemCancel
                      <div className="col-auto ml-1">
                        <button type="button" className="btn btn-outline-muted" style={{width: "100px"}}
                                onClick={handleChecklistExistingItemCancel}>
                          Cancel
                        </button>
                      </div>
                      */}
                    </div>
                  )}
                  </Card>
                ))}
                </DndProvider>

                {checklistIsEditing && (
                  <div className="row align-items-center no-gutters mt-2">
                    <div className="col">
                      <div className="form-group mb-0">
                        <input className="form-control behave-checklist-input" type="text" autoFocus={true}
                               onKeyDown={(e) => e.key === 'Enter' && handleChecklistNewItemBlur(e)}
                               onBlur={handleChecklistNewItemBlur} ref={newChecklistItemInput}/>
                      </div>
                    </div>

                    <div className="col-auto ml-1">
                      <button type="button" className="btn btn-outline-muted" style={{width: "100px"}}
                              onClick={handleChecklistNewItemCancel}>
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {!checklistIsEditing && allowEdit && (
                  <div className="row mt-3">
                    <div className="col">
                      <button type="button" className="btn c-btn-addto"
                              onClick={() => setChecklistIsEditing(true)}
                              style={{width: "170px"}}>
                        <i className="fas fa-plus"></i> Add Checklist Item
                      </button>
                    </div>
                  </div>
                )}
              </Fragment>
            )}

            {currentForm != null && (
              <Fragment>
                <div className="row mt-3 border-top">
                  <div className="col">
                  </div>
                </div>


                <div className="row mt-3 c-task-form">
                  <div className="col">
                  <Form customerId={props.customerId} entityId={taskId} entityType='Task'
                        form={currentForm} formDataIn={formData} setFormDataIn={setFormData}
                        formCompletedBy={formCompletedBy} setFormCompletedBy={setFormCompletedBy}
                        allowEdit={allowEdit} setCurrentForm={setCurrentForm} />
                </div>
              </div>
            </Fragment>
          )}

          {currentForm == null && formData != null && (
            <Fragment>
              <hr/>
              <p className="text-danger">
                The form associated with this task was deleted from the account after the form was filled out.
                Here is the raw data:
              </p>
              {Array.from(formData.values()).map(f => (
                <p className="text-danger">{f}</p>
              ))}

            </Fragment>
          )}

          {isRecurring && (
              <Fragment>
                <div className="row mt-3 align-items-center">
                  <div className="col-auto c-recurring-title-row-width">
                    <div className="c-internal-heading">Repeat every
                      <i className="fas fa-asterisk c-text-required mt-1"></i>
                    </div>
                  </div>

                  <div className="col">
                    <input className="form-control" id="recurring_interval" type="number"
                           placeholder="example: 3"
                           onChange={handleRecurringIntervalChange}
                           value={recurringInterval}/>
                  </div>

                  <div className="col">
                    <Select
                        components={{DropdownIndicator: DropdownIndicatorBlocks, IndicatorSeparator}}
                        styles={{
                          control: (baseStyles, state) => {
                            return {
                              ...baseStyles,
                              color: '#2c2c2c',
                              borderWidth: '1px',
                              borderColor: '#b3b3b3',
                              backgroundColor: '#ffffff',
                              cursor: 'pointer',
                            }
                          }
                        }}
                        onChange={handleRecurringPeriodChange}
                        value={recurringPeriodOptions.find(o => o.value === recurringPeriod)}
                        options={recurringPeriodOptions}
                    />
                  </div>
                </div>

                <div className="row mt-3 align-items-center">
                  <div className="col-auto c-recurring-title-row-width">
                    <div className="c-internal-heading">Repeat relative to</div>
                  </div>

                  <div className="col">
                    <div className="row flatpickr" ref={recurringRelativeDateInput}>
                      <div className="col">
                        {/* We will need to attach a class onto the input group that the classes below respect for red line
                               because flatpickr clones our input group here and makes a new one */}
                        <div
                            className='input-group mb-0'>
                          <input type="text" className="form-control" data-input=""
                                 id="task_relative_date_value"/>
                          <div className="input-group-append" style={{'zIndex': '0'}}>
                            <button className="btn c-btn-dropdown-delete-border-left"
                                    type="button"
                                    id="relative-due-date-remove-button"
                                    data-clear="">
                              <i className="fal fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3 align-items-center d-none-feature">
                  <div className="col-auto c-recurring-title-row-width">
                    <div className="c-internal-heading">Repeat on</div>
                  </div>

                  <div className="col">
                    <div className="row no-gutters">
                      <div className="col-auto">
                        <a className="btn p-0" type="button">
                          <div className="c-avatar-container c-recurring-day-bg">
                            <div className="c-center-element-parent">
                              <div className="c-center-element-child">
                                <div className="c-avatar-initials">S</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col-auto">
                        <a className="btn p-0" type="button">
                          <div className="c-avatar-container c-recurring-day-bg-selected">
                            <div className="c-center-element-parent">
                              <div className="c-center-element-child">
                                <div className="c-avatar-initials c-custom-colors">M</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col-auto">
                        <a className="btn p-0" type="button">
                          <div className="c-avatar-container c-recurring-day-bg">
                            <div className="c-center-element-parent">
                              <div className="c-center-element-child">
                                <div className="c-avatar-initials">T</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col-auto">
                        <a className="btn p-0" type="button">
                          <div className="c-avatar-container c-recurring-day-bg">
                            <div className="c-center-element-parent">
                              <div className="c-center-element-child">
                                <div className="c-avatar-initials">W</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col-auto">
                        <a className="btn p-0" type="button">
                          <div className="c-avatar-container c-recurring-day-bg">
                            <div className="c-center-element-parent">
                              <div className="c-center-element-child">
                                <div className="c-avatar-initials">T</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col-auto">
                        <a className="btn p-0" type="button">
                          <div className="c-avatar-container c-recurring-day-bg">
                            <div className="c-center-element-parent">
                              <div className="c-center-element-child">
                                <div className="c-avatar-initials">F</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col-auto">
                        <a className="btn p-0" type="button">
                          <div className="c-avatar-container c-recurring-day-bg">
                            <div className="c-center-element-parent">
                              <div className="c-center-element-child">
                                <div className="c-avatar-initials">S</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row pt-3 pr-3 pl-3 pb-0">
                  <div className="col border c-dynamic-due-date">

                    <div className="row mt-3 d-none-feature">
                      <div className="col c-internal-heading">
                        The next task will be created on
                      </div>
                    </div>

                    <div className="row align-items-center mt-2 mb-2 d-none-feature">
                      <div className="col-md">
                        <div className="dropdown">
                          <a className="btn c-btn-dropdown" href="#" role="button" data-toggle="dropdown"
                             aria-expanded="false">
                            <div className="row">
                              <div className="col text-left">When previous task is completed</div>
                              <div className="col-auto ml-2"><i className="fas fa-caret-down"></i></div>
                            </div>
                          </a>

                          <div className="dropdown-menu">
                            <a className="dropdown-item active" href="#">
                              When previous task is completed
                            </a>

                            <a className="dropdown-item" href="#">
                              Date
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="col">
                        Datepicker macro here
                      </div>
                    </div>

                    <div className="row mt-3 mb-2">
                      <h4 className="col-auto c-cursor-pointer"
                          onClick={() => {
                            setRecurringParameter(
                                'relative_start_date_enabled',
                                !relativeStartDateEnabled,
                                () => {
                                  setRelativeStartDateEnabled(!relativeStartDateEnabled)
                                })
                          }}>
                        {relativeStartDateEnabled ? (
                            <i className="fal fa-toggle-on fa-lg text-primary"></i>
                        ) : (
                            <i className="fal fa-toggle-off fa-lg text-primary"></i>
                        )}
                        &nbsp;Relative Start Date <span className="c-text-info">(optional)</span>
                      </h4>
                    </div>

                    {relativeStartDateEnabled && (
                        <Fragment>
                          <div className="row mt-3 align-items-center">
                            <div className="col-auto pr-0">
                              <i className="fas fa-plus"></i>
                            </div>

                            <div className="col-md-2 col-3">
                              <input className="form-control" id="" type="text"
                                     onChange={(event) => {
                                       const val = event.target.value;
                                       if (val !== '' && isNaN(val)) {
                                         return;
                                       }
                                       setRecurringParameter('relative_start_date_count',
                                           event.target.value,
                                           () => {
                                             setRelativeStartDateCount(val)
                                           })
                                     }}
                                     value={relativeStartDateCount}/>
                            </div>

                            <div className="col pl-0">
                              <div className="dropdown">
                                <a className="btn c-btn-dropdown" href="#" role="button" data-toggle="dropdown"
                                   aria-expanded="false">
                                  <div className="row">
                                    <div className="col text-left">
                                      {daysWeeksMonthsString[relativeStartDateDaysWeeksMonths]}
                                    </div>
                                    <div className="col-auto ml-2"><i className="fas fa-caret-down"></i></div>
                                  </div>
                                </a>

                                <div className="dropdown-menu">
                                  <a className="dropdown-item active"
                                     onClick={() => {
                                       setRecurringParameter('relative_start_days_weeks_months',
                                           'days',
                                           () => setRelativeStartDateDaysWeeksMonths('days'))
                                     }}
                                     href="#">
                                    Days
                                  </a>

                                  <a className="dropdown-item"
                                     onClick={() => {
                                       setRecurringParameter('relative_start_days_weeks_months',
                                           'weeks',
                                           () => setRelativeStartDateDaysWeeksMonths('weeks'))
                                     }}
                                     href="#">
                                    Weeks
                                  </a>

                                  <a className="dropdown-item"
                                     onClick={() => {
                                       setRecurringParameter('relative_start_days_weeks_months',
                                           'months',
                                           () => setRelativeStartDateDaysWeeksMonths('months'))
                                     }}
                                     href="#">
                                    Months
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-auto pl-sm-0 mt-sm-0 mt-2">
                              From task creation
                            </div>
                          </div>
                        </Fragment>
                    )}

                    {!relativeStartDateEnabled && (
                        <Fragment>
                          <div className="row mt-2 mb-3">
                            <div className="col">
                            <span className="c-text-info font-italic">
                              No Start Date will be set
                            </span>
                            </div>
                          </div>
                        </Fragment>
                    )}

                    <div className="row mt-4 mb-2">
                      <h4 className="col-auto c-cursor-pointer"
                          onClick={() => {
                            setRecurringParameter(
                                'relative_due_date_enabled',
                                !relativeDueDateEnabled,
                                () => {
                                  setRelativeDueDateEnabled(!relativeDueDateEnabled)
                                })
                          }}>
                        {relativeDueDateEnabled ? (
                            <i className="fal fa-toggle-on fa-lg text-primary"></i>
                        ) : (
                            <i className="fal fa-toggle-off fa-lg text-primary"></i>
                        )}
                        &nbsp;Relative Due Date <span className="c-text-info">(optional)</span>
                      </h4>
                    </div>

                    {relativeDueDateEnabled && (
                        <Fragment>
                          <div className="row mt-3 mb-3 align-items-center">
                            <div className="col-auto pr-0">
                              <i className="fas fa-plus"></i>
                            </div>

                            <div className="col-md-2 col-3">
                              <input className="form-control" id="" type="text"
                                     onChange={(event) => {
                                       const val = event.target.value;
                                       if (val !== '' && isNaN(val)) {
                                         return;
                                       }
                                       setRecurringParameter('relative_due_date_count',
                                           event.target.value,
                                           () => {
                                             setRelativeDueDateCount(val)
                                           })
                                     }}
                                     value={relativeDueDateCount}/>
                            </div>

                            <div className="col pl-0">
                              <div className="dropdown">
                                <a className="btn c-btn-dropdown" href="#" role="button" data-toggle="dropdown"
                                   aria-expanded="false">
                                  <div className="row">
                                    <div className="col text-left">
                                      {daysWeeksMonthsString[relativeDueDateDaysWeeksMonths]}
                                    </div>
                                    <div className="col-auto ml-2"><i className="fas fa-caret-down"></i></div>
                                  </div>
                                </a>

                                <div className="dropdown-menu">
                                  <a className="dropdown-item active"
                                     onClick={() => {
                                       setRecurringParameter('relative_due_days_weeks_months',
                                           'days',
                                           () => setRelativeDueDateDaysWeeksMonths('days'))
                                     }}
                                     href="#">
                                    Days
                                  </a>
                                  <a className="dropdown-item"
                                     onClick={() => {
                                       setRecurringParameter('relative_due_days_weeks_months',
                                           'weeks',
                                           () => setRelativeDueDateDaysWeeksMonths('weeks'))
                                     }}
                                     href="#">
                                    Weeks
                                  </a>
                                  <a className="dropdown-item"
                                     onClick={() => {
                                       setRecurringParameter('relative_due_days_weeks_months',
                                           'months',
                                           () => setRelativeDueDateDaysWeeksMonths('months'))
                                     }}
                                     href="#">
                                    Months
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-auto pl-sm-0 mt-sm-0 mt-2">
                              From task creation
                            </div>

                            <div className="col-lg-auto pl-lg-0 mt-lg-0 mt-2 d-none-feature">
                              <div className="dropdown">
                                <a className="btn c-btn-dropdown" href="#" role="button" data-toggle="dropdown"
                                   aria-expanded="false">
                                  <div className="row">
                                    <div className="col text-left">From task creation</div>
                                    <div className="col-auto ml-2"><i className="fas fa-caret-down"></i></div>
                                  </div>
                                </a>

                                <div className="dropdown-menu">
                                  <a className="dropdown-item active" href="#">
                                    From task creation
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    From relative start date
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                    )}

                    {!relativeDueDateEnabled && (
                        <Fragment>
                          <div className="row mb-3">
                            <div className="col">
                            <span className="c-text-info font-italic">
                              <span>No Due Date will be set</span>
                            </span>
                            </div>
                          </div>
                        </Fragment>
                    )}

                  </div>
                </div>


                <div className="row mt-3 mb-2">
                  <div className="col">
                    <div className="c-internal-heading">Add to which group?</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="dropdown">
                      <a className="btn c-btn-dropdown" href="#" role="button" data-toggle="dropdown"
                         aria-expanded="false">
                        <div className="row">
                          <div className="col text-left">
                            {getSelectedGroupName()}
                          </div>
                          <div className="col-auto ml-2"><i className="fas fa-caret-down"></i></div>
                        </div>
                      </a>

                      <div className="dropdown-menu">
                        <a className={"dropdown-item " + (recurringGroupId === null ? 'active' : '')} href="#"
                           onClick={() => setRecurringParameter('group_id', null, () => setRecurringGroupId(null))}>
                          Ungrouped
                        </a>

                        {groups.map((group) => (
                            <a key={group.group_id}
                               className={"dropdown-item " + (recurringGroupId === group.group_id ? 'active' : '')}
                               onClick={() => setRecurringParameter('group_id', group.group_id, () => setRecurringGroupId(group.group_id))}
                               href="#">
                              {group.group_title}
                            </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* footer area */}
                <div className="row no-gutters mt-4">
                  <div className="col border-top"></div>
                </div>


                {/*if the task is not paused, info message on recurring tasks*/}
                {(getNextTaskCreationDate() && !isPaused) ? (
                  <div className="row mt-3 align-items-center">
                    <div className="col-12 mb-3 text-center text-primary">
                      <h3 className="font-weight-bold">
                        <i className="fas fa-check"></i>
                        {props.isOnTemplate ? (
                          <text>
                            This is a template, no tasks will be created until a project is created from this template.
                          </text>

                        ): (
                          <text>
                            The Next Recurring Task Has Been Scheduled!
                          </text>
                        )}
                      </h3>
                    </div>

                    <div className="col">
                      <h3>The next task will automatically be created on</h3>
                    </div>
                    <div className="col-auto">
                      <h2 className="text-primary-accent mt-2">{getNextTaskCreationDate()}</h2>
                    </div>
                  </div>
                  ) : (
                  !isPaused && (
                  <div className="row no-gutters mt-3 align-items-center border-bottom pb-3">
                  <div className="col text-danger text-center">
                        <h3>Missing Values From Required Fields</h3>
                        <i className="fas fa-asterisk mt-1"></i> No tasks will be created until
                        required fields are filled in <i className="fas fa-asterisk mt-1"></i>
                      </div>
                    </div>
                  )
                )}
                {/*if the task is not paused, info message on recurring tasks*/}


                {getNextTaskCreationDate() && !isPaused && !props.isOnTemplate && (
                  <div className="row no-gutters mt-3 align-items-center border-bottom pb-3">
                    <div className="col">
                      Don't want to wait until {getNextTaskCreationDate()}?
                    </div>

                    <div className="col-auto">
                      <button className="btn btn-primary"
                              ref={createNowButton}
                              onClick={handleCreateRecurringTaskNow}>
                        Create Task Now
                      </button>

                      <button className="btn btn-link text-decoration-none d-none" ref={createdNowIndicator}>
                        The Task was Created!
                      </button>
                    </div>
                  </div>
                )}

                <div className="row mt-2 pb-0 align-items-end">
                  <div className="col">
                    <h3 className="text-secondary">
                      <i className="fal fa-save"></i> All of Your Changes are Automatically Saved
                    </h3>
                  </div>

                  <div className="col-auto">
                    <button className="btn btn-link text-secondary pr-0 pb-0" data-dismiss="modal">
                      <i className="fas fa-times"></i> Close
                    </button>
                  </div>
                </div>
                {/* footer area */}

              </Fragment>
          )}

            {/* Time tracking when the time is being recorded */}
            {timeTrackingTaskId === taskId && (


                <div className="row no-gutters c-border-time-tracking mt-4 p-2 mb-3">
                  <div className="col">
                  <div className="row">
                      <div className="col text-center">
                        <h3 className="c-internal-heading text-primary-accent"><i
                            className="far fa-stopwatch-20 text-upgrade"></i> You are recording time on this task</h3>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col text-primary-accent text-center">
                        {/* TODO: have the timer dynamically change in real time every .01hours */}
                        <h2 ref={timerIntervalRef}>{getLiveTimeTrackingInterval()} <span>hours</span></h2>
                      </div>
                    </div>

                    <div className="row mt-4 align-items-end">
                      <div className="col">
                        <span className="c-text-optional ">Note: </span> {liveTimeTrackingNote}
                      </div>

                      <div className="col-auto">
                        <button type="button" className="btn btn-danger"
                                id="stop-timer-button"
                                ref={stopTimerButtonRef}
                                onClick={() => handleStopTimer()}>
                          Stop Timer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

            )}

            {/* record time */}
            {timeTrackingPendingNewEntry && timeTrackingTaskId === null && (
                <Fragment>
                  <Fragment>
                    <div className="row mt-3 no-gutters border-top">
                      <div className="col">
                      </div>
                    </div>

                <div className="row pt-3 pr-3 pl-3 pb-0">
                  <div className="col border c-dynamic-due-date">
                    <div className="row mt-3">
                      <div className="col c-internal-heading">
                        <h3 className="c-internal-heading text-primary-accent">
                          <i className="far fa-stopwatch-20 text-upgrade mr-1"></i> Automatic Time Tracking
                        </h3>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col">
                        Once time recording starts, you can stop it from any page within CoordinateHQ
                      </div>
                    </div>

                    <div className="row align-items-center no-gutters mt-2">
                      <div className="col mr-2">
                        <div className="form-group mb-0">
                          <input
                              className={"form-control " + (liveTimeTrackingNoteError === null ? '' : 'is-invalid')}
                              value={liveTimeTrackingNote}
                              id="timer-recording-note"
                              onChange={(event) => setLiveTimeTrackingNote(event.target.value)}
                              type="text" placeholder="Required - Enter note about what you are tracking"/>
                          <div className="invalid-feedback">
                            {liveTimeTrackingNoteError}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row no-gutters mt-2 pb-3">
                      <div className="col mr-2">
                            <span className="c-text-info font-italic">
                              <i className="fal fa-info-circle"></i> You can only record time on 1 task at a time
                            </span>
                      </div>

                      <div className="col-auto mr-2">
                        <button type="button" className="btn btn-outline-secondary"
                                onClick={() => setTimeTrackingPendingNewEntry(false)}>
                          Cancel
                        </button>
                      </div>

                      <div className="col-auto mr-2">
                        <button type="button" className="btn btn-primary"
                                onClick={handleStartTimer}
                                id="timer-recording-start">
                          Start Timer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            </Fragment>
          )}


            {props.timeTrackingEnabled && (
                <Fragment>
                <TimeTracking timeTrackingEntries={timeTrackingEntries}
                                setTimeTrackingEntries={setTimeTrackingEntries}
                                timeTrackingIsEditing={timeTrackingIsEditing}
                                setTimeTrackingIsEditing={setTimeTrackingIsEditing}
                                timeTrackingDateEntryValue={timeTrackingDateEntryValue}
                                setTimeTrackingDateEntryValue={setTimeTrackingDateEntryValue}
                                isBudgeting={false}
                            stakeholders={stakeholders}
                            allowEdit={allowEdit}
                            customerId={props.customerId}
                            taskId={taskId}
              />

              <TimeTracking timeTrackingEntries={timeTrackingEntriesBudget}
                            setTimeTrackingEntries={setTimeTrackingEntriesBudget}
                            timeTrackingIsEditing={timeTrackingIsEditingBudget}
                            setTimeTrackingIsEditing={setTimeTrackingIsEditingBudget}
                            timeTrackingDateEntryValue={timeTrackingDateEntryValue}
                            setTimeTrackingDateEntryValue={setTimeTrackingDateEntryValue}
                            isBudgeting={true}
                            stakeholders={stakeholders}
                            allowEdit={allowEdit}
                            customerId={props.customerId}
                            taskId={taskId}
              />
            </Fragment>
          )}

            {isUser && (
             <Tags taskId={taskId} customerId={props.customerId}
                  tags={tags} setTags={setTags} allAvailableTags={allAvailableTags} />
            )}

            {(relatedGoals.length > 0 || outboundTasks.length > 0 || inboundTasks.length > 0) && (
              <div className="row mt-3 border-top">
                <div className="col">
                </div>
              </div>
            )}

            {(outboundTasks.length > 0 || inboundTasks.length > 0 || relatedGoals.length > 0) && (
              <div className="row mt-3 mb-2">
                <div className="col c-internal-heading">This Task's Dependencies</div>
              </div>
            )}


            {outboundTasks.map(task => (
              <div key={task.task_id} className="row align-items-center no-gutters c-hover-indicator-container border-bottom">
                <div className="col-auto">
                  <span className="c-text-info">Blocks</span>
                </div>

                  <div className="col ml-2">
                    <a href="#" className="btn-link"
                       onClick={() => {
                         window.openTaskId = task.task_id;
                         document.getElementById('close_react_modal').click();
                       }}
                       data-behave-blocked-task-outbound={task.task_title}>
                      {task.task_title}
                    </a>
                  </div>

                  <div className="col-auto ml-1 c-hover-show" onClick={handleOutboundTaskRemoveClick}
                       data-behave-remove-blocked-task-outbound={task.task_title}
                       data-task-id={task.task_id}>
                    <button className="btn btn-link-edit-danger">
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
              </div>
            ))}


            {inboundTasks.map(task => (
              <div key={task.task_id}
                   className="row align-items-center no-gutters c-hover-indicator-container border-bottom">
                <div className="col-auto">
                  <span className="c-text-info">Is Blocked By</span>
                </div>

                <div className="col ml-2">
                  <a href="#"
                     onClick={() => {
                       window.openTaskId = task.task_id;
                       document.getElementById('close_react_modal').click();
                     }}
                     className="btn-link"
                     data-behave-inbound-blocker-task={task.task_title}>
                    {task.task_title}
                  </a>
                </div>

                <div className="col-auto ml-1 c-hover-show" onClick={handleInboundTaskRemoveClick}
                     data-task-id={task.task_id}>
                  <button className="btn btn-link-edit-danger">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            ))}

            {relatedGoals.length > 0 && (
              <Fragment>
                {relatedGoals.map(goal => (
                  <div key={goal.goal_id}
                       className="row align-items-center no-gutters c-hover-indicator-container border-bottom">
                    <div className="col-auto">
                      <span className="c-text-info">Related Goal</span>
                    </div>
                    <div className="col ml-2">

                      <a href2={getGoalUrl(goal.goal_id, props.customerId, isUser, props.stakeholderToken, props.vendorId)}
                         onClick={() => {
                           window.openGoalId = goal.goal_id;
                           document.getElementById('close_react_modal').click();
                         }}
                         data-behave-related-goal-title={goal.goal_title}
                         className="btn-link">
                        {goal.goal_title}
                      </a>
                    </div>

                    {isUser && (
                      <div className="col-auto ml-1 c-hover-show" onClick={handleRelatedGoalRemove}
                           data-goal-id={goal.goal_id}>
                        <button className="btn btn-link-edit-danger" data-behave-remove-outbound-goal={goal.goal_title}>
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    )}

                  </div>
                ))}
              </Fragment>

            )}
          </div>

          {allowEdit && (
          <div className="col-auto c-sticky-parent c-side-menu c-desktop-md">
            <div className="c-sticky-dialog-menu">

              <div className="row mt-3 mb-2">
                <div className="col c-side-menu-heading">
                  Add Task Fields
                </div>
              </div>

              {/*Separator line - side menu*/}
              <div className="row mt-2 mb-2 pl-3 pr-3">
                <div className="col border-top">
                </div>
              </div>

              {(description == null && !descriptionIsEditing) && (
                <div className="row mb-2">
                  <div className="col text-center">
                    <button type="button" className="btn c-btn-addto"
                            id="add_description_button"
                            onClick={() => setDescriptionIsEditing(true)}>
                      Description
                    </button>
                  </div>
                </div>
              )}

              {(startDate == null && !startDateIsEditing) && !isRecurring && (
                <div className="row mb-2">
                  <div className="col text-center">
                    <button type="button" className="btn c-btn-addto behave-add-start-date"
                            onClick={() => {setStartDateIsEditing(true); hasPoppedOnceStartDate.current = false}}>
                      Start Date
                    </button>
                  </div>
                </div>
              )}

              {progress == null && !isRecurring && (
                <div className="row mb-2">
                  <div className="col text-center">
                    <button type="button" className="btn c-btn-addto"
                            onClick={handleProgressCreate}>Progress Bar</button>
                  </div>
                </div>
              )}

              {(checklist.length === 0 && !checklistIsEditing) && (
                 <div className="row mb-2">
                  <div className="col text-center">
                    <button type="button" className="btn c-btn-addto"
                            onClick={() => setChecklistIsEditing(true)}>
                      Checklist
                    </button>
                  </div>
                </div>
              )}


              {/* Add Form */}
              {currentForm == null && (
                <div className="row mb-2">
                  <div className="col">
                    <div className="dropdown" data-flip="false">
                      <a className={'btn c-btn-addto '}
                         href="#" role="button"
                         id="addFormDropdown"
                         data-toggle="dropdown" aria-expanded="false">
                        <div className="row align-items-center no-gutters d-flex flex-nowrap">
                          <div className="col-auto text-left">
                            Form
                          </div>
                          <div className="col-auto ml-2">
                            <i className="fas fa-caret-down"></i>
                          </div>
                        </div>
                      </a>

                      <div className="dropdown-menu">
                        {forms.map(form => (
                          <a className="dropdown-item"
                             href="#" key={form.form_id} data-form-name={form.form_name}
                             onClick={() => handleAddForm(form)}>
                              {form.form_name}
                            </a>
                          ))}
                          <div className="dropdown-divider"></div>

                          <a className="dropdown-item" href="/settings/forms">Create a new Form</a>
                          <a className="dropdown-item" href="https://coordinatehq.wistia.com/medias/hbszbm5idc" target="_blank">Learn about Forms</a>

                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {allowUpload && (
                  <div className="row mb-2">
                    <div className="col text-center">
                      <button type="button" className="btn c-btn-addto"
                              onClick={() => attachFileInput.current.click()}>
                        <i className="fas fa-paperclip"></i> Attach Files
                      </button>
                    </div>
                  </div>
                )}

                {isUser && !hasClickedAddDependency && !isRecurring && (
                   <div className="row mb-2">
                    <div className="col text-center">
                      <button id="addDependencyButton" type="button" className="btn c-btn-addto"
                              onClick={() => setHasClickedAddDependency(true)}>
                        Dependencies
                      </button>
                    </div>
                  </div>
                )}

                {isUser && hasClickedAddDependency && !isRecurring && (
                <Fragment>
                  <div className="row mt-3 mb-2">
                    <div className="col c-side-menu-heading">
                      Dependencies
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col text-center">
                      {renderRelatedGoalsDropdown(true)}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col">
                      {renderTasksBlockedDropdown()}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col">
                      {renderTasksBlockingInbound()}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col text-center">
                      <a className="btn c-btn-addto" href="https://coordinatehq.wistia.com/medias/6sad6u0m16" target="_blank">
                        Learn about dependencies
                      </a>
                    </div>
                  </div>

                </Fragment>
                )}

                {/*Separator line - side menu*/}
                {props.timeTrackingEnabled && isUser && !isRecurring && (
                  <div className="row mt-2 mb-2 pl-3 pr-3">
                    <div className="col border-top">
                    </div>
                  </div>
                )}

                {/* Start Recording Time */}
                {props.timeTrackingEnabled && isUser && (timeTrackingTaskId === undefined || timeTrackingTaskId == null) && !isRecurring && (
                  <div className="row mb-2">
                    <div className="col text-center">
                      <button type="button" className="btn c-btn-addto"
                              onClick={() => setTimeTrackingPendingNewEntry(true)}
                              id="start-recording-timer">
                        <i className="far fa-stopwatch-20"></i> Start Recording Time
                      </button>
                    </div>
                  </div>
                )}

                {/* Time Tracking */}
                {(props.timeTrackingEnabled && isUser && timeTrackingEntries.length === 0 && !timeTrackingIsEditing) && !isRecurring && (
                  <div className="row mb-2">
                    <div className="col text-center">
                      <button type="button" className="btn c-btn-addto"
                              id="add-track-time-button"
                              onClick={() => { setTimeTrackingIsEditing(true); setTimeTrackingDateEntryValue(new Date())}}>
                        Time Tracking
                      </button>
                    </div>
                  </div>
                )}
                {/* Time Tracking */}

                {/* Time Tracking Budget */}
                {(props.timeTrackingEnabled && isUser && timeTrackingEntriesBudget.length === 0 && !timeTrackingIsEditingBudget) && !isRecurring && (
                  <div className="row mb-2">
                    <div className="col text-center">
                      <button type="button" className="btn c-btn-addto"
                              id="add-track-time-button-budget"
                              onClick={() => { setTimeTrackingIsEditingBudget(true); setTimeTrackingDateEntryValue(new Date())}}>
                        Time Tracking Budget
                      </button>
                    </div>
                  </div>
                )}
                {/* Time Tracking Budget */}

                {/*Separator line - side menu*/}
                <div className="row mt-2 mb-2 pl-3 pr-3">
                  <div className="col border-top">
                  </div>
                </div>

                {!isRecurring && isUser && renderMoveTaskRow(true)}

                {isUser && (
                  <Fragment>
                    <div className="row mt-2">
                      <div className="col text-center">
                        <TagsDropdown taskId={taskId} customerId={props.customerId} tags={tags} setTags={setTags}
                                      allAvailableTags={allAvailableTags} />
                      </div>
                    </div>
                  </Fragment>
                )}


                {!isRecurring && (
                  <div className="row mt-2">
                    <div className="col-auto">
                      <button href="#" id="copy_link_button2" className="btn c-btn-addto"
                              onClick={() => {
                                try {
                                  navigator.clipboard.writeText(copyLinkUrl);
                                  document.getElementById('copy_link_button2').classList.add('d-none');
                                  document.getElementById('copied_link_notification2').classList.remove('d-none');
                                  setTimeout(x => {
                                    document.getElementById('copy_link_button2').classList.remove('d-none');
                                    document.getElementById('copied_link_notification2').classList.add('d-none');
                                  }, 1000);
                                } catch (e) {
                                }
                              }}>
                        <i className="fal fa-copy"></i> Copy shareable link
                      </button>
                      <span className="d-none font-weight-normal ml-2" id="copied_link_notification2">
                        Copied to clipboard!
                      </span>
                    </div>
                  </div>
                )}


                {(locked === false && isUser) && !isRecurring && !isTemplate && (
                  <div className="row mt-2">
                    <div className="col text-center">
                      <button type="button" className="btn c-btn-addto"
                              onClick={handleLockClick}>
                        <i className="fal fa-lock-alt"></i> Lock Task
                      </button>
                    </div>
                  </div>
                )}

                <div className="row mt-2 mb-3">
                  <div className="col text-center">
                    {hasClickedDelete ? (
                       <button type="button" className="btn btn-outline-danger c-btn-addto"
                               data-behave_task_delete_title={title}
                               onClick={handleConfirmDeleteClick}>
                        Confirm Delete?
                      </button>
                    ) : (
                      <button type="button" className="btn c-btn-delete c-btn-addto"
                              data-behave_task_delete_title={title}
                              onClick={() => setHasClickedDelete(true)}>
                        <i className="far fa-trash-alt"></i> Delete {isRecurring ? 'Recurring' : ''}Task
                      </button>
                    )}

                  </div>
                </div>
              </div>
            </div>

          )}
        </div>

        {!props.isOnTemplate && !isRecurring && (
        <div className="row border-top pt-3 c-modal-discussion">
          <div className="col" style={isMobile() ? {paddingRight: '1px'} : {}}>
            <nav className="mb-3">
              <div className="c-card-nav nav nav-pills" id="nav-tab" role="tablist">
                <a className={'nav-link ' + (!isPrivate && !props.openInternal && 'active')}  id="nav-discussion-tab" data-toggle="tab" href="#nav-discussion" role="tab"
                    onClick={() => {
                      setUnseenMentions(0);
                      setNewChatIcon(false);
                    }}
                   aria-controls="nav-home" aria-selected="true">

                  <span className="mr-2">Discussion</span>

                  {/* we force them to look at this tab in all cases except for a bug when coming in from the email
                      or maybe the activity feed, that bug we deprioritised to just leave this as d-none for now
                  */}
                  {props.openInternal && unseenMentions > 0 && (
                    <div className="c-chat-notification-dm-task-row mention-state-count-div">
                      <div className="c-center-element-parent">
                        <div className="c-center-element-child mention-state-count">
                          {unseenMentions}
                        </div>
                      </div>
                    </div>
                  )}

                  {props.openInternal && unseenMentions === 0 && newChatIcon && (
                      <span className="mention-state-chat-bubble">
                      <i className="fas fa-circle c-font-6 text-notification"></i>
                    </span>
                  )}

                </a>

                <a className="nav-link" id="nav-history-tab" data-toggle="tab" href="#nav-history" role="tab"
                   data-customer-id={props.customerId} data-entity-id={taskId}
                   aria-controls="nav-home">Activity</a>

                {isUser && (
                  <a className={'nav-link ' + ((isPrivate || props.openInternal) && 'active')} id="nav-internal-tab" data-toggle="tab" href="#nav-internal" role="tab"
                     data-customer-id={props.customerId} data-entity-id={taskId}
                     onClick={() => {
                       if(internalModifyIcon) {
                         setInternalModifyIcon(false);
                         setUnseenInternalMentions(0);
                         fetchSession(`/js/customer/${props.customerId}/task/${taskId}/set_seen_internal`,
                           {method: 'post'})
                       }

                     }}
                     aria-controls="nav-home">
                    <i className="fas fa-eye-slash mr-1"></i>

                    <span className="mr-2">Internal Notes</span>

                    {unseentInternalMentions > 0 && (
                      <div className="c-chat-notification-dm-task-row mention-state-count-div">
                        <div className="c-center-element-parent">
                          <div className="c-center-element-child mention-state-count">
                            {unseentInternalMentions}
                          </div>
                        </div>
                      </div>
                    )}

                    {unseentInternalMentions === 0 && internalModifyIcon && (
                      <span className="mention-state-chat-bubble">
                        <i className="fas fa-circle c-font-6 text-notification"></i>
                      </span>
                    )}
                  </a>
                )}
              </div>
            </nav>

            <div className="tab-content">
              <div className={'tab-pane ' + (!isPrivate && !props.openInternal && 'active')} id="nav-discussion" role="tabpanel" aria-labelledby="nav-discussion-tab">

                {locked ? (
                  <p className="c-text-none-assigned mt-3 mb-3">Commenting disabled on locked task</p>
                ) :
                  isPrivate ? (
                      <p className="c-text-none-assigned mt-3 mb-3">Public commenting disabled on private task</p>
                  ) : (
                   <Comment key='newComment' projectId={props.customerId} targetEntityId={taskId}
                           atCompleteNames={atCompleteNames} comments={comments} setComments={setComments}
                           isNewComment={true} commentMoveTargets={commentMoveTargets} badgeElement={badgeElement}
                           entityType="task" commentReplyTarget={commentReplyTarget}
                           setCommentReplyTarget={setCommentReplyTarget} allowUpload={allowUpload} />
                  )
                }

                {comments.map(comment => (<Comment key={comment.discussion_entry_id} projectId={props.customerId}
                                                   targetEntityId={taskId} atCompleteNames={atCompleteNames}
                                                   comment={comment} comments={comments} setComments={setComments}
                                                   otherComments={commentsInternal} setOtherComments={setCommentsInternal}
                                                   commentMoveTargets={commentMoveTargets} entityType="task"
                                                   setCommentReplyTarget={setCommentReplyTarget}
                                                   allowUpload={allowUpload}/> ))}
              </div>

              <div className="tab-pane c-activity-container" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
                <p className="c-text-none-assigned mt-3 mb-3">No Activity Present</p>
              </div>

              {isUser && (
                <div className={'tab-pane ' + ((isPrivate || props.openInternal) && 'active')} id="nav-internal" role="tabpanel" aria-labelledby="nav-internal-tab">
                  <h3 className="text-secondary pb-3 pl-1">
                    <i className="fal fa-hand-point-right"></i>
                    This tab is internal and will not be shared with collaborators on this portal.
                  </h3>

                  {locked ? (
                     <p className="c-text-none-assigned mt-3 mb-3">Commenting disabled on locked task</p>
                  ) : (
                   <Comment key='newComment' projectId={props.customerId} targetEntityId={taskId}
                           atCompleteNames={atCompleteNamesInternal} comments={commentsInternal}
                           setComments={setCommentsInternal}
                           isNewComment={true} commentMoveTargets={commentMoveTargetsInternal}
                           badgeElement={badgeElement}
                           entityType="task" internal={true}
                           commentReplyTarget={commentReplyTarget} setCommentReplyTarget={setCommentReplyTarget}/>
                  )}


                  {commentsInternal.map(comment => (<Comment key={comment.discussion_entry_id}
                                                             projectId={props.customerId}
                                                     targetEntityId={taskId} atCompleteNames={atCompleteNamesInternal}
                                                     comment={comment} comments={commentsInternal}
                                                     setComments={setCommentsInternal}
                                                     commentMoveTargets={commentMoveTargetsInternal} entityType="task"
                                                     otherComments={comments} setOtherComments={setComments}
                                                     setCommentReplyTarget={setCommentReplyTarget}/> ))}
                </div>
              )}

            </div>
          </div>
        </div>

        )}
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="modal-header data-behave-click-target">
          <h5 className="modal-title">
          </h5>
          <button id="close_react_modal" type="button" className="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>

      <div className="modal-body p-5 text-center mt-5 mb-5">
        {/* position this modal body roughly the size of a newly created task */}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="spinner-border text-light" role="status" style={{width: '10rem', height: '10rem'}}><span
          className="sr-only">Loading...</span>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </Fragment>
  )


}

