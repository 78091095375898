
// Shared status between Task and Goal
// Mirrored by Task.TaskStatuses

//
// Status
export const Status = {
  NotStarted: 'not_complete',
  Information: 'information',
  InProgress: 'in_progress', // Used by task
  OnTrack: 'on_track', // used by goal
  OffTrack: 'off_track', // used by goal
  DependencyWait: 'dependency_wait', // Used by task
  Blocked: 'blocked',
  Complete: 'complete'
}

export const statusCategories = {
  not_complete: "Not Started",
  information: "Information Only",
  in_progress: "In Progress",
  dependency_wait: "Wait on Dependencies",
  blocked: "Blocked",
  complete: "Complete",

  on_track: "On Track", // used by goal
  off_track: "Off Track", // used by goal

}

