import React, {useState, useRef, Fragment, useEffect} from "react"
import {fetchSession} from "./common_functions";


export function NotificationSettings(props) {
  const [loaded, setLoaded] = useState(false);
  const [preferences, setPreferences] = useState({})

  function updatePreferences(newPreferences) {
    let updateUrl = `/js/customer/${props.customerId}/notification_settings/${props.stakeholderId}`
    if(props.vendorSettings != null) {
      updateUrl = `/js/settings/notification_defaults/${props.vendorSettings}`
    }
    fetchSession(updateUrl, {
         method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({preferences: newPreferences})
    }).then(response => {
        return response.json();
      })
      .then(result => {
        setPreferences(result.preferences)
      });
  }

  useEffect(() => {
    if (loaded === false) {
      let fetchUrl = `/js/customer/${props.customerId}/notification_settings/${props.stakeholderId}`;
      if(props.vendorSettings != null) {
        fetchUrl = `/js/settings/notification_defaults/${props.vendorSettings}`
      }
      fetchSession(fetchUrl)
        .then(response => {
          return response.json();
        })
        .then(result => {
          setPreferences(result.preferences)
          setLoaded(true);
        });
    }
  })

  return loaded && (
    <Fragment>
        {(props.vendorSettings == null || !props.vendorSettings.includes('profile')) && (
          <div className="row mt-2 align-items-center">

              <div className="col">
                <h3 className="font-weight-bold">
                  For&nbsp;
                  {(props.vendorSettings == null) ? props.emailAddress : (props.vendorSettings === 'manager') ? 'Managers' : 'Collaborators'}
                </h3>
              </div>


            <div className="col-auto d-none-feature"> {/* TODO bring back as feature when presets */}
              <div className="dropdown">
                <a className="btn c-btn-dropdown" href="#" role="button"
                   data-toggle="dropdown">
                  <div className="row no-gutters">
                    <div className="col text-left">
                      <span>Presets: Account Manager</span>
                    </div>

                    <div className="col-auto ml-2">
                      <i className="fas fa-caret-down"></i>
                    </div>
                  </div>
                </a>

                <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">Real-time Watch</a>
                  <a className="dropdown-item" href="#">Collaborators</a>
                  <a className="dropdown-item" href="#">Off</a>
                  <a className="dropdown-item" href="#">Custom</a>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className="row mt-3">
        <div className="col">
          <p>
            Notifications marked as Instant are emailed once per activity. Notifications marked "Daily"
            are bunched together into a single digest email sent out once a day.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <h4>Communication</h4>
        </div>
      </div>

      {/* Comments */}
      <div className="row no-gutters mt-2 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">Comments</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              All communication on this project
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col pl-md-3">
          <div className="row no-gutters">
            <div className="col-12 mt-md-0 mt-2">
              <div className="dropdown">
                <a className="btn c-btn-dropdown" href="#" role="button"
                   data-toggle="dropdown">
                  <div className="row no-gutters">
                    <div className="col text-left">
                      {preferences.all_comments_scope === 'all' ? (
                        <span>All tasks</span>
                      ) : (
                        <span>Only your tasks</span>
                      )}
                    </div>

                    <div className="col-auto ml-2">
                      <i className="fas fa-caret-down"></i>
                    </div>
                  </div>
                </a>

                <div className="dropdown-menu">
                  {preferences.all_comments_scope === 'all' ? (
                    <a className="dropdown-item" href="#"
                       onClick={() => updatePreferences({
                          ...preferences,
                          all_comments_scope: 'only_assigned_tasks'
                        })}>
                      Only your tasks
                    </a>
                  ) : (
                    <a className="dropdown-item" href="#"
                                           onClick={() => updatePreferences({
                          ...preferences,
                          all_comments_scope: 'all'
                        })}>
                      All tasks
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="col mt-2">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label id="all-communication-instant"
                       className={"btn btn-outline-primary " + (preferences.all_comments_schedule === 'instant' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           all_comments_schedule: 'instant'
                         })} />
                  Instant
                </label>
                <label id="all-communication-daily"
                       className={"btn btn-outline-primary " + (preferences.all_comments_schedule === 'daily' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           all_comments_schedule: 'daily'
                         })} />
                  Daily
                </label>
                {/* coming in a future card
                <label className="btn btn-outline-primary">
                  <input type="radio" name="options" id="option3" /> Weekly
                </label>
                */}
                <label id="all-communication-mute"
                       className={"btn btn-outline-primary " + (preferences.all_comments_schedule === 'mute' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           all_comments_schedule: 'mute'
                         })} />
                  Mute
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
      </div>
      {/* END Comments */}

      {/* @mentions */}
      <div className="row no-gutters mt-3 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">@-Mentions</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              New communications specifically directed at you
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col pl-md-3 mt-2">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label id="mentions-instant"
                   className={"btn btn-outline-primary " + (preferences.at_mentions_schedule === 'instant' ? 'active': '') }>
              <input type="radio" id="at-mentions-instant"
                     onClick={() => updatePreferences({
                       ...preferences,
                       at_mentions_schedule: 'instant'
                     })} />
              Instant
            </label>
            <label id="mentions-mute"
                   className={"btn btn-outline-primary " + (preferences.at_mentions_schedule === 'mute' ? 'active': '') }>
              <input type="radio" id="at-mentions-mute"
                     onClick={() => updatePreferences({
                       ...preferences,
                       at_mentions_schedule: 'mute'
                     })} />
              Mute
            </label>
          </div>
        </div>
        {/* Right Side */}
      </div>
      {/* END @mentions */}

      {/* End of the communication notifications section */}

      <div className="row mt-4">
        <div className="col">
          <h4>Reminders</h4>
        </div>
      </div>


      {/* Assignment Email */}
      <div className="row no-gutters mt-2 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">Task Assignment</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              Tasks newly assigned to you.
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col pl-md-3 mt-2">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label id="assignment-instant"
                   className={"btn btn-outline-primary " + (preferences.task_assignment_schedule === 'instant' ? 'active': '') }>
              <input type="radio" id="at-mentions-instant"
                     onClick={() => updatePreferences({
                       ...preferences,
                       task_assignment_schedule: 'instant'
                     })} />
              Instant
            </label>
            <label id="assignment-mute"
                   className={"btn btn-outline-primary " + (preferences.task_assignment_schedule === 'mute' ? 'active': '') }>
              <input type="radio" id="at-mentions-mute"
                     onClick={() => updatePreferences({
                       ...preferences,
                       task_assignment_schedule: 'mute'
                     })} />
              Mute
            </label>
          </div>
        </div>
        {/* Right Side */}
      </div>
      {/* END Upcoming Items */}


      {/* Upcoming Items */}
      <div className="row no-gutters mt-2 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4 c-settings-wide-md-break">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">Upcoming Items</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              When do you want to first be notified of upcoming due dates?
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col-md-8  pl-md-3">
          <div className="row no-gutters">
            <div className="col-12 mt-md-0 mt-2">
              <div className="dropdown">
                <a className="btn c-btn-dropdown behave-reminder-scope-dropdown" href="#" role="button"
                   data-toggle="dropdown">
                  <div className="row no-gutters">
                    <div className="col text-left">
                      {preferences.reminder_scope === 'all' ? (
                        <span>All tasks</span>
                      ) : (
                        <span>Only your tasks</span>
                      )}
                    </div>

                    <div className="col-auto ml-2">
                      <i className="fas fa-caret-down"></i>
                    </div>
                  </div>
                </a>

                <div className="dropdown-menu">
                  {preferences.reminder_scope === 'all' ? (
                    <a className="dropdown-item behave-reminder-scope-your-tasks" href="#"
                       onClick={() => updatePreferences({
                          ...preferences,
                          reminder_scope: 'only_assigned_tasks'
                        })}>
                      Only your tasks
                    </a>
                  ) : (
                    <a className="dropdown-item behave-reminder-scope-all-tasks" href="#"
                                           onClick={() => updatePreferences({
                          ...preferences,
                          reminder_scope: 'all'
                        })}>
                      All tasks
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="col mt-2">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label id="reminder-3-month"
                       className={"btn btn-outline-primary " + (preferences.reminder_timeframe === '3months' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           reminder_timeframe: '3months'
                         })} />
                  3 Months
                </label>

                <label id="reminder-1-month"
                       className={"btn btn-outline-primary " + (preferences.reminder_timeframe === '1month' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           reminder_timeframe: '1month'
                         })} />
                  1 Month
                </label>

                <label id="reminder-1-week"
                       className={"btn btn-outline-primary " + (preferences.reminder_timeframe === '1week' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           reminder_timeframe: '1week'
                         })} />
                  1 Week
                </label>

                <label id="reminder-1-day"
                       className={"btn btn-outline-primary " + (preferences.reminder_timeframe === '1day' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           reminder_timeframe: '1day'
                         })} />
                  1 Day
                </label>

                <label id="reminder-never"
                       className={"btn btn-outline-primary behave-reminder-schedule-never" + (preferences.reminder_timeframe === 'never' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           reminder_timeframe: 'never'
                         })} />
                  Never
                </label>

              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
      </div>
      {/* END Upcoming Items */}

      {/* Overdue Items */}
      <div className="row no-gutters mt-3 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">Overdue Items</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              How often do you want to be reminded of overdue tasks or goals?
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col pl-md-3">
          <div className="row no-gutters">
            <div className="col-12 mt-md-0 mt-2">
              <div className="dropdown">
                <a className="btn c-btn-dropdown behave-overdue-scope-dropdown" href="#" role="button"
                   data-toggle="dropdown">
                  <div className="row no-gutters">
                    <div className="col text-left">
                      {preferences.overdue_scope === 'all' ? (
                        <span>All tasks</span>
                      ) : (
                        <span>Only your tasks</span>
                      )}
                    </div>

                    <div className="col-auto ml-2">
                      <i className="fas fa-caret-down"></i>
                    </div>
                  </div>
                </a>

                <div className="dropdown-menu">
                  {preferences.overdue_scope === 'all' ? (
                    <a className="dropdown-item behave-overdue-scope-your-tasks" href="#"
                       onClick={() => updatePreferences({
                          ...preferences,
                          overdue_scope: 'only_assigned_tasks'
                        })}>
                      Only your tasks
                    </a>
                  ) : (
                    <a className="dropdown-item behave-overdue-scope-all-tasks" href="#"
                                           onClick={() => updatePreferences({
                          ...preferences,
                          overdue_scope: 'all'
                        })}>
                      All tasks
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="col mt-2">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label id="all-communication-instant"
                       className={"btn btn-outline-primary behave-overdue-schedule-daily " + (preferences.overdue_timeframe === 'daily' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           overdue_timeframe: 'daily'
                         })} />
                  Daily
                </label>

                <label id="all-communication-daily"
                       className={"btn btn-outline-primary " + (preferences.overdue_timeframe === 'weekly' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           overdue_timeframe: 'weekly'
                         })} />
                  Weekly
                </label>

                <label id="all-communication-mute"
                       className={"btn btn-outline-primary behave-overdue-schedule-never" + (preferences.overdue_timeframe === 'mute' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           overdue_timeframe: 'mute'
                         })} />
                  Never
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
      </div>
      {/* END Overdue Items */}

      {/* Items with no due dates */}
      <div className="row no-gutters mt-3 pl-4">
        {/* left side */}
        <div className="col-md-4">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">
                Items with No Due Dates
              </span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              How often do you want to be reminded of your tasks with no due date?
            </div>
          </div>
        </div>
        {/* left side */}

        {/* right side */}
        <div className="col pl-md-3 mt-2">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label id="all-communication-instant"
                   className={"btn btn-outline-primary " + (preferences.no_due_date_timeframe === 'daily' ? 'active': '') }>
              <input type="radio"
                     onClick={() => updatePreferences({
                       ...preferences,
                       no_due_date_timeframe: 'daily'
                     })} />
              Daily
            </label>
            <label id="all-communication-daily"
                   className={"btn btn-outline-primary " + (preferences.no_due_date_timeframe === 'weekly' ? 'active': '') }>
              <input type="radio"
                     onClick={() => updatePreferences({
                       ...preferences,
                       no_due_date_timeframe: 'weekly'
                     })} />
              Weekly
            </label>
            <label id="all-communication-mute"
                   className={"btn btn-outline-primary " + (preferences.no_due_date_timeframe === 'mute' ? 'active': '') }>
              <input type="radio"
                     onClick={() => updatePreferences({
                       ...preferences,
                       no_due_date_timeframe: 'mute'
                     })} />
              Never
            </label>
          </div>
        </div>
        {/* right side */}
      </div>
      {/* END Items with no due dates */}

      <div className="row mt-4">
        <div className="col">
          <h4>Activity</h4>
        </div>
      </div>

      <div className="row no-gutters mt-2 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4 c-settings-wide-md-break">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">Tasks</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              Any changes related to a task.
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col-md-8  pl-md-3">
          <div className="row no-gutters">
            <div className="col-12 mt-md-0 mt-2">
              <div className="dropdown">
                <a className="btn c-btn-dropdown behave-activity-scope-dropdown" href="#" role="button"
                   data-toggle="dropdown">
                  <div className="row no-gutters">
                    <div className="col text-left">
                      {preferences.activity_task_scope === 'all' ? (
                        <span>All tasks</span>
                      ) : (
                        <span>Only your tasks</span>
                      )}
                    </div>

                    <div className="col-auto ml-2">
                      <i className="fas fa-caret-down"></i>
                    </div>
                  </div>
                </a>

                <div className="dropdown-menu">
                  {preferences.activity_task_scope === 'all' ? (
                    <a className="dropdown-item behave-activity-scope-your-tasks" href="#"
                       onClick={() => updatePreferences({
                          ...preferences,
                          activity_task_scope: 'only_assigned_tasks'
                        })}>
                      Only your tasks
                    </a>
                  ) : (
                    <a className="dropdown-item behave-activity-scope-all-tasks" href="#"
                                           onClick={() => updatePreferences({
                          ...preferences,
                          activity_task_scope: 'all'
                        })}>
                      All tasks
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="col mt-2">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label id="task-activity-daily"
                       className={"btn btn-outline-primary " + (preferences.activity_task_schedule === 'daily' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_task_schedule: 'daily'
                         })} />
                  Daily
                </label>

                <label id="task-activity-weekly"
                       className={"btn btn-outline-primary " + (preferences.activity_task_schedule === 'weekly' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_task_schedule: 'weekly'
                         })} />
                  Weekly
                </label>

                <label id="task-activity-mute"
                       className={"btn btn-outline-primary " + (preferences.activity_task_schedule === 'mute' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_task_schedule: 'mute'
                         })} />
                  Mute
                </label>

              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
      </div>

      <div className="row no-gutters mt-2 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4 c-settings-wide-md-break">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">Goals</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              Any changes related to a goal.
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col-md-8  pl-md-3">
          <div className="row no-gutters">
            <div className="col mt-2">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label id="goal-activity-daily"
                       className={"btn btn-outline-primary " + (preferences.activity_goal_schedule === 'daily' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_goal_schedule: 'daily'
                         })} />
                  Daily
                </label>

                <label id="goal-activity-weekly"
                       className={"btn btn-outline-primary " + (preferences.activity_goal_schedule === 'weekly' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_goal_schedule: 'weekly'
                         })} />
                  Weekly
                </label>

                <label id="goal-activity-mute"
                       className={"btn btn-outline-primary " + (preferences.activity_goal_schedule === 'mute' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_goal_schedule: 'mute'
                         })} />
                  Mute
                </label>

              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
      </div>

      <div className="row no-gutters mt-2 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4 c-settings-wide-md-break">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">Collaborators</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              Collaborators are added or removed.
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col-md-8  pl-md-3">
          <div className="row no-gutters">
            <div className="col mt-2">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label id="collaborator-activity-daily"
                       className={"btn btn-outline-primary " + (preferences.activity_collaborator_schedule === 'daily' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_collaborator_schedule: 'daily'
                         })} />
                  Daily
                </label>

                <label id="collaborator-activity-weekly"
                       className={"btn btn-outline-primary " + (preferences.activity_collaborator_schedule === 'weekly' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_collaborator_schedule: 'weekly'
                         })} />
                  Weekly
                </label>

                <label id="collaborator-activity-mute"
                       className={"btn btn-outline-primary " + (preferences.activity_collaborator_schedule === 'mute' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_collaborator_schedule: 'mute'
                         })} />
                  Mute
                </label>

              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
      </div>

      <div className="row no-gutters mt-2 pl-4 pb-3 border-bottom">
        {/* Left Side */}
        <div className="col-md-4 c-settings-wide-md-break">
          <div className="row">
            <div className="col">
              <span className="c-internal-heading">Project Changes</span>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              When changes are made to the project, for example the project's manager changes.
            </div>
          </div>
        </div>
        {/* Left Side */}

        {/* Right Side */}
        <div className="col-md-8  pl-md-3">
          <div className="row no-gutters">
            <div className="col mt-2">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label id="project-activity-daily"
                       className={"btn btn-outline-primary " + (preferences.activity_project_schedule === 'daily' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_project_schedule: 'daily'
                         })} />
                  Daily
                </label>

                <label id="project-activity-weekly"
                       className={"btn btn-outline-primary " + (preferences.activity_project_schedule === 'weekly' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_project_schedule: 'weekly'
                         })} />
                  Weekly
                </label>

                <label id="project-activity-mute"
                       className={"btn btn-outline-primary " + (preferences.activity_project_schedule === 'mute' ? 'active': '') }>
                  <input type="radio"
                         onClick={() => updatePreferences({
                           ...preferences,
                           activity_project_schedule: 'mute'
                         })} />
                  Mute
                </label>

              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
      </div>

    </Fragment>
  )
}