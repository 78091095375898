import React, {useState, useRef, Fragment, useEffect} from "react"
import {Status} from "./constants";
import {fetchSession} from "./common_functions";

export function Title({initialTitle, allowEdit, startInEditMode, status, customerId, entityId, entityType}) {
  const [title, setTitle] = useState(initialTitle);
  const [titleIsEditing, setTitleIsEditing] = useState(startInEditMode);
  const titleRef = useRef();
  let initialTitleValue = initialTitle;

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleTitleBlur(event) {
    setTitleIsEditing(false);
    if(entityType === 'task') {
      window.taskIsDirty = true;
    }

    if(title !== initialTitleValue) {
      fetchSession(`/js/customer/${customerId}/${entityType}/${entityId}/set`, {
          method: 'post',
          headers: {
           'Content-type': 'application/json'
          },
          body: JSON.stringify({title: title})
        });
        // TODO error handling
    }
  }

  return titleIsEditing ? (
    <div className="form-group mb-0">
      <input className="form-control form-control-lg mt-1" type="text"
             placeholder="Create a task title"
             autoFocus
             onFocus={() => {setTimeout(() => titleRef.current.select(), 30)}}
             ref={titleRef}
             value={title}
             id="task_title_value"
             onKeyDown={(e) => e.key === 'Enter' && handleTitleBlur(e)}
             onChange={handleTitleChange} onBlur={handleTitleBlur}/>
    </div>
  ) : (
    <h2 className={status === Status.Complete ? 'text-dim' : ''}
        style={{'cursor': 'pointer'}}
        id="task_title_value"
        onClick={() => allowEdit && setTitleIsEditing(true)}>
      {status === Status.Complete ? (
        <span>
          <i className="far fa-check-circle text-success"></i> {title}
          {allowEdit && (
            <i className="fal fa-edit ml-2 c-font-8"></i>
          )}
        </span>
      ) : (
        <div>
          {title}

          {allowEdit && (
            <i className="fal fa-edit ml-2 c-font-8"></i>
          )}
        </div>
      )}
    </h2>
  )
}
