import React, {useState, useRef, Fragment, useEffect} from "react"
import {fetchSession} from "./common_functions";

export function Progress({customerId, entityId, entityType, progress, setProgress, progressIsEditing, setProgressIsEditing,
                    allowEdit, setGoalStatus, setGoalCompletedDate}) {
  const progressNumeratorInput = useRef();
  const progressDenominatorInput = useRef();
  const progressUnitInput = useRef();
  const progressPreviousValue = useRef(progress);


  function handleProgressSave() {
    // try to validate and mark is-invalid
    // Numerator treat empty as a 0 here
    if(progressNumeratorInput.current.value === '') {
      progressNumeratorInput.current.value = '0';
    }

    if(isNaN(progressNumeratorInput.current.value)) {
      progressNumeratorInput.current.classList.add('is-invalid');
      return;
    } else {
      progressNumeratorInput.current.classList.remove('is-invalid');
    }

    if(isNaN(progressDenominatorInput.current.value) || parseInt(progressDenominatorInput.current.value) === 0) {
      progressDenominatorInput.current.classList.add('is-invalid')
      return;
    } else {
      progressDenominatorInput.current.classList.remove('is-invalid')
    }

    const numerator = parseInt(progressNumeratorInput.current.value);
    const denominator = parseInt(progressDenominatorInput.current.value);
    const unit = progressUnitInput.current.value;

    const newValue = {
      numerator: numerator,
      denominator: denominator,
      unit: unit
    }

    fetchSession(`/js/customer/${customerId}/${entityType}/${entityId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({progress: newValue})
    }).then((response) => response.json())
      .then((result) => {
        if(Object.is(result)
           && 'goal_status' in result
           && setGoalStatus !== undefined
           && setGoalCompletedDate !== undefined) {
          setGoalStatus(result.goal_status);
          setGoalCompletedDate(result.goal_completed_dt);
        }
        setProgress(newValue);
        progressPreviousValue.current = newValue;
        setProgressIsEditing(false);
    });
  }

  function handleProgressCancel() {
    progressNumeratorInput.current.value = progressPreviousValue.current.numerator;
    progressDenominatorInput.current.value = progressPreviousValue.current.denominator;
    progressUnitInput.current.value = progressPreviousValue.current.unit;
    setProgressIsEditing(false);
  }

  function handleProgressRemove() {
    fetchSession(`/js/customer/${customerId}/${entityType}/${entityId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({progress: null})
    }).then(() => {
      setProgressIsEditing(false);
      setProgress(null);
      progressPreviousValue.current = null;
    });
  }

  return (
    <Fragment>
      <div className="row mt-3 mb-1">
        <div className="col c-internal-heading">Progress Bar
        </div>
        <div className="col-auto">
          {progressIsEditing && (
            <span className="wistia_embed wistia_async_1grx8ony5z popover=true popoverContent=link email={{ user.email }}">
              <a href="#"><i className="fal fa-question-circle"></i> Learn about progress bars</a>
            </span>
          )}
        </div>
      </div>

      {progressIsEditing ? (
        <Fragment>
          <div className="row align-items-center no-gutters c-hover-indicator-container">
            <div className="col-3">
              <div className="form-group mb-0">
                <input className="form-control" type="text" defaultValue={progress.numerator}
                       id="progress-bar-numerator"
                       autoFocus={true}
                       ref={progressNumeratorInput} />
                <div className="invalid-feedback">
                  Must be a number.
                </div>
              </div>
            </div>

            <div className="col-auto p-2">
              /
            </div>

            <div className="col-3">
              <div className="form-group mb-0">
                <input className="form-control" type="text" defaultValue={progress.denominator}
                       ref={progressDenominatorInput} />
                <div className="invalid-feedback">
                  Must be a non-zero number.
                </div>

              </div>
            </div>

            <div className="col-auto p-2">
              /
            </div>

            <div className="col">
              <div className="form-group mb-0">
                <input className="form-control" type="text" defaultValue={progress.unit}
                       ref={progressUnitInput} />
              </div>
            </div>
          </div>
          <div className="row justify-content-end mt-2">
            <div className="col-auto">
              <button className="btn btn-outline-secondary" onClick={handleProgressCancel}>Cancel</button>
            </div>
            <div className="col-auto pl-0">
              <button className="btn btn-primary" id="save-progress-bar-button" onClick={handleProgressSave}>Save</button>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
        <div className="row align-items-center c-hover-indicator-container">
          <div className="col" onClick={() => allowEdit && setProgressIsEditing(true)}>
            <div className="progress" style={{height: "25px"}}>
              <div className="progress-bar"
                   role="progressbar"
                   style={{width: `${Math.round((progress.numerator / progress.denominator) * 100)}%`}}>
                {/*}
                {`${Math.round((progress.numerator / progress.denominator) * 100)}`}%
                */}

                  {progress.unit === 'Percent' ? (
                    <span className="position-absolute w-100 pr-4">
                      {`${Math.round((progress.numerator / progress.denominator) * 100)}`}%
                    </span>
                  ): (
                    <span className="position-absolute w-100 pr-4">
                      {progress.numerator} / {progress.denominator} {progress.unit}
                    </span>
                  )}
              </div>
            </div>
          </div>

          {allowEdit && (
            <div className="col-auto c-hover-show" onClick={handleProgressRemove}>
              <button className="btn btn-link-edit-danger">
                <i className="fas fa-times"></i>
              </button>
            </div>
          )}
        </div>
        </Fragment>
      )}
    </Fragment>
  )
}
