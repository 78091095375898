import React, {useState, useRef, Fragment, useEffect} from "react"
import {fetchSession, parseDateIgnoringTimezoneGetDate} from "./common_functions";
import parse from "html-react-parser";
import {Description} from "./description";
import flatpickr from "flatpickr";
import {Tags, TagsDropdown} from "./tags";
import {ProjectCustomFields, ProjectCustomFieldsManage} from "./project_custom_fields";
import {Comment} from "./comment";

export function ProjectDetailEditor(props) {

  const [isLoaded, setIsLoaded] = useState(false);

  const [name, setName] = useState(null);
  const [nameIsEditing, setNameIsEditing] = useState(false);


  function setAttribute(attribute, value, callback) {
    fetchSession(`/js/project/${props.customerId}/set`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({[attribute]: value})
    }).then(() => {
      callback();
    });
  }

  function updateName() {
    setAttribute('name', name, () => {
      setNameIsEditing(false);
    })

  }

  const [isTemplate, setIsTemplate] = useState(false);

  const [isActive, setIsActive] = useState(true);

  const [status, setStatus] = useState(null);
  const [statusList, setStatusList] = useState(null);

  const statusCategoryIcons = {
    'Pre-Sale': ['text-secondary', 'fas fa-circle', 'Not Started'],
    'Pre-Deployment': ['text-success', 'fal fa-circle', 'On Track'],
    'Active' : ['text-success', 'fad fa-circle', 'Complete'],
    'Inactive (churned)' : ['text-danger', 'fa fa-circle', 'Abandoned'],
    'Inactive (other)' : ['text-danger', 'far fa-circle', 'Off Track'],
    'Churned' : ['text-danger', 'fa fa-circle']
  }

  const [managerEmail, setManagerEmail] = useState(null);
  const [allUsers, setAllUsers] = useState(null);

  const [description, setDescription] = useState(null);
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const estimatedStartDateInput = useRef();
  const [estimatedStartDate, setEstimatedStartDate] = useState(null);
  const estimatedEndDateInput = useRef();
  const [estimatedEndDate, setEstimatedEndDate] = useState(null);

  const effortList = [
    {'effort': 1, 'label': "Small"},
    {'effort': 3, 'label': "Medium"},
    {'effort': 5, 'label': "Large"},
    {'effort': 7, 'label': "Extra-Large"},
  ];
  const [estimatedEffort, setEstimatedEffort] = useState(null);

  const [tags, setTags] = useState([]);
  const [allAvailableTags, setAllAvailableTags] = useState([]);

  const [externalObjectId, setExternalObjectId] = useState(null);
  const [externalObjectIdIsEditing, setExternalObjectIdIsEditing] = useState(false);

  const [isFreeVersion, setIsFreeVersion] = useState(true);

  const [isDeleting, setIsDeleting] = useState(false);


  const [atCompleteNamesInternal, setAtCompleteNamesInternal] = useState([]);
  const [badgeElement, setBadgeElement] = useState();
  const [commentsInternal, setCommentsInternal] = useState([]);
  const [commentReplyTarget, setCommentReplyTarget] = useState(null);

  const [relatedOrgs, setRelatedOrgs] = useState([]);


  //
  //
  //
  useEffect((didUpdate) => {
    if(isLoaded === false) {
      fetchSession(`/js/project/${props.customerId}`)
        .then(response => {
          return response.json();
        })
        .then(result => {
          setName(result.project_name);
          setIsTemplate(result.is_template);
          setIsActive(result.is_active);

          setStatus(result.project_status_uuid);
          setStatusList(result.project_statuses);

          setAllUsers(result.all_users);

          setManagerEmail(result.manager_email);

          setDescription(result.project_description);

          setEstimatedStartDate(result.project_estimated_start_date);
          setEstimatedEndDate(result.project_estimated_end_date);
          setEstimatedEffort(result.project_estimated_effort);

          setExternalObjectId(result.external_object_id);

          setTags(result.tags);
          setAllAvailableTags(result.all_tags);

          setIsFreeVersion(result.is_free_version);

          setAtCompleteNamesInternal(result.atCompleteNamesInternal);
          setBadgeElement(result.badge_html);
          setCommentsInternal(result.comments_internal);

          setRelatedOrgs(result.related_orgs);

          setIsLoaded(true);
        });
    }

    // estimatedStartDate init flatpickr
    if(isLoaded && !isTemplate) {
      window.estimatedStartDateFlatpickr = flatpickr(estimatedStartDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: estimatedStartDate,
        onChange: (selectedDates, dateStr, instance) => {
          setAttribute("customer_estimated_start_date", dateStr, () => {
            setEstimatedStartDate(selectedDates.length > 0 ? selectedDates[0] : null);
          })
        }
      });

      window.estimatedEndDateFlatpickr = flatpickr(estimatedEndDateInput.current, {
        wrap: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: estimatedEndDate,
        onChange: (selectedDates, dateStr, instance) => {
          setAttribute("customer_estimated_end_date", dateStr, () => {
            setEstimatedEndDate(selectedDates.length > 0 ? selectedDates[0] : null);
          })
        }
      });
    }

  })


  return isLoaded ? (
    <div className="modal-content">
      <div className="modal-header pt-2 pb-1 border-0">
        <h3 className="text-secondary text-center pt-2 pb-2">
          <i className="fal fa-hand-point-right"></i>
          This page is internal and will not be shared with collaborators on this portal.
        </h3>

        <button id="close_react_modal" type="button" className="close c-font-12" data-dismiss="modal">
          <span aria-hidden="true"><i className="fa fa-times"></i></span>
        </button>
      </div>

      <div className="modal-body pt-0 pb-0">
        {/* mobile hambuger */}
        <div className="c-phone-md">
          <nav className="navbar navbar-light pb-0 pt-2">
            <div className="row">
              <div className="col-auto pr-1 pl-1">
              </div>
            </div>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#test-menu">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse border" id="test-menu">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item c-side-menu-heading pt-0 pb-0 pl-2">
                  Add Info Fields
                </li>

                {!description && !isEditingDescription && !isTemplate && (
                <li className="nav-item pt-0 pb-0 pl-4">
                  <a className="nav-link" href="#" onClick={() => setIsEditingDescription(true)}>
                    Description
                  </a>
                </li>
                )}

                {(!externalObjectId && !externalObjectIdIsEditing) && !isTemplate && (
                  <li className="nav-item pt-0 pb-0 pl-4">
                    <a className="nav-link" href="#" onClick={() => setExternalObjectIdIsEditing(true)}>
                      External Object ID
                    </a>
                  </li>
                )}

                <li className="nav-item pt-0 pb-0 pl-4 mb-2">
                <TagsDropdown customerId={props.customerId} tags={tags} setTags={setTags}
                              allAvailableTags={allAvailableTags} />
                </li>

                {/*<li className="nav-item pt-0 pb-0 pl-4">*/}
                {/*  <a href="/settings/tags">Manage Tags</a>*/}
                {/*</li>*/}

                <li className="nav-item dropdown-divider"></li>

                {!isTemplate && (
                <li className="nav-item pt-0 pb-0 pl-4 mb-0">
                  <a className="nav-link"
                     href={`/customer/${props.customerId}/email_log`}>
                    Email Log
                  </a>
                </li>
                )}

                {!isTemplate && (
                <Fragment>
                  <li className="nav-item dropdown-divider"></li>

                  {isDeleting && (
                    <li className="nav-item pt-2 pb-3 pl-4">
                        <a className="nav-link btn-link-dismiss"
                                onClick={() => setIsDeleting(false)}>
                          <i className="fal fa-trash-alt"></i> Cancel Delete Project
                        </a>
                    </li>
                  )}

                  <li className="nav-item pt-2 pb-3 pl-4">
                    {isDeleting ? (
                      <a className="nav-link btn-link-edit-danger"
                              onClick={() => {
                                fetchSession(`/customer/${props.customerId}/delete`, {
                                  method: 'POST'
                                })
                                .then(response => window.location.href = '/')
                              }}>
                        <i className="fal fa-trash-alt"></i> Confirm Delete Project (this cannot be undone)
                      </a>
                    ) : (
                      <a className="nav-link btn-link-edit-danger" onClick={() => setIsDeleting(true)}>
                        <i className="fal fa-trash-alt"></i> Delete Project
                      </a>
                    )}
                  </li>
                </Fragment>
                )}

              </ul>
            </div>
          </nav>
        </div>

        <div className="row d-flex flex-nowrap">
          <div className="col pb-4 pt-0 c-modal-left-side">
            <div className="row mt-0">
              <div className="col">
                <h2 className="c-hover-indicator-container c-internal-title text-break">
                  {nameIsEditing === true ? (
                    <Fragment>
                      <input defaultValue={name} type="text" style={{width: '100%'}}
                             autoFocus={true}
                             onKeyDown={(e) => e.key === 'Enter' && updateName()}
                             onChange={(e) => setName(e.target.value)}
                             onBlur={updateName}/>
                    </Fragment>
                  ) : (
                    <span style={{'cursor': 'pointer'}} id="customer_name_display" onClick={() => !isTemplate && setNameIsEditing(true)}>
                      {name}
                      <i className="fal fa-edit ml-2 c-font-8"></i>
                    </span>
                  )}

                  {props.renderProjectPageLink && (
                   <span className="btn btn-link">
                     {props.renderProjectPageLink}
                     <a href={`/customer/${props.customerId}/shared_portal`}>(Goto Project)</a>
                  </span>
                  )}

                </h2>
              </div>
            </div>

            {relatedOrgs.length > 0 && (
               <div className="row mt-3">
                <div className="col-auto c-internal-heading pr-0">
                  Organization
                </div>
                <div className="col pl-2">
                  {relatedOrgs.map(org => (
                      <a key={org.org_id} className="btn btn-link text-secondary p-0 ml-2"
                         href={`/organization/${org.org_id}`}>
                        {org.name}
                      </a>
                  ))}
                </div>
              </div>
            )}

            {/* project inactive toggle */}
            {!isTemplate && (
            <Fragment>
            <div className="row mt-3">
              <div className="col">
                  <div className={"custom-control custom-switch " + (isActive ? "" : "text-danger")}>
                    <input className="custom-control-input"
                           type="checkbox"
                           id="inactiveSwitchReact"
                           data-customer_name={name}
                           defaultChecked={isActive}
                           onClick={() => setAttribute('customer_active', !isActive, () =>
                           {setIsActive(!isActive);})} />
                    <label className="custom-control-label font-weight-bold c-cursor-pointer"
                           style={{'paddingTop': '2px'}}
                           htmlFor="inactiveSwitchReact">
                      {isActive ? ("Click to Archive") : ("This is Archived")}
                    </label>
                  </div>
              </div>
            </div>

            <div className="row mt-3 border-top">
              <div className="col">
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="row mt-3 mb-1">
                  <div className="col c-internal-heading">Project Manager</div>
                </div>

                <div className="row align-items-center d-flex flex-nowrap no-gutters">
                  <a className="btn c-btn-dropdown-btn-assignee c-btn-dropdown c-btn-override-border-radius"
                       id="behave-customer-manager"
                       href="#"
                       data-toggle="dropdown"
                       aria-haspopup="true"
                       aria-expanded="false">
                    <div className="row align-items-center d-flex flex-nowrap no-gutters">
                      <div className="col-auto">
                        {parse((allUsers.find((s) => s.email === managerEmail)).badge_html)}
                      </div>
                      <div className="col ml-2">
                        <div className="text-truncate c-avatar-name-with-truncate">
                          {allUsers.find((s) => s.email === managerEmail).user_full_name}
                        </div>
                      </div>
                      <div className="col-auto c-btn-dropdown-caret">
                        <i className="fas fa-caret-down"></i>
                      </div>
                    </div>
                  </a>

                  <div className="dropdown-menu dropdown-menu-right">
                    {allUsers.filter(u => u.email !== managerEmail).map(u => (
                      <a key={u.email}
                         className="dropdown-item"
                         data-behave_customer_manager={u.user_full_name}
                         onClick={() => {
                          fetch(`/customer/${props.customerId}/manager_update/${u.user_id}/return_js/true`)
                            .then(respsone => respsone.json())
                            .then(result => {
                              result.traceIds.forEach(traceId => {
                                let el = document.createElement('p');
                                el.setAttribute('data-email_trace_id', traceId);
                                el.innerText = traceId;
                                el.classList.add('d-none');
                                el.classList.add('behave-email-trace-id');
                                document.body.append(el);
                              })
                              setManagerEmail(u.email)
                          });
                         }}>

                        <div className="row align-items-center d-flex flex-nowrap no-gutters">
                          <div className="col-auto">
                            {parse(u.badge_html)}
                          </div>
                          <div className="col ml-2" style={{maxWidth: "300px"}}>
                            <div className="text-truncate">{u.user_full_name}</div>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row mt-3 mb-1">
                  <div className="col c-internal-heading">Project Status</div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="dropdown">
                      <a className="btn c-btn-dropdown"
                         id="behave-customer-status"
                         href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                        <div className="row">
                          <div className="col text-left text-truncate">
                            <i className={statusCategoryIcons[statusList.find((e) => e.uuid === status).category][1]
                                          + " mr-1 "
                                          + statusCategoryIcons[statusList.find((e) => e.uuid === status).category][0]}></i>
                            {statusList.find((e) => e.uuid === status).text}
                          </div>

                          <div className="col-auto pl-0 pr-2">
                            <i className="fas fa-caret-down"></i>
                          </div>
                        </div>
                      </a>

                      <div className="dropdown-menu">
                        {statusList.map((statusOption) => (
                          <a className={"dropdown-item " + (statusOption.uuid === status ? 'active' : '') }
                             key={statusOption.uuid} href="#"
                             data-behave_customer_status={statusOption.text}
                             onClick={() => setAttribute('customer_status', statusOption.uuid,
                                                 () => setStatus(statusOption.uuid))}>
                            <i className={statusCategoryIcons[statusOption.category][1]
                                          + " mr-1 "
                                          + statusCategoryIcons[statusOption.category][0]}></i>
                            {statusOption.text}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {(description || isEditingDescription) && (
            <Description customerId={props.customerId} entityId={props.customerId} entityType='customer'
                         descriptionIsEditing={isEditingDescription} setDescriptionIsEditing={setIsEditingDescription}
                         description={description} setDescription={setDescription} allowEdit={true} />
            )}



            <div className="row mt-3 border-top">
              <div className="col">
              </div>
            </div>


            <div className="row mt-3 mb-2 align-items-center">
              <div className="col c-internal-heading">Custom Fields</div>
              <div className="col-auto">
                <a className="btn btn-link" href="/settings/custom_fields">
                  <i className="fas fa-cog"></i> Settings</a>
              </div>
            </div>


            {/* Enabled on free version for now TODO remove this false if we leave it free */}
            {isFreeVersion && false ? (
              <div className="row align-items-center no-gutters mb-1 pb-1">
                <div className="col">
                  <p>
                    You can customize Coordinate with your own Custom Fields on each project.
                    If you're interested in learning more, <a href="/settings/custom_fields">click here</a>.
                  </p>
                </div>
              </div>
            ) : (
              <ProjectCustomFieldsManage vendorId={props.vendorId} projectId={props.customerId}/>
            )}

            {/*
            <div className="row align-items-center no-gutters border-bottom mb-1 pb-1">
              <div className="col">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Has this client paid?
                  </label>
                </div>
              </div>
            </div>

            <div className="row align-items-center no-gutters border-bottom mb-1 pb-1">
              <div className="col">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    another checkbox
                  </label>
                </div>
              </div>
            </div>

            <div className="row align-items-center no-gutters border-bottom mb-1 pb-1">
              <div className="col-auto">
                <span className="c-text-info">Monthly revenue:</span>
              </div>

              <div className="col ml-2">
                $1000
              </div>
            </div>

            <div className="row align-items-center no-gutters border-bottom mb-1 pb-1">
              <div className="col-auto">
                <span className="c-text-info">Next Steps:</span>
              </div>

              <div className="col ml-2">
                Assign task
              </div>
            </div>

            <div className="row align-items-center no-gutters mb-1 pb-1">
              <div className="col-auto">
                <span className="c-text-info">Next Steps:</span>
              </div>

              <div className="col ml-2">
                <form action="" method="post">
                  <div className="input-group">
                    <input className="form-control" name="" id="" type="text" defaultValue="TODO: click to edit"
                           minLength="1" required />
                  </div>
                </form>
              </div>
            </div>
            */}

            <div className="row mt-3 border-top">
              <div className="col">
              </div>
            </div>

            <div className="row mt-3 align-items-center">
              <div className="col c-internal-heading">Capacity Planning</div>
              <div className="col-auto">
                <a className="btn btn-link" href="/reporting">
                  <i className="fas fa-chart-bar"></i>
                  <span>Reports</span>
                </a>
              </div>
            </div>

            <div className="row">
               <div className="col">
                Fill out this section to track this project via capacity planning
              </div>
            </div>

            <div className="row">
              <div className="col-lg-auto">
                <div className="row mt-3 mb-2">
                  <div className="col c-internal-heading">Estimated Effort</div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="dropdown">
                      <a className="btn c-btn-dropdown" href="#" role="button"
                         data-toggle="dropdown">
                        <div className="row">
                          <div className="col text-left">
                            {estimatedEffort ? (
                              <span>{estimatedEffort.label}</span>
                            ) : (
                              <span>No Estimate</span>
                            )}
                          </div>
                          <div className="col-auto ml-2">
                            <i className="fas fa-caret-down"></i>
                          </div>
                        </div>
                      </a>

                      <div className="dropdown-menu">
                        <a className={'dropdown-item ' + (!estimatedEffort ? 'active': '')}
                           onClick={() => setAttribute('customer_estimated_effort_json', null, () => setEstimatedEffort(null))}
                           href="#">
                          No Estimate
                        </a>

                        {effortList.map(entry => (
                          <a key={entry.effort} onClick={() => setAttribute('customer_estimated_effort_json', entry, () => setEstimatedEffort(entry))}
                             className={'dropdown-item ' + ((estimatedEffort && estimatedEffort.label === entry.label) ? 'active' : '') } href="#">
                            {entry.label} ({entry.effort})
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div className="row mt-3 mb-2">
                  <div className="col c-internal-heading">Start Date</div>
                </div>

                <div className="row flatpickr" ref={estimatedStartDateInput}>
                  <div className="col">
                    <div className="input-group">
                      <input className="form-control c-hover-indicator-container"
                             data-input="" />
                      <div className="input-group-append">
                        <button className="btn c-btn-dropdown-delete-border-left" type="button" data-clear="">
                          <i className="fal fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div className="row mt-3 mb-2">
                  <div className="col c-internal-heading">End Date</div>
                </div>

                <div className="row flatpickr" ref={estimatedEndDateInput}>
                  <div className="col">
                    <div className="input-group">
                      <input className="form-control c-hover-indicator-container" data-input="" />
                      <div className="input-group-append">
                        <button className="btn c-btn-dropdown-delete-border-left" type="button" data-clear="">
                          <i className="fal fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            </Fragment>
            )}

            <Tags projectStyle={true}
                  customerId={props.customerId} tags={tags} setTags={setTags} allAvailableTags={allAvailableTags} />


            {(externalObjectId || externalObjectIdIsEditing) && !isTemplate && (
              <Fragment>
                <div className="row mt-3 border-top">
                  <div className="col">
                  </div>
                </div>

                <div className="row mt-3 mb-2">
                  <div className="col c-internal-heading">External Object ID</div>
                </div>

                <div className="row">
                  <div className="col">
                    {!externalObjectIdIsEditing ? (
                      <div className="row align-items-center no-gutters c-hover-indicator-container"
                           style={{cursor: "pointer"}}
                           onClick={() => setExternalObjectIdIsEditing(true)}>
                        <div className="col">
                          <span>
                            {externalObjectId}
                          </span>
                        </div>

                        <div className="col-auto ml-1 c-hover-show">
                          <button className="btn btn-link-edit-danger">
                            <i className="fas fa-times" onClick={() => {
                              setAttribute('external_object_id', null, () => {
                                setExternalObjectId(null);
                                setExternalObjectIdIsEditing(false);
                              })
                            }}></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="input-group">
                        <input className="form-control" id="externalObjectIdInput" type="text"
                               onBlur={(e) => {
                                 setAttribute('external_object_id', e.target.value, () => {
                                   setExternalObjectId(e.target.value);
                                   setExternalObjectIdIsEditing(false);
                                 });
                               }}
                               autoFocus={true}
                               defaultValue={externalObjectId} minLength="1" required />
                      </div>
                    )}


                  </div>
                </div>
              </Fragment>
            )}

          </div>

          {/* primary right side menu */}
          <div className="col-auto c-sticky-parent c-side-menu c-desktop-md">
            <div className="row mt-2 mb-2">
              <div className="col c-side-menu-heading">
                Add Info Fields
              </div>
            </div>

            {!description && !isEditingDescription && !isTemplate && (
            <div className="row mb-2">
              <div className="col text-center">
                <button type="button" className="btn c-btn-addto" id="projectAddDescription"
                        onClick={() => setIsEditingDescription(true)}>
                  Description
                </button>
              </div>
            </div>
            )}

            {(!externalObjectId && !externalObjectIdIsEditing) && !isTemplate && (
              <div className="row mt-2">
                <div className="col c-hover-indicator-container">
                  <button className="btn c-btn-addto"
                          onClick={() => setExternalObjectIdIsEditing(true)}>
                    External Object ID
                  </button>
                </div>
              </div>
            )}

            <div className="row mt-2 mb-2">
              <div className="col text-center">
                <TagsDropdown customerId={props.customerId} tags={tags} setTags={setTags}
                              allAvailableTags={allAvailableTags} />
              </div>
            </div>

            {/*<div className="row">*/}
            {/*  <div className="col text-left">*/}
            {/*    <a href="/settings/tags">Manage Tags</a>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {!isTemplate && (
            <Fragment>


            <div className="row mt-3 border-top">
              <div className="col"></div>
            </div>

            <div className="row mt-2 mb-2">
              <div className="col c-hover-indicator-container">
                <a className="btn c-btn-addto"
                   href={`/customer/${props.customerId}/email_log`}
                   id="shared_portal_email_log_link">
                  Email Log
                </a>
              </div>
            </div>


            <div className="row mt-2 border-top">
              <div className="col"></div>
            </div>

            {isDeleting && (
              <div className="row mt-3">
                <div className="col c-hover-indicator-container">
                  <button className="btn btn-outline-secondary" style={{width: '150px'}}
                          onClick={() => setIsDeleting(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            )}

            <div className="row mt-3 mb-3">
              <div className="col c-hover-indicator-container">
                {isDeleting ? (
                  <button className="btn btn-danger" style={{width: '150px'}}
                          onClick={() => {
                            fetchSession(`/customer/${props.customerId}/delete`, {
                              method: 'POST'
                            })
                            .then(response => window.location.href = '/')
                          }}>
                    Confirm Delete?
                    <div className="font-italic c-font-8">(this cannot be undone)</div>
                  </button>
                ) : (
                  <button className="btn c-btn-delete c-btn-addto" onClick={() => setIsDeleting(true)}>
                    <i className="far fa-trash-alt"></i> Delete Project
                  </button>
                )}

              </div>
            </div>
            </Fragment>
            )}
          </div>
        </div>

        {!isTemplate && (
        <div className="row border-top pt-3 c-modal-discussion">
          <div className="col mb-3">
            <h5 className="">
              Internal Notes & Discussion
            </h5>
          </div>
          <div className="col-12">
            <Comment key='newComment' projectId={props.customerId} targetEntityId={props.customerId}
                           atCompleteNames={atCompleteNamesInternal} comments={commentsInternal}
                           setComments={setCommentsInternal}
                           isNewComment={true} commentMoveTargets={[]} badgeElement={badgeElement}
                           entityType="customer" internal={true}
                           commentReplyTarget={commentReplyTarget} setCommentReplyTarget={setCommentReplyTarget} />


            {commentsInternal.map(comment => (<Comment key={comment.discussion_entry_id}
                                                       projectId={props.customerId}
                                               targetEntityId={props.customerId} atCompleteNames={atCompleteNamesInternal}
                                               comment={comment} comments={commentsInternal}
                                               setComments={setCommentsInternal}
                                               commentMoveTargets={[]} entityType="customer"
                                               setCommentReplyTarget={setCommentReplyTarget}/> ))}

          </div>
        </div>
        )}
      </div>
    </div>
  ) : (
     <Fragment>
      <div className="modal-header data-behave-click-target">
          <h5 className="modal-title">
          </h5>
          <button id="close_react_modal" type="button" className="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>

      <div className="modal-body p-5 text-center mt-5 mb-5">
        {/* position this modal body roughly the size of a newly created task */}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="spinner-border text-light" role="status" style={{width: '10rem', height: '10rem'}}><span
          className="sr-only">Loading...</span>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </Fragment>
  )
}
