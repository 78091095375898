import React, {useState, useRef, Fragment, useEffect} from "react"
import * as ReactDOMClient from 'react-dom/client';

import {fetchSession, formatDate3, getIsUser} from "./common_functions";
import {Comment} from "./comment";


export function Discussion(props) {

  const [isLoaded, setIsLoaded] = useState(false);
  const [commentLoadLimited, setCommentLoadLimited] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [comments, setComments] = useState([]);
  const [commentReplyTarget, setCommentReplyTarget] = useState(null);
  const [commentMoveTargets, setCommentMoveTargets] = useState([]);
  const [allowUpload, setAllowUpload] = useState(true);

  const [entityId, setEntityId] = useState(null);

  // We do not need a badge for the "click here to comment" area since we are always in edit mode
  const [badgeElement, setBadgeElement] = useState("<span></span>");

  const [atCompleteNames, setAtCompleteNames] = useState([]);

  const chatOutputContainer = useRef();
  const loadLimit = 25

  function handleLoadAll() {
    setIsLoadingMore(true);
    load(null);
  }

  function load(limit) {
    // we have this reloading within the inbox so we need to reload if the entityId changes
    const fetchUrl = (getIsUser() && props.internal)
      ? `/js/customer/${props.customerId}/discussion/${props.entityName}/${props.entityId}/True`
      :  `/js/customer/${props.customerId}/discussion/${props.entityName}/${props.entityId}`;

    fetchSession(fetchUrl, {
        method: 'post',
        headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({limit: limit})
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        setCommentLoadLimited(result.comments.length === loadLimit)
        setIsLoadingMore(false);

        setComments(result.comments);
        setCommentMoveTargets(result.commentMoveTargets);
        setAtCompleteNames(result.atCompleteNames);
        setIsLoaded(true);
        setAllowUpload(result.allow_upload);
        setEntityId(props.entityId);
      });
  }

  useEffect(() => {
    if(!isLoaded || props.entityId !== entityId) {
      load(loadLimit);
    }

    if(chatOutputContainer.current) {
      let scrollTimer = null;
      const resizeObserver = new ResizeObserver((entries) => {
        if(scrollTimer != null) {
          clearTimeout(scrollTimer);
        }
        scrollTimer = setTimeout(() => scrollToBottom(), 20);
      });
      resizeObserver.observe(chatOutputContainer.current);

      setTimeout(() => {
        chatOutputContainer.current.scrollTo(0, chatOutputContainer.current.scrollHeight);
      }, 250)
    }
  });

  function scrollToBottom() {
    // this could be a lot cleaner but for right now just wait 10ms then scroll us

    setTimeout(() => {
      if(chatOutputContainer.current !== null) {
        chatOutputContainer.current.scrollTo(0, chatOutputContainer.current.scrollHeight)
      }
    }, 10);
  }

  function handleTitleClick() {
    // We open the modal for the thing if its a task or goal
    let hidden_pop_button = window.parent.document.querySelector('#hidden_button_for_pop_modal_url');
    if(props.entityName === 'Task') {
       hidden_pop_button.dataset.taskId = props.entityId;
       hidden_pop_button.dataset.customerId = props.customerId;
       hidden_pop_button.dataset.newTask = 'false';
       hidden_pop_button.click();
    }
    if(props.entityName === 'Goal') {
       hidden_pop_button.dataset.goalId = props.entityId;
       hidden_pop_button.dataset.customerId = props.customerId;
       hidden_pop_button.dataset.newGoal = 'false';
       hidden_pop_button.click();
    }
  }

  return (
    isLoaded ? (
      <Fragment>
        <div className="row no-gutters border-bottom align-items-center p-2">
          <div className="col-auto c-phone-md">
            <a className="btn-link p-1" type="button" data-toggle="collapse" data-target="#navbarNav"
               aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <h2 className="c-navbar-icon"><i className="fas fa-bars"></i></h2>
            </a>
          </div>

          <div className="col pl-2">
            <h3 className={['Task', 'Goal'].includes(props.entityName) ? 'c-cursor-pointer c-hover-indicator-container btn-link' : ''}
                onClick={handleTitleClick}>
              {props.entityTitle} {props.internal && (<i>(Internal Notes)</i>)}
              {['Task', 'Goal'].includes(props.entityName)}
            </h3>
          </div>

          {/*Custom message - general ONLY*/}
          <div className="col ml-3 d-none-feature">
            {/*This is the custom message - hide when they are editing the message - form is below*/}
            <span className="">Lorem ipsum dolor sit amet,</span>

            {/*This is the form - hide this when they are done editing*/}
            <div className="input-group mb-0 ">
              <input type="text" className="form-control" placeholder="Enter custom channel message" data-input=""/>
              <div className="input-group-append">
                <button className="btn c-btn-dropdown-delete-border-left"
                        type="button"
                        data-clear="">
                  <i className="fal fa-times"></i>
                </button>
              </div>
            </div>

            <div className="row justify-content-start mt-2 ">
              <div className="col-auto">
                <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal">Save</button>
              </div>
            </div>
          </div>
          {/*Custom message - general ONLY*/}

          <div className="col-auto pl-0 d-none-feature">
            <div className="dropdown c-icon-btn">
              <a id="upper-right-hamburger" className="c-center-element-parent"
                 type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-ellipsis-h c-center-element-child c-safari-fix"></i>
              </a>

              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="">
                <a className="dropdown-item" href="#">
                  Dropdown Options
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="c-chat-output-container-flex mb-2" id="chatOutputContainer" ref={chatOutputContainer}>
          <div className="c-chat-output-baseline-placement-fix">
            {commentLoadLimited && (
                <Fragment>
                  <button onClick={handleLoadAll} className="btn btn-outline-primary">Load older conversations</button>
                {isLoadingMore && (
                  <h3>Loading more...</h3>
                )}
              </Fragment>
            )}
            {Object.entries(
              comments.reduce(
                (memo, val) => {
                  // We are grouping the comments into days, then sorting then formatting the date display to
                  // Today, Yesterday, Monday, January 15
                  let date = new Date(val.discussion_timestamp_dt);
                  date.setHours(0);
                  date.setMinutes(0);
                  date.setSeconds(0);
                  date.setMilliseconds(0);

                  if(date in memo) {
                    memo[date].push(val);
                  } else {
                    memo[date] = [val];
                  }
                  return memo;
                }, {})
              // this sort below has to recast to date since the object coming in casts them to strings....
            ).sort((a, b) => (new Date(a[0])) < (new Date(b[0])) ? -1 : 1)
             .map(a => {
               const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
               const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
               const date = new Date(a[0]);
               const today = new Date();

               let label = a[0] = `${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}`;
               if(today.getDate() === date.getDate()
                  && today.getMonth() === date.getMonth()
                  && today.getFullYear() === date.getFullYear()) {
                 label = 'Today';
               } else if(today.getDate() - 1 === date.getDate()
                 && today.getMonth() === date.getMonth()
                 && today.getFullYear() === date.getFullYear()) {
                 label = 'Yesterday';
               }

               return [
                 label,
                 a[1].sort((a, b) => a.discussion_timestamp_dt < b.discussion_timestamp_dt ? -1 : 1)
               ];
             })
             .map(commentList => (
               <Fragment key={commentList[0]}>
                 <div className="row no-gutters">
                   <div className="col c-date-header">
                       <hr/>
                     <span className="c-date-text">{commentList[0]}</span>
                   </div>
                 </div>
                 {commentList[1].map(comment => (
                        <Comment key={comment.discussion_entry_id} projectId={props.customerId}
                                       targetEntityId={props.entityId} atCompleteNames={atCompleteNames}
                                       comment={comment} comments={comments} setComments={setComments}
                                       commentMoveTargets={commentMoveTargets} entityType={props.entityName}
                                       setCommentReplyTarget={setCommentReplyTarget}
                                       raisedZindex={props.raisedZIndex} allowUpload={allowUpload} />
                 ))}
               </Fragment>
            ))}
          </div>
        </div>

        {/*Adding fixed width to this container to ensure there is no overlap from long reply messages.
        We also need to set a fixed width for the left hand side menu by setting a fixed width of 300px on the
        general messages selection which constrains the whole area. there are media overrides for point break md for
        padding in this area - affected files are comment.js, project_discussion.html and this file*/}
        <div className="c-chat-input-container">
          <Comment key='newComment' projectId={props.customerId} targetEntityId={props.entityId}
                   atCompleteNames={atCompleteNames} comments={comments} setComments={setComments}
                   isNewComment={true} commentMoveTargets={commentMoveTargets} badgeElement={badgeElement}
                   entityType={props.entityName} startAsEditing={true} newCommentPostHook={scrollToBottom}
                   commentReplyTarget={commentReplyTarget} setCommentReplyTarget={setCommentReplyTarget}
                   raisedZindex={props.raisedZIndex} allowUpload={allowUpload} internal={props.internal} />
        </div>
        {/* upload spinner makes the chat larger which scrolls it off the page, just make some space below for now */}
      </Fragment>
      ) : (
        <p>loading ...</p>
      )
  )


}