import React, {useState, useRef, Fragment, useEffect} from "react"
import {DateTime} from "luxon";

import {
  fetchSession,
} from "./common_functions";



export function ChatGPTModal({customerId}) {

  const [prompt, setPrompt] = useState(localStorage.getItem(`gpt_prompt_${customerId}`) === null ? '' : localStorage.getItem(`gpt_prompt_${customerId}`));
  const [groups, setGroups] = useState([]);
  const [resultsPending, setResultsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [template, setTemplate] = useState(null);

  const ignoreDatesCheckbox = useRef();

  const now = DateTime.now(); // Will be used for calculating offsetdays in task display later

  function handlePromptSubmit() {
    if(prompt === '') {
      return;
    }

    setResultsPending(true);
    setErrorMessage(null);

    fetchSession(`/js/customer/${customerId}/prompt_results`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({prompt: prompt})
    })
    .then(response => response.json())
    .then(result => {
      if('error' in result) {
        console.log(result.gptResult);
        setErrorMessage("There was a problem generating the plan, please try again.")
        setResultsPending(false);
        return;
      }

      setTemplate(result);
      setGroups(result.milestones)
      setResultsPending(false);
      setErrorMessage(null);
    })
    .catch(error => {
      setErrorMessage("There was a problem generating the plan, please try again.")
      setResultsPending(false);
    });
  }

  function handleApply() {
    if(groups.length === 0) {
      return;
    }

    setErrorMessage(null);

    fetchSession(`/js/customer/${customerId}/apply_results`, {
      method: 'post',
      headers: {
       'Content-type': 'application/json'
      },
      body: JSON.stringify({template: template, ignoreDates: ignoreDatesCheckbox.current.checked})
    })
    .then(response => response.json())
    .then(result => {
      window.location.reload()
    })
    .catch(error => {
      setErrorMessage("There was a problem applying the plan, please try again.")
    });
  }


  return (
    <Fragment>
      <div className="modal-header pt-2 pb-0 border-0">
        <h5><i className="fal fa-robot"></i> Create Plan With AI</h5>

        <button id="close_react_modal" type="button" className="close c-font-12" data-dismiss="modal">
          <span aria-hidden="true"><i className="fa fa-times"></i></span>
        </button>
      </div>
      <div className="form-group p-3 mb-0 border-bottom">
        {/*{# Clicking inside should expand the input field to be multi-line like in discussion #}*/}
        <textarea className="form-control" rows="5"
                  value={prompt}
                  onChange={(event) => {
                    setPrompt(event.target.value);
                    localStorage.setItem(`gpt_prompt_${customerId}`, event.target.value);
                  }}
                  placeholder="Enter your prompt..."
                  autoFocus>
        </textarea>
        <button className="btn btn-primary float-right mt-2" onClick={handlePromptSubmit}>Generate</button>
      </div>
      <div className="modal-body c-modal-left-side d-none">
        <div className="row mb-3">
          <div className="col">
            <p>
              Instructions here. Something about after you generate the plan, if you are mostly happy with it, create it
              and do minor edits in the plan and shift tasks around.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2 className="mb-3">Example Prompts</h2>

            <p>
              prompt 1, prompt 2, prompt 3
            </p>

            <p>
              prompt 1, prompt 2, prompt 3
            </p>

            <p>
              prompt 1, prompt 2, prompt 3
            </p>
          </div>
        </div>
      </div>
      <div className="modal-body c-modal-left-side d-none">
        <div className="row">
          <div className="col text-center">
            <i className="fad fa-spinner"></i> spinner here
          </div>
        </div>
      </div>
      <div className="modal-body c-modal-left-side">
        {groups.length === 0 && !resultsPending && !errorMessage && (
          <div>
            <p className="mr-auto text-dim">
              Please provide a description above then click generate, your plan will be displayed here!
            </p>
          </div>
        )}

        {resultsPending && (
          <div>
            <p className="mr-auto text-dim">
              <i className="fad fa-hourglass-half"></i>&nbsp;
              Please wait while we generate your plan...
            </p>
            <div className="progress m-1 mt-2"
                   style={{width: '100%', height: '40px'}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                     style={{width: '100%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                  Generating plan...
                </div>
              </div>
          </div>
        )}

        {errorMessage && (
          <div>
            <p className="mr-auto text-danger">
              {errorMessage}
            </p>
          </div>
        )}

        {groups.map((group, index) => (
          <div key={group.milestone_uuid} className={"card mb-3 " +  (index !== 0 ? 'mt-5' : '')}>
            <div className="card-header">
              {/*{# Group Title #}*/}
              <div className="row">
                {/*{# title #}*/}
                <div className="col">
                  [Group] - {group.milestone_title}
                </div>
                {/*{# title #}*/}

                {/*{# optional icons for private group and collaborators have permission to edit #}*/}
                <div className="col-auto d-none-feature">
                  <i className="fas fa-eye-slash" data-toggle="tooltip" data-placement="top" title="Private Group"></i>
                  <i className="fas fa-pen-square c-font-12 mt-1" data-toggle="tooltip" data-placement="top"
                     title="Collaborators have permission to edit"></i>
                </div>
                {/*{# optional icons for private group and collaborators have permission to edit #}*/}
              </div>
              {/*{# Group Title #}*/}
            </div>

            <div className="card-body pb-0">
              {/*{# Group Title #}*/}
              <div className="row mb-3 border-bottom d-none">
                {/*{# title #}*/}
                <div className="col">
                  <p>
                    [Group] - Group Name
                  </p>
                </div>
                {/*{# title #}*/}

                {/*{# optional icons for private group and collaborators have permission to edit #}*/}
                <div className="col-auto">
                  <p>
                    <i className="fas fa-eye-slash" data-toggle="tooltip" data-placement="top" title="Private Group"></i>
                    <i className="fas fa-pen-square c-font-12 mt-1" data-toggle="tooltip" data-placement="top"
                       title="Collaborators have permission to edit"></i>
                  </p>
                </div>
                {/*{# optional icons for private group and collaborators have permission to edit #}*/}
              </div>
              {/*{# Group Title #}*/}

              {group.tasks.map((task, index) => (
                <div key={task.task_uuid}
                     className={'row mb-3 ml-3 ' +  (index !== group.tasks.length - 1 ? 'border-bottom' : '')}>
                  {/*{# Task title #}*/}
                  <div className="col">
                    <p>
                      {task.task_title}
                    </p>
                  </div>
                  {/*{# Task title #}*/}

                  {/*{# optional date #}*/}
                  <div className="col-auto">
                    <p>
                      <i className="fal fa-calendar"></i>&nbsp;
                      {now.plus({days: task.task_due_offset_days}).toFormat("MM/dd/yyyy")}
                    </p>
                  </div>
                  {/*{# optional date #}*/}

                  {/*{# optional description #}*/}
                  <div className="col-12">
                    <p className="">{task.task_description}</p>
                  </div>
                  {/*{# optional description #}*/}
                </div>

              ))}
            </div>
          </div>
        ))}














        {/*{# ungrouped Task section v1 #}*/}
        <div className="row mb-3 border-bottom d-none-feature">
          {/*{# Task title #}*/}
          <div className="col">
            <p>
              Task Name
            </p>
          </div>
          {/*{# Task title #}*/}

          {/*{# optional date #}*/}
          <div className="col-auto">
            <p>
              <i className="fal fa-calendar"></i> 02/13/2024
            </p>
          </div>
          {/*{# optional date #}*/}
        </div>
        {/*{# ungrouped Task section #}*/}

        {/*{# TODO: set up this code if this is the last item in the list for ungrouped Task section #}*/}
        <div className="row mb-3 d-none-feature">
          {/*{# Task title #}*/}
          <div className="col">
            <p>
              Task Name
            </p>
          </div>
          {/*{# Task title #}*/}

          {/*{# optional date #}*/}
          <div className="col-auto">
            <p>
              <i className="fal fa-calendar"></i> 02/13/2024 - 02/20/2024
            </p>
          </div>
          {/*{# optional date #}*/}

          {/*{# optional description #}*/}
          <div className="col-12">
            <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lectus leo, sollicitudin
              sit amet porttitor hendrerit, mollis ut dui. Donec imperdiet rutrum leo. Nunc id eleifend nulla,
              sollicitudin venenatis metus. Nam fringilla a eros eget consequat. Praesent feugiat enim ut massa
              elementum, commodo consectetur libero semper. Duis vel congue nunc, id efficitur diam. Duis ac nisi
              tincidunt, efficitur nisi sit amet, fermentum ipsum. Sed in nibh lacus. Sed a metus quis nulla luctus
              accumsan id vel arcu. Nulla dignissim nunc at tortor sagittis aliquet. Nulla convallis aliquam velit vel
              sodales. Nullam suscipit velit quis nisi pharetra consectetur.</p>
          </div>
          {/*{# optional description #}*/}
        </div>
        {/*{# ungrouped Task section v1 #}*/}

        {/*{# Group section #}*/}
        <div className="card mb-3 mt-5 d-none-feature">
          <div className="card-header">
            {/*{# Group Title #}*/}
            <div className="row">
              {/*{# title #}*/}
              <div className="col">
                [Group] - Group Name
              </div>
              {/*{# title #}*/}

              {/*{# optional icons for private group and collaborators have permission to edit #}*/}
              <div className="col-auto">
                <i className="fas fa-eye-slash" data-toggle="tooltip" data-placement="top" title="Private Group"></i>
                <i className="fas fa-pen-square c-font-12 mt-1" data-toggle="tooltip" data-placement="top"
                   title="Collaborators have permission to edit"></i>
              </div>
              {/*{# optional icons for private group and collaborators have permission to edit #}*/}
            </div>
            {/*{# Group Title #}*/}
          </div>

          <div className="card-body pb-0">
            {/*{# Group Title #}*/}
            <div className="row mb-3 border-bottom d-none">
              {/*{# title #}*/}
              <div className="col">
                <p>
                  [Group] - Group Name
                </p>
              </div>
              {/*{# title #}*/}

              {/*{# optional icons for private group and collaborators have permission to edit #}*/}
              <div className="col-auto">
                <p>
                  <i className="fas fa-eye-slash" data-toggle="tooltip" data-placement="top" title="Private Group"></i>
                  <i className="fas fa-pen-square c-font-12 mt-1" data-toggle="tooltip" data-placement="top"
                     title="Collaborators have permission to edit"></i>
                </p>
              </div>
              {/*{# optional icons for private group and collaborators have permission to edit #}*/}
            </div>
            {/*{# Group Title #}*/}

            {/*{# Grouped Task section #}*/}
            <div className="row mb-3 ml-3 border-bottom">
              {/*{# Task title #}*/}
              <div className="col">
                <p>
                  Task Name
                </p>
              </div>
              {/*{# Task title #}*/}

              {/*{# optional date #}*/}
              <div className="col-auto">
                <p>
                  <i className="fal fa-calendar"></i> 02/13/2024
                </p>
              </div>
              {/*{# optional date #}*/}

              {/*{# optional description #}*/}
              <div className="col-12">
                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lectus leo,
                  sollicitudin sit amet porttitor hendrerit, mollis ut dui. Donec imperdiet rutrum leo. Nunc id eleifend
                  nulla, sollicitudin venenatis metus. Nam fringilla a eros eget consequat. Praesent feugiat enim ut
                  massa elementum, commodo consectetur libero semper. Duis vel congue nunc, id efficitur diam. Duis ac
                  nisi tincidunt, efficitur nisi sit amet, fermentum ipsum. Sed in nibh lacus. Sed a metus quis nulla
                  luctus accumsan id vel arcu. Nulla dignissim nunc at tortor sagittis aliquet. Nulla convallis aliquam
                  velit vel sodales. Nullam suscipit velit quis nisi pharetra consectetur.</p>
              </div>
              {/*{# optional description #}*/}
            </div>
            {/*{# Grouped Task section #}*/}

            {/*{# Grouped Task section #}*/}
            <div className="row mb-3 ml-3 border-bottom">
              {/*{# Task title #}*/}
              <div className="col">
                <p>
                  Task Name
                </p>
              </div>
              {/*{# Task title #}*/}

              {/*{# optional date #}*/}
              <div className="col-auto d-none">
                <p>
                  <i className="fal fa-calendar"></i> 02/13/2024
                </p>
              </div>
              {/*{# optional date #}*/}
            </div>
            {/*{# Grouped Task section #}*/}

            {/*{# Grouped Task section #}*/}
            <div className="row mb-3 ml-3 border-bottom">
              {/*{# Task title #}*/}
              <div className="col">
                <p>
                  Task Name
                </p>
              </div>
              {/*{# Task title #}*/}

              {/*{# optional date #}*/}
              <div className="col-auto">
                <p>
                  <i className="fal fa-calendar"></i> 02/13/2024 - 02/20/2024
                </p>
              </div>
              {/*{# optional date #}*/}
            </div>
            {/*{# Grouped Task section #}*/}

            {/*{# TODO: set up this code if this is the last item in the list for Grouped Task section #}*/}
            <div className="row mb-0 ml-3">
              {/*{# Task title #}*/}
              <div className="col">
                <p>
                  Task Name
                </p>
              </div>
              {/*{# Task title #}*/}

              {/*{# optional date #}*/}
              <div className="col-auto">
                <p>
                  <i className="fal fa-calendar"></i> 02/13/2024
                </p>
              </div>
              {/*{# optional date #}*/}
            </div>
            {/*{# Grouped Task section #}*/}
          </div>
        </div>
        {/*{# Group section #}*/}


        {/*{# Goal section #}*/}
        <div className="card mb-3 mt-5 d-none-feature">
          <div className="card-header">
            [Goals]
          </div>

          <div className="card-body pb-0">
            {/*{# Goal heading #}*/}
            <div className="row mb-3 border-bottom d-none">
              {/*{# title #}*/}
              <div className="col">
                <p>
                  [Goals]
                </p>
              </div>
              {/*{# title #}*/}
            </div>
            {/*{# Goal heading #}*/}

            {/*{# Goal #}*/}
            <div className="row mb-3 border-bottom">
              {/*{# Goal title #}*/}
              <div className="col">
                <p>
                  Goal Name
                </p>
              </div>
              {/*{# Goal title #}*/}

              {/*{# optional description #}*/}
              <div className="col-12">
                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lectus leo,
                  sollicitudin sit amet porttitor hendrerit, mollis ut dui. Donec imperdiet rutrum leo. Nunc id eleifend
                  nulla, sollicitudin venenatis metus. Nam fringilla a eros eget consequat. Praesent feugiat enim ut
                  massa elementum, commodo consectetur libero semper. Duis vel congue nunc, id efficitur diam. Duis ac
                  nisi tincidunt, efficitur nisi sit amet, fermentum ipsum. Sed in nibh lacus. Sed a metus quis nulla
                  luctus accumsan id vel arcu. Nulla dignissim nunc at tortor sagittis aliquet. Nulla convallis aliquam
                  velit vel sodales. Nullam suscipit velit quis nisi pharetra consectetur.</p>
              </div>
              {/*{# optional description #}*/}
            </div>
            {/*{# Goal #}*/}

            {/*{# TODO: set up this code if this is the last item in the list for Goal section #}*/}
            {/*{# Goal #}*/}
            <div className="row mb-0">
              {/*{# Goal title #}*/}
              <div className="col">
                <p>
                  Goal Name
                </p>
              </div>
              {/*{# Goal title #}*/}

              {/*{# optional description #}*/}
              <div className="col-12 d-none">
                <p className="">description</p>
              </div>
              {/*{# optional description #}*/}
            </div>
            {/*{# Goal #}*/}
          </div>
        </div>
        {/*{# Goal section #}*/}

      </div>
      <div className="modal-footer d-none">
        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" className="btn btn-primary">Generate Plan</button>
      </div>

      <div className="modal-footer">
        <input type="checkbox" ref={ignoreDatesCheckbox} />
        <span>Do not import dates</span>
        <button type="button" className="btn btn-danger mr-auto d-none-feature" data-dismiss="modal">Start Over</button>
        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" className="btn btn-primary" disabled={groups.length === 0} onClick={handleApply}>Save Plan</button>
      </div>


    </Fragment>
  )
}