import flatpickr from "flatpickr";
import {Status} from "./constants";

let userStakeholderId = null;
let isUser = null;

// TODO refactor these into props instead of global
export function setIsUser(_isUser) { isUser = _isUser }
export function getIsUser() { return isUser }
export function setUserStakeholderId(_userStakeholderId) { userStakeholderId = _userStakeholderId }
export function getUserStakeholderId() { return userStakeholderId }

export function fetchSession(input, init) {

  input = `${input}?isUser=${isUser}&userStakeholderId=${userStakeholderId}`;
  return fetch(input, init);
}

export function parseDateIgnoringTimezone(dateString) {
  let date = parseDateIgnoringTimezoneGetDate(dateString)

  return parseDateObjectIgnoringTimezoneGetIsoString(date);
}

export function parseDateObjectIgnoringTimezoneGetIsoString(date) {
  // month is zero based!! date is the day of the month, get day is like day of week i guess
  // we need the padding to be able to reconvert this string into another format later
  return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}

export function parseDateIgnoringTimezoneGetDate(dateString) {
  if(dateString == null) {
    return '';
  }

  if(typeof(dateString) === 'object') {
    return dateString
  }

  let threeLetterCode = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
  if(dateString.includes(threeLetterCode)) {
    return new Date(dateString);
  } else {
    return new Date(dateString.replace('GMT', threeLetterCode));
  }
}

export function formatDate3(dateString) {
  if(dateString instanceof Date) {
    return flatpickr.formatDate(dateString, "Y-m-d")
  }

  return flatpickr.formatDate(parseDateIgnoringTimezoneGetDate(dateString), "Y-m-d")
}

export function formatDate(dateString) {
  if(dateString instanceof Date) {
    return flatpickr.formatDate(dateString, "F j, Y")
  }

  return flatpickr.formatDate(flatpickr.parseDate(dateString), "F j, Y");
}

export function formatDateShort(dateString) {
  if(dateString instanceof Date) {
    return flatpickr.formatDate(dateString, "m/d/Y")
  }

  return flatpickr.formatDate(parseDateIgnoringTimezoneGetDate(dateString), "m/d/Y")
}


export function dateIsBeforeToday(first) {
  let second = new Date();
  if(first === null || second === null) {
    return false;
  }

  if(typeof(first) === 'string') {
    first = parseDateIgnoringTimezoneGetDate(first);
  }

  return first < second;
}

const statusRender = {
  [Status.NotStarted]: {text: 'Not Started', style: '', buttonStyle: ''},
  [Status.Information]: {text: 'Information Only', style: '', buttonStyle: ''},
  [Status.InProgress]: {text: 'In Progress', style: '', buttonStyle: ''},
  [Status.DependencyWait]: {text: 'Wait on Dependencies', style: '', buttonStyle: ''},
  [Status.OnTrack]: {text: 'On Track', style: '', buttonStyle: ''},
  [Status.Blocked]: {text: 'Blocked', style: '', buttonStyle: 'outline-danger'},
  [Status.OffTrack]: {text: 'Off Track', style: '', buttonStyle: 'outline-warning'},
  [Status.Complete]: {text: 'Complete', style: 'text-muted', buttonStyle: ''}
}

export function getStatusNameAndStyle(status, label=null, labels=null) {
  let val = statusRender[status];

  if(label && labels.find(e => e.uuid === label)) {
    return {
      text: labels.find(e => e.uuid === label).label,
      style: val.style,
      buttonStyle: val.buttonStyle
    }
  }
  return val;
}



//
//
export function handleAttachFileChange(files, attachments, setAttachments, customerId, entityId, entityType,
                                       setUploadInProgress, setUploadProgress, discussion_entry_id=null,
                                       newAttachmentCallback=null) {
  setUploadInProgress(true);
  setUploadProgress([]);

  Promise.all([...files].map(file =>
    // for each file go grab an ephemeral upload link
    fetchSession(`/get_upload_permission_object/${customerId}`, {method: 'post'})
      .then(response => response.json())
      .then(result => {
        return new Promise((resolve, reject) => {
          const fileUid = result.fields.key.split('_')[2]
          // now send it to S3, when we succeed let the backend know
          const formData = new FormData()
          for (const key in result.fields) {
            formData.append(key, result.fields[key]);
          }
          formData.append('file', file);

          // setup the progress entry
          setUploadProgress(progress => {
            progress.push({
              fileUid: fileUid,
              name: file.name,
              progress: 0,
              done: false
            })
            return [...progress];
          })

          const xhr = new XMLHttpRequest();

          /* We don't need a load start i think
          xhr.upload.addEventListener('loadstart', (event) => {
            console.log(event);
          });
           */

          xhr.upload.addEventListener('progress', (event) => {
            setUploadProgress(progress => {
              progress.find(entry => entry.fileUid === fileUid).progress = (event.loaded / event.total) * 100;
              return [...progress]
            })
          })

          xhr.upload.addEventListener('loadend', (event) => {
            setUploadProgress(progress => {
              progress.find(entry => entry.fileUid === fileUid).done = true;
              // determine if everyone is done
              if(progress.filter(e => !e.done).length === 0) {
                setUploadInProgress(false);
              }
              return [...progress]
            })
          })

          function errorAction(event) {
            // TODO some error message?
            setUploadInProgress(false);
            reject({
              status: xhr.status,
              statusText: xhr.statusText
            })
          }

          xhr.upload.addEventListener('error', errorAction)
          xhr.upload.addEventListener('abort', errorAction)
          xhr.upload.addEventListener('timeout', errorAction)

          xhr.onload = (event) => {
            if(xhr.readyState === 4 && xhr.status === 204) {
              const capType = entityType === 'task' ? 'Task' : 'Goal';
              // Upload is complete, track the upload against the backend
              const fetchUrl = discussion_entry_id ?
                `/js/customer/${customerId}/attach_ephemeral/entity/${entityId}/entity_type/${capType}/discussion_entry_id/${discussion_entry_id}`
                : `/js/customer/${customerId}/attach_ephemeral/entity/${entityId}/entity_type/${capType}`;

              fetchSession(fetchUrl, {
                method: 'post',
                headers: {
                  'Content-type': 'application/json'
                },
                body: JSON.stringify({
                  'name': file.name,
                  'size': file.size,
                  'type': file.type,
                  'file_uid': fileUid,
                })
              }).then(response => response.json())
                .then(result => {
                  if(setAttachments) { // We won't have this in the comment case
                    setAttachments(attachments => ([...attachments, ...result]));
                  }

                  if(newAttachmentCallback) {
                    newAttachmentCallback(result);
                  }

                  resolve(result);

                })
            }
          }

          xhr.open('post', result.url)
          xhr.send(formData);
        });
      })
  )).then((results) => {
    const capType = entityType === 'task' ? 'Task' : 'Goal';
    const fetchCompleteUrl = discussion_entry_id ?
      `/js/customer/${customerId}/attach_ephemeral_complete/entity/${entityId}/entity_type/${capType}/discussion_entry_id/${discussion_entry_id}`
      : `/js/customer/${customerId}/attach_ephemeral_complete/entity/${entityId}/entity_type/${capType}`;
    fetchSession(fetchCompleteUrl, {method: 'post'}).then(() => {
    })
  })


}

export function isMobile() {
  // We use this to rip out padding for the froala editor so a user can type with the mobile keyboard covering half
  // the screen
  return /Mobile|Android/.test(navigator.userAgent);
}
